#wrapper {
	.search-header {
		font-size: 20px;
		span {
			color: #666;
		}
	}
	&.pt_product-search-result {
		#main {
			padding: 0 15px;
			box-sizing: border-box;
			.slot-grid-header {
				display: none;
			}
		}
		.refinement-header,
		.refinement-header-mobile,
		#secondary {
			display: none;
		}
		#primary {
			width: 100%;
			.search-result-content {
				margin: 0 auto 1em;
				#quickviewbutton {
					display: none;
				}
			}
		}
		.search-result-options {
			max-width: 970px;
			margin: auto;
			margin-bottom: 0.6667rem;
			.sort-by {
				float: right;
				@media screen and (min-width: 1000px) {
					margin-right: 0;
				}
				#grid-sort-header {
					background-position: right center;
					width: 112px;
				}
			}
		}
	}
}

@import "search_results_content";