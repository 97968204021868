///////////////////////
/////Order Confirmation
///////////////////////
#wrapper.pt_order-confirmation,
.pt_order-legacy {
    h1 {
        @include font-size(24px);
        color: $gray-dark;
        margin-bottom: rem(10px);

        @include respond(small) {
            @include font-size(26px);
            margin-bottom: rem(15px);
        }
    }

    .confirmation-message {
        @include font-size(14px);
        padding: 0;
        margin: rem(16px) 0 rem(20px) 0;
        border: 0;
        min-height: 1em;

        @include respond(small) {
            @include font-size(12px);
            @include line-height(16px);
        }
    }

    button.print-page{
        @extend .button-text;
        @include font-regular(12px);
        text-decoration: underline;
        position: relative;
        right: auto;
        margin-top: 0;
        float: none;

        @include respond(small) {
            display: none;
        }
        @include respond(print) {
            display: none;
        }
    }

    .order-information {
        div {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;

            span {
                @include font-size(12px);

                &.label{
                    @include font-bold();
                }
            }
            &.order-status {
                display: none;
            }
        }
    }

    .order-information,
    .confirmationregister {
        display: inline-block;
        //border-bottom: 1px solid $gray-light;
        padding-bottom: rem(34px);
        width: 100%;

        @include respond(small) {
            padding-bottom: rem(27px);
        }

        .login-box-content {
            .hidden {
                visibility: hidden;
                height: 0px;

                .form-row {
                    padding: 0;
                    margin: 0;
                    height: 0px;
                    display: inline-block;
                }
            }

            .confirm-login-btn {
                width: 340px;

                @include respond(small) {
                    width: 100%;
                    margin-left: 0;
                }

                button {
                    float: left;
                }
            }

            .form-row{
                position: relative;

                label{
                    .required-indicator{
                        display: none;
                    }
                }
                &.email-line{
                    .field-wrapper{
                        margin-bottom: 1px;
                    }
                    .email{
                        margin-top: 8px;
                        @include respond(small) {
                            margin-top: 0;
                        }
                    }
                    label{
                        margin-bottom: 2px;
                    }
                }
                &.required{
                    label{
                        span{
                            padding-right: 5px;
                            &::after{
                                content: " *";
                                color: $gray-dark;
                            }
                        }
                    }
                }
                .form-caption{
                    margin: 5px 0 0;
                    font-size: 11px;
                    color: $gray-warm;
                    @include respond(small) {
                        margin-left: 0;
                        display: none;
                    }
                }

                .field-wrapper{
                    float: left;
                    width: 82%;
                    padding-left: 0;
                    margin-bottom: 5px;
                    @include respond(small) {
                        width: 100%;
                    }
                    input[type="password"],
                    input[type="text"]{
                        width: 340px;
                        @include respond(small) {
                            width: 100%;
                        }
                    }
                    span{
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .content-header {
        @include font-size(20px);
        font-family: $oswald;
        font-weight: 700;
        font-style: normal;
        @include line-height(20px);
        padding-bottom: rem(14px);

        @include respond(small) {
            @include font-size(20px);
        }
    }

    .confirmationregister{
        padding: 20px 0;
        @include font-size(14px);
        @include respond(small) {
            padding-bottom: 30px;
        }
    }
    .order-confirmation-details{
        h2{
            margin-top: 36px;
            @include respond(small) {
                margin: 15px 0 14px;
            }
        }
        div.content-header{
            @include respond(small) {
                margin: 16px 0 0 ;
            }
        }
        table.item-list{
            border-bottom: 1px solid $gray-light;

            tr{
                border: 0;
                .section-header{
                    padding: 10px 0;
                    @include font-size(14px);
                    color: $gray-dark;
                    background: none;
                    @include respond(small) {
                        display: none;
                    }
                }

                td {
                    @include font-size(12px);
                    @include line-height(22px);
                    padding: 0 10px 0 0;

                    &.order-payment-instruments{
                        .payment-amount{
                            display: none;
                        }
                    }

                    .mobile-visible {
                        display: none;

                        @include respond(small) {
                            display: block;
                            padding: 0;
                            @include font-bold(12px);
                            @include line-height(22px);
                        }
                    }
                    @include respond(small) {
                        width: 100% ;
                        float: left;
                    }

                    &:last-child {
                        padding: 0 50px 0 0;
                        width: 30%;

                        @include respond(small) {
                            width: 100%;
                            max-width: rem(375px);
                        }
                    }
                    address{
                        font-style: normal;
                    }
                    &.order-information{
                        display: none;
                    }
                    &.order-payment-summary{
                        @include respond(small) {
                            padding-bottom: 10px;
                            .section-header.mobile-visible{
                                padding-bottom: 15px;
                            }

                        }
                        .order-detail-summary{
                            .order-totals-table{

                                tr{
                                    border: 0;

                                    td {
                                        padding: 0 0 11px 0;
                                        @include font-size(12px);
                                        //@include line-height(22px);
                                        line-height: 100%;

                                        &:first-child {
                                            @include font-bold();
                                            text-align: left;
                                        }

                                        @include respond(small) {
                                            width: auto ;
                                            float: none;
                                        }
                                    }
                                    &.order-shipping-discount,
                                    &.order-discount{
                                        td{
                                            color: $red;
                                        }
                                    }
                                    &.order-sales-tax{
                                        td{
                                            padding-bottom: 19px;
                                        }
                                    }
                                    &.order-total{
                                        td{
                                            @include font-size(16px);
                                            border-top: 1px solid $gray-light;
                                            padding: 16px 0 20px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child{
                    td{
                        padding-bottom: 20px;
                        @include respond(small) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        table.order-shipment-table{
            &:last-child{
                tr:last-child{
                    td{
                        border-bottom: 0;
                    }
                }
            }
            &.table-2,
            &.table-3{
                tr{
                    &.desktop-visible{
                        display: none;
                    }
                    td{
                        border-bottom: 0;
                    }
                }
            }

            tr{
                &.row-cancel {
                    position:relative;
                }
                &.desktop-visible{
                    @include respond(small) {
                        display: none;
                    }
                }
                th{
                    padding: 5px 20px;
                    background: $gray-white;
                    &:nth-child(2){
                        text-align: center;
                    }
                    &:nth-child(3){
                        text-align: left;
                        padding: 14px 0 14px 30px;
                    }
                    &:nth-child(4){
                        text-align: right;
                        padding-left: 0
                    }
                    @include respond(small) {
                        display: none;
                    }
                }
                td {
                    padding: 14px 0 23px;
                    text-transform: capitalize;
                    color: $gray-dark;
                    border-bottom: 1px solid $gray-light;

                    @include respond(small) {
                        width: 58%;
                        float: right;
                        border-bottom: 0;
                        text-align: left;
                        padding: 0;
                    }

                    .label {
                        @include font-bold(12px);

                        &.grouplabel {
                            display: inline-block;
                            margin-top: 1em;
                        }
                    }

                    .value {
                        @include font-regular(12px);
                    }

                    .mobile-visible{
                        display: none;
                        @include respond(small) {
                            display:inline-block;
                            font-size: 11px;
                            @include font-bold();
                            color: $gray-dark;
                            text-transform: uppercase;
                        }
                    }
                    &.order-shipment-gift-message-header{
                        padding: 30px 0 0 0;
                        @include font-size(12px);
                        text-transform: uppercase;
                        border-bottom: 0;
                        @include respond(small) {
                            width: 100%
                        }
                    }
                    &.order-shipment-gift-message{
                        @include font-size(12px);
                        color: $gray-warm;
                        padding: 0 0 30px 0;
                        border-bottom: 0;
                        @include respond(small) {
                            width: 100%
                        }
                    }
                    &.line-item-price {
                        p {
                            @include font-size(12px);
                            white-space: nowrap; // Force the upsell price and the + symbol to be inline
                        }
                    }
                    &.line-item-pricetd {
                        @include respond(small) {
                            display: none;
                        }
                    }
                    &.line-item-shippingtd {
                        @include respond(small) {
                            padding-bottom: 20px;
                        }
                    }
                    &.line-item-details{
                        width: 40%;
                        @include respond(small) {
                            width: 100%;
                            padding: 10px 0 0 0;
                        }

                        .item-image {
                            margin-left: 10px;
                        }

                        .logo-order-details {
                            float: right;
                            width: 52%;
                            margin-top: 8px;
                            @include respond(large) {
                                float: left;
                                width: 43%;
                                clear: left;
                            }
                            @include respond(small) {
                                width: 58%;
                            }
                        }
                        .product-list-item {
                            float: right;
                            width: 52%;

                            @include respond(large) {
                                float: left;
                                width: 42%;
                            }

                            @include respond(small) {
                                float: right;
                                width: 58%;
                                .mobile-on {
                                    margin-top: 12px;
                                }
                            }

                            @include respond(print) {
                                float: left;
                                width: 100%;
                            }

                            div.name a {
                                @include font-bold(14px);
                                color: $gray-dark;
                                text-decoration: none;
                            }

                            div.name {
                                padding-bottom: 7px;
                            }

                            .sku{
                                padding-bottom: 10px;
                                span{
                                    @include font-regular(11px);
                                    color: $gray-warm;
                                }
                            }
                            .attribute{
                                padding-bottom: 5px;
                                span{
                                    font-size: 12px;
                                    text-transform: capitalize;
                                    color: $gray-warm;
                                    &.label{
                                        color: $gray-dark;
                                        @include font-bold();
                                    }
                                    .attribute-iron-label {
                                        text-transform: none;
                                    }
                                }
                            }
                            .product-option {
                                padding-bottom: 5px;
                            }
                        }
                        .og {
                            .product-list-item {
                                .show-reorder {
                                    display: none;
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        text-align: center;
                        @include respond(small) {
                            text-align: left;
                            color: $gray-warm;
                            padding-top: 10px;
                        }
                    }
                    &:nth-child(3){
                        text-align: left;
                        padding: 14px 30px;
                        @include respond(small) {
                            padding: 10px 0;
                        }
                    }
                    &:nth-child(4){
                        text-align: right;
                        padding-right: 20px;
                        @include respond(small) {
                            text-align: left;
                            padding-right: 0;
                            padding-top: 10px;
                        }
                    }

                    &.line-item-details{
                        .item-image{
                            @include respond(small) {
                                width: 100px;
                                margin-right: 20px;
                                float: left;
                            }
                            img{
                                margin-right: 10px;
                                width: 123px !important;
                                float: left;
                                @include respond(large) {
                                    width: 90px;
                                    margin-right: 10px;
                                }
                                @include respond(small) {
                                    width: 100%;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    &.order-shipment-details{
                        width: 30%;
                        border-right: 0;
                        border-bottom: 0;
                        @include respond(largeUp) {
                            padding: 25px 20px;
                        }


                        .order-shipment-address{
                            margin-top: -14px;
                            position: relative;
                            @include respond(small) {
                                margin-top: 0;
                            }
                        }
                        &.last{
                            display: none;
                        }
                        @include respond(small) {
                            &.first{
                                display: none;
                            }
                            &.last{
                                display: block;
                                width: 100%;
                                box-sizing: border-box;
                                border-bottom: 2px solid $gray-light;
                                padding-bottom: 20px;

                                .mobile-visible{
                                    @include font-size(12px);
                                    @include font-bold();
                                    margin: 15px 0 5px 0;

                                    &.gift-mobile{
                                        display:block;
                                        .order-shipment-gift-message-header{
                                            @include font-size(12px);
                                        }
                                        .order-shipment-gift-message{
                                            color: $gray-warm;
                                            @include font-size(12px);
                                            text-transform: none;
                                            font-weight: normal;
                                            border-bottom: 1px solid #e6e6e6;
                                            padding-bottom: 20px;
                                        }
                                    }
                                }

                            }
                        }

                        .order-shipment-address {
                            .name {
                                @include font-size(12px);
                                color: $gray-warm;
                                @include line-height(22px);
                                text-transform: none;

                                .phone {
                                    display: none;
                                }
                            }
                        }

                        .shipping-status,
                        .shipping-method,
                        .trackingnumber {
                            .value,
                            .label{
                                @include font-size(12px);
                                color: $gray-dark;
                                @include line-height(22px);
                            }
                            .value{
                                color: $gray-warm;
                            }
                            a {
                                @include font-size(12px);
                                color: $gray-warm;
                            }
                            a:hover {
                                text-decoration: underline;
                            }
                        }
                        .trackingnumber {
                            a {
                                color: $red;
                            }
                            a:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                td,th{
                    &.header-divider{
                        width: 25px;
                        border: 0;
                        @include respond(small) {
                            display: none;
                        }
                    }
                }
                td.header-divider{
                    @include respond(small) {
                        border-bottom: 1px solid $gray-light;
                        display: block;
                        float: left;
                        width: 100%;
                    }
                }
                td.line-item-quantity {
                    position: relative;
                    text-align: center;
                    @include respond(small) {
                        display: none;
                    }
                }
                .product-availability-list {
                    display: block;
                    text-align: left;
                    @include respond(largeUp) {
                        position: absolute;
                        left: 34px;
                        width: 251px;
                    }
                    li.instock-date {
                        line-height: 1.25;
                        @include respond(largeUp) {
                            max-width: 183px;
                        }
                    }
                }
            }
        }
    }

    .actions {
        padding: 20px 0;
        border-top: 1px solid $gray-light;
        width: 100%;
        display: inline-block;

        @include respond(small) {
            border-top: 0;
            padding: 0 0 20px;
        }

        .premiumaccountactive {
            float: left;
            width: 70%;

            @include respond(small) {
                width: 100%;
            }
        }

        .logo-suggest {
            width: 50%;
            margin-top: 2em;
            @include respond(small) {
                width: 100%;
            }
            .suggest-header {
                @include font-size(15px);
                @include font-bold;
                margin-bottom: 1.5em;
            }
            label {
                display: none;
            }
            .field-wrapper {
                width: 100%;
                padding: 0;
            }
        }
    }

    .order-shipment-table {
        .promo-adjustment {
            .pa-quantity {
                display: none;
            }
        }
    }
    .sms-channel-section {
        #errorPlaceHolder {
            color: #E31837;
        }
        .content-header {
            margin-bottom: 27px !important;
        }
        .sms-channel-form {
            padding-bottom: rem(20px);
            .titleist-btn {
                margin-top: rem(8px);
            }
            .form-row {
                input[type="checkbox"] {
                    margin-left: 0;
                    margin-top: 0;
                    margin-right: rem(12px);
                    margin-bottom: 0;
                    vertical-align: top;
                }
                & + .form-row {
                    margin-top: rem(9px);
                }
                &.smschannelphone {
                    max-width: rem(342px);
                    margin-bottom: rem(12px);
                }
                &.checkbox-input {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    label {
                        display: inline-block;
                        font-weight: 400;
                        font-size: rem(12px);
                        line-height: (16px);
                        color: #4C4C4C;
                        position: relative;
                        top: rem(-2px);
                        max-width: rem(332px);
                    }
                }
                &.custom-checkbox {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: rem(2px);
                    input[type="checkbox"] {
                        margin-top: rem(3px);
                    }
                    .field-wrapper {
                        display: flex;
                        align-items: flex-start;
                        .lbl-check {
                            margin-top: 0;
                            font-weight: 400;
                            .signup-lable-text {
                                font-size: rem(12px);
                                line-height: rem(16px);
                                max-width: rem(316px);
                                display: inline-block;
                                p {
                                    font-size: rem(12px);
                                    line-height: rem(16px);
                                }
                            }
                        }
                        input[type="checkbox"] {
                            &.error {
                                & + label {
                                    color: #E31837;
                                }
                                &:checked {
                                    & + label {
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }
                }
                &.sms-notification {
                    margin-top: 0;
                    font-style: italic;
                    max-width: rem(342px);
                    padding-left: rem(25px);
                    color: #989898;
                    box-sizing: border-box;
                    @include respond(largeUp) {
                        max-width: rem(496px);
                    }
                    p {
                        font-size: rem(11px);
                        line-height: rem(14px);
                        margin: 0;
                        @include respond(largeUp) {
                            line-height: rem(16px);
                        }
                        & + p {
                            margin-top: rem(12px);
                        }
                    }
                    a {
                        color: #E31837;
                        font-size: rem(11px);
                        line-height: rem(14px);
                        text-decoration: underline;
                        @include respond(largeUp) {
                            line-height: rem(16px);
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .field-wrapper {
                    span.error ~ span.error {
                        display: none !important;
                    }
                }
            }
        }
        .sms-channel-orderconfirmation-subscribed-content {
            margin-bottom: rem(32px);
        }
        .sms-channel-orderconfirmation-subscribed-text {
            display: flex;
            align-items: center;
            margin-bottom: rem(10px);
            font-size: rem(14px);
            line-height: rem(20px);
            font-weight: 700;
            color: #4C4C4C;
            p {
                margin: 0;
            }
            img {
                margin-right: rem(10px);
            }
        }
        .sms-channel-orderconfirmation-subscribed-actions {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            .titleist-btn {
                margin-left: 0;
                margin-right: rem(24px);
            }
            .titleist-link {
                font-size: rem(14px);
                line-height: rem(20px);
                color: #E31837;
                text-decoration: underline;
                font-weight: 400;
                cursor: pointer;
                margin: rem(4px) 0;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

#wrapper.pt_order {
	#main .order-confirmation-details table.order-shipment-table tr .product-availability-list {
		@include respond(largeUp) {
			width: 202px;
		}
	}
}

#wrapper.pt_order-confirmation {
	#main .order-confirmation-details table.order-shipment-table {
		.gc-to-email .value {
			text-transform: none;
		}
		.order-shipment-details {
			.egc-to-email {
				text-transform: none;
			}
		}
        .line-item-price {
            .price-promotion {
                .price-standard {
                    text-decoration: line-through;
                }

                .price-sales {
                    color: $color-secondary;
                }
            }
        }
        .total-item-price {
            .price-unadjusted {
                display: none;
                text-decoration: line-through;

                .label {
                    display: none;
                }
            }

            .price-adjusted-total {
                .label {
                    display: none;
                }
            }
        }
	}
    &.pt_order-confirmation-sms {
        #primary {
            & > h1 {
                color: $black;
                margin-top: 45px;
                margin-bottom: 6px;
                @include respond(largeUp) {
                    margin-top: 54px;
                }
            }
            .confirmation-message {
                display: none;
            }
            .order-information {
                padding-bottom: 28px;
                div {
                    span {
                        font-size: 14px;
                    }
                }
            }
            .sms-channel-form {
                .smschannelphone.valid {
                    & + .error {
                        display: none;
                    }
                }
            }
        }
    }
}
