#wrapper.pt_checkout {
	#main {
		#primary {
			.checkout-billing {
                > .giftcards {
                    h2 {
                        padding: 20px 0;
                        margin: 0;
                        &.close {
                            padding-bottom: 20px;
                        }
                    }
                }
                .giftcards {
                    .giftcert-container {
                        margin: -13px 0 24px;
						display: flex;
						flex-wrap: wrap;
						position: relative;
                        @include respond(largeUp) {
                            margin: -20px 0 18px;
                        }
                        .gc-messaging {
							width: 100%;
                            margin-top: 14px;
                            letter-spacing: normal;
                        }
						.pin {
							.close-tooltip {
								display: none;
							}
						}
                    }
                }
                .gift-cert-used {
                    display: none;
                }
				#is-CREDIT_CARD,
				#is-PayPal,
				#is-DW_APPLE_PAY {
					margin-top:6px;
				}
				label.cc-label,
				label[for="is-PayPal"] {
					margin-top: 5px;
				}
				label[for="is-DW_APPLE_PAY"] {
					margin-top: -2px;
					padding: 0;
				}
				span.icon-Visa,
				span.icon-Amex,
				span.icon-MasterCard,
				span.icon-Discover {
					img {
						height: 26px;
						margin-left: 10px;
						opacity: .3;
					}
				}
				@extend %titleistFormWidths;

				.fieldset {
				    &.billing-address-static {
                        color: $gray-warm;
                        line-height: 22px;
                        font-size: 12px;
                        padding-bottom: 25px;
                        padding-top: 10px;
                    }
					&.address {
						display: none;

						&.open{
							display: block;
						}
					}

					& > .form-row.form-row-button {
						display: none;
					}

					& > .payment-method-options.form-indent {
						display: flex;
						margin-left: 0;

						> .form-row {
							background: $white;
							border: 1px solid $gray-light;
							margin: 2px 0 0;
							padding: 0.5rem 1rem 0.4rem;

							@include respond(small) {
								padding: 0.5rem 0.5rem 0.3rem;
								width: 33%;
							}

							+ .form-row {
								border-left: none;
							}

							&.selected {
								border-bottom: none;
							}

							label {
								@extend %titleistInlineRadioButtons;
								float: left;
								&[for="is-PayPal"] {
									overflow: visible;
								}
							}

							span.icon {
								background:  url("../images/billing-icon.gif") no-repeat right center;
								width: 230px;
								height: 26px;
								float: left;
								font-size: 0;
								line-height: 0;
								margin: -5px 0 0 10px;

								@include respond(small) {
									width: 180px;
									background-size: 100% auto;
								}
							}

							span.icon-PayPal,
							span.icon-paypal {
								width: 71px;
								height: 28px;
								float: left;
								font-size: 0;
								line-height: 0;
								margin: -4px 0 0 0;
								@include respond(small) {
									width: 71px;
									background-size: 100% auto;
								}
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
				.payment-method {
					border: 1px solid $gray-light;
					border-bottom: none;
					padding: 20px 32px;
					margin-top: -1px;
					&.payment-method-expanded {
						.submit-button-wrapper {
							text-align: right;
						}
						.form-row {
							&.form-row-button {
								display: none;
							}
							&.label-inline.form-indent {
								margin-left: 24%;
								
								@include respond(small) {
									margin-left: 0;
									margin-bottom: 16px;
								}

								input[type="checkbox"] {
									margin-top: 4px;
								}
							}
						}
					}
					@include respond(small) {
						padding: 12px 16px;
						.form-row.form-row-button {
							display: none;
						}
					}
					> span {
						display: inline-block;
						max-width: rem(440px);
						padding: rem(20px) 0;
						@include respond(small) {
							padding: rem(8px) 0;
						}
					}
					.adyen-checkout__threeds2__challenge {
						@include respond(small) {
							margin-left: -10px;
							max-width: 350px;
						}

						.adyen-checkout__iframe--threeDSIframe {
							@include respond(small) {
								max-width: 350px !important;
							}
						}
					}
					.form-row.cvn {
						.field-wrapper {
							width: 29%;
							margin-right: 1em;
						}

						.form-field-tooltip {
							left: auto;
							margin: rem(16px) 0 0 rem(8px);
							position: relative;
						}

						@include respond(small) {
							margin-bottom: rem(11px);

							.field-wrapper {
								width: 100%;
							}

							input {
								width: rem(135px);
								margin-right: 1em;
							}

							.form-field-tooltip {
								display: none;
							}
						}
					}
					button {
						&.adyen-checkout__button {
							@extend .button;
							height: initial;
							width: initial;
							box-shadow: none;
						}
					}
					.adyen-checkout__paypal__button {
						max-width: 260px;
						margin-bottom: 0;
					}
				}
				h2 {
					a {
						color: $black;
						& > span{
							background: url("../images/down-arrow.png") no-repeat scroll right 10px center;
							display: block;
							width: 100%;
							cursor: pointer;
						}
					}
					&.close{
						span{
							background: url("../images/arrow-right.png") no-repeat scroll right 10px center;
						}
					}
					&.secure {
						&::after {
							content: url('../images/secure-tr-icon.gif');
							display: inline-block;
							margin-left: 4px;
							vertical-align: middle;
							opacity: .75;
						}
					}
				}

				// submit order button
				.form-row-button {
					margin: 0 0 40px;
					padding: 0 32px 20px;
					border: 1px solid $gray-light;
					border-top: none;
					box-sizing: border-box;
					width: 100%;

					button {
						@extend %normalCase;
						letter-spacing: $globalLetterSpacing;
					}

                    #submitBillingButton {
                        &.visually-hidden {
                            display: none !important;
                        }
                    }

					@include respond(small) {
						margin-bottom: 20px;
						padding: 12px 16px 20px;
						button {
							width: auto;
						}
					}

					.checkout-privacy-policy {
						float: left;
						@include font-regular(12px);
						@include line-height(20px);
						text-align: left;
						padding: rem(14px) 0;

						a {
							color: $black;
							text-decoration: underline;

							&:hover{
								text-decoration: none;
							}
						}
					}
				}
				.save-my-card-label {
					padding-bottom: 15px;
					
					a {
						color: $black;
						text-decoration: underline;

						&:hover{
							text-decoration: none;
						}
					}

					.og-text {
						display: none;
					}

					&.og-visible {
						.og-text {
							display: inline;
						}
					}
				}
				.dw-apple-pay-button {
					border-radius: 2rem;
					margin-top: 15px;
					min-width: 210px;
				}
				&.address {
					&.required-error {
						.payment-method {
							button.adyen-checkout__button {
								opacity: 0.5;
								pointer-events: none;
							}
							.adyen-checkout__paypal__button {
								opacity: 0.5;
								pointer-events: none;
							}
						}
					}
				}
			}

			.gc-content {
                &.close {
                    display: none;
                }
                margin: 0;
                .form-row {
                    display: inline-block;
                    margin-right: 13px;
                    &.pin {
						.form-field-tooltip {
							white-space: nowrap;
							@include respond(small) {
								left: auto;
								top: -3px;
								width: auto;
								right: rem(-65px);
							}
						}
                        input[type='tel'] {
                            width: 55px;
							@include respond(largeUp) {
								width: 70px;
							}
                        }
                    }
                    &.balance {
                        margin: 0;
                        display: block;
                    }
                    &.giftcert-error {
                        margin: 0;
                        span {
                            display: inline-block;
                            &.message-text {
                                width: 167px;
                                @include respond(largeUp) {
                                    width: 193px;
                                }
                            }
                            &.gc-number {
                                width: 118px;
                                @include respond(largeUp) {
                                    width: 160px;
									margin-right: 16px;
                                }
                            }
                        }
                    }
                    &.redemption {
                        display: block;
                        margin: 0;
                        .gc-message {
                            margin-bottom: 4px;
                            color: $black;
							font-size: 10px;
							line-height: 18px;
							@include respond(largeUp) {
								font-size: 12px;
							}
                            span {
                                display: inline-block;
								margin-right: 10px;
								width: auto;
								@include respond(largeUp) {
									margin-right: 16px;
								}
                            }
                            a {
                                color: $black;
                                text-decoration: underline;
								font-size: 10px;
								@include respond(largeUp) {
									font-size: 12px;
								}
                                &:hover {
                                    @include respond(largeUp) {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        .remaining-order-total {
                            display: none;
                        }
                    }
                    label {
                        display: block;
                        float: none;
                        width: auto;
                    }
                    .field-wrapper {
                        input[type='tel'] {
                            padding: 0 8px;
                            background-color: transparent;
							@include respond(largeUp) {
								padding: 0 16px;
							}
                        }
                        .gift-card-number {
                            width: 174px;
							@include respond(largeUp) {
								width: 190px;
							}
                        }
						.gift-card-number,
						#dwfrm_svsgiftcard_giftCertPin {
							padding: 0.5em 1em !important;
						}
                    }
                    .form-field-tooltip {
                        left: 0;
                        top: 60px;
                        width: 110%;
                        padding: 0;
                        margin-left: 0;
                        font-size: 12px;
                        letter-spacing: normal;
                        a {
                            font-size: 12px;
                        }
						.ui-tooltip-content {
							color: $white !important;
						}
                    }

                }
                .giftcert-apply {
                    display: inline-block;
                    position: relative;
					width: 100%;
					margin-top: -10px;
                    @include respond(largeUp) {
                        top: 42px;
						width: auto;
                    }
                    button {
                        font-size: 12px;
                        height: 32px;
                        line-height: 18px;
                    }
                }
            }
			////////////
			// Checkout Billing Step Coupon code
			///////////

			.coupon-code,
			.giftcards {
				margin: 20px 0;
			}

			.billing-coupon-code {
				position: relative;
				margin-left: 0;

				&.close{
					display: none;
				}

				.content-asset{
					display: none;
				}

				.form-row {
					width: auto;
					margin: 0;

					&.label-above {
						//display: inline-block;
						//width: rem(190px);
						//margin-right: 1em;

						.field-wrapper {
							//width: auto;
							float: left;
							@include respond(largeUp) {
								width: 75%;
							}
						}
					}
				}

				button#add-coupon {
					position: absolute;
					top: 22px;
					right: 0;
				}

				.redemption .success {
					color: initial;
				}

				@include respond(small) {

					.form-row {
						&.label-above {
							display: inline-block;
							width: rem(195px);
							margin-right: 1em;

							.field-wrapper {
								float: none;
							}
						}
					}

					button#add-coupon {
						position: static;
						display: inline-block;
					}
				}
			}

			///////// END COUPON STYLES ///////////

			.form-row {
				&.cc-header {
					margin-bottom: rem(20px);
					label {
						@include font-size(15px);
						font-weight: bold;
						margin-top: rem(4px);
						@include respond(small) {
							width: auto;
						}
					}
				}
				&.year, &.month {
					width: 27%; //rem(120px);
					margin: 0;

					label, .form-caption {
						display: none;
					}
					select {
						width: 100%;
					}
					.field-wrapper{
						width: 100%;
						padding-left: 0;
					}

					@include respond(small) {
						width: rem(135px);
					}
				}
				&.month{
					margin-right: rem(20px);

					@include respond(small) {
						float: left;
						padding-left: 0;
					}
				}
			}
			.form-row.year + .form-row{
				display: none;
			}
			.error-form {
				padding: 0;
				@include font-bold(16px);
				margin-bottom: 2em;

				span {
					color: $white;
					width: 24px;
					height: 24px;
					line-height: 24px;
					font-size: 12px;
					background: #b2595c;
					border-radius: 12px;
					display: inline-block;
					text-align: center;
					margin-right: 6px;
				}
			}
			.form-horizontal .field-wrapper {
				padding-left: 0;
			}
		}

		#secondary {
			.order-component-block {
				.section-header {
					border-bottom: none;
				}
			}
			.checkout-mini-cart .mini-cart-attributes {
				float: left;
			}
		}
		.gc-mini-attributes {
			padding: 0 0 rem(15px);
			> div {
				margin: 6px 0;
			}
		}
		.gc-shipline,
		.gc-mini-attributes {
			letter-spacing: normal;
			font-size: rem(11px);
			.label {
				font-weight: 700;
			}
		}
	}
	&.egc-checkout {
		#main {
			#primary {
				.fieldset {
					&.address {
						display: block;
					}
				}
			}
			#secondary {
				.order-totals-table {
					tr {
						&.order-subtotal,
						&.order-sales-tax,
						&.order-total {
							td {
								&:first-child {
									font-size: 12px;
								}
							}
						}
						&.order-total {
							td {
								&:first-child {
									font-size: 14px;
								}
							}
						}
					}
					.order-shipping {
						display: none;
					}
				}
				.checkout-order-totals {
					border-top: none;
				}
				.checkout-mini-cart {
					.mini-cart-image {
						width: 30%;
					}
					.mini-cart-name,
					.mini-cart-id,
					.mini-cart-pricing {
						width: 70%;
						padding-right: 0;
						float: right;
						font-size: 14px;
						letter-spacing: normal;
					}
					.mini-cart-name {
						font-weight: bold;
						.mini-cart-name-value {
							color: $black;
							margin-bottom: 0;
						}
					}
					.mini-cart-pricing {
						margin-top: 34px;
						.mini-cart-price {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.order-component-block .details {
		margin-top: 1em;
		padding: 0;
		@include font-size(12px);
		background-color: transparent;

		.minishipments-method {
			margin-top: 1em;

			span:first-child {
				@include font-bold();
			}
		}
	}
}

.submit-order {
	text-align: left;

	button {
		margin-left: 0;
	}
}
