
nav.primary.desktop {
	position: absolute;
	top: 0;
	left: 0;
	font-family: $sans-serif;
	font-weight: 400;
	width: 100%;
	z-index: 100;
	margin: 0 auto;
	padding-top: 45px;
	height: 66px;
	&.hide-sticky {
        display: none;
    }
}

nav.primary.desktop .nav-animate-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding-top: 45px;
	background-color: #fff;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	border-bottom: 1px solid #f2f2f2
}

@media (max-width:899px) {
	nav.primary.desktop {
		font-size: .875em
	}
}

@media (max-width:1199px) {
	nav.primary.desktop {
		display: none
	}
}

nav.primary.desktop.is-fixed,
nav.primary.mobile.is-fixed {
	position: fixed;
	top: 1px;
	transform: translateY(-1px);
}
nav.primary.desktop.is-fixed {
    z-index: -1;
}
nav.primary.desktop.is-visible {
    z-index: 100;
}

nav.primary.desktop.is-fixed .nav-animate-container {
	transform: translateY(-100%)
}

nav.primary.desktop.is-animated .nav-animate-container {
	transition: transform .3s ease
}

nav.primary.desktop.is-visible .nav-animate-container {
	transform: translateY(0)
}

nav.primary.desktop .top-content {
	position: absolute;
	top: 12px;
	right: 40px;
	font-size: .75em
}

@media (max-width:899px) {
	nav.primary.desktop .top-content {
		right: 20px
	}
}

nav.primary.desktop .left-content {
	padding-left: 160px
}

@media (max-width:1349px) {
	nav.primary.desktop .left-content {
		padding-left: 4vw
	}
}

@media (max-width:1199px) {
	nav.primary.desktop .left-content {
		padding-left: calc(10vw)
	}
}

@media (max-width:1023px) {
	nav.primary.desktop .left-content {
		padding-left: 40px
	}
}

@media (max-width:899px) {
	nav.primary.desktop .left-content {
		padding-left: 20px
	}
}

nav.primary.desktop ul,
nav.primary.mobile ul {
	height: 55px
}

nav.primary.desktop ul li {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end
}

nav.primary.desktop ul.page-links {
	width: 730px;
	a {
		font-size: $navFontSize;
		position: relative;

		.beta {
			position: absolute;
			color: $red;
			font-size: 8px;
			transform: translate(-5px, -5px);
			text-transform: uppercase;
		}
	}
}

nav.primary.desktop ul.page-links li {
	font-family: $navFontFamily;
	font-weight: 500;
	a {
		font-size: $navFontSize;
	}
}

nav.primary.desktop ul.page-links a:after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 1px;
	background-color: #e31837;
	bottom: -3px;
	transition: all 150ms ease-out
}

nav.primary.desktop ul.page-links a:hover:after,
nav.secondary a:hover:after {
	width: 100%;
	opacity: 1
}

nav.primary.desktop ul.page-links a.is-active:after {
	width: 100%;
	opacity: 1;
	background-color: #000
}

nav.primary.desktop ul.right-content {
	box-sizing: content-box;
	padding-right: 20px;
	height: auto;

	.pt_checkout & {
		width: 75px;
	}

	.titleist-search-box-cta,
	.account-toggle .nav-item,
	.mini-bag-toggle {
		padding: 0 15px 10px 15px;
	}

	.mini-bag-toggle {
		padding-bottom: 21px;
	}
	.mini-bag-content {
		.mini-bag-toggle {
			padding: 0;
		}
	}
	.account-notoggle {
		.nav-item {
			padding-bottom: 10px;
		}
	}

}

nav.primary.desktop ul.right-content .nav-item {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	cursor: pointer;
	&.open-search-overlay {
		cursor: pointer;
		position: relative;
		top: 4px;
	}
}

nav.primary.desktop ul.right-content .nav-item .icon-v2 {
	top: .4em
}

@media (max-width:899px) {
	nav.primary.desktop ul.right-content {
		padding-right: 0;
		width: 150px
	}
}

nav.primary.desktop .account-toggle {
	position: relative
}

nav.primary.desktop .account-toggle>a {
	z-index: 1
}

nav.primary.desktop .account-menu {
	position: absolute;
	right: -2em;
	top: 5em;
	background-color: #fff;
	box-sizing: border-box;
	padding: 1em 1.5em;
	width: 18em;
	font-size: .9em;
	display: none
}

@media (max-width:899px) {
	nav.primary.desktop .account-menu {
		top: 6em
	}
}

nav.primary.desktop .account-menu .icon-arrow-drop-up {
	position: absolute;
	right: 1.25em;
	top: -1.75em;
	width: 3em;
	height: 3em;
	background-color: #fff
}

@media (max-width:899px) {
	nav.primary.desktop .account-menu .icon-arrow-drop-up {
		top: -1.7em
	}
}

nav.primary.desktop .account-menu ul {
	position: relative;
	display: block;
	list-style: none;
	padding: 0;
	margin: 0;
	height: auto;
	width: auto
}

nav.primary.desktop .account-menu li {
	position: relative;
	display: block;
	width: auto;
	height: auto;
	padding: 0;
	margin: 0 0 1em
}

nav.primary.desktop .account-menu li:last-child {
	margin: 0
}

nav.primary.desktop .account-menu li:nth-child(4) {
	padding: 0 2em
}

nav.primary.desktop .account-menu a {
	display: block;
	text-align: center;
	padding: .5em 3em!important
}

nav.primary .mini-bag {
	position: relative
}

nav.primary .mini-bag .nav-item {
	position: relative;
	z-index: 1
}

nav.primary .mini-bag .nav-item .mini-bag-count {
	position: absolute;
	top: 1.25em;
	right: 10px;
	width: 1.4em;
	height: 1.4em;
	font-size: .8em;
	text-align: center;
	border-radius: 50%;
	background-color: #e31837;
	color: #fff;
	pointer-events: none
}

nav.primary .mini-bag .nav-item .mini-bag-count-text {
	position: relative;
	top: .2em;
	font-size: rem(12.8px);
}

.mini-bag-content-overlay {
    display: none;
}

nav.primary .mini-bag-content {
    position: absolute;
    right: -2em;
    top: 5em;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 1em 1.5em;
    width: 28em;
    font-size: 0.9em !important;
    display: none;
}

nav.primary .nav-item.mini-cart-empty + .mini-bag-content {
    .mini-bag-products {
        max-height: calc(100% - 85px);
    }
    .mini-bag-totals {
        min-height: 85px;
    }
    .mini-bag-buttons {
        padding-top: 0;
    }
}

nav.primary {
	&.is-fixed-layout {
		position: fixed;
		top: 1px;
		transform: translateY(-1px);
		.nav-animate-container {
			width: calc(100% - 17px);
			&::after {
				content: '';
				height: 100%;
				width: 17px;
				border-bottom: 1px solid #f2f2f2;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				transform: translateX(100%);
    			box-sizing: content-box;
			}
		}
		&.is-visible {
			.nav-animate-container {
				.mini-bag-content {
					right: -17px;
				}
			}
		}
		.mini-bag-content.cart-show {
			right: 0;
		}
	}

	.mini-bag-content,
	.mini-bag-content-overlay {
		height: calc(100vh - 111px);
		@include respond(largeUp) {
			top: 111px;
		}
        @include respond(small) {
            width: 100%;
        }
	}
	.mini-bag-content-wrapper {
		height: 100%;
	}
	.mini-bag-content {
		display: block !important;
		position: fixed;
		transition: transform .65s;
		z-index: 999;
		transform: translateX(102%);
		padding: 0;
		right: -17px;
		font-family: $sans-serif;
		@include respond(largeUp) {
			min-height: calc(100vh - 111px);
            width: 400px;
			top: 111px;
		}
		.icon-arrow-drop-up {
			display: none;
		}
		.mini-bag-header {
			padding: 1.2em;
    		font-size: 1.3125em;
			color: black;
			font-family: $sans-serif;
		}
		.mini-bag-close {
			height: 19px;
			width: 19px;
			background: url("../images/icons/close-minicart.svg") no-repeat;
			cursor: pointer;
			position: absolute;
			top: 23px;
			right: 18px;
            background-size: cover;
		}
		.mini-bag-product {
			.mini-bag-image .media-wrap {
				margin-top: 10px;
			}
			.mini-bag-product-info {
				padding: 2em 1em 2em 1.5em;
				.mini-bag-pricing {
					padding-top: 20px;
					padding-bottom: 10px;
                    color: $black;
				}
				.mini-bag-name {
					font-weight: 700;
					font-size: 14px;
				}
				.mini-bag-attributes {
					font-size: 12px;
                    .toggle-mini-line {
                        display: none;
                        background-image: url("../images/icons/arrow-down-black.svg");
                    }
                    .promo {
                        font-weight: 700;
                    }
                    &.collapse {
                        max-height: 56px !important;
                        transition: all 0.5s ease-in-out;
                        overflow: hidden;
                        position: relative;
                        .toggle-mini-line {
                            display: block;
                            width: 14px;
                            height: 8px;
                            position: absolute;
                            right: 0;
                            top: 8px;
                            cursor: pointer;
                        }
                        &.opened {
                            max-height: 1000px !important;
                            .toggle-mini-line {
                                transform: rotate(180deg);
                                transition: all linear 300ms;
                            }
                        }
                        .config-attributes {
                            padding-right: 25px;
                        }
                    }
                    .attribute {
                        .label {
                            text-transform: none;
                        }
                    }
				}
			}
		}
		.mini-bag-products {
			box-sizing: border-box;
			height: 100%;
			overflow-y: auto;
			padding: 0 0 0 15px;
			.mini-bag-product {
				&:last-of-type {
					border-bottom: none;
				}
			}
			&.mini-bag-products-empty {
				text-align: center;
				font-weight: 700;
				font-size: 27px;
				line-height: 35px;
				color: black;
                display: flex;
                align-items:center;
                justify-content:center;
			}
		}
		.mini-bag-product-details {
			min-width: 47%;
		}
		.mini-bag-totals {
			position: fixed;
			bottom: 0;
			width: 100%;
			padding: 15px 0;
			background-color: $white;
			border-top: 1px solid #ECECEC;
            color: $black;
			z-index: 1;
			.label,
			.value {
				padding: 0.5em 20px 0;
				font-size: 14px;
			}
			.mini-bag-buttons {
				padding-top: 20px;
                display: flex;
			}
            .minicart-subline {
                font-size: 11px;
                text-align: center;
            }
		}
		&.cart-show {
			transform: translateX(0%);
		}
		.product-availability-list {
			display: block;
			height: initial;
			font-size: 0.9em !important;
			.on-order {
				display: inline;
			}
		}
		.mini-bag-pricing {
			margin-top: 0 !important;
		}
	}
	.mini-bag-content-overlay {
		display: block !important;
		position: fixed;
		transition: opacity .65s;
		z-index: 998;
		width: 100vw;
		left: 0;
		opacity: 0;
		pointer-events: none;
		@include respond(small) {
			display: none !important;
		}
		&.cart-show {
			opacity: 0.95;
    		pointer-events: auto;
		}
	}
	&.is-fixed {
		.mini-bag-content,
		.mini-bag-content-overlay {
			height: 100vh;
		}
		&.is-visible {
			.mini-bag-content,
			.mini-bag-content-overlay {
				height: calc(100vh - 111px);
			}
		}
	}
}

nav.primary .mini-bag-content *,
nav.primary .mini-bag-content :after,
nav.primary .mini-bag-content :before,
nav.search-overlay-content *,
nav.search-overlay-content :after,
nav.search-overlay-content :before {
	box-sizing: inherit
}

@media (max-width:899px) {
	nav.primary .mini-bag-content {
		top: 5em
	}
}

nav.primary .mini-bag-content .padding-wrapper {
	padding: 1em 1.5em
}

nav.primary .mini-bag-content .icon-arrow-drop-up {
	position: absolute;
	right: 1.25em;
	top: -1.75em;
	width: 3em;
	height: 3em;
	background-color: #fff
}

@media (max-width:899px) {
	nav.primary .mini-bag-content .icon-arrow-drop-up {
		top: -1.7em
	}
}

nav.primary .mini-bag-content {
    .label,
    .value {
        font-weight: 700;
    }
	.label {
		text-transform: capitalize;
	}
	.value {
		font-weight: 400;
	}
}

nav.primary .mini-bag-content .mini-bag-header {
	display: block;
	font-size: 1.4em;
	text-align: left;
	border-bottom: solid 2px #f3f3f3
}

nav.primary .mini-bag-content .mini-bag-product {
	position: relative;
	display: flex;
	border-bottom: solid 2px #f3f3f3
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-image {
	width: 30%;
	flex-basis: 30%
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-image .media-wrap {
	width: 100px;
	height: 100px;
    background-color: #f6f6f6;
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-name {
	font-weight: 700;
	font-size: 1.2em;
    margin-bottom: 10px;
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-pricing {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
    .price-sales {
        order: 3;
    }
    .value {
        flex-grow: 1;
        margin-left: 5px;
    }
    &.sales-strike {
        .price-sales {
            color: $red;
            margin-left: 5px;
        }
        .price-standard {
            text-decoration: line-through;
        }
    }
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-pricing .mini-bag-price {
	text-align: right;
	font-weight: 700;
}

.see-all-toggle .see-all,
nav.primary .mini-bag-content .mini-bag-pricing {
	display: block
}

nav.primary .mini-bag-totals .mini-bag-more-count {
	text-align: center;
	padding: .5em 0;
	border-bottom: solid 2px #f3f3f3
}

nav.primary .mini-bag-totals .mini-bag-subtotals {
	display: flex;
	padding: .5em 0;
    &.subtotes-promos {
        padding-top: 0;
        color: $red;
    }
}

nav.primary .mini-bag-totals .mini-bag-subtotals .value {
	flex-grow: 1;
	text-align: right;
	font-weight: 700
}

nav.primary .mini-bag-totals .mini-bag-buttons {
	margin-bottom: 1em;
	line-height: normal
}

nav.primary .mini-bag-totals .mini-bag-buttons a {
    width: 45%;
    display: block;
    line-height: 36px;
    padding: 0;
    margin-top: 20px;
    border: solid 2px $red;
    &.outline {
        color: $red;
        margin-top: 20px;
        &:hover {
            color: $white;
        }
    }
}

nav.primary.desktop img.logo {
	position: relative;
	top: 4px;
	width: 190px;
	height: 55px
}

nav.primary.mobile {
    .account-toggle,
    .account-notoggle {
        position: relative;
    }
}

nav.primary.desktop .account-toggle.is-open .account-menu {
    display: block;
}

nav.primary.desktop .account-toggle.is-open .account-menu label {
    text-transform: capitalize;
}

nav.primary.desktop .account-toggle>a {
    z-index: 1;
}

nav.primary.desktop .account-menu {
    position: absolute;
    right: -2em;
    top: 5em;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 1em 1.5em;
    width: 18em;
    font-size: 0.9em;
    display: none;
}

@media (max-width: 899px) {
    nav.primary.desktop .account-menu {
        top: 6em;
    }
}

nav.primary.desktop .account-menu .icon-arrow-drop-up {
    position: absolute;
    right: 1.25em;
    top: -1.75em;
    width: 3em;
    height: 3em;
    background-color: #fff;
}

@media (max-width: 899px) {
    nav.primary.desktop .account-menu .icon-arrow-drop-up {
        top: -1.7em;
    }
}

nav.primary.desktop .account-menu ul {
    position: relative;
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
}

nav.primary.desktop  .account-menu li {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0 0 1em;
}

nav.primary.desktop .account-menu li:last-child {
    margin: 0;
}

nav.primary.desktop .account-menu li:nth-child(4) {
    padding: 0 2em;
}

nav.primary.desktop .account-menu a {
    display: block;
    text-align: center;
    padding: 0.5em 3em 0.5em 3em !important;
}

nav.primary.desktop .account-menu a.titleist-btn {
    color: #FFFFFF;
}

nav.primary.desktop  .account-menu:not(.logged-off),nav.primary.mobile .account-menu:not(.logged-off) {
    font-family: $sans-serif;
    border-top: 1px solid #e41837;
    width: 20.75rem;
    box-shadow: 0px 0px 8px #dfdfdf inset;
}

nav.primary.desktop .account-menu .icon-arrow-drop-up,nav.primary.mobile .account-menu .icon-arrow-drop-up {
    opacity: 0;
}

nav.primary.desktop .account-menu .input-group,nav.primary.mobile .account-menu .input-group {
    padding-bottom: 0.875rem;
}

nav.primary.desktop .account-menu .parsley-errors-list,nav.primary.mobile .account-menu .parsley-errors-list {
    margin-top: -0.25em !important;
    line-height: 1.3 !important;
}

nav.primary.desktop .account-menu .parsley-errors-list>li,nav.primary.mobile .account-menu .parsley-errors-list>li {
    padding: 0;
    color: #e21837;
    line-height: 1.3 !important;
}

nav.primary.desktop .account-menu .parsley-errors-list+span.error,nav.primary.mobile .account-menu .parsley-errors-list+span.error {
    display: none !important;
}

nav.primary.desktop .account-menu .pwd-field-type,nav.primary.mobile .account-menu .pwd-field-type {
    position: relative;
}

nav.primary.desktop .account-menu .pwd-field-type.show-pwd .fa-eye,nav.primary.mobile .account-menu .pwd-field-type.show-pwd .fa-eye {
    background: url("../../images/icons/SVG/show-password.svg") no-repeat;
}

nav.primary.desktop .account-menu .fa-eye,nav.primary.mobile .account-menu .fa-eye {
    background: url("../../images/icons/SVG/hide-password.svg") no-repeat;
    cursor: pointer;
    width: 35px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 7px;
}

@media screen and (max-width: 901px) {
    nav.primary.desktop .account-menu,nav.primary.mobile .account-menu {
        top: 5em;
    }
}

nav.primary.desktop .account-menu:not(.logged-off):before,nav.primary.mobile .account-menu:not(.logged-off):before {
    content: '';
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 5px;
    top: -6px;
    left: 0;
}

nav.primary.desktop .account-menu h2,nav.primary.mobile .account-menu h2 {
    font-size: 1.5rem;
    margin-bottom: 0.375em;
    margin-top: 0;
}

nav.primary.desktop .account-menu .form-footer a,nav.primary.mobile .account-menu .form-footer a {
    padding: 0 !important;
    margin: 0.5em 0;
    display: inline-block;
    color: #e31837;
    font-size: 0.75rem;
    border-bottom: 1px solid #e31837;
}

nav.primary.desktop .account-menu .form-footer a:hover,nav.primary.desktop .account-menu .form-footer a:focus,nav.primary.mobile .account-menu .form-footer a:hover,nav.primary.mobile .account-menu .form-footer a:focus {
    color: #4C4C4C;
    border-bottom-color: #4C4C4C;
}

nav.primary.desktop .account-menu .form-footer span,nav.primary.mobile .account-menu .form-footer span {
    font-size: 0.75rem;
    margin-top: 0.75em;
    margin-bottom: 0.5em;
}

nav.primary.desktop .account-menu .form-footer button,nav.primary.desktop .account-menu .form-footer input[type="submit"],nav.primary.mobile  .account-menu .form-footer button,nav.primary.mobile .account-menu .form-footer input[type="submit"] {
    margin-bottom: 0.75em !important;
    margin-top: -0.25em !important;
}

nav.primary.mobile .account-toggle {
    position: relative;
}

nav.primary.mobile {
    .account-toggle,
    .account-notoggle {
        .account-menu {
            position: absolute;
            right: -2.19em;
            top: 3em;
            background-color: #fff;
            box-sizing: border-box;
            padding: 1em 1.5em;
            font-size: 0.9em;
            display: none;
        }
    }
}

nav.primary.mobile .account-toggle .account-menu .parsley-errors-list {
    line-height: 1.3 !important;
    height: auto;
}

nav.primary.mobile .account-toggle .account-menu .parsley-errors-list > li {
    padding: 0;
    color: #e21837;
    line-height: 1.3 !important;
}

nav.primary.mobile .account-toggle .account-menu .parsley-errors-list + span.error {
    display: none !important;
}

nav.primary.mobile .account-toggle.is-open .account-menu {
    display: block;
}

nav.primary.desktop .account-toggle .account-menu.logged-in,nav.primary.mobile .account-toggle .account-menu.logged-in {
    width: 16rem;
    padding: 6px 6px 6px;
    border-top: none;
    top: 4.5em;
}

nav.primary.desktop .account-toggle .account-menu.logged-in:before,nav.primary.mobile .account-toggle .account-menu.logged-in:before {
    background-color: transparent;
    height: 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div {
    background-color: #000;
    color: #fff;
    padding: 12px 14px;
    box-shadow: 0 6px 2px -2px #dfdfdf
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div div,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div div {
    padding: 10px 0;
    font-size: 0.75rem;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div p,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div p {
    color: #fff
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div a,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div a {
    color: #fff;
    text-align: left;
    padding: 0 !important;
    font-size: 0.75rem;
    text-decoration: underline;
    margin: 10px 0 8px 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div a:hover,nav.primary.desktop .account-toggle .account-menu.logged-in>div>div a:active,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div a:hover,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div a:active {
    filter: brightness(85%);
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div p,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div p {
    line-height: 1.5;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div p:first-of-type,nav.primary.mobile .account-toggle .account-menu.logged-in>div>div p:first-of-type {
    margin: 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>div p:not(:first-of-type),nav.primary.mobile .account-toggle .account-menu.logged-in>div>div p:not(:first-of-type) {
    font-size: 0.75rem;
    padding-right: 26px;
    color: #cdcdcd;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>ul,nav.primary.mobile .account-toggle .account-menu.logged-in>div>ul {
    padding-top: 12px;
    display: block;
    height: auto;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>ul li,nav.primary.mobile .account-toggle .account-menu.logged-in>div>ul li {
    border-top: 1px solid #F6F6F6;
    padding: .7em 1.5em;
    margin: 0;
    font-size: 0.875rem;
    font-family: $sans-serif;
    color: #000;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>ul li:first-child,nav.primary.mobile .account-toggle .account-menu.logged-in>div>ul li:first-child {
    border-top: none;
}

nav.primary.desktop .account-toggle .account-menu.logged-in>div>ul li a,nav.primary.mobile .account-toggle .account-menu.logged-in>div>ul li a {
    padding: 0 !important;
    text-align: left;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .status--progress,nav.primary.mobile .account-toggle .account-menu.logged-in .status--progress {
    overflow: hidden;
    position: relative;
    background: #363636;
    padding: 4px;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .status--progress__col,nav.primary.mobile .account-toggle .account-menu.logged-in .status--progress__col {
    height: 12px;
    line-height: 1.1;
    float: left;
    width: calc(100% - 40px);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 12px;
    padding: 0;
    background: #cdcdcd;
    background: linear-gradient(180deg, #cdcdcd 0%, #b8b8b8 50%, #a8a8a8 100%)
}

nav.primary.desktop .account-toggle .account-menu.logged-in .status--progress__col-last,nav.primary.mobile .account-toggle .account-menu.logged-in .status--progress__col-last {
    float: right;
    width: 40px;
    text-align: right;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.1;
    height: 12px;
    padding: 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .status--score,nav.primary.mobile .account-toggle .account-menu.logged-in .status--score {
    color: #cdcdcd;
    display: block;
    font-weight: 700;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .profile-progress,nav.primary.mobile .account-toggle .account-menu.logged-in .profile-progress {
    margin: 10px 0 12px 0;
    padding: 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .profile-progress__complete,nav.primary.mobile .account-toggle .account-menu.logged-in .profile-progress__complete {
    padding-bottom: 0;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .profile-progress__complete>p:first-child,nav.primary.mobile .account-toggle .account-menu.logged-in .profile-progress__complete>p:first-child {
    text-transform: uppercase;
    color: #79cb04;
    padding-bottom: 14px;
    font-size: 0.688rem;
    font-weight: 700;
    margin-top: -6px;
}

nav.primary.desktop .account-toggle .account-menu.logged-in .profile-progress h2,nav.primary.mobile .account-toggle .account-menu.logged-in .profile-progress h2 {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    text-align: left;
    line-height: 1.2;
    color: #cdcdcd;
}

nav.primary.desktop .account-toggle .account-menu.logged-in progress[value],nav.primary.mobile .account-toggle .account-menu.logged-in progress[value] {
    height: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 100%;
    background-color: transparent;
    position: relative;
    margin: 0;
    border-radius: 2px;
    top: 0;
    color: #79cb04;
}

nav.primary.desktop .account-toggle .account-menu.logged-in progress[value]::-webkit-progress-bar,nav.primary.mobile .account-toggle .account-menu.logged-in progress[value]::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 2px;
}

nav.primary.desktop .account-toggle .account-menu.logged-in progress[value]::-moz-progress-bar,nav.primary.mobile .account-toggle .account-menu.logged-in progress[value]::-moz-progress-bar {
    background: #79cb04;
    background: linear-gradient(180deg, #79cb04 0%, #61b112 35%, #336d2f 100%);
    border-radius: 2px;
}

nav.primary.desktop .account-toggle .account-menu.logged-in progress[value]::-webkit-progress-value,nav.primary.mobile .account-toggle .account-menu.logged-in progress[value]::-webkit-progress-value {
    position: relative;
    border-radius: 2px;
    background: #79cb04;
    background: linear-gradient(180deg, #79cb04 0%, #61b112 35%, #336d2f 100%);
}

nav.primary.desktop .account-toggle .account-menu.logged-in progress[value]::-moz-progress-value,nav.primary.mobile .account-toggle .account-menu.logged-in progress[value]::-moz-progress-value {
    position: relative;
    border-radius: 2px;
    background: #79cb04;
    background: linear-gradient(180deg, #79cb04 0%, #61b112 35%, #336d2f 100%);
}

nav.primary.mobile .account-toggle .account-menu.logged-in {
    top: 2.5em;
}
