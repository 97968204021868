.ui-dialog.ui-widget {
    &.password-reset-dialog {
        box-sizing: border-box;
        background: #fff !important;
        height: auto !important;
        width: 80%;
        margin: 9% auto 0;
        padding: 25px;
        position: relative;
        background: rgba(0,0,0,0.9);
        border-radius: 20px;
        transform: translateY(calc(-15% + 120px));
        padding-bottom: 0;
		.ui-dialog-title {
			min-height: 30px;
		}
        .ui-icon-closethick {
            &::before {
                position: absolute;
                left: 0;
                top: 9px;
                height: 13px;
                width: 14px;
                background: url("../images/icons/close.png") no-repeat;
                content: "";
            }
        }
        #dialog-container {
            margin-top: -62px;
            h1 {
                border-bottom: 1px solid #dfdfdf;
                padding-bottom: 8px;
                margin-bottom: 18px;
                font-weight: 600;
                font-size: 24px;
                text-transform: none;
            }
            .ui-dialog-title {
                display: none;
            }
            #PasswordResetForm {
                fieldset {
                    position: relative;
                }
                button {
                    background: #e31837;
                    color: #fff;
                    font-size: $ctaFontSize;
					font-weight: 700;
                    padding: 0.6em 3em;
                    margin: 0.5em auto;
                    height: 32.7px;
                    line-height: 1;
                    border-radius: 2rem;
                    width: auto;
                }
                .form-row {
                    width: 100%;
                    label {
                        text-transform: capitalize;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: normal;
                        float: none;
                    }
                }
            }
        }
    }
}

h1 .account-logout {
	font-size: 0.6em;
	float: none;
}

.registration-selection {
	input, label {
		display: inline;
		float: none;
	}
}

.address-list {
	padding: 0;
	li {
		float: left;
		list-style: none outside none;
		margin: 0;
		min-height: 135px;
		padding: 1em 0;
		width: 30%;
		&.default {
			border-bottom: 1px solid $gray-white;
			float: none;
			width: auto;
		}
	}
	.mini-address-title {
		font-style: italic;
	}
}
.payment-list {
	overflow: hidden;
	padding: 0;
	li {
		float: left;
		list-style: none outside none;
		margin: 0;
		min-height: 110px;
		width: 33%;
	}
}

.order-history-header {
	background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
	overflow: hidden;
	button {
		float: right;
	}
	.order-date, .order-status, .order-number {
		float: left;
		margin: 2px 0;
		width: 60%;
	}
}

.order-history-items {
	margin: 20px 0;
}

.order-history-table {
	th, td {
		padding: 5px 10px;
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none outside none;
	}
	.order-shipped-to,
	.order-items,
	.order-total-price {
		width: 33%;
	}
	.toggle {
		cursor: pointer;
		display: block;
	}
}

.js .order-history-table .hidden {
	display: none;
}

#wrapper.pt_order{
	#main.page-content.innerWidth{
		@include respond(large) {
			padding: 0 1em;
			box-sizing: border-box;
		}
	}
}
#wrapper.pt_account{
	select {
		background-color: $white!important;
	}
}
#wrapper.pt_wish-list,
#wrapper.pt_account{
	select {
		background-color: $white!important;
	}
	/////////////////////
	//////Styles for Login page
	/////////////////////
	#main{
		@include respond(large) {
			padding: 0 1em;
			box-sizing: border-box;
		}
		@include respond(small) {
			box-sizing: border-box;
		}
	}
	#primary{
		//checkbox and radio
		input[type="checkbox"] {
		  display:inline;
			width: auto;
			float: left;
		}
		h1{
			color: $gray-dark;
			border-bottom: 1px solid $clp-border;
			line-height: 120%;
			@include respond(small) {
				font-size: 20px;
				margin: 0;
				padding: 30px 0 17px;
			}
		}
		h2{
			color: $gray-dark;
			line-height: 120%;
			margin: 10px 0;
			@include respond(small) {
				font-size: 16px;
				margin: 13px 0 12px;
			}
		}
		p{
			font-size: 14px;
			color: $gray-warm;
			line-height: 20px;
			padding: 0 0 20px 0;
			margin: 0;
			width: 80%;
			@include respond(small) {
				font-size: 12px;
				width: 100%;
			}
			&.reg-info{
				padding: 10px 0 12px;
				@include respond(small) {
					padding: 13px 0 0;
				}
			}
		}
		h3{
			color: $gray-dark;
			line-height: 120%;
			text-transform: uppercase;
			padding: 14px 0 24px;
		}
		h4{
			color: $gray-dark;
			line-height: 120%;
			text-transform: uppercase;
			margin: 0;
		}
		a{
			font-size: 14px;
			color: $gray-dark;
			line-height: 120%;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
		button {
			@extend .button;
		}
		select {
			margin: 0.5em 0;
		}
		#NewPasswordForm{
			.form-row-button{
				margin-bottom: 20px;
			}
			.form-row{
				@include respond(large) {
					label{
						width: 50%;
					}
				}
			}
		}
		.form-row{
			label{
				width: 100%;
				.required-indicator{
					display: none;
				}
				&::after{
					content: " *";
					color: $gray-dark;
				}
			}
		}
		.content-asset{
			p{
				padding-bottom: 23px;
			}
		}
		.col-1{
			@include respond(small) {
				width: 100%;
				border-bottom: 1px solid $clp-border;
				padding-bottom: 10px;
			}
			.login-box-content{
				form{
					.form-row{
						margin: 6px 0 20px;
						@include respond(small) {
							margin: 0 0 20px 0;
						}
						.field-wrapper{
							width: 100%;
							float: left;
							margin-bottom: 0;
							@include respond(small) {
								width: 100%;
							}
							input[type="text"],
							input[type="tel"],
							input[type="password"],
							input[type="email"]{
								max-width: 100%;
								height: 33px;
								@include line-height(33px);
								background: transparent;
							    outline: none;
							    -webkit-appearance: none;
							    -moz-appearance: none;
							}
							.error{
								display: block;
							}
						}
						&.label-inline{
							margin: 15px 0 10px;
							@include respond(small) {
								margin-left: 0;
								margin-bottom: 15px;
							}
							label{
								width: auto;
								text-transform: none;
								color: $gray-warm;
								font-size: 12px;
								line-height: 120%;
								padding-left: 0;

								&::after{
									content: "";
								}
							}

						}
						&.form-row-button{
							button{
								margin-left: 0;
							}
						}
					}
					.reset-pass{
						width: 100%;
						margin-top: -13px;
						@include respond(small) {
							padding-left: 0;
						}
						@include respond(large) {
							max-width: 100%;
							padding-left: 0;
						}
						a#password-reset{
							font-size: 12px;
							color: $crimson;
						}
					}
				}
			}
		}
		.col-2{
			width: 43.4%;
			min-height: 775px;
			@include respond(large) {
				min-height: 0;
			}
			@include respond(small) {
				width: 100%;
				min-height: 0;
			}
			h2{
				@include respond(small) {
					margin: 10px 0;
				}
			}
			h3{
				@include respond(small) {
					font-size: 14px;
					padding: 0 0 23px 0;
				}
			}
			h4{
				@include respond(small) {
					font-size: 12px;
				}
			}
			.content-asset{
				p{
					@include respond(small) {
						padding-bottom: 16px;
					}
				}
			}
		}
	}
	/////////////////////
	//////END Styles for Login page
	/////////////////////

	/////////////////////
	//////Styles for Register page
	/////////////////////
	#RegistrationForm{
		min-height: 740px;
		@include respond(large) {
			min-height: 0;
		}
		@include respond(small) {
			min-height: 0;
		}
		span.required {
			text-align: right;
			font-size: 11px;
			color: $gray-warm-old;
			display: block;
			text-transform: capitalize;
			@include respond(small) {
				width: 100%;
				padding-left: 0;
			}
		}
		.form-row{
			margin: 0 0 20px;
			label {
				text-align: left;
			}
            .input-checkbox {
                width: 142px;
				height: auto;
            }
			.field-wrapper{
				width: 100%;
				padding-left: 0;

				@include respond(small) {
					flex-wrap: wrap;
				}
			}
			.form-caption{
				color: $gray-warm-old;
				margin-top: 0;
			}
			&.label-inline{
				margin-top: 15px;
				@include respond(small) {
					 margin-bottom: 0;
   					margin-top: 10px;
				}
				label{
					width: 340px;
					color: $gray-warm;
					font-size: 12px;
					text-transform: none;
					line-height: 20px;
					margin-top: 0;
					@include respond(small) {
						margin-left: 0;
						width: 100%;
						box-sizing: border-box;
					}
					&::after{
						content: "";
					}

					a{
						color: $gray-dark;
						text-decoration: underline;
						text-transform: none;
						font-size: 12px;
						@include respond(small) {
							display: inline-block;
						}
						&:hover{
							text-decoration: none;
						}
					}
				}
				.input-checkbox {
					margin-top: 3px;
                    width: auto;
					& + label {
						a {
							color: $crimson;
						}
					}
                }
                input[type="checkbox"] + label{
					background-position: left 5px;
				}
				input[type="checkbox"]:checked + label{
					background-position: left 5px;
				}
			}
			&.form-row-button{
				margin: 24px 0;
				@include respond(small) {
					margin: 5px 0 40px;
				}

				button {
					@include respond(small) {
						margin-left: 0;
					}
				}
			}
		}
	}
}
/////////////////////
//////Styles for Left nav menu
/////////////////////
#wrapper{
	#main{
		#secondary{
			padding-top: 31px;
			@include respond(small) {
				padding: 0 1em;
				box-sizing: border-box;
			}

			nav{
				padding: 0;
				.secondary-navigation{
					> a {
						color: $crimson;
					}
					@include respond(small) {
						position: relative;
						padding-bottom: 10px;
					}
					.log-out{
						display: none;
						@include respond(small) {
							display: block;
							text-decoration: none;
							position: absolute;
							right: 0;
							top: 5px;
							text-transform: none;
							span{
								text-decoration: underline;
								&:hover{
									text-decoration: none;
								}
							}
						}
					}
					span.toggle,
					h2{
						font-size: 20px;
						line-height: 51px;
						padding: 0;
						margin: 0;
						border-bottom: 1px solid $clp-border;
						@include respond(small) {
							color: $white;
							font-size: 12px;
							background:  $crimson;
							width: 55%;
							line-height: 34px;
							border-radius: 2rem;
							padding: 0 20px;
							border: 0;
							margin: 33px 0 10px;
							cursor: pointer;
							&:after {
								content: ">";
								float: right;
								font-size: 24px;
								line-height: 34px;
							}
							&.open:after {
								-ms-transform: rotate(90deg); /* IE 9 */
							    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
							    transform: rotate(90deg);
							    -webkit-transition-duration: 0.5s;
								-moz-transition-duration: 0.5s;
								-o-transition-duration: 0.5s;
								 transition-duration: 0.5s;
								 line-height: 36px;
						    }
						}
					}
					ul{
						padding: 0;
						margin: 0;
						li{
							border-bottom: 1px solid $clp-border;
							a{
								font-size: 14px;
								color: $gray-warm;
								text-transform: uppercase;
								padding: 4px 0;
								line-height: 157%;
								text-decoration: none;
								@include respond(small) {
									display: block;
								}

								&:hover{
									color: $gray-dark;
								}
							}
							@include respond(small) {
								display: block;
								&.hide{
									display: none;
								}
							}
							&.mobile{
								display: none;
								@include respond(small) {
									display: block;
									border-bottom: 0;
								}
							}
						}
					}
					.content-asset{
						.content-asset{
							@include respond(small) {
								display: none;
							}
						}
						span.toggle{
							font-family: $oswald;
							@include respond(small) {
								font-family: $titillium;
							}
						}
					}
				}
			}
		}
		.account-nav-asset,
		.account-nav-asset-mobile {
			@include respond(small) {
				border-bottom: 1px solid $clp-border;
			}
			p{
				font-size: 12px;
				color: $gray-warm;
				line-height: 18px;
				margin: 0;
				span{
					color: $gray-dark;
				}
			}
		}
		.account-nav-asset-mobile {
			.content-asset p {
				padding-bottom: 0;
			}
		}
		#primary.account-content{
			box-sizing: border-box;
		    padding-left: 230px;
		    width: 100%;
		    @include respond(small) {
		    	padding: 0 1em;
		    }
		}

        &.hide-acctMenu {
            #primary.account-content {
                @include respond(largeUp) {
                    padding-left: 0;
                }
            }
            #secondary {
                display: none;
            }
        }

        .promo {
            color: $red;
        }
	}
}
#wrapper.pt_wish-list,
#wrapper.pt_account,
#wrapper.pt_order{
	#primary{
		h1{
			color: $gray-dark;
			line-height: 120%;
			margin-bottom: 0;
			border-bottom: 0;
			padding-top: 20px;
			@include respond(small) {
				font-size: 20px;
				margin: 0;
				padding: 18px 0 10px;
			}
			&.border-bottom{
                border-bottom: 2px solid $clp-border;
				margin-bottom: 13px;
    			padding-bottom: 16px;
    			@include respond(small) {
    				padding-top: 30px;
    			}
			}
		}
		span.log-out{
			display: block;
			width: 100%;
			font-size: 14px;
			color: $gray-warm;
			border-bottom: 1px solid $clp-border;
			padding-bottom: 15px;
			a{
				color: $crimson;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
			@include respond(small) {
				display: none;
			}
		}
	}
}
/////////////////////
//////Styles for Account Order page
/////////////////////
#wrapper.pt_order{
	#primary,
    #legacy-order{
		.search-result-options{
			margin: 20px 0 24px;
			@include respond(small) {
				margin: 0;
				padding-bottom: 15px;
			}
			.pagination{
				float: left;
				font-size: 12px;
				width: 100%;
				position: relative;
				@include respond(small) {
					padding: 0;
					line-height: 28px;
					.results-hits{
						width: 100%;
						font-size: 12px;
					}
				}
				@include respond(small) {
					.mobile-pagination {
						float: left;
						position: absolute;
						width: auto;
						right: 0;
						&:first-child {
							right: auto;
							left: 0;
						}
					}
				}
				.desktop-pagination{
					li{
						vertical-align: top;
						height: 17px;
						a{
							height: 17px;
							line-height: 17px;
							margin-top: 0;
						}
					}
				}
			}
		}
		.search-result-items{
			left: 0;
			width: 100%;
			border: 0;
			@include respond(small) {
				margin: 0;
			}
			li{
				.order-history-header{
					background: $gray-white;
					padding: 10px 14px;

					.left-order-info{
						float: left;
						@include respond(large) {
							width: 70%;
						}
					}
					button{
						height: 34px;
						line-height: 30px;
						font-size: 11px;
						padding: 0 18px;
						margin-top: 12px;
						@include respond(small) {
							float: left;
							margin: 16px 0 3px 3px;
						}
					}
					div{
						margin: 0;
						@include respond(small) {
							width: 100%;
						}
						span{
							font-size: 12px;
							color: $gray-dark;
						}
						.value{
							color: $gray-warm;
						}
					}
				}
				.order-history-items{
					@include respond(small) {
						margin: 20px 0 10px;
					}
					.order-history-table{
						.mobile-visible{
							display: none;
							@include respond(small) {
								display: block;
								font-size: 12px;
								text-transform: uppercase;
								color: $gray-dark;
								margin-bottom: 5px;
                                font-weight: 700;
							}
						}
						tr{
							th{
								font-size: 12px;
								text-transform: uppercase;
								padding: 5px 15px 3px;
								&.order-shipped-to{
									width: 40%;
								}
								@include respond(small) {
									display: none;
								}
							}
							td{
								padding: 0 15px;
								font-size: 12px;
								color: $gray-warm;
								text-transform: uppercase;
								line-height: 100%;
								@include respond(small) {
									float: left;
									width: 100%;
									box-sizing: border-box;
									padding-bottom: 27px;
								}
								&.total-order{
									@include respond(small) {
										text-align: left;

										span.name{
											display: block;
											margin-bottom: 7px;
										}
										span.value{
											font-size: 12px;
											color: $gray-warm;
											text-transform: uppercase;
										}
									}
								}
								&.order-total{
									@include respond(small) {
										display: none;
									}
								}
								&.order-items{
									ul{
										li{
											line-height: 120%;
    										padding-bottom: 6px;
											padding-top: 0;
											text-transform: none;
										}
									}
									a{
										font-size: 12px;
										color: $gray-dark;
										text-transform: none;
										text-decoration: underline;
										margin-top: 5px;
										&:hover{
											text-decoration: none;
										}

									}
								}
							}
							th:last-child,
							td:last-child{
								text-align: right;
								@include respond(small) {
									text-align: left;
									border-bottom: 1px solid $clp-border;
								}
							}
						}
					}
				}
			}
		}
		/////////////////////
		//////Styles for Account Order History Detail page
		/////////////////////
		h1{
			@include respond(small) {
				margin: 0!important;
			}
		}
		.order-information{
			padding: 20px 0 30px;
			@include respond(small) {
				padding: 0 0 10px 0!important;
			}
		}
		.order-confirmation-details{
			.order-hide{
				display: none;
			}
			table.item-list{
				margin-top: 10px;
			}
			table.order-shipment-table{
				@include respond(large) {
					th.header-divider,
					td.header-divider{
						width: 5px;
						padding: 0;
					}
					td.line-item-details{
						width: 30% !important;
						@include respond(large) {
							width: 50% !important;
							.item-image{
								float: left;
							}
						}
					}
					th:nth-child(3),
					td:nth-child(3){
						padding: 15px 20px !important;
					}
					td.order-shipment-details{
						padding-right: 10px !important;
					}
				}
			}
		}
		.order-detail-options{
			padding-bottom: 60px;
			@include respond(small) {
				padding-bottom: 20px;
			}
			@include respond(large) {
				margin-right: 10px;
			}
			a{
				font-size: 14px;
				color: $gray-dark;
				text-decoration: underline;
				margin: 0;
				@include respond(small) {
					font-size: 12px;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}
/////////////////////
//////Styles for Account Payment page
/////////////////////
#wrapper.pt_account{
	#primary{
		a.button.address-create,
		a.button.add-card{
			text-transform: uppercase;
			text-decoration: none;
			color: $white;
			@include line-height(44px);

			&.section-header-note{
				margin-top: 20px;
				@include respond(small) {
					margin-top: 6px;
				}
			}
			&:hover{
				color: $white;
			}
		}
		ul.payment-list{
			margin: 20px 0;

			li {
				width: 26.4%;
				font-size: 14px;
				color: $gray-warm;
				line-height: 20px;
				margin: 10px 0;
				&:nth-child(4n) {
					width: 20%;
					@include respond(small) {
						width: 100%;
					}
				}
				@include respond(small) {
					font-size: 12px;
					line-height: 18px;
				}
				@include respond(small) {
					width: 100%;
					border-bottom: 1px solid $clp-border;
					margin-bottom: 5px;
					padding-bottom: 19px;

					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
	}
	/////////////////////
	//////Styles for Landing page
	/////////////////////
	#primary{
		&.account-content--old {
			h1{
				color: $gray-dark;
				line-height: 120%;
				margin-bottom: 0;
				border-bottom: 0;
				padding-top: 20px;
				@include respond(small) {
					font-size: 20px;
					margin: 0;
					padding: 18px 0 10px;
				}
				&.border-bottom{
					border-bottom: 2px solid $clp-border;
					margin-bottom: 13px;
					padding-bottom: 16px;
					@include respond(small) {
						padding-top: 30px;
					}
				}
			}
			span.log-out{
				display: block;
				width: 100%;
				font-size: 14px;
				color: $gray-warm;
				border-bottom: 1px solid $clp-border;
				padding-bottom: 15px;
				a{
					color: $crimson;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
				@include respond(small) {
					display: none;
				}
			}
		}
		h1.mobile-hidden{
			@include respond(small) {
				display: none;
			}
		}
		.main-box{
			float: left;
			width: 100%;
			margin-right: 0;
			margin-bottom: 100px;
			@include respond(small) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 30px;
			}
			ul.account-options{
				li{
					padding: 26px 0 25px 50px;
					margin: 0;
					width: 100%;
					box-sizing: border-box;
					height: auto;
					@include respond(small) {
						padding: 19px 0 17px 50px;
					}
					&.my-Information{
						background: url("../images/my-Information-icon.png") no-repeat left center;
					}
					&.addresses{
						background: url("../images/addresses-icon.png") no-repeat left center;
					}
					&.payments{
						background: url("../images/payments-icon.png") no-repeat left center;
					}
					&.orders{
						background: url("../images/orders-icon.png") no-repeat left center;
					}
					&.wishlist{
						background: url("../images/wishlist-icon.png") no-repeat left center;
					}

					a{
						font-size: 14px;
						text-transform: uppercase;
						padding-left: 0;
						text-decoration: none;
						margin-bottom: 2px;
						@include respond(small) {
							font-size: 12px;
							line-height: 16px;
							margin-bottom: 4px;
						}
					}
					p{
						padding-bottom: 0;
						display: block;
						line-height: 20px;
						@include respond(small) {
							line-height: 16px;
						}
					}
				}
			}
		}
		.secondary-box{
			display: none;
			float: left;
			width: 21%;
			@include respond(small) {
				width: 100%;
				margin-bottom: 20px;
			}
			h3{
				font-size: 14px;
				padding: 12px 0 0;
			}

			p{
				font-size: 12px;
				line-height: 16px;
				width: 100%;
				padding-bottom: 16px;
			}

			ul{
				margin: 0;
				padding: 0 10px;
				color: $gray-dark;

				li{
					font-size: 9px;
					color: $gray-dark;
					list-style: square;
					line-height: 16px;
					padding-bottom: 16px;

					span{
						font-size: 12px;
						color: $gray-warm;
						line-height: 100%;
					}
				}
			}
		}
		.community-join{
			a{
				line-height: 30px;
				height: 34px;
				text-decoration: none;
				box-sizing: border-box;
				font-size: 12px;
				padding: 0 18px;

				&:hover{
					color: $white;
				}
			}
		}
	}
}
/////////////////////
//////Styles for Addresses page
/////////////////////
#wrapper.pt_account{
	#main{
		#secondary{
			& + #primary{
				.account-slot-banner{
					display: none;
				}
			}
		}
		#primary{
			h1{
				@include respond(small) {
					border-bottom: 1px solid $clp-border;
					padding: 30px 0 17px;
				}
			}
			.account-slot-banner{
				display: inline-block;
				width: 100%;
				padding: 30px 0 0;

				p{
					padding: 0;
					margin: 0;
					width: 100%;
				}
				img{
					max-width: 100%;
				}
			}
			ul.address-list{
				li{
					padding: 25px 0 40px;
					color: $gray-warm;
					width: 25%;
					@include respond(large) {
						width: 33.33%;
					}
					@include respond(small) {
						width: 100%;
						padding: 15px 0 28px;
						border-bottom: 1px solid $clp-border;
						&:last-child{
							border-bottom: 0;
						}
					}

					&.default{
						padding: 20px 0 40px;
						border-width: 2px;
						width: 100%;
						@include respond(small) {
							border-width: 1px;
							padding: 10px 0 30px;
						}
						a.address-edit{
							margin-left: 0;
						}
					}
					h3{
						font-size: 14px;
						font-family: $titillium;
						padding: 0 0 10px 0;
						margin: 0;
						@include respond(small) {
							font-size: 12px;
						}
					}
					div{
						font-size: 14px;
						line-height: 20px;
						font-style: normal;
						color: $gray-warm;
						@include respond(small) {
							line-height: 18px;
							font-size: 12px;
						}
						&.mini-address-title{
							color: $gray-dark;
						}
						&.mini-address-location{
							padding-bottom: 10px;
							@include respond(small) {
								padding-bottom: 10px;
							}
							address{
								font-style: normal!important;
							}
						}
					}
					a{
						font-size: 12px;
						color: $crimson;
						margin: 0 5px;
						&:hover {
							color: $mine-shaft;
						}
						@include respond(large) {
							margin: 0 2px;
						}
						&.address-make-default{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
/////////////////////
//////Styles for Account UI dialog
/////////////////////

.ui-widget-overlay.ui-front {
	z-index: 1000;
}
.ui-dialog.ui-widget {
	z-index: 1001;
	border-radius: 20px;

	@include respond(small) {
		width: 90% !important;
		padding: 0 5px!important;
	}

    .contact-phone {
        background: url(../images/icons/reset-mail.png) no-repeat 0px 9px;
        padding-left: 34px;
        padding-right: 5px;
        background-size: 30px;
        margin-bottom: 0;
        color: #000;
        line-height: 1.9;
    }

	&.password-reset-dialog,
	&.add-card-dialog,
	&.add-address-dialog {
		#dialog-container {
			padding: 30px 25px 25px !important;

			@include respond(1024px) {
				padding: 17px 20px 30px !important;
			}
		}

		.ui-dialog-titlebar-close {
			margin: 5px 20px 0 0 !important;

			@include respond(1024px) {
				margin: 5px 10px 0 0 !important;
			}
		}
	}
    &.password-reset-dialog {
        .ui-dialog-titlebar-close {
            @include respond(largeUp) {
                margin: -22px 0 0 !important;
            }
        }
    }
	select {
		background-color: $white!important;
	}
	.ui-widget-header{
		.ui-button{
			position: relative;
			z-index: 100;

			.ui-icon-closethick::before {
				color: $black;
				font-weight: bold;
				font-size: 18px;
			}

			.ui-button-icon-primary{
				display: block;
			    height: 24px;
			    margin-left: 0 !important;
			    margin-top: 0;
			    width: 23px;
			}
		}
	}
	#dialog-container{
		@include respond(small) {
			padding: 10px!important;
		}
		h1{
			padding: 10px 0 14px;
			margin-bottom: 0;
			font-size: 24px;
			line-height: normal;
			color: $black;
			text-transform: none;
			border-bottom: 1px solid $clp-border;
		}
		p{
			font-size: 14px;
			color: $gray-warm;

			a{
				font-size: 14px;
				color: $gray-dark;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
				@include respond(small) {
					word-wrap: break-word;
				}
			}
		}
		.dialog-required{
			float: right;
			font-size: 11px;
			color: $gray-warm-old;
			text-transform: capitalize;
			padding: 7px 0 5px;
			width: 100%;
			text-align: right;
			&::before{
				content: "*";
			}
		}
		#edit-address-form{
			.form-row-button{
				.delete-button{
					display: none;
				}
				input[type="text"],
				input[type="tel"],
				input[type="password"],
				input[type="email"]{
					height: 33px;
					line-height: 30px;
				}
			}
		}
		#edit-address-form,
		#CreditCardForm{
			float: left;
			width: 100%;
			a.tooltip {
				color: $gray-warm-old;
				text-decoration: none;
			}
			.form-label-text,
			.form-row label{
				float: left;
				width: 32%;
				font-size: 12px;
				text-transform: none;
				line-height: 33px;
				margin: 0;
				text-align: left;
				font-weight: 400;
				@include respond(small) {
					width: 100%;
					line-height: 120%;
					margin-bottom: 5px;
				}
				.required-indicator{
					display: none;
				}
			}
			.form-label-text{
				padding-top: 0;
				width: 100%;
				font-size: 14px;
			}
			.form-row.required label{
				&::after{
					content: " *";
					color: $gray-dark;
				}
			}
			.field-wrapper{
				width: 68%;
				margin-bottom: 4px;
				@include respond(small) {
					width: 100%;
				}
				select{
					height: 33px;
					line-height: normal;
					background-color: #f7f7f7;
				}
			}
			.form-row{
				.form-caption{
					margin-left: 34%;
					font-size: 12px;
					color: $gray-warm-old;
					@include respond(small) {
						margin-left: 0;
					}
				}
				&.year,
				&.month{
					margin-bottom: 16px;
					@include respond(small) {
						margin-left: 0;
						float: left;
						width: 48%;
						overflow: visible;
						label{
							display: none;
						}
					}
					.field-wrapper{
						width: 100%;
						position: relative;
						padding-left: 0;
						select{
							width: 143px;
							@include respond (large) {
								width: 213px;
							}
							&.error {
								margin-bottom: 21px;
							}
							@include respond(small) {
								width: 100%;
							}
						}
						span.error {
							position: absolute;
							top: 33px;
							left: 0;
							width: 150%;

						}
					}
				}
				&.year{
					@include respond(small) {
						margin-left: 10px;
					}
				}
				&.form-row-button{
					.apply-button,
					#applyBtn, button.cancel{
						background: $crimson;
						padding: 0.6em 3em;
						&:hover {
							color: $white;
						}
					}
				}
				&.firstname {
					clear: both;
				}
			}
			.form-field-tooltip {
				margin-left: 0;
			}
		}
	}
}
.ui-dialog.promo-details-dialog {
	max-width: 594px;
    width: 100% !important;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    z-index: 1001 !important;
}
.ui-dialog.edit-product-modal {
	top: 100px !important;
}
.ui-dialog.promo-details-dialog #dialog-container p a,
.ui-dialog.promo-details-dialog #dialog-container a {
    color: $red;
    text-decoration: none;
    letter-spacing: $globalLetterSpacing;
    &:hover,
    &:focus {
        color: $primary-color;
        border-bottom-color: rgba(0, 0, 0, 0.1);
    }
}
.ui-dialog.promo-details-dialog #dialog-container ul li {
	line-height: 1.125rem;
}
#wrapper.pt_wish-list{
	#main{
		#secondary{
			@include respond(small) {
				padding: 0;
			}
		}
		#primary{
			a {
				color: $crimson;
				&:hover {
					color: $mine-shaft;
					text-decoration: underline;

				}
			}
			@include respond(small) {
				padding: 0;
			}
			table.item-list{
				tr{
					&:last-child{
						border-bottom: 0;
					}
					td{
						&.item-image{
							width: 135px;
							padding-right: 20px;
							padding-left: 0;
							img{
								width: 135px;
							}
							@include respond(small) {
								float: left;
								width: 100px;
								img{
									width: 100px;
								}
							}
						}
						&.item-details{
							padding: 15px 0 25px 20px;
							width: calc(100% - 150px);
							@include respond(small) {
								float: right;
								width: calc(100% - 115px);;
								padding: 10px 0 0 0;
							}
							.product-list-item{
								.name,
								.name a{
										font-style: normal;
										text-decoration: none;
								}
								.name {
									padding-bottom: 7px;
								}
								.sku{
									padding-bottom: 5px;
									span{
										font-size: 11px;
										color: $gray-warm;
										line-height: 20px;
									}
								}
								.price{
									padding-bottom: 11px;
	    							padding-top: 8px;

									span.label{
										display: none;
									}
									span.value{
										font-size: 11px;
										line-height: 18px;
									}
								}
								.attribute,
								.product-option {
									padding-bottom: 0;

									span{
										font-size: 11px;
										text-transform: capitalize;
										line-height: 18px;
										color: $gray-dark;
										font-weight: 700;

										&.value{
											color: $gray-warm;
											font-weight: normal;
										}

										.attribute-iron-label {
											text-transform: none;
										}
									}
								}
								&.myjoy-item{
									.attribute{
										padding-bottom: 10px;
										display: block;

										.label,
										.value{
											color: $gray-warm;

										}
										&.name{
											padding-bottom: 0;
											span.value{
												color: $gray-dark;
												padding-bottom: 0;
												display: block;
											}
										}
									}
									.price{
										padding-top: 0;
									}
								}
							}
							& > a,
							.option-update button{
								padding: 0;
								text-transform: none;
								margin-bottom: 5px;
								text-decoration: underline;
								color: $crimson;
								font-size: 12px;
								line-height: 18px;
								height: auto;
								margin: 4px 0;
								background: none;
								&:hover{
									text-decoration: none;
								}
							}
						}
						&.item-dashboard{
							padding-right: 0;
							text-align: right;
							padding-top: 15px;
							width: calc(100% - 150px);
							@include respond(small) {
								float: right;
								width: calc(100% - 115px);;
								padding: 0;
								text-align: left;
							}
							.product-availability-list{
								display: block;
								font-size: 12px;
								color: $gray-dark;
								padding: 0;
								margin: 0;
								@include respond(small) {
									padding: 10px 0 0;
								}
								li{
									&.is-in-stock{
										color: $gray-dark;
									}
									&.notavailable{
										color: $red;
									}
								}
							}
							.option-date-added{
								.field-wrapper{
									.value{
										font-size: 12px;
										color: $gray-warm;
										text-transform: none;
										width: auto;
										line-height: 140%;
										float: none;
										margin-top: 0;
										&::after{
											content: "";
										}
										@include respond(small) {
											span{
												display: block;
												line-height: 120%;
											}
										}
									}
								}
							}
							.option-add-to-cart{
								float: right;
								margin-bottom: 20px;

								@include respond(small) {
									width: 100%;
								}
								input{
									width: 70px;
									&.read-only {
										background-color: $white;
										border: 0;
									}
								}
								select{
									width: 70px;
									&.read-only {
										background-color: $white;
										border: 0;
									}
								}
								button{
									@include line-height(33px);
									padding: 0 20px;
								}
							}
						}
					}
				}
			}
			.col-1{
				h2{
					@include respond(small) {
						margin: 2px 0 12px;
					}
				}
			}
			.col-2{
				.login-box.login-create-account{
					.login-box-content{
						@include respond(small) {
							margin-bottom: 35px;
						}
					}
					.createbenefits{
						ul{
							padding: 0;
							margin: 0;
							list-style: none;
							margin-left: 0!important;
							li{
								span{
									display: block;
									color: $gray-dark;
									text-transform: uppercase;
									@include respond(small) {
										padding-bottom: 0;
										line-height: 150%;
									}
								}
								p{
									padding-bottom: 21px;
									@include respond(small) {
										padding-bottom: 18px;
										line-height: 18px;
									}
								}
							}
						}
					}
					form{
						.form-row-button{
							margin: 4px 0;
							@include respond(small) {
								margin: 0;
							}
							button{
								padding: 0 27px;
								@include respond(small) {
									padding: 0 17px;
								}
							}
						}
					}
				}
			}
		}
        .contactus-asset {
            @include respond(small) {
                padding: 0 17px 6px;
            }
        }
	}
}

#wrapper.pt_account,
#wrapper.pt_order,
#wrapper.pt_wish-list {
	#main {
    	margin-bottom: 2em;
	}
}
#dialog-container {
	&.dialog-content {
		.year {
			float: right;
			margin-left: 1%;
		}
	}
}

#wrapper.pt_wish-list {
	.recos-wishlist {
		background-color: $white;

		.club-pdp-recommendations {
			margin: 0 auto;
			padding: 80px 0 50px;
			@media screen and (min-width: 992px) {
				padding: 80px 0 120px;
			}
		}
	}
}

#wrapper.pt_wish-list,
.pt_account-pages {
	background: #f7f7f7 !important;
}

.pt_account .innerWidth,
.pt_wish-list .innerWidth {
	max-width: 1080px;
}

#wrapper.pt_account #primary .account__header,
#wrapper.pt_wish-list #primary .account__header {
	color: $black;
	margin: 0 0 10px;
	padding-top: 0;

	&.account__header--login {
		padding: 15px 0 10px 0 !important;
		border-bottom: none !important;
        @include respond(0, 901px) {
            font-size: 24px !important;
        }
	}

	&.account__header--reset-password {
		font-size: 24px;
		padding-bottom: 10px;
		border-bottom: 1px solid #dfdfdf;
	}
}

.account__column--form {
	padding: 20px;

	@include respond(standard) {
		padding: 48px 50px 82px 60px;
	}
}

.account__column--form-login {
	@include respond(standard) {
		padding: 60px 70px 82px 90px;
	}
}

.account__column--first {
	@include respond(standard) {
		flex: 1 1 53%;
	}
}

.account__column--reset-password {
	@include respond(standard) {
		padding: 65px 50px 82px 95px;
	}
}

.account__column--second {
	flex: 1 1 47%;
}

.form__columns {
	@include respond(standard) {
		display: flex;
		gap: 20px;
	}
}

.form__columns__col {
	flex: 0 0 calc(50% - 10px);
}

.form__gender {
	margin-bottom: 5px;

	.field-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 13px;
		margin-top: 10px;
	}
}

.field-wrapper__radio-button {
	position: relative;
}

.field-wrapper__radio-button__input {
	width: 1px;
	height: 1px;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.field-wrapper__radio-button__label {
	padding: 12px 22px;
	background: $white;
	box-shadow: 0px 0px 0px 1px #dfdfdf;
	transition: box-shadow 0.1s linear;
	border-radius: 2px;
	min-width: 52px;
	white-space: nowrap;
	cursor: pointer;

	&:hover,
	&:focus {
		box-shadow: 0px 0px 0px 2px #000;
	}

	&::after {
		display: none;
	}
}

.field-wrapper__radio-button__input:checked + .field-wrapper__radio-button__label {
	color: $white;
	background: $black;
}

#wrapper.pt_account #primary,
#wrapper.pt_wish-list #primary {
	.input-text,
	.input-select {
		transition: box-shadow 0.1s linear;

		&:hover,
		&:focus {
			box-shadow: 0px 0px 0px 2px $black;
		}
	}
}

#wrapper.pt_account #primary .account-wrapper,
#wrapper.pt_wish-list #primary .account-wrapper {
	margin-top: 60px;
	margin-bottom: 60px;
	box-shadow: 0px 1px 4px #999;
    background: $white;
    @include respond(0, 901px) {
        margin-top: 0;
    }

	@include respond(standard) {
		display: flex;
	}

	&.account-wrapper--reset-password {
		max-width: 620px;
		max-height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	&.account-wrapper--login {
		@include respond(1000px) {
			max-height: 560px;
		}
	}
}

#wrapper .account-menu {
	.login-box-content {
		.form-row {
			margin: 0;
		}

		.form-row-button,
		.form-row__details {
			margin-top: 10px;
		}

		.required-indicator {
			display: none;
		}

		.form-row__details__link,
		#password-reset-header {
			text-align: left;
			padding: 0 !important;
			font-size: 12px;
			line-height: normal;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		.reset-pass {
			margin-top: 10px;
		}
	}
}

#wrapper.pt_account #primary .account-wrapper,
#wrapper.pt_wish-list #primary .account-wrapper,
#wrapper .account-menu {
	input[type="date"] {
		padding-right: 10px;
	}

	.error-form {
		padding-left: 0;
	}

	.side-form {
		.form-caption {
			max-width: 270px;
		}
	}

	.form-row {
		&.form-row--custom-checkbox {
			.input-checkbox {
				position: absolute;
				opacity: 0;
				height: 0;
				width: 0;
			}

			label {
				position: relative;
				display: block;
				margin-top: 0;
				padding-left: 25px;
				font-weight: normal;
				font-size: 14px;

				&::before {
					content: '';
					position: absolute;
					top: 3px;
					left: 0;
					height: 14px;
					width: 14px;
					border-radius: 4px;
					border: 0 none;
					box-shadow: 0px 0px 0px 1px #dfdfdf;
					transition: box-shadow 0.1s linear;
				}

				&::after {
					content: "";
					position: absolute;
					display: none;
					left: 5px;
					top: 4px;
					width: 5px;
					height: 10px;
					border: solid $white;
					border-width: 0 2px 2px 0;
					transform: rotate(28deg);
				}
			}

			.input-checkbox:checked + label {
				&::before {
					background-color: $black;
					box-shadow: 0 0 0 2px $black;
				}

				&::after {
					display: block;
				}
			}
		}

		&.form-row--subscribe {
			width: 70%;
            @include respond(0, 901px) {
                margin: 0 0 1.1rem 0;
                padding-right: 20px;
            }
		}

		&.form-row-button.register {
			margin-bottom: 20px;
		}
	}

    .username {
        padding-bottom: 0.875rem;
    }

	.login-rememberme {
		margin-top: 20px;
		margin-left: 0;
	}

	a#password-reset,
	a#password-reset-header {
		color: $color-secondary;
	}

	.form-row__description {
		width: 100%;
		font-size: 11px;
		line-height: normal;
		padding: 0;
		margin-bottom: 10px;

        &.privacy-row {
            @include respond(0, 901px) {
                width: 72%;
            }
        }

		&.form-row__description--last {
			margin-top: 20px;
			margin-bottom: 0;
		}

		.form-row__description__link {
			font-size: 11px;
		}

		.form-row__description__link--secondary {
			color: $color-secondary;
		}
	}

	.form-row__details {
		width: 100%;
		font-size: 12px;
		line-height: 1.6;
		padding: 0;
		margin-top: 20px;

		.form-row__details__link {
			font-size: 12px;
			color: $color-secondary;
		}
	}

	p.opt-in-address {
		margin-top: 15px;
		font-size: 11px;
		line-height: 18px;
		color: #4C4C4C;
	}

	.opt-in-address__link {
		font-size: 11px;
	}
}

.account__column--banner {
	position: relative;
}

.account__column--banner__image {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.account__column--banner__image__img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.account__column--banner__info {
	display: flex;
	position: relative;
	align-items: start;
	justify-content: start;
	width: 100%;
	height: 100%;
	padding: 90px 46px;

	@include respond(largeUp) {
		padding: 250px 86px;
	}

	ul,
	li {
		color: $white;
	}
}

.account-wrapper--login {
	.account__column--banner__info {
		@include respond(largeUp) {
			padding: 70px 94px 100px 92px;
		}
	}
}

#wrapper.pt_account #primary .account__column--banner__info__title,
#wrapper.pt_wish-list #primary .account__column--banner__info__title {
	margin: 0 0 24px;
	padding: 0 0 0 70px;
	color: $white;
	font-size: 30px;
	line-height: 1.9;
	text-transform: uppercase;
	background: url('../../images/account/tt-logo.svg');
	background-size: 80px 42px;
	background-repeat: no-repeat;
	background-position: left;
}

.account__column--banner__info__list {
	list-style: none;
	margin: 0;
	padding: 0 0 0 28px;
}

.account__column--banner__info__list__item {
	margin: 0;
	padding: 10px 0 10px 55px;
	font-size: 15px;
	line-height: 1.6;
	font-weight: 700;
	color: $white;
	background-size: 28px 28px;
	background-repeat: no-repeat;
	background-position: left center;

	&.events {
		background-image: url('../../images/account/events.svg');
	}

	&.discussions {
		background-image: url('../../images/account/discussions.svg');
		background-size: 36px 40px;
		background-position: 0 14px;
	}

	&.sweeps {
		background-image: url('../../images/account/sweepstakes.svg');
		background-size: 33px 36px;
	}

	&.account {
		background-image: url('../../images/account/expert.svg');
		background-position: 2px 17px;
	}
}

#wrapper #main #primary.account-content {
	@include respond(1024px) {
		box-sizing: border-box;
		padding-left: 230px;
		width: 100%;
	}
}

.full-page-width {
	margin-left: calc((100% - 100vw) / 2);
	margin-right: calc((100% - 100vw) / 2);
	overflow: hidden;
}

.breadcrumbs__wrapper {
	background: $white;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
    padding: 0 10px;

    .bc-innerWidth {
        list-style-type: none;
        max-width: 1120px;
        margin: 0 auto;
    }

    .bc-sv_SE,
    .bc-de_DE,
    .bc-fr_FR,
    .bc-en_NL {
        .bc-teamtitleist {
            display: none;
        }
    }

	.breadcrumb {
		margin: 0;
		padding: 12px 0;

		@include respond(0, 990px) {
			text-align: center;
		}

		a {
			color: $black;
			border-bottom: none;
			line-height: 27px;
			font-size: 11px;
			text-transform: uppercase;
			font-weight: 700;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}

			&::after {
				content: "/";
				display: inline-block;
				padding: 0 4px 0 2px;
				line-height: 27px;
				color: $color-secondary;
				font-size: 11px;
				font-weight: bold;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}
}

.account__nav {
    margin: 0 auto;

    .oh-innerWidth {
        box-shadow: 0 1px 1px #e6e6e6;
        background-color: $white;
    }

	.secondary-navigation--custom {
		.content-asset {
			margin: 0 auto;
			overflow-x: auto;
			overflow-y: hidden;
			text-align: center;
		}

		ul {
            list-style-type: none;
            display: inline-flex;
			justify-content: flex-start;
			padding: 10px 10px 4px 10px;
            margin: 0 auto 1.125rem auto;
            flex-wrap: nowrap;
			@include respond(0, 1023px) {
				display: inline-flex;
                height: 70px;
				justify-content: flex-start;
                flex-wrap: nowrap;
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 0.625rem;
			}
		}

		li {
			padding: 0 10px 6px;
			line-height: 1;

			&:first-child {
				padding-left: 5px;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		a {
            padding-left: 26px;
            padding-right: 26px;
            font-weight: 700;
            white-space: nowrap;
            padding-top: 0.475em;
            padding-bottom: 0.475em;
            margin: 7px 0;
		}
	}
}

.account__nav .secondary-navigation--custom.account-navon-wl a {
    &.secondary-navigation__link--wishlist {
        @extend .profile-lnk-on;
    }
}

.account__nav .secondary-navigation--custom.account-navon-oh a {
    &.secondary-navigation__link--orders {
        @extend .profile-lnk-on;
    }
}

.account__nav .secondary-navigation--custom.payment-on {
    .secondary-navigation__link--info {
        @extend .profile-lnk-on;
    }
}

.account__nav .secondary-navigation--custom.account-navon-msi a {
    &.secondary-navigation__link--subscriptions {
        @extend .profile-lnk-on;
    }
}

.profile-lnk-on {
	background: #f7f7f7;
	border-color: #C4C4C4;
	color: $color-primary;
}

.profile-body {
	margin: 0 auto 30px auto;
    padding: 0 10px 10px 10px;

	@include respond(1024px) {
		padding: 0 10px 85px;
        max-width: 1140px;
	}

}
.order-unregistered {
    .profile-body {
        margin-top: 9px;
        @include respond(largeUp) {
            margin-top: 17px;
        }
    }
}

.profile-box {
	box-shadow: 0px 1px 4px #999;
	background: $white;

	&.profile-box--order-status {
		padding-bottom: 60px;
	}
}

.profile-heading {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	background: $black;
	border-bottom: 5px solid #E41837;

	@include respond(1024px) {
		margin-bottom: 38px;
	}

	span.is-hidden-desktop::after {
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 8px solid #fff;
		margin: 0 0 5px 14px;
		cursor: pointer;
		transition: all ease-in-out 0.5s;
	}
}

.pt_account-pages {
	h1 {
		margin: 12px 20px;
		color: $white;
		font-size: 26px;
		font-family: $titleCondensedFontFamily;
		text-transform: uppercase;

		@include respond(1024px) {
			margin: 16px 50px;
			font-size: 32px;
            line-height: 1.2;
		}
	}
}

.profile-columns {
	min-height: 250px;
	padding-bottom: 10px;
	flex-direction: column;

	@include respond(1024px) {
		flex-direction: row;
		padding-bottom: 40px;
	}

	&.padded {
		display: flex;
		position: relative;
		padding: 0 50px 0 20px;

		@include respond(1024px) {
			min-height: 550px;
		}
	}
}

.profile-column__wide {
	padding: 0 10px;

	@include respond(1024px) {
		padding: 0 92px;
	}
}

.profile-box.expand {
	.profile-left-column {
		max-height: 300px;
		margin-bottom: 14px;
		transition: all 0.5s ease-in;

		&::after {
			background-color: #E41837;
		}
	}

	.profile-heading h1 span.is-hidden-desktop::after {
		transform: rotate(180deg);
	}
}

.profile-left-column {
	padding: 20px 10px 10px 10px;
    flex-basis: 24%;
    z-index: 1;

	@include respond(0, 1023px) {
		flex-basis: 100%;
		flex: none;
		padding: 0;
		right: 10px;
		left: 10px;
		background: $white;
		max-height: 0;
		position: relative;
		transition: all 0.5s ease-out;
		margin-left: -20px;
		overflow: hidden;
	}

	&::after {
		content: '';
		position: absolute;
		background-color: transparent;
		width: calc(100% + 20px);
		height: 1px;
		bottom: 0;
		left: -10px;
		transition: all 0.5s ease-out;
	}

	ul {
		list-style-type: none;
		margin: 0 0 13px;
		padding: 0;
	}

	li {
		display: block;
		background-size: auto;
		background-repeat: no-repeat;
		background-position: left center;
		padding: 4px 10px 4px 36px;

		&.selected {
			a {
				border-bottom: 1px solid $color-secondary;
			}
		}

		&.account-icon {
			background-image: url('../../images/account/news.svg');
		}

		&.payment-icon {
			background-image: url('../../images/account/payment.svg');
		}

		&.address-icon {
			background-image: url('../../images/account/address.svg');
		}

		&.visibility-icon {
			background-image: url('../../images/account/visibility.svg');
		}

		&.game-icon {
			background-image: url('../../images/account/game-details.svg');
		}

		&.verification-icon {
			background-image: url('../../images/account/military.svg');
		}
	}

	a {
		color: $black;
		border-bottom: 0 none;
		font-weight: 700;
		text-decoration: none;

		&:hover {
			border-bottom: 1px solid $color-secondary;
		}
	}
}

.profile-right-column {
	padding: 20px 10px 50px 10px;
	flex-basis: 76%;
	z-index: 10;

	&.narrow {
		flex-basis: 76%;
		padding-right: 20%;

		@include respond(0, 1023px) {
			flex-basis: 90%;
			padding-top: 4px;
			padding-right: 8%;
			padding-left: 0;
			padding-bottom: 20px;
		}
	}

	h2 {
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 12px;
		margin-top: 0;
		margin-bottom: 18px;
	}

	.content-box {
		margin-bottom: 20px;
		padding: 0;
		list-style: none;

		h3 {
			font-size: 14px;
			text-transform: uppercase;
			margin: 0;
		}

		li {
			margin: 0;
			font-size: 13px;
			line-height: 1.3;

			&:first-child {
				padding-top: 0;
			}
		}

		.button-text {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.cc-list {
		h3 {
			margin-bottom: 10px;
		}

		.content-box {
			li {
				border-bottom: 1px $clp-border solid;
				padding: 12px 0;

				&.last,
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

.is-hidden-mobile {
	@include respond(0, 900px) {
		display: none !important;
	}
}

.is-hidden-desktop {
	@include respond(901px) {
		display: none !important;
	}
}

.content-header {
	display: flex;
	align-items: end;
	margin-bottom: 30px !important;
	border-bottom: 1px solid #E3DEDE;

	@include respond(largeUp) {
		margin-bottom: 14px;
	}

	h2 {
		text-transform: capitalize;
		font-size: 1.5rem;
		flex: 2;
		margin: 0;
		padding-bottom: 12px;
        align-self: flex-end;
        padding-top: 6px;
        line-height: 1.3;
	}
}

.account-nav-asset {
	padding-right: 10px;
	align-self: flex-end;
	line-height: 1;

	@include respond(1024px) {
		padding-bottom: 12px;
	}

	&.account-nav-asset--footer {
		display: block;

		@include respond(1024px) {
			display: none;
		}
	}
}

.contact {
    h3 {
        margin: 0;
        color: #4C4C4C;
        text-transform: uppercase;
        line-height: 0.6;
        font-size: .688rem;
    }

    p {
        background: url('../../images/account/icon-phone.png') no-repeat left center;
        padding-left: 20px;
        padding-right: 5px;
        font-size: .688rem;
        background-size: 18px;
        margin: 0;
        display: inline-block;
        color: $black;
        line-height: 1.9;

        a {
            color: $black;
            text-decoration: none;
            font-size: .688rem !important;
            max-height: 20px;
            font-weight: 700;
        }
    }

    span {
        color: #787878;
        display: block;
        line-height: 1.5;
        font-size: .688rem;
        display: inline-block;
    }

    em {
        display: block;
        font-size: 11px;
        font-style: normal;
        color: #787878;

        a {
            font-size: .688rem;
            color: $color-secondary;
            text-decoration: underline;
        }
    }
}

.club-pdp-recommendations.order-history-wishlist {
	margin: 0 auto;
	padding: 80px 0 50px;
	@media screen and (min-width: 992px) {
		padding: 80px 0 120px;
	}
	.product-content-headers {
		text-align: left;
		margin: 0 auto;
		padding: 0 5%;
		margin-bottom: 44px;
		@media screen and (min-width: 385px) {
			padding: 0 7%;
		}
		@media screen and (min-width: 400px) {
			padding: 0 10%;
		}
		@media screen and (min-width: 551px) {
			padding: 0 10% 0 25%;
		}
		@media screen and (min-width: 992px) {
			padding: 0 10%;
			margin-left: 11px;
			margin-right: 11px;
			margin-bottom: 64px;
		}
		@media screen and (min-width: 1200px) {
			padding: 0 calc((100% - 1140px) / 2) !important;
		}
	}
	.product-content-subheader {
		margin-top: 0;
		font-size: rem(24px);
		text-transform: uppercase;
		line-height: 1;
		text-align: left;
		margin-bottom: 0.3em;
		@media screen and (min-width: 992px) {
			font-size: rem(30px);
		}
	}
	.link {
		text-decoration: underline;
	}
	.button-text {
		text-decoration: underline;
	}
	.product-tile .product-tile-details {
		padding: 0;
	}
	.product-tile .product-category-primary {
	margin-bottom: 10px;
	}
}

.contact-asset {
    padding-top: 20px;
}

.recos-myaccount {
    background-color: $white;
}

.content-collection {
	.content-item {
		margin-bottom: 20px;
		min-height: 120px;
	}

	.content-item-header {
		display: flex;
		justify-content: space-between;
		padding: 10px 15px;
		border: 1px solid #e6e6e6;
		background-color: #f7f7f7;
		font-size: 0.813rem;

		@include respond(1024px) {
			align-items: center;
		}

		.button-text {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.item__date__label {
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	.content-item-detail-wrapper {
		border-left: 1px solid #e6e6e6;
		border-right: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		padding: 14px 2px;

		@include respond(1024px) {
			padding: 0 20px 0 20px;
		}
	}

	.content-item-detail {
		display: flex;
		flex-wrap: wrap;
	}

	.item__remove {
		button {
			font-size: 12px;
		}
	}

	.item-image {
		flex: 0 0 166px;
        img {
            width: 100%;
        }

		@include respond(900px) {
			flex: 0 0 200px;
		}
	}

	.item-details {
		flex: 1;
		padding-bottom: 0;
		font-size: 14px;
		line-height: 18px;
        padding-top: 5%;

        &.item-wishlist {
            margin-left: 20px;

			@include respond(largeUp) {
				margin-left: 40px;
				margin-bottom: 20px;
			}

            .price {
                display: none;
            }
        }
		.product-list-item .name {
			font-size: 14px;
			font-weight: 700;
			font-style: normal;

			a {
				color: $black;
			}
		}
        .attribute,
		.sku,
		.notavailable {
            font-size: 0.813rem !important;

			.label {
				font-weight: bold;
			}

			.value {
				font-weight: normal;
			}
        }
	}

	.item-dashboard {
		flex: 1 1 100%;
		padding: 20px 0 30px 186px;

		@include respond(1024px) {
			margin-left: auto;
			width: auto;
			padding: 40px 18px 20px 0;
			flex: initial;
		}
	}

	.product-availability-list {
		li {
			margin: 0;
			padding: 0;
			line-height: normal;
			font-size: 0.813rem;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	.option-add-to-cart {
		margin-top: 10px;
		width: auto;

        .qty-lbl {
            font-size: 0.875rem;
            color: #4C4C4C;
        }

		.quantity-dropdown {
			width: auto;
			margin: 0;
		}

		button {
			flex: 1 1 100%;
			min-width: 125px;
			margin-top: 10px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.item-edit {
		text-decoration: underline;
		font-size: 0.813rem;

		&:hover {
			text-decoration: none;
		}
	}

	.login-order-track {
		max-width: 428px;
		margin-bottom: 25px;

		label {
			display: flex;
			gap: 1px;

			span {
				order: 1;
				font-size: 16px;
				line-height: 1;
			}

			.required-indicator {
				color: #4C4C4C;
				order: 2;
			}
		}

		#dwfrm_ordertrack_postalCode {
			max-width: 177px;
		}
	}
}

.profile-heading-toggle {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none !important;
	padding: 0;
	margin: 0;
	background: none !important;
	opacity: 0 !important;
}

.ui-dialog.ui-dialog #dialog-container {
	width: 100% !important;

	#edit-address-form,
	#CreditCardForm {
		.form-row label {
			float: none;
			width: auto;
			font-size: 14px;
			font-weight: 700;
			line-height: normal;
		}

		.field-wrapper {
			width: 100%;
			float: none;
		}

		.form-row .form-caption {
			margin-left: 0;
			font-size: 12px;
		}

		.form-row.month,
		.form-row.year {
			width: calc(50% - 10px);

			.field-wrapper select {
				width: 100%;
			}

			label {
				display: block;
				float: none;
				height: auto;
				width: auto;
				overflow: initial;
				position: static;
			}
		}

		.month {
			margin-right: 10px;
		}

		.year {
			margin-left: 10px;
		}

		.form-label-text {
			display: none;
		}

		.form-row-button.form-row-button {
			width: 100%;
			margin-left: 0;
		}

		.form-field-tooltip {
			display: none;
		}

		#dwfrm_paymentinstruments_creditcards_newcreditcard_type {
			width: calc(50% - 10px);
		}

		.form-row--dwfrm_paymentinstruments_creditcards_address_postal,
		.form-row--dwfrm_paymentinstruments_creditcards_address_country {
			width: calc(50% - 10px);
		}

		.form-row--dwfrm_paymentinstruments_creditcards_address_postal {
			float: left;
		}

		.form-row--dwfrm_paymentinstruments_creditcards_address_country {
			float: right;
		}

		.form-row--dwfrm_paymentinstruments_creditcards_address_states_state {
			clear: both;
		}

		.input-text,
		.input-select {
			transition: box-shadow 0.1s linear;

			&:hover,
			&:focus {
				box-shadow: 0px 0px 0px 2px $black;
			}
		}
	}
}

#wrapper.pt_account #primary .account-wrapper,
#wrapper.pt_wish-list #primary .account-wrapper,
#wrapper .account-menu,
.members-only-modal,
.members-only-modal-another {
	.password {
		position: relative;

		.password__show-hide {
			display: block;
			position: absolute;
			top: 30px;
			right: 15px;
			margin: 0;
			padding: 0;
			border: none;
			width: 23px;
			height: 19px;
			min-width: auto;
			background: url('../../images/account/hide-password.svg');

			@include respond(small) {
				top: 33px;
			}

			&.password__show-hide--active {
				background: url('../../images/account/show-password.svg');
			}

			&::before,
			&::after,
			&:hover::before,
			&:hover::after {
				display: none;
			}
		}

		.form-row {
			margin-bottom: 0;

			.form-caption {
				display: none;
			}
		}

		.form-caption {
			margin-bottom: 20px;
			color: #989898;
			font-size: 11px;

			.password__validation {
				margin-right: 10px;

				&.valid::after {
					content: '\2713';
					display: inline-block;
					color: #307C2E;
					padding-left: 2px;
					font-size: 14px;
				}
			}
		}
	}
}

.members-only-modal,
.members-only-modal-another {
	.password {
		.field-wrapper {
			position: relative;
		}

		.password__show-hide {
			top: initial;
			bottom: 6px;
			right: 0;
		}

		.form-caption {
			margin-bottom: 0;
		}
	}
}

.members-only-dialog.ui-dialog .members-only-account .form-row .form-caption {
	display: block;
}

::-ms-reveal {
    display: none;
}

// MSI
#og-msi {
	.og-message-main {
		border: none;
		box-shadow: none;
		color: $color-primary;
		font-size: 14px;
		line-height: 20px;
	}
}

#wrapper {
    &.pt_account {
		&.pt_account_login_register {
			.account-hidden {
				display: none;
			}

			.returning-customers-container {
				h3 {
					display: none;
				}
			}

			#primary {
				.col-1 {
					.login-box-content {
						form {
							.reset-pass {
								a#password-reset {
									font-size: 14px;
								}
							}

							.form-row.label-inline {
								label {
									font-size: 14px;
									padding-left: 25px;
								}
							}
						}

						.new-customers-container {
							display: none;

							&.account {
								display: block;
							}
						}
					}
				}

				.col-2 {
					margin: 0;
					min-height: 0;
					width: auto;
				}
			}
		}
	}
}


#wrapper.pt_order,
#wrapper.pt_order-confirmation {
	#main {
		.som-order-history {
			.order-products {
				.og-cart-container {
					display: none;
				}
			}
		}

		.content-detail {
			.som-attributes {
				.og-cart-container {
					display: none;
				}
			}
		}
	}
}

#wrapper.pt_account.pt_account_login_register #primary {
    .col-2 {
        margin: 0;
        min-height: 0;
        width: auto;
    }
    .col-1 .login-box-content form .form-row.label-inline label {
        font-size: 14px;
        padding-left: 25px;
        line-height: 20px;
    }
}

.edit-product-modal {
	.ui-dialog-content {
		.pdp-main {
			.quickview-nav {
				.quickview-prev,
				.quickview-next {
					display: none;
				}
			}
		}
	}
}

.pt_wish-list {
	#primary {
		.login-box-content {
			.new-customers-container {
				display: none;

				&.account {
					display: block;
				}
			}

			.returning-customers-container-text {
				display: none;
			}
		}
	}
}


.checkoutlogin {
	.col-2 {
		p {
			&.checkout-create-account-info {
				margin-bottom: 0;
				font-size: 14px;
				letter-spacing: normal;
				line-height: 20px;
			}
		}
	}
}


.profile-box {
    .item-edit-details {
        padding: 0 !important;
    }
}
