.sms-landing-page {
    .sms-landing-container {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(444px);
        text-align: center;
        padding-left: rem(15px);
        padding-right: rem(15px);
        padding-bottom: rem(20px);
        @media screen and (min-width: 1024px) {
            padding-bottom: rem(60px);
        }
        #errorPlaceHolder {
            color: #e31837;
        }
    }
    .sms-landing-container-header {
        color: #000000;
        font-size: rem(28px);
        font-weight: 500;
        letter-spacing: rem(0.39px);
        line-height: 1;
        margin-bottom: rem(34px);
        margin-top: rem(39px);
        position: relative;
        &::after {
            content: '';
            background-color: #E41837;
            bottom: rem(-20px);
            display: inline-block;
            height: rem(3px);
            left: 50%;
            position: absolute;
            transform: translate(-50%, 100%);
            width: rem(113px);
        }
    }
    .sms-landing-container-subheader {
        color: #4C4C4C;
        font-size: rem(14px);
        line-height: rem(20px);
    }
    .fi_sms_marketing-form {
        .form-row {
            max-width: rem(307px);
            margin-left: auto;
            margin-right: auto;
            label {
                text-align: left;
            }
            .field-wrapper {
                span.error ~ span.error {
                    display: none !important;
                }
            }
        }
        .receive-messages {
            margin-bottom: 0;
            label {
                display: inline-block;
                color: #4C4C4C;
                font-size: rem(12px);
                line-height: rem(16px);
                font-weight: 400;
            }
            .sms-landing-read-more-btn {
                font-size: rem(12px);
                line-height: rem(16px);
                color: #e31837;
                text-decoration: underline;
                position: relative;
                cursor: pointer;
                &::after {
                    content: '';
                    background-image: url(../images/icons/arrow-right-red.svg);
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: rem(13px) rem(13px);
                    transform: rotate(90deg);
                    width: rem(13px);
                    height: rem(17px);
                    position: absolute;
                    top: rem(1px);
                    right: rem(-18px);
                    display: inline-block;
                    cursor: pointer;
                }
                &.show {
                    &::after {
                        transform: rotate(270deg);
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .sms-landing-read-more-content {
            display: none;
            font-size: rem(12px);
            line-height: rem(16px);
            color: #4C4C4C;
            margin-bottom: rem(7px);
            p {
                font-size: rem(12px);
                line-height: rem(16px);
                margin: 0;
                & + p {
                    margin-top: rem(12px);
                }
            }
            a {
                font-size: rem(12px);
                line-height: rem(16px);
                color: #4C4C4C;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
            &.show {
                display: block;
            }
        }
    }
}
