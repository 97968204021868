.plp-wrapper {
	.didyou-mean {
		margin: -20px 0 20px;
	}
	.no-results-content {
		margin-top: 2em;
		border-top: $base-border;
	}
	&.no-products {
		#secondary,
		#search-results-main {
			display: none;
		}
		#primary {
			width: 100% !important;
		}
	}
	.no-hits-search,
	.no-results-content {
		@include respond(small) {
			padding: 0 20px;
		}
	}
	#resultToggle {
		.isrefine-false li.appended-res,
		.isrefine-price li.main-togglinks {
			display: none;
		}
		.category-refs li.appended-res {
			display: block;
		}
	}
	#search-results-main {
		@include respond(largeUp) {
			padding-left: 10px;
		}
		&.toggle-refined {
			.search-results-content {
				display: none;
			}
		}
		&.toggle-products .results-products,
		&.toggle-content .results-content,
		&.toggle-discussions .results-discussions,
		&.toggle-players .results-players,
		&.toggle-products .results-products {
			display: block;
		}

	}
	.search-results-content {
		color: $black;
			margin-bottom: 70px;

		@media screen and (max-width: 768px) {
			padding: 0 20px;
			margin-top: -10px;
		}
		h3 {
			text-transform: uppercase;
		}
		h3 {
			padding-bottom: 6px;
			border-bottom: $base-border;
			margin-bottom: 20px;
			&.noline {
				border-bottom: none;
				padding: 0;
				margin-bottom: 13px;
			}
		}
		.searchres-description {
			font-size: 14px;
			margin-bottom: 35px;
			color: #4C4C4C;
			line-height: 1.9;
		}
	}
	.searchres-player {
		margin-bottom: 35px;
		
	}
	.searchres-date {
		font-size: 12px;
		text-transform: uppercase;
		margin-top: 6px;
		color: #949494;
		height: 15px;
		span {
			padding-right: 6px;
		}
	}
	.searchres-discussion {
		margin-bottom: 25px;
		font-size: 14px;	
		line-height: 1.9;
		color: #4C4C4C;
	}
	.searchres-block {
		display: none;
		.searchres-readmore,
		.searchres-less {
			display: none;
		}

		&.line-clamp {
			.searchres-discussion {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;							
			}
		}
	}
	.results-players {
		ul {
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			li {				
				margin-right: 20px;
				line-height: 1.9;
				.contentres-player {
					font-size: 14px;
					@include respond(largeUp) {
						font-size: 16px;
					}
				}
				@include respond(largeUp) {
					width: calc(25% - 15px);
					&:nth-child(4n) {
						margin-right: 0;
					}
				}
				@media screen and (max-width: 768px) {
					width: calc(50% - 10px);
					&:nth-child(2n) {
						margin-right: 0;						
					}
				}
				a {
					display: block;
					&.view-player {
						color: #4C4C4C;
						text-decoration: underline;
						margin: -7px 0 11px 0;
						font-size: 12px;
						@include respond(largeUp) {
							font-size: 14px;
						}
					}
				}
				img.player-thumb {
					height: auto;
					width: 100%;
				}
				.player-info {
					padding: 0 5px 15px 10px;
					.contentres-player-badge {
						margin: -7px 0 0px;
						font-style: italic;
						font-size: 12px;
						@include respond(largeUp) {
							font-size: 14px;
						}
					}					
				}
				.players-tour {
					display: inline-block;
					background-color: #ddd;
					color: #666;
					font-size: 9px;
					padding: 4px 5px;
					margin-bottom: 0;
					text-transform: uppercase;
					line-height: normal;
				}
				.players-logos {
					margin-bottom: 3px;
					display: flex;

					.player-logo-right {
						margin-left: auto;
					}
				}
			}
		}
	}
	.contentres-lable {
		font-weight: 700;
		font-size: 14px;
		color: $black;		
		&:hover {
			color: #4c4c4c;
			text-decoration: underline;
		}
	}
	.loading-loadmore {
		display: flex;
		justify-content: center;
		align-items: center;	
	}
	.load-more-content,
	#search-results-main.refined-search .solr-result,
	.infinite-scroll-placeholder.complete {
		display: none;
	}
	.load-more,
	.load-more-content {
		padding: 14px 85px 14px 50px;
		height: 44px;
		span {
			&::after {
				content: "";
				background: url("../images/icons/loadmore.png") no-repeat;
				width: 24px;
				height: 25px;
				position: absolute;
				top: 9px;
				right: 40px;
			}

		}
		&.complete {
			display: none !important;
		}
    }
	#search-result-items {
		&.loading-indicator:before {
			content: '';
			display: block;
			position: fixed;
			background: url("../images/infinite-loading-indicator.gif") center no-repeat;
			width: 220px;
			height: 34px;
			top: calc(50% - 110px);
			left: calc(50% - 100px);
			z-index: 1;
			@include respond(largeUp) {
				top: calc(50% - 20px);
			}
		}
	}
}
.pt_plp-page {
	.no-hits-search,
	.search-header-top form {
		margin-top: 0;
		color: $black;
		.search-button {
			float: right;
			margin: -37px 14px 0 0;                
			.icon-v2 {
				width: 24px;
				height: 24px;
			}
		}
		::placeholder {
			color: #6c6c6c;
			font-style:normal;
		}
		.search-q {
			margin-left: -3px;
			color: $black;
		}
		input[type="text"] {
			padding: 9px 2.5em 9px 1em;
			background-color: #F6F6F6;
		}
	}
	.no-hits-search {
		@include respond(largeUp) {
			width: 34%;
			padding-left: 10px;
		}
	}
}

body.toggles-on {
	.pt_clubs-search-result #secondary .product-refinements,
	.pt_clubs-search-result #refined-by-attributes .selected-attributes {
		display: none;
	}
}
