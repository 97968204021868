html {
    //override for global app_storefront style causing mobile issues
    overflow: inherit !important;
	&.freeze-scroll {
		@media screen and (max-width: 799px) {
			position: fixed !important;
			top: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			left: 0 !important;
		}
        overflow: hidden !important;
		body {
			@media screen and (min-width: 800px) {
				padding-right: 17px;
			}
		}
    }
	&.freeze-scrolling {
        overflow: hidden !important;
    }
}

body {
	background-color: $body-bg-color;
    @include font-regular(12px);
    letter-spacing: $globalLetterSpacing;
    color: $primary-color;
    margin: 0;
	-webkit-font-smoothing: antialiased;
}

h1,h2,h3,h4,h5{
    font-weight: $titleFontWeight;
    font-family: $titleFontFamily;
    color: $black;
    letter-spacing: normal;
}
h1 {
    @include h1;
}

h2 {
    @include h2;
}

h3 {
    @include h3;
}

h4 {
    @include h4;
}
a {
    color: $red;
    text-decoration: none;
    letter-spacing: $globalLetterSpacing;

    &:hover,
    &:focus {
        color: $primary-color;
        border-bottom-color: rgba(0, 0, 0, 0.1);
    }
}
p {
    letter-spacing: $globalLetterSpacing;

    a {
        @include font-regular(14px);
    }
}
ol,ul{
    padding-left: calculateEm(15px);
    li{
        @include font-regular(14px);
        @include line-height(26px);
        color: $primary-color;
        padding: calculateEm(10px) 0;
    }
}
fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}
input, textarea, keygen, select, button {
    letter-spacing: $globalLetterSpacing;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}

input[type="radio"] {
    background-color: rgba(255, 255, 255, 0.00784314);
    &[checked="checked"], &:checked {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

//the global page-width wrapper
.innerWidth{
    margin: 0 auto;
    max-width: $standard-breakpoint;
    clear: both;

    /*@include respond(large) {
        max-width: $small-breakpoint;
    }
    @include respond(small) {
        max-width: $small-breakpoint;
    }*/

    &:after{
        content: '';
        clear: both;
        float: none;
        display: block;
    }
}
form{
    .form-row.error{
        .error,
        .error-message{
            color: $error-red;
        }
    }
}
#wrapper{
    .error-form{
        border: 0;
        float: left;
        width: 100%;
    }
}
.icon-v2 {
    font-family: $titleist-icons;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    @include font-size(17px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.js .icon-v2 {
    @include opacity(1);
    -webkit-transition: opacity linear 0.2s;
    -moz-transition: opacity linear 0.2s;
    -ms-transition: opacity linear 0.2s;
    transition: opacity linear 0.2s;
}
.ui-dialog .ui-dialog-titlebar-close{
    height: 30px;
}
.ui-dialog .ui-icon-closethick{
    background: none !important;
    cursor: pointer;
    height: 25px;
    width: 25px;
    z-index: 111;
    &:before{
        content: "\e608";
        font-family: $titleist-icons;
        @include font-size(20px);
        font-weight: 100;
        color: $primary-color;
        position: absolute;
        left: 0;
        top: 0;
        text-indent: 0;
    }
}

.ui-dialog.egc-dialog {
	left: 50%!important;
	transform: translateX(-50%);
	max-width: 742px!important;
	width: 100%!important;
	.dialog-content {
		width: 100%!important;
		.content-asset {
			a {
				outline: 0px solid transparent;
			}
		}
	}
}

.tooltip-icon {
	display: inline-block;
	text-decoration: none;
	@include font-size(12px);

	&:before {
		content: '?';
		display: block;
		border-radius: 50%;
		border: 1px solid $gray-warm-old;
		color: $gray-warm-old;
		height: 1.167em;
		width: 1.167em;
		line-height: 1.167em;
		text-align: center;
	}
}

.ui-tooltip-content .close-tooltip {
    @include media-breakpoint-down(md) {
        height: 16px;
        line-height: inherit;
        width: 15px;
        padding: 0;
        line-height: 15px;
        margin-left: 10px;
    }
}

.ui-tooltip {
    background: #000 !important;
    padding: rem(10px) !important;
}
.ui-tooltip-content {
    padding: rem(8px) !important;
    p,
    ul li {
        font-weight: 400 !important;
        font-size: rem(10.5px) !important;
        color: $white !important;
        line-height: normal !important;
    }
    ul li {
        padding: 2px 0;
    }
}

.desktop-only {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.mobile-only {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.hide {
	&.d-none {
		display: none !important;
	}
}

.overlay-layer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background-color: $white;
	&.show {
		display: block;
	}
}

