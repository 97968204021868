.global-notification-banner {
    background: $black-haze;
    .js-global-notification-banner {
        &.slick-slider .slick-list .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.global-notification-banner__wrapper {
    position: relative;
    max-width: 680px;
    margin: 0 auto;

    &:not(.slick-initialized) {
        overflow: hidden;

        .global-notification-banner__slide {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .slick-arrow {
        width: 20px;
        height: 100%;
        margin: 0;
        top: 0;
        opacity: 1;
        background: $black-haze url(../images/icons/arrow-left-black.svg) no-repeat center center;
        background-size: 5px;
    }

    .slick-prev {
        left: -3px;

        @include respond(largeUp) {
            left: 0;
        }
    }

    .slick-next {
        right: -3px;
        transform: rotate(180deg);

        @include respond(largeUp) {
            right: 0;
        }
    }
}

.global-notification-banner__slide {
    padding: 10px 20px;
    text-align: center;
    font-size: 0.75rem;
    color: $greyText;
    font-weight: 400;
}

.global-notification-banner__header {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: $greyText;
    font-weight: 600;
    overflow: hidden;

    @include respond(small) {
        display: block;
    }
}

.global-notification-banner__copy {
    font-size: 0.75rem;
    color: $greyText;
    font-weight: 400;
    overflow: hidden;

    @include respond(small) {
        display: block;
    }

    .global-notification-banner__link {
        @include respond(small) {
            display: inline;
        }
    }
}

.global-notification-banner__link,
.global-notification-banner__slide a {
    font-size: 0.75rem;
    color: $greyText;
    font-weight: 400;
    text-decoration: underline;
    overflow: hidden;

    @include respond(small) {
        display: block;
    }

    &:hover {
        text-decoration: none;
    }
}
