
nav.primary.mobile,
nav.primary.mobile .nav-animate-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff
}

nav.primary.mobile {
	font-family: $sans-serif;
	font-weight: 400;
	padding: 0;
	z-index: 100;
	transition: height .5s ease;
	display: none;
	margin: 0 auto
}

@media (max-width:899px) {
	nav.primary.mobile {
		font-size: .875em
	}
}

@media (max-width:1199px) {
	nav.primary.mobile {
		display: block
	}
}

nav.primary.mobile .nav-animate-container {
	box-sizing: border-box;
	padding-top: 30px;
	height: 71px;
	border-bottom: 1px solid #f2f2f2
}

nav.primary.mobile.is-fixed .nav-animate-container {
	transform: translateY(-100%)
}

nav.primary.mobile.is-animated .nav-animate-container {
	transition: transform .3s ease
}

nav.primary.mobile.is-visible .nav-animate-container {
	transform: translateY(0)
}

nav.primary.mobile .top-content {
	right: auto;
	top: 0;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: #f2f2f2;
	text-align: center;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 7pt
}

nav.primary.mobile .middle-content {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%
}

nav.primary.mobile .left-content {
	display: flex;
	justify-content: space-evenly
}

nav.primary.mobile .mobile-nav-lines {
	position: relative;
	width: 20px;
	height: 24px
}

nav.primary.mobile .mobile-nav-lines .line {
	background-color: #000;
	border-radius: 2px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	transition: all .2s ease
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(1) {
	width: 20px;
	height: 2px;
	top: 8px
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(2) {
	width: 20px;
	height: 2px;
	top: 13px
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(3) {
	width: 20px;
	height: 2px;
	top: 18px
}

nav.primary.mobile .search.icon {
	position: relative;
	top: 6px;
	width: 18px;
	height: 18px
}

nav.primary.mobile .cart.icon,
nav.primary.mobile .profile.icon {
	position: relative;
	top: 6px;
	width: 16px;
	height: 18px
}

nav.primary.mobile .cart.icon {
	width: 17px
}

nav.primary.mobile .left-content,
nav.primary.mobile .right-content {
	width: 75px;
	position: relative;
	top: 4px
}

nav.primary.mobile img.logo {
	width: 110px;
	height: auto
}

nav.primary.mobile a {
	display: flex;
	flex-direction: column;
	justify-content: flex-start
}

#wrapper {
    overflow-x: hidden;
}
nav.primary.mobile {
    &.is-fixed-layout {
        position: fixed;
        top: 1px;
        transform: translateY(-1px);
		.mini-bag-content.cart-show {
			min-height: calc(100dvh - 5em);
			right: 0;
		}
    }
    .mini-cart-link,
    .nav-item {
        position: relative;
        z-index: 1;
        .mini-bag-count-text {
            position: relative;
            top: .2em;
            font-size: rem(12.8px);
        }
    }
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(1) {
	transform: translateX(-50%) rotate(50deg);
	top: 13px
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(2) {
	opacity: 0;
	left: -5px
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(3) {
	transform: translateX(-50%) rotate(-50deg);
	top: 13px
}

nav.primary.mobile .mini-bag .nav-item .mini-bag-count {
	right: -5px;
}
