@keyframes cancelBtnAnimationBefore {
    0% {
        transform: translate(calc(-100% - 4.5em), -50%);
    }
    50% {
        transform: translate(calc(-3.75em), -50%);
    }
    100% {
        transform: translate(calc(-100% - 4.5em), -50%);
    }
}

@keyframes cancelBtnAnimationAfter {
    0% {
        left: -6em;
    }
    50% {
        left: calc(100% - 4em);
    }
    100% {
        left: -6em;
    }
}

body.refinements-active {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        width: auto;
        height: auto;
        overflow: auto;
    }
}

#wrapper {
    &.pt_clubs-search-result {
        .clubs-breadcrumbs {
            .breadcrumb {
                margin: 20px 0;
                @include media-breakpoint-up(lg) {
                    margin-top: 42px;
                }
            }
        }
        #main {
            #secondary {
                padding: 0 30px 150px;
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
            }
        }
    }
}

.pt_clubs-search-result {
    #secondary {
        position: fixed;
        top: 74px;
        left: 0;
        width: 100%;
        height: calc(100vh - 154px);
        overflow-y: auto;
        background-color: $white;
        z-index: 100;
        @include media-breakpoint-up(lg) {
            position: static;
            width: 195px;
            height: auto;
            overflow-y: visible;
            margin-left: 50px;
        }
        .swatches {
            li.selected {
                a {
                    color: $white;
                }
            }
        }
        li {
            line-height: 18px;
            &.unselectable {
                a {
                    color: $text-gray;
                }
            }
        }
        a {
            font-size: 13px;
            color: $black;
            text-decoration: none;

            &:focus {
                outline: none;
            }
        }
        .color {
            a {
                font-size: 11px;
            }
        }
        .category-refinement {
            h3 {
                display: block;
            }
            @include media-breakpoint-up(lg) {
                padding: 0;
                ul {
                    padding-bottom: 1.167rem;
                }
            }
            li {
                line-height: rem(15px);
                a {
                    text-transform: uppercase;
                    font-size: rem(12px);
                    &.active {
                        font-weight: 700;
                        &.sub-categories-active {
                            text-decoration: none;
                        }
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .category-refinement {
                    border-bottom: none;
                }
            }
            .category-level-2 {
                padding-bottom: 0 !important;
            }
            .refinement-top-level {
                padding: 0 0 rem(20px);
                a {
                    font-family: neuzeit-grotesk, sans-serif;
                    font-size: rem(12px);
                    color: $crimson;
                    text-transform: uppercase;
                }
            }
            .category-level-1 {
                padding-top: 0;
                &.category-one {
                    display: none;
                    border-bottom: none;
                }
            }
            .category-level-2 {
                padding: rem(10px) rem(20px);
                li a {
                    &.active {
                        text-decoration: underline;
                    }
                }
            }
            .root-parent {
                display: none;
            }
            .root-match {
                display: block;
                a.parent-link {
                    font-weight: 700;
                }
            }
        }
    }

    #refined-by-attributes {
        width: 100%;
        max-width: 1242px;
        margin: 0 auto;
        padding: 0;
        border: none;
        @include media-breakpoint-up(lg) {
            padding: 0 50px;
        }
        @include custom-media-breakpoint-up(xl) {
            max-width: none;
            padding: 0 150px;
        }
        @include respond(small) {
            position: static;
            overflow-x: auto;
            overflow-y: hidden;
            margin: 0 8px;
            width: auto;
            max-width: none;
            padding: 0;
            &::before,
            &::after {
                content: ' ';
                position: absolute;
                top: auto;
                bottom: unset;
                height: 37px;
            }
            &::before {
                left: 0;
                width: 11px;
                background: linear-gradient(90deg, #fff 0%, rgba(255,255,255,0) 100%);
            }
            &::after {
                left: unset;
                right: 0;
                transform: translateY(-100%);
                width: 15px;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #fff 100%);
            }
        }
        .selected-attributes {
            @include media-breakpoint-up(lg) {
                padding-bottom: 8px;
                margin-bottom: 0;
                border-bottom: $base-border;
            }
            @include respond(small) {
                display: inline-flex;
                max-width: 680px;
                margin: 0 0 5px;
                flex-wrap: nowrap;
            }
        }
    }
    .refined-by-attributes {
        .selected-attributes {
            display: flex;
            flex-wrap: wrap;
            margin: 30px 0 0;
            .selected-refinement {
                width: auto;
                border: $base-border;
                margin: 0 10px 10px 0;
                .selected-refinement-value {
                    display: flex;
                    align-items: center;
                    padding: 5px 2px 5px 8px;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    line-height: normal;

                    @include respond(small) {
                        padding: 5px 0 5px 5px;
                    }
                    span {
                        white-space: nowrap;
                    }
                    a {
                        margin: 2px 0 0 6px;
                        align-self: flex-end;
                    }
                }
            }
            .clear-all-refinements {
                white-space: nowrap;
            }
        }
    }

    .plp-actions {
        width: 100%;
        max-width: 1242px;
        margin: 0 auto 22px;
        background-color: $white;
        @include media-breakpoint-up(lg) {
            padding: 0 50px;
        }
        @include custom-media-breakpoint-up(xl) {
            max-width: none;
            padding: 0 150px;
        }

        .refinements-active & {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1001;
            @include media-breakpoint-up(lg) {
                position: static;
            }
        }

        .plp-actions-container {
            padding: 0 20px;
            border-top: $base-border;
            border-bottom: $base-border;
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
        .plp-actions-button {
            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }
            &.refinements-toggle-button {
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    height: 33px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .refinements-active & {
                    width: 100%;
                }
            }
            &.sort-by-button {
                padding: 12px 0;
                .refinements-active & {
                    display: none;
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
            }

            .plp-actions-toggle-refine {
                display: block;
                padding-left: 48px;
                margin: 8px 0;
                font-size: 13px;
                font-weight: 500;
                line-height: 40px;
                text-decoration: none;
                color: $black;
                position: relative;
                .refinements-active & {
                    @include media-breakpoint-down(md) {
                        background: url(../images/icons/icon-close-black.svg) center right 20px no-repeat;
                        background-size: 12px;
                    }
                }

                @include media-breakpoint-up(lg) {
                    margin: 0;
                }

                &:focus {
                    outline: none;
                }

                // background: url(../images/icons/icon-filter.svg) 0 center no-repeat;
                // background-size: 33px 33px;

                .icons-wrapper {
                    position: absolute;
                    left: 0;
                    width: 33px;
                    height: 33px;
                    @media screen and (max-width: 420px) {
                        top: 7px;
                    }
                }

                .icon {
                    display: block;
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    left: 0;
                    background: url(../images/icons/icon-filter-top.svg) right 7px no-repeat;
                    transition: background 0.3s ease-in;
                    cursor: pointer;

                    .refinements-active & {
                        background-position: left 7px;
                    }

                    &.icon-bottom {
                        transform: rotate(180deg);
                    }
                }
                @include media-breakpoint-up(lg) {
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .active-filter,
                .inactive-filter,
                .results-count {
                    white-space: nowrap;
                }

                .results-count {
                    @media screen and (max-width: 420px) {
                        display: block;
                        margin-top: -20px;
                    }
                }

                .active-filter {
                    display: none;
                    .refinements-active & {
                        display: inline-block;
                    }
                }

                .inactive-filter {
                    display: inline-block;
                    .refinements-active & {
                        display: none;
                    }
                }
            }
        }
    }

    .plp-refinement-mobile-cta {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px 30px 25px;
        background-color: $white;
        border-top: $base-border;
        z-index: 101;
        &::after {
            content: none;
        }
        .refinements-active & {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .button {
            width: calc(50% - 10px);
			padding: 0.6em;

            &.button-primary {
                background-color: $black;

				&:before {
					background-color: $color-primary;
				}
				&:after {
					border-left-color: $color-primary;
				}
            }
        }

        .button-clear-filter {
            line-height: 1.2;
            font-size: 16px;
            text-decoration: none;
            text-align: center;
            position: relative;
            border-radius: 2rem;
            border: none;
            padding: 0.6em;
            margin: 0.5em auto;
            display: inline-block;
            cursor: pointer;
            overflow: hidden;
            transform: translateZ(0);
            color: #fff;
            appearance: none;
            font-weight: 700;
            background-color: #999;
            width: calc(50% - 10px);

            &::before {
                content: " ";
                display: none;
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 6em;
                transition: 250ms ease transform;
                z-index: -1;
                transform: translate(calc(-100% - 4.5em), -50%);
            }

            &::after {
                content: " ";
                display: none;
                position: absolute;
                top: 50%;
                width: 0;
                height: 100%;
                border-top: 3em solid transparent;
                border-bottom: 3em solid transparent;
                border-left-width: 3em;
                border-left-style: solid;
                box-sizing: border-box;
                transform: translateY(-50%);
                transition: 250ms ease all;
                z-index: -2;
                left: -6em;
            }

            &.active {
                &::before {
                    display: block;
                    background-color: #4c4c4c;
                    animation: 0.5s linear 0s cancelBtnAnimationBefore;
                }

                &::after {
                    display: block;
                    border-left-color: #4c4c4c;
                    animation: 0.5s linear 0s cancelBtnAnimationAfter;
                }
            }
        }
    }

    .sort-by {
        float: none;
        min-width: 0;
        max-width: none;
        margin: 0;
        form {
            display: flex;
            align-items: center;
        }
        label {
            display: none;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            white-space: nowrap;
            margin: 0 20px 0 0;
            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }
        select {
            position: relative;
            height: 32px;
            line-height: inherit;
            min-width: 162px;
            font-weight: 500;
            text-transform: capitalize !important;
        }
    }

    .refinements {
        display: none;
        .refinements-active & {
            display: block;
        }

        .refinement {
			h3 {
				text-transform: none;
			}
		}

        .refinement,
        .content-refinement {
            border-bottom: $base-border;
            h3.toggle {
                &.expanded {
                    i {
                        transform: rotate(0);
                    }
                }
                i {
                    background: url("../images/icons/arrow-down-black.svg") no-repeat center;
                    background-size: 14px 8px;
                    transform: rotate(-90deg);
                    transition: .15s ease;
                }
            }
            &.putter-length,
            &.wedge_loft {
                .swatches {
                    li {
                        width: calc(33% - 3px);
                    }
                }
            }
            .swatches {
                display: block;
                width: auto;
                margin-right: -5px;
                @include media-breakpoint-up(lg) {
                    margin-right: -3px;
                }
                &.gearBagTopCuffDividers,
                &.gearCollection,
                &:not(.color) {
                    margin-right: 0;
                    li {
                        width: calc(32% - 4px);
                        max-width: none;
                        margin-bottom: 8px;
                        margin: 0 8px 8px 0;
                        a {
                            width: 100%;
                        }
                        &.selected {
                            a {
                                background-color: $black;
                            }
                        }
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                }
                &.hand,
                &.orientation {
                    margin-right: 0;
                    li {
                        width: calc(50% - 4px);
                        max-width: none;
                        margin-bottom: 8px;
                        margin-right: 0;
                        &:nth-child(odd) {
                            margin-right: 8px;
                        }
                        a {
                            width: 100%;
                        }
                        &.selected {
                            a {
                                background-color: $black;
                            }
                        }
                    }
                }
                li {
                    width: calc(25% - 5px);
                    max-width: 86px;
                    margin: 0 5px 5px 0;
                    padding: 0;
                    @include media-breakpoint-up(lg) {
                        width: calc(25% - 3px);
                        margin: 0 3px 3px 0;
                    }
                    &.selected {
                        a {
                            color: $white;
                            border-color: $black;
                            font-weight: 400;
                        }
                    }
                    &.unselectable {
                        a {
                            background: $bon-jour;
                        }
                    }
                    a {
                        width: 100%;
                        height: auto;
                        padding: 7.5px 4px;
                        border: $base-border;
                        border-radius: 2px;
                        white-space: nowrap;
                    }
                }
                &.color {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        width: 66px;
                        margin: 0;
                        a {
                            border-radius: 50%;
                            width: 34px;
                            height: 34px;
                            position: relative;
                            padding: 0;
                            white-space: normal;
                            margin: 5px auto 32px;
                            line-height: 13px;
                            span {
                                position: absolute;
                                top: 40px;
                                transform: translateX(-50%);
                                left: 50%;
                                width: 60px;
                            }
                        }
                        &.selected {
                            &.swatch-black,
                            &.swatch-blue,
                            &.swatch-green {
                                a {
                                    &::after {
                                        background-image: url("../images/icons/ico_checkmark_light.svg");
                                    }
                                }
                            }
                            a {
                                color: $black !important;
                                border-color: $bon-jour;
                                &::after {
                                    content: '';
                                    background: url("../images/icons/ico_checkmark.svg") no-repeat center;
                                    background-size: 13px;
                                    height: 15px;
                                    width: 13px;
                                    position: absolute;
                                    top: 8.5px;
                                    left: 8.5px;
                                }
                            }
                        }
                    }
                }
            }

            .selected {
                .refinement-link,
                .content-link,
                .refinedcont-link {
                    .selected-indicator {
                        background: url("../images/icons/icon-checkbox-checked.svg") no-repeat;
                    }
                }
            }
            li {
                position: relative;
                .hit-count {
                    position: absolute;
                    right: 0;
                    top: 8px;
                }
            }

            .refinement-link,
            .content-link,
            .refinedcont-link {
                padding-right: 20px;
                display: inline-block;
                .selected-indicator {
                    background: url("../images/icons/icon-checkbox-unchecked.svg") no-repeat;
                    margin: 0 15px 4px 0;
                    float: left;
                    width: 1rem;
                    height: 1rem;
                }
            }
            .checkable-values,
            .price-refinements {
                li {
                    line-height: 14px;
                    padding: 8px 0;
                }
            }
            &.customProductID,
            &.productComparisonCategory {
                display: none;
            }
        }
        .scrollable .hit-count {
            @include respond(small) {
                right: 12px !important;
            }
        }
    }

    ul.swatches li {
        &.swatch-orange a {
            background-color: #FF6F00;
        }
        &.swatch-red a,
        &.swatch-matte_red a {
            background-color: #E31837;
        }
        &.swatch-pink a,
        &.swatch-matte_pink a {
            background-color: #F8BBD0;
        }
        &.swatch-green a,
        &.swatch-matte_green a {
            background-color: #1B5E20;
        }
        &.swatch-yellow a {
            background-color: #FDD835;
        }
        &.swatch-white a,
        &.swatch-sky_white a,
        &.swatch-visi_white a {
            background-color: $white;
            border-color: #eae6e6;
        }
        &.swatch-black a {
            background-color: $black;
        }
        &.swatch-grey a {
            background-color: #CCD1D1;
        }
        &.swatch-blue a {
            background-color: #1A237E;
        }
        &.swatch-light_blue a {
            background-color: #BBDEFB;
        }
        &.swatch-tan a {
            background-color: #D2B48C;
        }
        &.swatch-purple a {
            background-color: #9575CD;
        }
        &.swatch-visi_white,
        &.swatch-white,
        &.swatch-sky_white,
        &.swatch-sky_navy {
            a {
                border-color: #eae6e6;
            }
        }
    }

    .clubs-breadcrumbs,
    .search-header-top {
        width: 100%;
        max-width: 1242px;
        padding: 0 20px;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            padding: 0 50px;
        }
        @include custom-media-breakpoint-up(xl) {
            max-width: none;
            padding: 0 150px;
        }

        .breadcrumb-element {
            font-weight: 600;
            font-size: 11px;
            color: $black;
            text-transform: uppercase;
            text-decoration: none;
            &::after {
                color: $color-secondary;
            }
        }
        form {
            @include media-breakpoint-up(lg) {
                width: 560px;
            }
            margin-bottom: 15px;
        }
    }

    .content-slot {
        &.clubs-p1-module {
            position: relative;
            picture {
                display: block;
                line-height: 0;
            }
            img,
            video {
                width: 100%;
            }
            .text-wrapper {
                padding: 0 28px;
                @include media-breakpoint-up(lg) {
                    display: flex;
                    align-items: flex-end;
                    height: 100%;
                    width: 100%;
                    padding: 0 50px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                @include media-breakpoint-up(xl) {
                    max-width: 1242px;
                }
                @include custom-media-breakpoint-up(xl) {
                    max-width: none;
                    padding: 0 150px;
                }
            }
            .text-content {
                margin-bottom: 32px;
                @include media-breakpoint-down(md) {
                    padding-top: 20px;
                }
                @include media-breakpoint-up(lg) {
                    width: 75%;
                    padding-bottom: 52px;
                    margin-bottom: 0;
                }
                @include media-breakpoint-up(xl) {
                    width: 50%;
                }
                .header {
                    font-size: 24px;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 26px;
                        color: $white;
                        font-size: 36px;
                    }
                }
                .sub-copy {
                    font-size: 16px;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.375;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    letter-spacing: normal;
                    color: $color-primary;

                    @include media-breakpoint-up(lg) {
                        color: $white;
                        margin-bottom: 32px;
                    }
                }
                .cta-wrapper {
                    margin-top: 14px;
                    @include media-breakpoint-up(lg) {
                        margin-top: 32px;
                    }

                    a {
                        display: inline-block;
                        min-width: auto;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 13px;
                        padding: 12px 26px;
                        margin-right: 12px;
                    }
                    .button-outline {
                        font-size: 14px;
                        font-weight: 700;
                        color: $black;
                        border: 1px solid rgba(0, 0, 0, 1);
                        border-radius: 2px;
                        text-decoration: none;

                        &:hover {
                            border-color: rgba(0, 0, 0, 0.5);
                        }

                        @include media-breakpoint-up(lg) {
                            color: $white;
                            border-color: rgba(255, 255, 255, 1);

                            &:hover {
                                border-color: rgba(255, 255, 255, 0.5);
                            }
                        }
                    }
                }
            }
        }
    }

    .plp-noproducts-wrapper {
        width: 100%;
        margin: 0 auto;

        .product-content {
            width: 100%;
            padding: 0;
        }
    }

    .plp-wrapper {
        width: 100%;
        max-width: 1242px;
        margin: 0 auto;
        @include custom-media-breakpoint-up(xl) {
            max-width: none;
            padding: 0 100px;
        }

        &.empty-cat {
            @include respond('largeUp') {
                .primary-content {
                    width: 100% !important;
                }
            }
            #secondary {
                display: none;
            }
        }

        .primary-content {
            width: 100%;
            @include media-breakpoint-up(lg) {
                padding: 0 40px;
            }
            .refinements-active & {
                @include media-breakpoint-up(lg) {
                    width: calc(100% - 290px);
                }
            }
            .searhres-hide {
                visibility: hidden;
            }
            .search-result-content {
                max-width: none;
                border: none;
                overflow-x: hidden;
                @include media-breakpoint-up(lg) {
                    overflow: visible;
                    margin-top: 8px;
                }
                @include respond('large') {
                    margin: 0 10px;
                }
            }
            .search-result-items {
                margin: 0 -11px;
                border: none;
                width: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include respond('largeUp') {
                    margin: 0 0 0 -15px;
                    width: calc(100% + 15px);
                }
                .grid-tile {
                    width: 50%;
                    padding: 0 15px;
                    margin-bottom: 30px;
                    flex: 0 50%;
                    @include media-breakpoint-up(lg) {
                        flex: 0 0 33.3%;
                    }

                    &.wide-tile-3x {
                        @include media-breakpoint-down(md) {
                            margin-bottom: 100%;
                        }

                        .product-tile {
                            @include media-breakpoint-down(md) {
                                overflow: initial;
                            }

                            .ingrid-image {
                                @include media-breakpoint-down(md) {
                                    vertical-align: middle;
                                }
                            }

                            .text-content {
                                @include media-breakpoint-down(md) {
                                    top: 100%;
                                    bottom: initial;
                                    color: $black;
                                    padding: 20px;
                                }

                                .ingrid-content-subheader {
                                    @include media-breakpoint-down(md) {
                                        margin-bottom: 10px;
                                    }
                                }

                                .ingrid-content-subcopy {
                                    @include media-breakpoint-down(md) {
                                        color: $color-primary;
                                    }
                                }

                                .ingrid-content-cta {
                                    @include media-breakpoint-down(md) {
                                        color: $black;
                                        border-color: $black;

                                        &:hover {
                                            border-color: rgba(0, 0, 0, 0.5);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .product-tile {
                        position: relative;
                        overflow: hidden;
                        height: 100%;
                        .ingrid-image {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            max-width: none;
                            object-fit: cover;
                        }
                        .text-content {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            padding: 20px;
                            color: $white;
                            @include media-breakpoint-up(lg) {
                                padding: 37px 33px;
                            }
                            > span {
                                display: block;
                                line-height: normal;
                                letter-spacing: .05em;
                            }
                            .ingrid-content-header {
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 1.07;
                                margin-top: 1.438em;
                                margin-bottom: 0.643em;
                                letter-spacing: normal;
                                text-transform: uppercase;
                            }
                            .ingrid-content-subcopy {
                                font-size: 14px;
                                font-size: 0.875rem;
                                font-weight: 400;
                                line-height: 1.6;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                letter-spacing: normal;
                            }
                            .ingrid-content-subheader {
                                font-size: 24px;
                                letter-spacing: -0.5px;
                                line-height: 1.08;
                                font-weight: 700;
                                text-transform: uppercase;
                                @include media-breakpoint-up(lg) {
                                    font-size: 36px;
                                    margin-bottom: 4px;
                                }
                            }
                            .cta-wrapper {
                                margin-top: 14px;
                            }
                            .ingrid-content-cta {
                                display: inline-block;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: normal;
                                letter-spacing: normal;
                                padding: 12px 20px;
                                color: $white;
                                border: 1px solid rgba(255, 255, 255, 1);
                                border-radius: 2px;
                                text-decoration: none;
                                min-width: 80px;
                                transition: all 0.1s linear;

                                &:hover {
                                    border-color: rgba(255, 255, 255, 0.5);
                                }
                            }
                        }
                    }
                    &.wide-tile-2x {
                        width: 100%;
                        flex-basis: 100%;
                        @include media-breakpoint-up(lg) {
                            width: calc((100% / 3) * 2);
                            flex-basis: calc((100% / 3) * 2);
                        }
                    }
                    &.wide-tile-3x {
                        width: 100%;
                        flex-basis: 100%;
                        .product-tile {
                            .text-content {
                                @include media-breakpoint-up(lg) {
                                    width: 50%;
                                    padding: 48px 0 48px 48px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pt_clubs-search-result,
.club-pdp-recommendations {
    .product-tile {
        text-align: left;
        color: $black;
		.product-swatches,
		.product-tagline {
			display: block;
		}
		.colors-available,
		.orientation-attribute {
			display: none;
			@media screen and (min-width: 768px) {
				display: none;
			}
		}

        .product-model-type {
            height: 12px;
            position: absolute;
            top: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;

            @include media-breakpoint-up(lg) {
                top: 20px;
            }
        }

        .product-image {
            margin-bottom: 6px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 16px;
            }
            .thumb-link {
                display: block;
                line-height: 0;
                background-color: $black-haze;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
			.badge-wrapper {
				& ~ .product-badge {
					display: none;
				}
			}
            .product-badge {
                display: inline-block;
                position: absolute;
                bottom: 10px;
                left: 13px;
                max-width: calc(100% - 26px);
                @include media-breakpoint-up(lg) {
                    bottom: 25px;
                    left: 20px;
                    max-width: calc(100% - 40px);
                }
                &.secondary-badge {
                	right: 9px;
                    bottom: 8px;
	    			left: unset;
	    			background-color: transparent;
                	@include media-breakpoint-up(lg) {
                        right: 13px;
                        bottom: 19px;
    				}
    				.badgeIcon {
    					width: auto;
    					height: 30px;
    					vertical-align: middle;
    					img {
    						width: auto;
                    		height: 100%;
    					}
    				}
    				span {
    					display: inline-block;
						overflow: hidden;
						white-space: nowrap;
    				}
    				span:not(.badgeIcon) {
    					transition: max-width 2s;
                        width: auto;
                        max-width: 0;
                        top: 6px;
                        position: relative;
                        left: 3px;
    					@include respond(small) {
							display: none;
						}
    				}
                }
            }
        }

        .product-tile-details {
            padding-left: 14px;
            position: relative;
            line-height: 1.25rem;
            @include media-breakpoint-up(lg) {
                padding: 0 0 20px;
            }
        }

        .product-name {
            word-break: break-word;
            margin-bottom: 1px;
            @include media-breakpoint-up(lg) {
                padding-right: 60px;
                margin-bottom: 4px;
            }
            a {
                font-size: rem(14px);
                font-weight: 600;
                text-decoration: none;
            }

        }
        .product-category {
            font-family: $gotham-book;
            font-size: rem(12px);
            font-weight: 500;
            color: $color-primary;
        }
        .product-category-primary {
            margin-bottom: 16px;
        }
        .product-tagline {
            display: none; // Don't display tagline on mobile
            margin-top: 6px;
            @include media-breakpoint-up(lg) {
                margin-top: 20px;
            }
        }
        .orientation-attribute {
            margin-top: 6px;
            @include media-breakpoint-up(lg) {
                margin-top: 20px;
            }
        }
        .colors-available {
            padding: 4px 0;
            min-height: rem(30px);
            font-family: $gotham-book;
            font-size: rem(12px);
            font-weight: 500;
            color: $color-primary;
			display: none;
            @include respond(small) {
                display: none;
            }
        }
        .product-pricing {
            margin: 0;
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .product-swatches {
            display: block;
            @include respond(small) {
                display: block;
            }
            margin: 13px 0;
            position: static;
            .swatch-list {
                text-align: left;
                padding: 0;
                li {
                    padding: 0;
                }
            }
            .swatch {
                display: block;
                width: 24px;
                height: 24px;
                margin: 0 1px 2px 0;
                border: 1px solid $color-gray5;
                padding: 1px;
                border-radius: 0;
                background-color: $color-gray5;
                &.selected {
                    border-color: $black;
                }
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    border-radius: 0;
                }
            }
        }

	    .swatch-list a {
		    position: relative;
		    .locked + .members-only-swatch-lock {
			    .locked-icon {
				    display: block;
			    }

			    .unlocked-icon {
				    display: none;
			    }
		    }

		    .members-only-swatch-lock {
			    position: absolute;
			    top: 15%;
			    left: 15%;
			    width: 70%;
			    height: 70%;
			    display: flex;
			    align-items: center;
			    justify-content: center;

			    .unlocked-icon {
				    display: block;
			    }

			    .locked-icon {
				    display: none;
			    }
		    }
	    }

	    .product-image .product-badge.members-only-lock-badge,
	    .members-only-cta {
		    display: none;
	    }

	    &.members-only-product {
		    &.locked .product-image .thumb-link > img {
			    opacity: 0.5;
			    transition: all 0.7s;
		    }

		    &.locked:hover .product-image .thumb-link > img {
			    opacity: 1;
			    transition: all 0.7s;

			    @include respond(small) {
				    opacity: 0.5;
			    }
		    }

		    .secondary-badge:not(.members-only-lock-badge) {
			    display: none;
		    }

		    .product-image .product-badge.members-only-lock-badge {
			    display: block;
		    }

		    .members-only-lock-badge {
			    @include respond(small) {
				    bottom: 12px!important
			    }

			    .badgeIcon {
				    @include respond(small) {
				        height: 18px!important;
				    }
			    }

			    .locked-icon {
				    display: none !important;
			    }
		    }

		    &.locked .members-only-lock-badge {
			    .locked-icon {
				    display: block !important;
			    }
				.unlocked-icon {
					display: none !important;
				}
		    }

		    .product-badge.secondary-badge {
			    padding: 0;
		    }

		    .product-badge:not(.secondary-badge) {
			    max-width: calc(100% - 20px - 44px);

			    @include respond(small) {
				    max-width: calc(100% - 13px - 40px);
			    }
		    }

		    &.locked .product-category-primary {
				display: none;
			}

		    .members-only-cta {
			    display: none;
		    }

		    &.locked .members-only-cta {
			    display: block;
			    font-size: rem(12px);
			    line-height: 20px;
			    margin-bottom: 16px;

			    a {
				    font-size: rem(12px);
				    color: $color-primary;
			    }
		    }

		    &.locked .members-only-swatch-lock .unlocked-icon {
			    display: none;
		    }
	    }
    }
}

.product-badge {
    display: inline-block;
    padding: 2px 8px;
    font-size: 11px;
    @include respond(small) {
    	font-size: 9px
    }
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;
    word-break: break-word;
    &.black-white {
        color: $white;
        background-color: $black;
    }
    &.red-white {
        color: $white;
        background-color: $shiraz;
    }
    &.grey-black {
        color: $color-primary;
        background-color: $bon-jour;
    }
    &.white-red {
        color: $error-red;
        background-color: $white;
        border: $base-border;
    }
    &.rainbow {
        background-color: $white;
        span {
            background: linear-gradient(to right, #f1cc48, #f16d3c, #bf4d9c, #7873a9, #5486b1, #00b37a, #44c7c4);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;

            @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                background: url(../images/icons/customize_it.png) -9px center no-repeat;
             }
        }
    }
    &.gray-white {
        color: $white;
        background-color: #808080;
    }
	&.white-black-red {
		color: $black;
		border: 1px solid $shiraz;
		background: $white;
	}
	&:not(.secondary-badge) {
		&.has-icon {
			padding-left: 25px;
			position: relative;

			.primary-badge-icon {
				position: absolute;
				left: 6px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				max-width: 14px;
				max-height: 14px;
			}
		}
	}

    &.secondary-badge {
    	right: 13px;
		left: unset;
		background-color: transparent;
    	@include media-breakpoint-up(lg) {
        	right: 20px;
		}
		.badgeIcon {
			width: 28px;
			height: 28px;
			vertical-align: middle;
		}
		span {
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
		}
		span:not(.badgeIcon) {
			transition: max-width 2s;
            width: auto;
            max-width: 0;
			@include respond(small) {
				display: none;
			}
		}
    }
}

.grid-tile:hover .product-tile .product-image .product-badge.secondary-badge span:not(.badgeIcon){
	max-width: 250px;
	@include respond(small) {
		display: none;
	}
}

.product-customizeit {
    padding: 4px 0;

    .product-customizeit-link {
        font-size: 12px;
        color: #ee1a28;
        text-decoration: none;
        font-weight: 500;
    }
}



