.pt_clubs-search-result .product-tile .product-image .thumb-link,
.club-pdp-recommendations .product-tile .product-image .thumb-link {
    padding-top: 20%;
    padding-bottom: 20%;

    @include media-breakpoint-up(sm) {
        padding-top: 14%;
        padding-bottom: 14%;
    }
}

.club-pdp-recommendations {
    .product-tile {
        height: auto;
        @include media-breakpoint-up(lg) {
            max-height: 576px;
        }
    }
    .pt_clubs-search-result & {
        margin-bottom: 30px;
        padding-top: 25px;
    }
}

.product-tile .product-swatches li {
    margin-bottom: 0;
}

.product-tile .product-swatches a {
    text-decoration: none;
    color: $color-primary;
}
.product-content .product-tile a {
    border-bottom: none;
}
