
.primary-nav-spacer {
	position: relative;
	width: 100%;
	height: 110px
}

@media (max-width:1199px) {
	.primary-nav-spacer {
		height: 71px
	}
}

nav.primary .top-content a {
	color: #707070;
	@media (min-width:800px) {
		font-size: rem(12px);
	}
}

nav.primary ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	vertical-align: bottom;
	font-size: 1rem;
	line-height: 1.15
}

nav.primary a {
	text-decoration: none;
	color: #000;
	border: 0;
	line-height: 1.15;
}

nav.primary a.titleist-btn,
nav.primary.desktop .account-menu a.titleist-btn {
	color: #fff
}

.show-error .input-error,
nav.primary.desktop .account-toggle.is-open .account-menu,
nav.primary.desktop .mini-bag.is-open .mini-bag-content,
select {
	display: block
}