:root {
	--app-height: 100%;
    --scene7-bg: #eeeeee;
}

/////View port breakpoints
$iphone5: 320px;
$iphone6: 375px;
$extra-small-breakpoint: 440px;
$breakpoint-small-nav: 680px;
$small-breakpoint: 768px;
$standard-breakpoint: 1000px;
$titleist-xl: 1440px;


// breakpoints from footer
$breakpoint-xwide: 1150px;
$breakpoint-wide: 1000px;
$breakpoint-large: 940px;
$breakpoint-mid: 800px;
$breakpoint-small: 660px;
$breakpoint-small-nav: $breakpoint-small + 20;
$notification-breakpoint-small: 600px;
$breakpoint-xsmall: 520px;
$breakpoint-tiny: 440px;
$breakpoint-minimum: 320px;

//Color variables from titleist.com
$color-base: #fff;
$color-black: #000;
$color-primary: #4C4C4C;
$color-highlight: #e8e8e8;
$color-border: #a3a3a3;
$color-border2: #c4c4c4;
$color-accent: #f6f6f6;
$color-border-light: #d6d6d6;
$color-secondary: #e31837;
$color-gray4: #ddd;
$color-gray5: #ececec;
$color-table-grey: #fafafa;

// colors
$black: #000;
$white: #fff;
$white-bg: #fff;
$gray-cirlce: #f2f2f2;
$clear: rgba(0, 0, 0, 0);
$trans: transparent;

//New variables of color
$mine-shaft: #4C4C4C;
$tundora_approx: #4C4C4C;
$bon-jour: #e2dede;
$img-gray: #f0f0f0;
$gray-nurse: #e8e8e8;
$black-haze: #f6f6f6;
$suva-gray: #868686;
$crimson: #e32338;
$crimson2:#C62132;
$shiraz: #b51029;
$shamrock: #23de7f;
$primary-border: #d6d6d6;
$gray_nurse_approx: #eaeaea;
$black_approx: #020202;
$bon-jour_approx: #e3dede;
$gray-warm-old: #989898;
$text-gray: #999999;

//redesign colors
$darkGrey: #dfdfdf;
$lightGrey: #f7f7f7;
$greyText: #6f6f6F;
$secondaryRed: #b51029;
$secondaryBlue:#0087E7;
$secondaryDarkBlue: #014880;

//Override color variables to Titleist store
$gray-dark: $mine-shaft;
$gray-warm: $mine-shaft;
$gray-white: $black-haze;
$gray-light: $gray-nurse;
$red: $crimson;
$red-sale: #d32300;
$green: $shamrock;

//Pneumatic color names
$error-red: $crimson;
$primary-color: $black;
$body-bg-color: $trans;
$clp-border: #e6e6e6;

//animation/transition speeds
$fastestSpeed: .1s;
$fastSpeed: .2s;
$quickSpeed: .5s;

//text-transform value for links
$linkCase: none;

//per comps, nearly all text in Titleist needs inherit this letter spacing, except Chronical font
$globalLetterSpacing: normal;

//borders
$default-border: 1px solid $color-border-light;
$base-border: 1px solid $bon-jour;

//these populate the color selection swatches (might not need it when we get to PDP)
$swatch-types:
"black" #000000,
"beige" #f5f5dc,
"blue" blue,
"light_blue" #8fc2e7,
"tan" #9e8063,
"purple" #67339a,
"red" #cc1a21,
"whiteroyalblue" #4169e1,
"whitesilver" #c0c0c0,
"whitelightgreycharcoal" #444444,
"brown" #42280e,
"lightgreynavyberry" #5f192c,
"whitegreyblue" #778899,
"navyorangelime" orange,
"white" white,
"green" #266b2e,
"grey" #93969a,
"pink" #fc77ac,
"orange" #f47b25,
"white" #ffffff,
"yellow" #fff200,
"blackwhitelightgrey" #ccc,
"lightgreygreennavy" lightgreen,
"navy" #28304c,
"blackred" darkred,
"blacklimelightgrey" lime,
"lime" #74c44e'';


// from vendor
// COMPONENT SIZES
$navHeight: 110px;
$categoryNavHeight: 120px;
$bannerHeight: 30px;

// FONT VARS
$navFontFamily: 'ProximaNovaCondensed', sans-serif;
$navFontWeight: 500;
$navFontSize: 18px;
$navSecondaryFontSize: 16px;
$ctaFontSize: 16px;

$footerFontFamily: 'ProximaNova', sans-serif;
$footerFontWeight: 400;

//$titleFontFamily: 'Gotham Cond A', 'Gotham Cond B', sans-serif;
$titleFontFamily: 'ProximaNovaExtraCondensed', sans-serif;
$titleCondensedFontFamily: 'ProximaNovaExtraCondensed', sans-serif;
$titleFontWeight: 700;

$subtitleFontFamily: 'ProximaNovaExtraCondensed', sans-serif;
$subtitleFontWeight: 500;

$mediumFontFamily: 'ProximaNova', sans-serif;
$mediumFontWeight: 500;

$copyFontFamily: 'ProximaNova', sans-serif;
$copyFontWeight: 500;

// Primary Colors
$white: #ffffff;
$lightGrey: #f2f2f2;
$darkGrey: #dfdfdf;
$greyText: #4c4c4c;
$black: #000000;
$red: #e31837;
$instructionGreen: #44a223;
// Secondary Colors
$lighterGrey: #f7f7f7;
$buttonGrey: #999999;
$secondaryRed: #b51029;
$secondaryBlue: #0087E7;
$secondaryDarkBlue: #014880;
// Feedback Colors
$errorColor: #e21837;
$successColor: #23de7f;
$warningColor: #ffd118;
// SCREEN SIZES
$navMobile: 800px;  // Custom breakpoint for text crowding on top nav fields


// OLD STANDARDS
$scrollbar: 17px;
$desktop-xl: 2000px;
$desktop: 1366px;
$tablet: 1024px;
$desktop-xs: 900px;
$phone: 800px;
//$phone: 530px;
$phone-sm: 540px;
$iPhone7P: 414px;
$iPhone7: 375px;
$phone-se: 320px;

// Animation Variables
$button-ease-duration: 250ms;

// OG colors
$ogBtn: #ababab;
$ogBtnBg: #ebebeb;
