.js .simple-submit {
	display: none;
}

legend {
	background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top $gray-white;
	border-bottom: 1em solid $white;
	font-size: 1.1em;
	@include font-bold();
	margin: 0;
	padding: 1.16em 2% .83em;
	position: relative;
	text-transform: uppercase;
	width: 96%;
	.ie7 & {
		width: 94%;
	}
	span {
		@include font-size(12px);
		position: relative;
		text-transform: none;
		padding: 0 .5em;
		em {
			color: $black;
		}
	}
}
.input-textarea {
	@include border-radius(2px);
    border: 1px solid $primary-border;
	background: $white;
	width: 98%;
    height: auto;
	@include font-regular();
}

.dialog-required {
	display: inline;
	@include font-size(12px);
	position: relative;
	padding: .16em 0;
	text-transform: none
	em {
		color: $black;
	}
}

label {
	display: block;
	font-size: rem(14px);
	@include font-bold();
	.form-horizontal & {
		float: left;
		width: 100%;
		@include respond(largeUp) {
			width: 25%;
		}
	}
	.label-inline & {
		display: inline;
		float: none;
		font-size: 1em;
		margin-top: 0;
		text-align: left;
		width: auto;
	}
	.label-above & {
		width: auto;
	}
}

.form-label-text {
	float: left;
	font-size: 1.1em;
    @include font-bold();
	padding: 0.75em 0 0;
	text-align: right;
	width: 25%;
}

.form-row {
	@extend %clearfix;
	margin: .5rem 0 1rem;
}

.form-row-button {
	clear: left;
	.form-horizontal & {
		@include respond(largeUp) {
			width: 62%;
		}
	}
}

.field-wrapper {
	box-sizing: border-box;
	width: 100%;
	.form-horizontal & {
		float: left;
	}
	.label-inline & {
		float: left;
		padding-left: 0;
		width: auto;
	}
	.label-above & {
		float: none;
		padding-left: 0;
		width: auto;
	}
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
input[type="email"],
textarea,
select {
    border-radius: 2em;
    border: 1px solid $darkGrey;
    background: #fff;
    @include font-regular(14px);
	line-height: 1.15;
	padding: 0.5em 2.5em 0.5em 1em;
	margin: 0.5em 0;
    color: $primary-color;
	width: 100%;
	box-sizing: border-box;
    outline: none;
	-webkit-appearance: none;
    -moz-appearance: none;
    &:focus{
        outline: none;
    }
    &::-ms-clear {
	    display: none;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

select {
	appearance: none;
    background-position: right 8px center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-image: url(../images/arrow-drop-down.svg);
}
.field-wrapper{

}

// IE fix for dropdown select default
select::-ms-expand {
    display: none;
}

input[type="checkbox"],
input[type="radio"] {
	margin: 1em 1% 1em 2%;
	.label-inline & {
		margin: 0 .3rem 0 0;
	}
}

.form-caption {
	clear: left;
	width: auto;
	.label-above & {
		margin-left: 0;
	}
	.form-indent & {
		margin-left: 0;
	}
}

.error-message {
	padding: 0;
	text-indent: 0;
	width: 49.5966%;
}

.error,
.error-text {
	background-color: $white;
	border-color: $error-red;
	color: $error-red;
}

span.error,
div.error,
div.error-message,
.ui-dialog label.error {
	background: none;
	color: $error-red !important;
	text-transform: none !important;
	border: none;
}

.ui-dialog label.error {
	font-weight: 400;
    font-size: 12px;
    font-size: 0.75rem;
}

.error-form {
	background-color: $white;
	border: 1px solid $error-red;
	color: $error-red;
	margin: 0.5em 0;
	padding: 0.8em;
}

.success-form {
  color: $green;
  padding: 8px 0 16px
}

.form-inline {
	form {
		padding: 0 0 2em;
		width: 98%;
	}
	label {
		padding: 0 0 0.75em;
		text-transform: uppercase;
		width: auto;
	}
	input[type="text"], select {
		margin-left: 0;
		width: 95%;
	}
	button {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.row-inline {
	display: inline;
	label {
		width: auto;
	}
}

.require-dialog {
	position: absolute;
	right: 1em;
	text-transform: none;
	top: 1em;
}

.required-indicator {
	color: $red;
}

.form-field-tooltip {
	float: left;
	margin-left: 3%;
	padding-top: .75em;
	width: 20%;
}

.contactus-form {
	.required-indicator {
	    color: $black;
	    float: right;
	    margin-left: 2px;
	}
}

.input-text {
	border-radius: 1.25rem;
}

.field-wrapper {
	input.error {
		border: 1px solid $error-red !important;
	}
    input.date-field {
        padding-right: 15px;
        height: 34px;
    }
}

.form-row.error {
	input {
		border: 1px solid $error-red;
	}
}

.add-card-dialog {
	#dialog-container {
		#CreditCardForm {
			.form-row {
				& + .form-label-text {
					width: 32%;
					font-size: 12px;
					@media screen and (max-width: 767px) {
						width: 100%;
					}
				}
				&.month {
					margin-left: 0;
				}
				&[class$="_preferred"] {
					.field-wrapper {
						display: inline-block;
						width: auto;
					}
				}
			}
		}
	}
}

.consent-non-registered {
	span:after {
		content: '';
	}
	.form-row.required {
		label {
			font-weight: normal !important;
			font-size: 12px !important;
			color: $color-primary;
			position: absolute;
			left: 24px;
			top: 8px;
		}
		span::after {
			content: '' !important;
		}
		span.error {
			font-size: 12px;
			color: $color-primary;
			position: absolute;
    		left: 24px;
			top: 25px;
		}
	}
}

#consent-required-detail {
	margin: 0 15px 0 !important;
	padding-bottom: 20px;

	@media screen and (max-width: 767px) {
		margin: 3px 14px !important;
	}

	p {
		font-size: 12px;
		color: $gray-warm;
		line-height: 20px;
		padding: 0;
    	margin: 20px 10px 10px;
		&.larger {
			font-weight: bold;
			font-size: 14px;
		}
		&:first-of-type {
			margin-top: 0;
		}
	}
}
