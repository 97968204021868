///////////////////////
/// Button Mixins
///////////////////////

@mixin redButton {
    color: $white;
    background-color: $crimson;
    border-color: $crimson;
    font-size: $ctaFontSize;
    font-weight: 700;
    &:hover{
        &:before {
            transform: translate(calc(-4em), -50%);
        }
        &:after {
            left: calc(100% - 4em);
        }
    }
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
    }
    &:before {
        transform: translate(calc(-100% - 4.5em), -50%);
        left: 0;
        width: 100%;
        height: 6em;
        transition: 250ms ease transform;
        background-color: $crimson2;
        z-index: -1;
    }
    &:after {
        left: -6em;
        width: 0;
        height: 100%;
        border-top: 3em solid transparent;
        border-bottom: 3em solid transparent;
        border-left: 3em solid $crimson2;
        box-sizing: border-box;
        transform: translateY(-50%);
        transition: 250ms ease all;
        z-index: -2;
    }
}

@mixin transpButtonWhite {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
    &:hover{
        border-color: rgba($white, 0.5);
    }
}

@mixin transpButtonBlack {
    color: $black;
    background-color: transparent;
    border: 1px solid $black;
    &:hover{
        border-color: rgba($black, 0.25);
    }
}

@mixin whiteButton {
    color: $black;
    border: 1px solid $black;
    background-color: $white;
    &:hover{
        color: $white;
        border: 1px solid $black;
        background-color: $black;
    }
}

@mixin primaryButton {
    @include redButton;
}

@mixin secondaryButton {
    @include whiteButton;
}

@mixin tertiaryButton {
    @include transpButtonWhite;
}

@mixin quaternaryButton {
    @extend .outline;
}

.button,
.add-to-cart,
.btn-secondary,
.btn-tertiary,
.buttonstyle,
button.black,
.titleist-btn,
button.largebutton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    line-height: 1.2;
    padding: 0.45em 3em;
    border-radius: 2rem;
    transform: translateZ(0);
    -webkit-appearance: none;
    cursor: pointer;
    text-align: center;
    outline: none;
    border: none;
    text-decoration: none;
    @include redButton;
    &.black {
        @include primaryButton;
    }
    &.white, &.secondary, &.button-secondary {
        @extend .btn-secondary;
    }
    &.transpWhite, &.tertiary, &.button-tertiary {
    	@include tertiaryButton;
    }
    &.transpBlack, &.quaternary, &.button-quanternary {
    	@include quaternaryButton;
    }

    &.large {
        font-size: 1.35em;
        text-transform: uppercase;
    }
    &.blk-large {
        font-size: $ctaFontSize;
        background-color: $black;
        &:hover {
            background-color: transparent;
            text-decoration: underline;
            color: $black;
            &:before,
            &:after {
                display: none;
            }
        }
    }
    &[disabled],
    &.disabled,
    &.disabled:hover {
        opacity: 0.5;
    }
    a {
        color: $white;
        &:hover {
            color: $red;
        }
    }
}

a.button {
    &:hover {
        color: $white;
    }
}

.button-fancy-small {
    height: 33px;
    @include line-height(28px);
}

.button-fancy-large {
    @extend .button.large;
}

.button-text {
    background-color: transparent;
    border-width: 0;
    color: $red;
    padding: 0;
    transform: none;
    border-radius: 0;
    &:hover {
        background-color: transparent;
        text-decoration: underline;
        color: $black;
        &:before,
        &:after {
            display: none;
        }
    }
}

.button-full-width {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
}

.btn-secondary {
    background-color: #999999;
    &:before {
        background-color:#4c4c4c
    }
    &:after {
        border-left-color: #4c4c4c;
    }
}

.btn-tertiary {
    background-color: $black;
    &:after {
        border-left-color: #4c4c4c;
    }
}

.btn-secondary,
.btn-tertiary {
    &:before {
        background-color:#4c4c4c
    }
    &:hover {
        &:before {
            background-color:#4c4c4c
        }
        &:after {
            border-left-color: #4c4c4c;
        }
    }
}

.outline {
    background: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: $black;
    color: $black;
    &:hover {
        background-color: $black;
        color: $white;
        &:before,
        &:after {
            display: none;
        }
    }
}
