.searchit-open body {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}
#search-suggestions {
	display: none;
}
#search-suggestions,
.search-suggest-default {
	position: absolute;
	top: 100%;
	bottom: auto;
	left: auto;
	overflow-y: auto;
	width: 100%;
	right: 0;
	z-index: 6;
	height: 100vh;
	background-color: $white;
	opacity: 1;
}

.search-suggest-default.suggestion-result {
	opacity: 0;
	z-index: 5;
	transition: all .1s;
}
.search-suggestion-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 1160px;
    margin: 0 auto;
    padding: 4px 0 160px;
	a {
		font-size: 0.8rem;
		position: relative;
		display: block;
		text-decoration: none;
		border: none;
		line-height: 1.15;
		font-weight: 400;
		color: $color-primary;
	}

	h4.header {
		font-family: $narrow;
		font-weight: 700;
		font-size: rem(16px);
		margin: 0;
		padding: 0 0 12px 0;
		border-bottom: 2px solid #f2f2f2;
		display: inline-block;
		text-transform: uppercase;
	}

	.hitgroup {
		.hit {
			padding-top: 10px;
			line-height: 1.15;
		}
	}
}

.phrase-suggestions {
    width: 100%;
    flex-basis: 100%;
	padding: 0 16px 16px;
	@include respond(largeUp) {
		width: 20%;
		flex-basis: 20%;
		padding: 16px 0;
	}
}

.product-suggestions {
    list-style: none;
    margin: 0;
    padding: 16px;
	@include respond(largeUp) {
		width: 80%;
		flex-basis: 80%;
		&.no-phrase {
			margin-left: 20%;
		}
	}
	.product-suggestions-hits {
		width: 100%;
		flex-basis: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		@include respond(small) {
			padding-bottom: 80px;
		}
	}
}

.product-suggestion {
    width: calc(50% - 16px);
    flex-basis: calc(50% - 16px);
	margin-right: 16px;
    padding: 16px 0;
	@include respond(largeUp) {
		width: calc(25% - 16px);
		flex-basis: calc(25% - 16px);
	}

	.product-image {
		img {
			width: 100%;
			height: auto;
			vertical-align: bottom;
			max-width: 208px;
		}
	}
	.product-details {
		.product-name {
			color: $color-primary;
			padding-top: 0.5em;
		}
		.product-category {
			font-size: 11px;
			/*font-size: 1.1rem;*/
			font-family: 'neuzeit-grotesk', sans-serif;
			color: #4a4a4a;
			letter-spacing: .3px;
			text-transform: capitalize;
		}
		.product-price {
			color: $black;
			padding-top: 0.25em;
		}
	}
}

.header-suggestion {
    &.selected {
        background: $color-gray5;
    }
}
