
.titleist-search-box {

    width: 100%;
    padding: 0;
    background: #fff;
    position: absolute;
    z-index: 10;
    box-sizing: border-box;

    .icon-search {
        width: 20px;
        position: relative;
        bottom: 11px;
        margin-right: 4px;
    }
    .close {
        display: none;
    }

    .header-search {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-width: 1160px;
        margin: 0 auto;

        .header-search-inner {
            width: 100%;
            flex-basis: 100%;
            display: flex;
            align-items: center;
            @media (max-width: 799px) {
                padding: 0 3%;
            }
            @include respond(largeUp) {
                padding-left: 20%;
            }
        }

        form {
            position: relative;
            width: 100%;
            padding: 1em;
            @media (min-width: 800px) {
                max-width: 580px;
            }
        }

        #suggestions {
            display: none !important;
        }
    }

    input[type="text"] {
        font-size: 0.875rem;
        display: block;
        border-radius: 2em;
        padding: 0.5em 1em;
        outline: none;
        margin: 0.5em 0;
        width: 100%;
        box-shadow: none;
        color: black;
        float: left;
        height: auto;
        background-color: #f2f2f2;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)  {
            height: initial;
        }
        display: block;
        line-height: normal;
        -webkit-appearance: textfield;
    }

    .icon-search {
        font-size: initial;
    }

    button {
        display: inline-block;
        right: 0;
        width: 25px;
        height: 25px;
        padding: 0;
        line-height: 25px;
        background: none;
        border: none;
        text-align: center;
        color: #666;
        margin: 0;
    }
}

#titleistSearch {
    display: none;
    html.searchit-open & {
        display: block;
        .close {
            @include respond(largeUp) {
                display: block;
            }
            position: absolute;
            top: 27px;
            right: 2.5em;
            cursor: pointer;
        }
    }
}

.no-hits-search,
.titleist-search-box {
    .search-button {
        float: right;
        margin: -24px 5px 0 0;
    }
}

.no-hits-search {
    margin-top: 1.3em;
    .search-button {
        margin-top: -35px;
    }
}

.media-wrap {
    position: relative;
    display: inline-block;
}

.media-wrap img,
.media-wrap picture,
.media-wrap video {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.visually-hidden {
    display: none;
}

.nobr,
nobr {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

h5.condensed {
    font-size: 1.25rem;
}

.small-text {
    font-size: 0.75rem;
    line-height: 1.7;
}

.small-text p,
.small-text ol,
.small-text ul,
.small-text table,
.small-text a {
    font-size: 0.75rem;
    line-height: 1.7;
}

.smaller-text {
    font-size: 0.625rem;
    line-height: 1.7;
    font-weight: $navFontWeight;
}

.smaller-text p,
.smaller-text ol,
.smaller-text ul,
.smaller-text table,
.smaller-text a,
.smaller-text span {
    font-size: 0.625rem;
    line-height: 1.7;
}

a.no-border,
a.noborder {
    border-bottom: none;
}

a.no-border {
    border-bottom: 0px;
}

a.black-link {
    color: #000;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

a.bold-link {
    font-weight: 700;
}

.icon-v2 {
    position: relative;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    background-color: #000000;
    mask-size: 100% auto;
    mask-repeat: no-repeat;
    mask-position: center;
    opacity: 0;
    line-height: 1.7;
    font-family: inherit;
    font-size: inherit;
}
.icon-v2:before {
	content: '';
}

.icon-v2:after {
	content: '';
}

.media-wrap {
    position: relative;
    display: inline-block;

    img,
    picture,
    video {
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
}

.visually-hidden {
    display: none;
}

.nobr,
nobr {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.white-gradient {
    background: -webkit-linear-gradient(45deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.open-search-overlay {
    cursor: pointer;
}

a {
    &.no-border,
    &.noborder {
        border-bottom: none;
    }

    &.no-border {
        border-bottom: 0px;
    }

    &.black-link {
        color: #000;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    &.bold-link {
        font-weight: 700;
    }
}

.balls-page nav.primary.desktop ul.page-links a.balls-link::after,
.clubs-page nav.primary.desktop ul.page-links a.clubs-link::after,
.gear-page nav.primary.desktop ul.page-links a.gear-link::after,
.tour-page nav.primary.desktop ul.page-links a.tour-link::after,
.instruction-page nav.primary.desktop ul.page-links a.instruction-link::after,
.team-page nav.primary.desktop ul.page-links a.team-link::after {
    content: '';
    width: 100%;
    opacity: 1;
    background-color: #000000;
}

.banner {
    width: 100%;
    background-color: #f2f2f2;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-size: 0.875rem;
}

nav.secondary,
nav.see-all-content {
    display: none;
    .plp-wrapper & {
        display: block;
    }
}

.navigation-gear nav.primary.desktop ul.page-links a.gear-link,
.navigation-balls nav.primary.desktop ul.page-links a.balls-link,
.navigation-clubs nav.primary.desktop ul.page-links a.clubs-link {
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1px;
        background-color: #000;
        bottom: -3px;
        transition: all 150ms ease-out;
        width: 100%;
    }
}
nav.primary {
    box-sizing: content-box;
    ol li, ul li {
        line-height: rem(18.4px);
        padding: 0;
        font-size: 1rem
    }
}

nav.primary.desktop.is-animated {
    ul.right-content .nav-item.titleist-search-box-cta.active {
        .close {
            display: none;
        }
    }
}

nav.primary .top-content a {
    color: #707070;
}

nav.primary ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    vertical-align: bottom;
    font-size: 1rem;
    line-height: 1.15;
}

nav.primary a {
    text-decoration: none;
    color: #000000;
    border: none;
}

nav.primary a .icon-v2 {
    background-color: #000000;
}

nav.primary a .icon-v2:hover {
    background-color: #e31837;
}

nav.primary a.titleist-btn {
    color: #FFFFFF;
}

nav.primary.desktop .account-menu a.titleist-btn {
    color: #FFFFFF;
}

nav.primary.desktop .mini-bag,
.checkout-mini-cart .mini-bag {
    position: relative;
}

nav.primary.desktop .mini-bag-toggle {
    padding-bottom: 12px;
    top: 12px;
    .hover-off {
        position: absolute;
        width: 50px;
        height: 20px;
        top: -12px;
        left: -12px;
    }
}

.mini-bag-href {
	font-size: 1rem;
	font-family: $navFontFamily;
}

.mini-bag-content {
    .mini-cart-promo {
        font-weight: 600;
        border-bottom: solid 2px #f3f3f3;
        padding: 20px 10px;
        font-size: 13px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: $black;
        img {
            margin-right: 7px;
        }
    }
}


nav.primary.mobile {
    #mobile-search {
        position: relative;
        top: 3px;
        .icon-search {
            width: 24px;
            height: 24px;
        }
    }
}

@media (max-width: 899px) {
    nav.primary.mobile {
        font-size: 0.875em;
    }
}

@media (max-width: 1199px) {
    nav.primary.mobile {
        display: block;
    }
}

nav.primary.mobile .top-content {
    right: auto;
    top: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f2f2f2;
    text-align: center;
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.625em;
    font-size: 7pt;
}

nav.primary.mobile .mobile-nav-lines {
    position: relative;
    width: 20px;
    height: 24px;
}

nav.primary.mobile .mobile-nav-lines .line {
    background-color: #000000;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: all 200ms ease;
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(1) {
    width: 20px;
    height: 2px;
    top: 8px;
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(2) {
    width: 20px;
    height: 2px;
    top: 13px;
}

nav.primary.mobile .mobile-nav-lines .line:nth-child(3) {
    width: 20px;
    height: 2px;
    top: 18px;
}

nav.primary.mobile .search.icon {
    position: relative;
    top: 6px;
    width: 18px;
    height: 18px;
}

nav.primary.mobile .profile.icon {
    position: relative;
    top: 6px;
    width: 16px;
    height: 18px;
}

nav.primary.mobile .cart.icon {
    position: relative;
    top: 6px;
    width: 17px;
    height: 18px;
}

nav.primary.mobile .left-content {
    width: 75px;
    position: relative;
    top: 4px;
}

nav.primary.mobile .right-content {
    width: 75px;
    position: relative;
    top: 4px;
}

nav.primary.mobile ul {
    height: 55px;
}

nav.primary.mobile img.logo {
    width: 110px;
    height: auto;
}

nav.primary.mobile a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

nav.primary.mobile .mobile-mini-bag {
    position: relative;
    line-height: 1.15;

    .mini-bag-count {
        position: absolute;
        top: 1.25em;
        right: -5px;
        width: 1.4em;
        height: 1.4em;
        font-size: .8em;
        text-align: center;
        border-radius: 50%;
        background-color: #e31837;
        color: #fff;
        pointer-events: none
    }

    .mini-bag-count-text {
        position: relative;
	    top: .2em;
	    font-size: rem(12.8px);
    }
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(1) {
    transform: translateX(-50%) rotate(50deg);
    top: 13px;
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(2) {
    opacity: 0;
    left: -5px;
}

nav.primary.mobile.is-open .mobile-nav-lines .line:nth-child(3) {
    transform: translateX(-50%) rotate(-50deg);
    top: 13px;
}

nav.mobile-overlay {
    display: none;
    width: 100%;
    background-color: #FFFFFF;
    height: 0;
    position: absolute;
    top: 70px;
    z-index: 100;
}

nav.mobile-overlay.is-open {
    display: flex;
}

nav.mobile-overlay.is-fixed {
    position: fixed;
}

nav.mobile-overlay .scroll-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

nav.mobile-overlay ul {
    margin: 0 auto 200px auto;
    padding: 0;
    list-style-type: none;
    width: 70%;
}

nav.mobile-overlay ul li {
    width: 100%;
    text-align: left;
    margin: 0 auto;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
}

.checkout-mini-cart .mini-bag-content {
    .label,
    .value {
        font-weight: bold;
    }
}

.checkout-mini-cart .mini-bag-content .mini-bag-header {
    display: block;
    padding: 1em 0 0.4em;
    font-size: 1.2em;
    text-align: center;
    border-bottom: solid 2px #F3F3F3;
}

.checkout-mini-cart .mini-bag-product {
    position: relative;
    display: flex;
    border-bottom: solid 2px #F3F3F3;
}

.checkout-mini-cart .mini-bag-product .mini-bag-image {
    width: 30%;
    flex-basis: 30%;
}

nav.primary .mini-bag-content .mini-bag-product .mini-bag-product-info,
.checkout-mini-cart .mini-bag-product .mini-bag-product-info {
	width: 70%;
	flex-basis: 70%;
	padding: 1em 0 1em 1em;
	display: flex;
	flex-direction: column;
    .on-order {
        font-size: 12px;
    }
}

.checkout-mini-cart .mini-bag-product .mini-bag-name {
    font-weight: bold;
    font-size: 1.2em;
}

.checkout-mini-cart .mini-bag-product .mini-bag-pricing {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
}

.checkout-mini-cart .mini-bag-product .mini-bag-pricing .mini-bag-price {
    font-weight: bold;
    flex-grow: 1;
    text-align: right;
    font-weight: bold;

    &.price-promotion {
        &.price-sales {
            color: #e32338;
            order: 5;
            flex-grow: initial;
            padding-left: 10px;
        }

        &.price-sales + .price-standard {
            text-decoration: line-through;
        }
    }
}

nav.mobile-overlay a {
    display: flex;
    text-decoration: none;
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    line-height: 1.15;
    font-size: $navSecondaryFontSize;
}
/*
   * Top nav is currently 110px tall - 45px top space, 55px content height, 10px bottom space
   */
nav.secondary {
    position: relative;
    font-family: $navFontFamily;
    font-weight: $navFontWeight;
    font-size: 0.8em;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    background-color: #FFFFFF;
    ol li, ul li {
        font-family: $navFontFamily;
        font-weight: $navFontWeight;
        font-size: $navSecondaryFontSize;
        padding: 0;
    }
}

nav.secondary button {
    color: #4c4c4c;
    font-weight: $navFontWeight;
    border: 0;
}

nav.secondary button:hover,
nav.secondary button:focus {
    background: none;
}

nav.secondary .scroll-container {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    border-bottom: 1px solid #f2f2f2;
}

nav.secondary .scroll-container::-webkit-scrollbar {
    display: none;
}

nav.secondary .controls {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    pointer-events: none;
    z-index: 2;
    overflow: hidden;
}

nav.secondary .controls .arrow {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    width: 30px;
    height: 100%;
    pointer-events: all;
    transition: all 300ms ease;
}

nav.secondary .controls .arrow.left {
    border-right: 2px solid #f2f2f2;
    top: 0;
    left: -35px;
    transform: rotateY(180deg);
}

nav.secondary .controls .arrow.left.is-active {
    left: 0;
}

nav.secondary .controls .arrow.right {
    border-left: 2px solid #f2f2f2;
    top: 0;
    right: -35px;
}

nav.secondary .controls .arrow.right.is-active {
    right: 0;
}

nav.secondary .controls .arrow img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

nav.secondary ul {
    height: 100px;
    margin: 0 auto;
    padding: 0 20px;
    list-style-type: none;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    vertical-align: bottom;
}

nav.secondary ul li {
    height: 100%;
    line-height: 1.15;
    font-size: $navSecondaryFontSize;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 0 auto;
    min-width: 100px;
    margin: 0 10px;

    a {
        color: $greyText;
    }
}


nav.secondary ul li img.icon {
    width: 100px;
    height: 70px;
    margin: 0 auto;
}

nav.secondary ul li.promo {
    width: 155px;
}

nav.secondary ul li.line {
    min-width: auto;
    width: 1px;
    background-color: #000000;
    height: 70px;
    position: relative;
    top: 8px;
}

nav.secondary ul li.see-all {
    min-width: 75px;
}

nav.secondary ul li.see-all button,
nav.secondary ul li.see-all a {
    width: 100%;
    height: 100%;
}

nav.secondary ul li.see-all .label {
    width: 100%;
    text-align: center;
}

nav.secondary ul.hide-see-all li.line,
nav.secondary ul.hide-see-all li.see-all {
    display: none;
}

nav.secondary .label {
    text-align: center;
    margin-top: 5px;
}

nav.secondary a {
    text-decoration: none;
    color: #4c4c4c;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border: none;
}

nav.secondary a:hover:after,
nav.secondary a.active:after {
    width: 100%;
    opacity: 1;
}

nav.secondary picture.box {
    position: relative;
    left: -8px;
}

nav.secondary picture.rounded img {
    border-radius: 3px;
}

nav.secondary img.see-all-right {
    width: 21px;
    height: 21px;
    transform: rotate(180deg);
    margin: 20px auto;
}

nav.secondary img.hide-all-right {
    width: 16px;
    height: 16px;
    margin: 24px auto;
}

.see-all-toggle .see-all {
    display: block;
}

.see-all-toggle .hide-all {
    display: none;
}

.see-all-toggle.is-open .see-all {
    display: none;
}

.see-all-toggle.is-open .hide-all {
    display: block;
}

.see-all-toggle.bottom {
    display: none;
    text-align: center;
    height: 27px;
}

.see-all-toggle.bottom.is-visible {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.see-all-toggle.bottom img.see-all-bottom {
    width: 17px;
    height: 17px;
    transform: rotate(180deg);
    margin: 5px;
}

.see-all-toggle.bottom img.hide-all-bottom {
    width: 10px;
    height: 10px;
    margin: 10px 8px 0 8px;
}

.see-all-toggle.bottom .label {
    margin-top: 8px;
    font-size: $navSecondaryFontSize;
}

.see-all-toggle.bottom .see-all {
    display: flex;
}

.see-all-toggle.bottom .hide-all {
    display: none;
}

.see-all-toggle.bottom.is-open .see-all {
    display: none;
}

.see-all-toggle.bottom.is-open .hide-all {
    display: flex;
}

nav.see-all-content {
    background-color: #f2f2f2;
    width: 100%;
    padding: 0;
    position: absolute;
    z-index: 5;
    height: 0;
    overflow: hidden;
    display: none;
}

nav.see-all-content.is-open {
    display: block;
    overflow-y: scroll;
}

nav.see-all-content .flex-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 900px;
    margin: 0 auto;
    padding: 30px 15px;
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper {
        flex-wrap: wrap;
    }
}

nav.see-all-content .flex-wrapper .items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 80%;
}

nav.see-all-content .flex-wrapper .items .item {
    flex-basis: 30%;
}

nav.see-all-content .flex-wrapper .items .featured-item li a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: none;
}

nav.see-all-content .flex-wrapper .items .featured-item .label {
    padding-top: 10px;
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper .items {
        flex-basis: 100%;
    }
}

@media (max-width: 599px) {
    nav.see-all-content .flex-wrapper .items {
        flex-basis: 100%;
    }

    nav.see-all-content .flex-wrapper .items .item {
        flex-basis: 45%;
    }
}

nav.see-all-content .flex-wrapper .col {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px 0;
    padding: 0 10px;
}

nav.see-all-content .flex-wrapper h3 {
    position: relative;
    text-transform: Capitalize;
    font-family: $navFontFamily;
    font-weight: $navFontWeight;
    font-size: $navSecondaryFontSize;
    margin: 0;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #FFFFFF;
    color: #000000;
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper h3 {
        padding-top: 10px;
    }
}

nav.see-all-content .flex-wrapper ul {
    margin: 0;
    padding: 0 0 20px 0;
    list-style-type: none;
    line-height: 1.15;
    font-size: 0.8rem;
    ol li, ul li {
        font-size: 0.8rem;
    }
}

nav.see-all-content .flex-wrapper ul li {
    padding: 10px 0 0;
    line-height: 1.15;
}

nav.see-all-content .flex-wrapper ul li a {
    color: $black;
    text-decoration: none;
    border: none;
    font-size: 14px;
}

@media (min-width: 800px) {
    nav.see-all-content .flex-wrapper .promos {
        flex-basis: 20%;
    }
}

nav.see-all-content .flex-wrapper .promos .promo {
    text-align: center;
    font-family: $navFontFamily;
    font-weight: $navFontWeight;
    font-size: $navSecondaryFontSize;
    margin-bottom: 10px;
}

nav.see-all-content .flex-wrapper .promos .promo a {
    color: #000000;
    border: none;
    line-height: 1.15;
}

nav.see-all-content .flex-wrapper .promos .promo .label {
    text-align: center;
    margin-top: 5px;
    line-height: 1.15;
}

nav.see-all-content .flex-wrapper .promos .promo picture.rounded img {
    border-radius: 3px;
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper .promos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin: 15px 0 0 0;
    }

    nav.see-all-content .flex-wrapper .promos .promo {
        flex-basis: 50%;
    }
}


@media (max-width: 799px) {
    nav.search-overlay-content .search-bar {
        display: flex;
        align-items: center;
    }

    nav.search-overlay-content .search-bar form {
        flex-grow: 1;
        padding-left: 0;
    }
}

nav.search-overlay-content .search-bar form input {
    background-color: #f2f2f2;
}

nav.search-overlay-content .search-bar form .icon-v2 {
    background-color: #e31837;
}

.titleist-search-box .close-button {
    cursor: pointer;
}

@media (min-width: 800px) {
    .titleist-search-box .close-button {
        position: absolute;
        top: 1.55em;
    }
}

@media (min-width: 900px) {
    .titleist-search-box .close-button {
        right: 2.5em;
    }
}

@media (max-width: 899px) and (min-width: 800px) {
    .titleist-search-box .close-button {
        right: 0.75em;
    }
}

@media (min-width: 800px) {
    .titleist-search-box .close-button .icon-v2 {
        width: 2em;
        height: 2em;
    }
}

.titleist-search-box .close-button:hover .icon-v2 {
    background-color: #e31837;
}


.banner {
    width: 100%;
    background-color: #f2f2f2;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-size: 0.875rem;
}

.mobile-overlay,
.sub-nav-menu {
    background-color: #ffffff;
    margin: 0;
    height: 100%;
    font-family: $sans-serif;
    font-weight: $navFontWeight;
    font-display: block;
    font-size: 100%;
    line-height: 1.7;
    color: #4c4c4c;
}

.media-wrap {
    position: relative;
    display: inline-block;

    img,
    picture,
    video {
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }
}

.visually-hidden {
    display: none;
}

.nobr,
nobr {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.white-gradient {
    background: -webkit-linear-gradient(45deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.open-search-overlay {
    cursor: pointer;
}

.small-text {
    font-size: 0.75rem;
    line-height: 1.7;

    p,
    ol,
    ul,
    table,
    a {
        font-size: 0.75rem;
        line-height: 1.7;
    }
}

.smaller-text {
    font-size: 0.625rem;
    line-height: 1.7;
    font-weight: $navFontWeight;

    p,
    ol,
    ul,
    table,
    a,
    span {
        font-size: 0.625rem;
        line-height: 1.7;
    }
}

a {
    &.no-border,
    &.noborder {
        border-bottom: none;
    }

    &.no-border {
        border-bottom: 0px;
    }

    &.black-link {
        color: #000;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }

    &.bold-link {
        font-weight: 700;
    }
}

/*
 * Universal styles in both desktop and mobile navs
 */

.primary-nav-spacer {
    position: relative;
    width: 100%;
    height: 110px;
}

@media (max-width: 1199px) {
    .primary-nav-spacer {
        height: 71px;
    }
}

nav.primary {
    &.is-fixed {
        position: fixed;
        top: -1px;
        transform: translateY(calc(-100% + 1px));
    }

    &.is-animated {
        transition: transform 300ms ease;
    }

    .top-content a {
        color: #707070;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        vertical-align: bottom;
        font-size: 1rem;
        line-height: 1.15;
    }

    a {
        text-decoration: none;
        color: #000000;
        border: none;

        .icon-v2 {
            background-color: #000000;

            &:hover {
                background-color: #e31837;
            }
        }

        &.titleist-btn {
            color: #FFFFFF;
        }
    }
    &.desktop {
        &.is-fixed {
            z-index: -1;
        }
        &.is-visible {
            z-index: 100;
        }
    }
}

.balls-page nav.primary.desktop ul.page-links a.balls-link::after,
.clubs-page nav.primary.desktop ul.page-links a.clubs-link::after,
.gear-page nav.primary.desktop ul.page-links a.gear-link::after,
.tour-page nav.primary.desktop ul.page-links a.tour-link::after,
.instruction-page nav.primary.desktop ul.page-links a.instruction-link::after,
.team-page nav.primary.desktop ul.page-links a.team-link::after {
    content: '';
    width: 100%;
    opacity: 1;
    background-color: #000000;
}

/*
 * Top nav is currently 110px tall - 45px top space, 55px content height, 10px bottom space
 */

.see-all-toggle {
    .see-all {
        display: block;
    }

    .hide-all {
        display: none;
    }

    &.is-open {
        .see-all {
            display: none;
        }

        .hide-all {
            display: block;
        }
    }

    &.bottom {
        display: none;
        text-align: center;
        height: 27px;

        &.is-visible {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
        }

        img {
            &.see-all-bottom {
                width: 17px;
                height: 17px;
                transform: rotate(180deg);
                margin: 5px;
            }

            &.hide-all-bottom {
                width: 13px;
                height: 13px;
                margin: 10px 5px 0 5px;
            }
        }

        .label {
            margin-top: 8px;
        }

        .see-all {
            display: flex;
        }

        .hide-all {
            display: none;
        }

        &.is-open {
            .see-all {
                display: none;
            }

            .hide-all {
                display: flex;
            }
        }
    }
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper {
        flex-wrap: wrap;
    }
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper .items {
        flex-basis: 100%;
    }
}

@media (max-width: 599px) {
    nav.see-all-content .flex-wrapper .items {
        flex-basis: 100%;

        .item {
            flex-basis: 45%;
        }
    }
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper h3 {
        padding-top: 10px;
    }
}

@media (min-width: 800px) {
    nav.see-all-content .flex-wrapper .promos {
        flex-basis: 20%;
    }
}

@media (max-width: 799px) {
    nav.see-all-content .flex-wrapper .promos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin: 15px 0 0 0;

        .promo {
            flex-basis: 50%;
        }
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .flex-wrapper {
        .search-links {
            width: 20%;
            flex-basis: 20%;
        }

        .search-results {
            width: 80%;
            flex-basis: 80%;
        }
    }
}

@media (max-width: 799px) {
    nav.search-overlay-content .flex-wrapper {

        .search-links,
        .search-results {
            width: 100%;
            flex-basis: 100%;
        }
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .search-bar {
        padding-left: 20%;

        form {
            max-width: 580px;
        }
    }
}

@media (max-width: 799px) {
    nav.search-overlay-content .search-bar {
        display: flex;
        align-items: center;

        form {
            flex-grow: 1;
            padding-left: 0;
        }
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .search-bar .close-button {
        position: absolute;
        top: 1.55em;
    }
}

@media (min-width: 900px) {
    nav.search-overlay-content .search-bar .close-button {
        right: 2.5em;
    }
}

@media (max-width: 899px) and (min-width: 800px) {
    nav.search-overlay-content .search-bar .close-button {
        right: 0.75em;
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .search-bar .close-button .icon-v2 {
        width: 2em;
        height: 2em;
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .search-results {
        padding: 16px;
    }
}

@media (max-width: 799px) {
    nav.search-overlay-content .search-results {
        padding: 16px 0;
    }
}

@media (min-width: 800px) {
    nav.search-overlay-content .search-results ul li {
        width: calc(25% - 16px);
        flex-basis: calc(25% - 16px);
    }
}

@media (max-width: 799px) {
    nav.search-overlay-content .search-results ul li {
        width: calc(50% - 16px);
        flex-basis: calc(50% - 16px);
    }
}

nav.search-overlay-content.is-open {
	display: block;
	overflow-y: scroll;
}

.banner {
    width: 100%;
    background-color: #f2f2f2;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    font-size: 0.875rem;
}

nav.secondary,
nav.see-all-content {
    display: none;
    .plp-wrapper & {
        display: block;
    }
}

nav.primary,
nav.secondary {
    font-size: initial;
}

::placeholder {
    color: #6c6c6c;
}

.navigation-gear nav.primary.desktop ul.page-links a.gear-link,
.navigation-balls nav.primary.desktop ul.page-links a.balls-link,
.navigation-clubs nav.primary.desktop ul.page-links a.clubs-link {
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1px;
        background-color: #000;
        bottom: -3px;
        transition: all 150ms ease-out;
        width: 100%;
    }
}

@import "header_nav_primary";
@import "header_nav_secondary";
@import "header_nav_desktop";
@import "header_nav_mobile";
@import "header_nav_overlay";

// Oeder Groove
nav.primary .mini-bag-content,
.checkout-mini-cart {
    .mini-bag-product {
        .mini-bag-product-info {
            .og-frequency {
                display: none;
            }

            og-offer[subscribed] {
                .og-frequency {
                    .og-offer-incentive {
                        display: none;
                    }

                    display: inline-block;
                    padding-left: 25px;
                }
            }

            .tooltip-label {
                font-size: 12px;
                color: $color-primary;
                margin-top: -10px;
                padding: 0 0 0 25px;
            }
        }
    }
}

.checkout-mini-cart .mini-bag-product og-offer {
    color: $color-primary;
    margin: 0 0 10px;
    padding: 0;
}
