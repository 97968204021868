button {
	cursor: pointer;
	background: none;
	outline: none;
	border: none;
	padding: 0;
	margin: 0;
}

.titleist-btn,
.btn-secondary,
.btn-tertiary {
	line-height: 1.2;
	font-size: $ctaFontSize;
	text-decoration: none;
	text-align: center;
	position: relative;
	border-radius: 2rem;
	border: none;
	padding: 0.5em 3em;
	margin: 0.5em auto;
	display: inline-block;
	cursor: pointer;
	overflow: hidden;
	transform: translateZ(0);
	color: #ffffff;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	font-weight: 700;
}
.titleist-btn:before,
.btn-secondary:before,
.btn-tertiary:before {
	content: " ";
	position: absolute;
	top: 50%;
	transform: translate(calc(-100% - 4.5em), -50%);
	left: 0;
	width: 100%;
	height: 6em;
	transition: 250ms ease transform;
	z-index: -1;
}
.titleist-btn:after,
.btn-secondary:after,
.btn-tertiary:after {
	content: " ";
	position: absolute;
	top: 50%;
	left: -6em;
	width: 0;
	height: 100%;
	border-top: 3em solid transparent;
	border-bottom: 3em solid transparent;
	border-left-width: 3em;
	border-left-style: solid;
	box-sizing: border-box;
	transform: translateY(-50%);
	transition: 250ms ease all;
	z-index: -2;
}
.titleist-btn:hover,
.titleist-btn:focus,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-tertiary:hover,
.btn-tertiary:focus {
	color: #ffffff;
}
.titleist-btn:hover:before,
.titleist-btn:focus:before,
.btn-secondary:hover:before,
.btn-secondary:focus:before,
.btn-tertiary:hover:before,
.btn-tertiary:focus:before {
	transform: translate(calc(-3.75em), -50%);
}
.titleist-btn:hover:after,
.titleist-btn:focus:after,
.btn-secondary:hover:after,
.btn-secondary:focus:after,
.btn-tertiary:hover:after,
.btn-tertiary:focus:after {
	left: calc(100% - 4em);
}
.titleist-btn .btn-text,
.btn-secondary .btn-text,
.btn-tertiary .btn-text {
	position: relative;
}
.titleist-btn .icon-v2,
.btn-secondary .icon-v2,
.btn-tertiary .icon-v2 {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 1.5em;
	transition: 250ms ease background-color;
	background-color: #ffffff;
}
.titleist-btn.outline,
.btn-secondary.outline,
.btn-tertiary.outline {
	background: transparent;
	border-style: solid;
	border-width: 2px;
	transition: 250ms ease color, 250ms ease background-color;
}
.titleist-btn.outline:before,
.titleist-btn.outline:after,
.btn-secondary.outline:before,
.btn-secondary.outline:after,
.btn-tertiary.outline:before,
.btn-tertiary.outline:after {
	display: none;
}
.titleist-btn.outline:hover,
.titleist-btn.outline:focus,
.btn-secondary.outline:hover,
.btn-secondary.outline:focus,
.btn-tertiary.outline:hover,
.btn-tertiary.outline:focus {
	color: #ffffff;
}
.titleist-btn.outline:hover .icon-v2,
.titleist-btn.outline:focus .icon-v2,
.btn-secondary.outline:hover .icon-v2,
.btn-secondary.outline:focus .icon-v2,
.btn-tertiary.outline:hover .icon-v2,
.btn-tertiary.outline:focus .icon-v2 {
	background-color: #ffffff;
}
.titleist-btn.align-icon-left,
.btn-secondary.align-icon-left,
.btn-tertiary.align-icon-left {
	padding-left: 3.5em;
}
.titleist-btn.align-icon-right,
.btn-secondary.align-icon-right,
.btn-tertiary.align-icon-right {
	padding-right: 3.5em;
}
.titleist-btn.align-icon-right .icon-v2,
.btn-secondary.align-icon-right .icon-v2,
.btn-tertiary.align-icon-right .icon-v2 {
	left: auto;
	right: 1.5em;
}

.titleist-btn {
	background-color: #e31837;
}
.titleist-btn:before {
	background-color: #b51029;
}
.titleist-btn:after {
	border-left-color: #b51029;
}
.titleist-btn:hover,
.titleist-btn:focus {
	background-color: #e31837;
}
.titleist-btn.outline {
	border-color: #e31837;
	color: #e31837;
}
.titleist-btn.outline .icon-v2 {
	background-color: #e31837;
}
.titleist-btn.outline:hover,
.titleist-btn.outline:focus {
	background-color: #e31837;
}


.btn-secondary:hover,
.btn-secondary:focus {
	background-color: #999999;
}
.btn-secondary.outline {
	border-color: #999999;
	color: #999999;
}
.btn-secondary.outline .icon-v2 {
	background-color: #999999;
}
.btn-secondary.outline:hover,
.btn-secondary.outline:focus {
	background-color: #999999;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
	background-color: #000000;
}
.btn-tertiary.outline {
	border-color: #000000;
	color: #000000;
}
.btn-tertiary.outline .icon-v2 {
	background-color: #000000;
}
.btn-tertiary.outline:hover,
.btn-tertiary.outline:focus {
	background-color: #000000;
}

