//-----------------------------------------------
//  TYPOGRAPHY styles
//-----------------------------------------------


// Headings
//---------------------------------------

// can apply directly in SCSS
@mixin h1 {
    //@include font-cond;
    @include font-bold;
    @include font-size(36px);
    @include line-height(40px);
}

@mixin h2 {
    //@include font-cond;
    @include font-bold;
    @include font-size(24px);
    @include line-height(30px);
}

@mixin h3 {
    //@include font-cond;
    @include font-bold;
    @include font-size(16px);
    @include line-height(20px);
}

@mixin h4 {
    //@include font-cond;
    @include font-bold;
    @include font-size(14px);
    @include line-height(18px);
}

@mixin h5 {
    //@include font-cond;
    @include font-size(27.2px);
    line-height: 1;
}


// can apply directly in ISML as a class
.h1 {
    @include h1;
}

.h2 {
    @include h2;
}

.h3 {
    @include h3;
}

.h4 {
    @include h4;
}

.h5,
.h6 {
    @include h5;
}

// Secondary paragraph text (smaller)
//---------------------------------------

// can apply directly in SCSS
@mixin alt-p {
    @include font-size(14px);
    @include line-height(26px);
}

// can apply directly in ISML as a class
.alt-p, p {
    @include alt-p;
}

// Informational Text (smallest)
//---------------------------------------

// can apply directly in SCSS nested within another mixin, like respond()
@mixin info {
    @include font-size(12px);
    @include line-height(18px);
}

// can apply directly in ISML as a class
.info {
    @include info;
    a{
        @include font-size(12px);
    }
}

//helper classes
.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.dark {
    color: $black;
}

.light {
    color: $white;
}

.condensed {
    font-family: $gotham-cond;
}

