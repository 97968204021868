
.icon-v2.icon-arrow-circle-up {
    -webkit-mask-image: url("../../images/icons/arrow-circle-up-48dp.svg");
            mask-image: url("../../images/icons/arrow-circle-up-48dp.svg"); }

  .icon-v2.icon-arrow-circle-down {
    -webkit-mask-image: url("../../images/icons/arrow-circle-down-48dp.svg");
            mask-image: url("../../images/icons/arrow-circle-down-48dp.svg"); }

  .icon-v2.icon-arrow-drop-down {
    -webkit-mask-image: url("../../images/icons/arrow-drop-down-48dp.svg");
            mask-image: url("../../images/icons/arrow-drop-down-48dp.svg"); }

  .icon-v2.icon-arrow-drop-up {
    -webkit-mask-image: url("../../images/icons/arrow-drop-up-48dp.svg");
            mask-image: url("../../images/icons/arrow-drop-up-48dp.svg"); }

  .icon-v2.icon-arrow-up {
    -webkit-mask-image: url("../../images/icons/arrow-up-48dp.svg");
            mask-image: url("../../images/icons/arrow-up-48dp.svg"); }

  .icon-v2.icon-call {
    -webkit-mask-image: url("../../images/icons/call-48dp.svg");
            mask-image: url("../../images/icons/call-48dp.svg"); }

  .icon-v2.icon-chat {
    -webkit-mask-image: url("../../images/icons/chat-48dp.svg");
            mask-image: url("../../images/icons/chat-48dp.svg"); }

  .icon-v2.icon-chevron-left {
    -webkit-mask-image: url("../../images/icons/chevron-left-48dp.svg");
            mask-image: url("../../images/icons/chevron-left-48dp.svg"); }

  .icon-v2.icon-chevron-right {
    -webkit-mask-image: url("../../images/icons/chevron-right-48dp.svg");
            mask-image: url("../../images/icons/chevron-right-48dp.svg"); }

  .icon-v2.icon-close {
    -webkit-mask-image: url("../../images/icons/close-48dp.svg");
            mask-image: url("../../images/icons/close-48dp.svg"); }

  .icon-v2.icon-comments {
    -webkit-mask-image: url("../../images/icons/comments-48dp.svg");
            mask-image: url("../../images/icons/comments-48dp.svg"); }

  .icon-v2.icon-customize {
    -webkit-mask-image: url("../../images/icons/customize-48dp.svg");
            mask-image: url("../../images/icons/customize-48dp.svg"); }

  .icon-v2.icon-delete {
    -webkit-mask-image: url("../../images/icons/delete-48dp.svg");
            mask-image: url("../../images/icons/delete-48dp.svg"); }

  .icon-v2.icon-events {
    -webkit-mask-image: url("../../images/icons/events-48dp.svg");
            mask-image: url("../../images/icons/events-48dp.svg"); }

  .icon-v2.icon-facebook {
    -webkit-mask-image: url("../../images/icons/facebook-48dp.svg");
            mask-image: url("../../images/icons/facebook-48dp.svg"); }

  .icon-v2.icon-favorite {
    -webkit-mask-image: url("../../images/icons/favorite-48dp.svg");
            mask-image: url("../../images/icons/favorite-48dp.svg"); }

  .icon-v2.icon-forums {
    -webkit-mask-image: url("../../images/icons/forums-48dp.svg");
            mask-image: url("../../images/icons/forums-48dp.svg"); }

  .icon-v2.icon-hole-in-one {
    -webkit-mask-image: url("../../images/icons/hole-in-one-48dp.svg");
            mask-image: url("../../images/icons/hole-in-one-48dp.svg"); }

  .icon-v2.icon-instagram {
    -webkit-mask-image: url("../../images/icons/instagram_icon.svg");
            mask-image: url("../../images/icons/instagram_icon.svg"); }

  .icon-v2.icon-language {
    -webkit-mask-image: url("../../images/icons/language-48dp.svg");
            mask-image: url("../../images/icons/language-48dp.svg"); }

  .icon-v2.icon-load-more {
    -webkit-mask-image: url("../../images/icons/load-more-48dp.svg");
            mask-image: url("../../images/icons/load-more-48dp.svg"); }

  .icon-v2.icon-location {
    -webkit-mask-image: url("../../images/icons/location-48dp.svg");
            mask-image: url("../../images/icons/location-48dp.svg"); }

  .icon-v2.icon-mail {
    -webkit-mask-image: url("../../images/icons/mail-48dp.svg");
            mask-image: url("../../images/icons/mail-48dp.svg"); }

  .icon-v2.icon-news {
    -webkit-mask-image: url("../../images/icons/news-48dp.svg");
            mask-image: url("../../images/icons/news-48dp.svg"); }

  .icon-v2.icon-orders {
    -webkit-mask-image: url("../../images/icons/orders-48dp.svg");
            mask-image: url("../../images/icons/orders-48dp.svg"); }

  .icon-v2.icon-person {
    -webkit-mask-image: url("../../images/icons/person-48dp.svg");
            mask-image: url("../../images/icons/person-48dp.svg"); }

  .icon-v2.icon-register {
    -webkit-mask-image: url("../../images/icons/register-48dp.svg");
            mask-image: url("../../images/icons/register-48dp.svg"); }

  .icon-v2.icon-review {
    -webkit-mask-image: url("../../images/icons/review-48dp.svg");
            mask-image: url("../../images/icons/review-48dp.svg"); }

  .icon-v2.icon-search {
    -webkit-mask-image: url("../../images/icons/search-48dp.svg");
            mask-image: url("../../images/icons/search-48dp.svg"); }

  .icon-v2.icon-send {
    -webkit-mask-image: url("../../images/icons/send-48dp.svg");
            mask-image: url("../../images/icons/send-48dp.svg"); }

  .icon-v2.icon-settings {
    -webkit-mask-image: url("../../images/icons/settings-48dp.svg");
            mask-image: url("../../images/icons/settings-48dp.svg"); }

  .icon-v2.icon-shipping {
    -webkit-mask-image: url("../../images/icons/shipping-48dp.svg");
            mask-image: url("../../images/icons/shipping-48dp.svg"); }

  .icon-v2.icon-shopping-bag {
    -webkit-mask-image: url("../../images/icons/shopping-bag-48dp.svg");
            mask-image: url("../../images/icons/shopping-bag-48dp.svg"); }

  .icon-v2.icon-shopping-bag-simple {
	-webkit-mask-image: url("../../images/icons/shopping-bag-48dp.svg");
			mask-image: url("../../images/icons/shopping-bag-48dp.svg"); }

  .icon-v2.icon-sweepstakes {
    -webkit-mask-image: url("../../images/icons/sweepstakes-48dp.svg");
            mask-image: url("../../images/icons/sweepstakes-48dp.svg"); }

  .icon-v2.icon-twitter {
    -webkit-mask-image: url("../../images/icons/twitter_icon.svg");
            mask-image: url("../../images/icons/twitter_icon.svg"); }

  .icon-v2.icon-video {
    -webkit-mask-image: url("../../images/icons/video-48dp.svg");
            mask-image: url("../../images/icons/video-48dp.svg"); }

  .icon-v2.icon-warranty {
    -webkit-mask-image: url("../../images/icons/warranty-48dp.svg");
            mask-image: url("../../images/icons/warranty-48dp.svg"); }

  .icon-v2.icon-youtube {
    -webkit-mask-image: url("../../images/icons/youtube_icon.svg");
            mask-image: url("../../images/icons/youtube_icon.svg"); }

  .icon-v2.icon-v2 {
    opacity: 1; }
