#wrapper {
	#main {
		#parcellab-track-and-trace {
			background-color: #fff;
			text-transform: initial;
			font-family: $sans-serif;

			* {
				font-family: $sans-serif;
			}

			h1, h2, h3, h4, h5, .ui-dialog .ui-dialog-title  {
				letter-spacing: 0;
			}
			div.pl-tab, .pl-box-heading, .pl-checkpoint b, #pl-courier-fwd-link, .pl-cal-week-day {
				text-transform: uppercase;
			}

			div.pl-tab {
				color: #4C4C4C !important;
				background-color: #F2F2F2 !important;

				&.pl-active {
					font-weight: bold;
					color: $black !important;
					background-color: #DFDFDF !important;
					border-bottom: #E31837 4px solid;
				}
			}

			.pl-header {
				h2.pl-order-no {
					text-transform: uppercase;
					color: #000;
					opacity: 1;
				}
				.pl-order-sub {
					text-transform: capitalize;
				}
			}
			#pl-reroute-link { 
				display: none;
			}
		}
	}
}