.pt_categorylanding {
	#main {
		border-bottom: 0!important;
		.innerWidth {
			@include respond(small) {
				padding: 0 5%;
				box-sizing: border-box;
			}
			@media screen and (min-width: 768px) and  (max-width: 1023px) {
				max-width: 760px;
			}
		}
	}
	@include respond(largeUp) {
		.col {
			float: left;
			width: 100%;
			&.pull-right {
				float: right;
			}
		}
		.col-md-50 {
			width: 50%;
			box-sizing: border-box;
			&.left-col {
				padding-right: 5%;
			}
			&.right-col {
				padding-left: 5%;
			}
			&.cat-promo {
				&.left-col {
					padding-right: 20px;
				}
				&.right-col {
					padding-left: 20px;
				}
			}
		}

	}
	img {
		max-width: 100%;
	}
	.cat-banner {
		position: relative;
		img {
			width: 100%;
		}
		.banner-wrapper {
			width: 100%;
			position: absolute;
			bottom: 0;
			background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
			@include respond(small) {
				position: static;
				background: none;
			}
			.innerWidth {
				padding-top: 200px;
				@include respond(large) {
					padding-top: 50px;
				}
				@include respond(small) {
					padding-top: 0;
				}
				h1,h2,h3,h4,h5,h6,p,span {
					color: $white;
					@include respond(small) {
						color: $mine-shaft;
					}
				}
				span {
					@include font-size(16px);
					font-weight: 700;
					line-height: 1.25;
					@include respond(small) {
						display: block;
						text-align: center;
						padding-top: 20px;
					}
				}
				h2 {
					@include font-size(46px);
					margin-bottom: 60px;
					margin-top: 25px;
					font-weight: 400;
					line-height: 1.1;
					@include respond(small) {
						text-align: center;
						margin-top: 20px;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	.cat-landing-heading {
		text-align: center;
		margin-top: 75px;
		margin-bottom: 45px;
		h1 {
			margin: 0;
		}
	}
	.cat-landing-heading2 {
		text-align: center;
		margin-bottom: 50px;
		@include respond(small) {
			margin-bottom: 20px;
		}
		h1 {
			margin: 0;
		}
	}
	.cat-product {
		font-size: 0;
		.custom-ball {
			img {
				padding-bottom: 30px;
			}
			p {
				margin-top: 0;
			}
		}
	}
	.cat-promo {
		font-size: 0;
		.promo-text {
			background: $black-haze;
			border-bottom: 5px solid $gray-nurse;
			padding: 20px;
			box-sizing: border-box;
			h2 {
				margin: 0;
			}
			.bold-link {
				@include font-size(14px);
				font-weight: 700;
				text-decoration: none;
			}
		}
	}
	.cat-product, .cat-promo {
		padding-bottom: 150px;
		@media screen and (min-width: 768px) and  (max-width: 1023px) {
			padding-bottom: 60px;
		}
		@include respond(small) {
			padding-bottom: 40px;
		}
	}
}

#wrapper.pt_cart {
	&.checkout-login {
		.profile-wrapper {
			background: $lightGrey;
			margin-bottom: 60px;
		}

		.innerWidth {
			max-width: 1119px;
		}
	}
}

.checkoutlogin {
	border: 1px $color-border2 solid;
	border-top: 26px $black solid;
	box-shadow: 0px 3px 6px $color-border2;
	margin: 10px 0 60px;
	padding: 0;
	width: 100%;

	.col-1 {
		display: none;
	}

	.col-2 {
		background-color: $white;
		border-top: 5px $red solid;
		float: none;
		margin: 0;
		padding: 40px 100px 75px;
		width: 100%;

		@include respond(small) {
			padding: 20px 15px 30px;
		}

		h2 {
			font-size: 36px;
			letter-spacing: 0.5px;
			line-height: 44px;
			margin: 0 0 10px;
			text-align: center;

			@include respond(small) {
				font-size: 26px;
				line-height: 34px;
			}
		}

		h3 {
			font-size: 24px;
			letter-spacing: 0.34px;
			line-height: 44px;
			margin: 0 0 0px;
			padding: 0;

			@include respond(small) {
				font-size: 20px;
				line-height: 30px;
			}
		}

		p {
			color: $greyText;
			font-size: 14px;
			letter-spacing: 0.2px;
			line-height: 20px;
			margin: 0 0 16px;
			padding: 0;

			@include respond(small) {
				line-height: 16px;
			}
		}

		.returning-customers-container {
			border-left: 1px $bon-jour_approx solid;
			float: right;
			padding: 0 50px;
			width: 50%;

			@include respond(small) {
				border-left: none;
				float: none;
				padding: 0;
				width: 100%;
			}

			.form-row {
				&.required{
					.required-indicator {
						display: none;
					}

					label::after{
						content: " *";
						color: $gray-dark;
					}
				}

				.input-text,
				.input-select {
					transition: box-shadow 0.1s linear;

					&:hover,
					&:focus {
						box-shadow: 0px 0px 0px 2px $black;
					}
				}
			}

			.password {
				position: relative;

				.password__show-hide {
					display: block;
					position: absolute;
					top: 30px;
					right: 15px;
					margin: 0;
					padding: 0;
					border: none;
					width: 23px;
					height: 19px;
					min-width: auto;
					background: url('../../images/account/hide-password.svg');

					@include respond(small) {
						top: 33px;
					}

					&.password__show-hide--active {
						background: url('../../images/account/show-password.svg');
					}

					&::before,
					&::after,
					&:hover::before,
					&:hover::after {
						display: none;
					}
				}

				.form-row {
					margin-bottom: 0;

					.form-caption {
						display: none;
					}
				}

				.form-caption {
					margin-bottom: 20px;
					color: #989898;
					font-size: 11px;

					.password__validation {
						margin-right: 10px;

						&.valid::after {
							content: '\2713';
							display: inline-block;
							color: #307C2E;
							padding-left: 2px;
							font-size: 14px;
						}
					}
				}
			}

			.reset-pass {
				padding: 10px 5px;

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.login-rememberme {
				margin: 10px 5px;

				label {
					font-weight: 400;
				}
			}
		}

		.new-customers-container {
			float: left;
			padding: 0 35px 0 0;
			width: 50%;

			@include respond(small) {
				float: none;
				width: 100%;
			}

			&.account {
				display: none;
			}
		}
	}
}
