/////// Overrides of _shopping_cart.scss ///////

.order-totals-table .order-subtotal {
	font-weight: normal;
}

/////// Replacement of _cart.scss ///////
/////// Styles all copied directly from app_storefront_core and modified below ///////////
/////// Some of the original code was very poorly written in terms of supporting /////////
/////// potential master architecture, as well as general structure and code bloat :( ////

#wrapper.pt_cart{
	#main{
		#primary{
			float: left;
			width: 100%;
			margin-top: rem(55px);

			.cart-action-paypal {
				&::after,
				&::before {
					display: none;
				}
			}

			.cart-banner-top{
				display: block;
				width: 100%;
				margin-bottom: 0;

				img{
					width: 100%;
				}

				@include respond(small) {
					display: none;
				}
			}
			.cart-empty{
				.continue-shopping-btn {
					display: block;
					text-align: center;
					font-weight: bold;
					font-size: 14px;
					text-decoration: none;
					&:hover {
						color: $color-secondary;
						text-decoration: underline;
					}
				}
			}
			.no-hits-shop-by-category {
				margin-top: 50px;

				&.home-bottom-slots {
					.promoSlotFull#home-full-1 {
						.bannerContent {
							display: none;
						}

						.itemContent {
							border-top: none;

							a {
								border: none;
								width: 20%;

								&:first-child {
									font-size: 20px;
									box-sizing: border-box;
									display: block;
									float: none;
									border: none;
									width: 100%;
									height: 55px;

									.headers-wrapper {
										width: 100%;
										position: static;
										-webkit-transform: none;
										transform: none;
										text-align: center;

										&:after {
											content: '';
											display: block;
											clear: both;
										}

										h2, h3 {
											display: inline-block;
											margin: 0;
											font-size: 30px;

											@include respond(small) {
												font-size: 1.25rem;
											}
										}

										h2 {
											margin-right: 10px;
										}
									}
								}
								&:not(:first-child) {
									@include respond(small) {
										width: 100%;
										height: auto;
										text-align: center;
										border-bottom: 2px solid #ccc;
										vertical-align: middle;

									}
									& > div {
										display: inline-block;
										width: 30%;
										min-width: 100px;
										padding: 5px 0;
										vertical-align: middle;
										text-align: left;
									}
									img {
										@include respond(small) {
											position: static;
											max-width: 25%;
											transform: none;
											-webkit-transform: none;
										}
									}
									h3 {
										@include respond(small) {
											position: static;
											display: inline-block;
											width: 20%;
											padding-left: 2%;
											margin: 0;
											vertical-align: middle;
											text-align: center;
										}
									}
								}
								&:last-child {
									@include respond(small) {
										border-bottom: none;
									}
								}
							}
						}
					}
				}
			}

			.no-hits-recommendations {
				margin-top: 40px;
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;

				@include respond(small) {
					margin-bottom: 40px;
				}

				h3 {
					margin-bottom: 60px;
				}

				&.pdp-recommendations {
					.product-listing-1x4 {
						.search-result-items {
							margin: 0;
						}
					}
				}
			}
			.name-container{
				float: left;
				width: 100%;
				border-bottom: none;
				padding-bottom: 0;

				@include respond(largeUp) {
					padding-bottom: rem(16px);
				}

				.cart-name {
					float: left;
					width: 44%;
					&.applepaybutton-enabled-cart-name {
						@media screen and (min-width: 768px) and (max-width: 1024px) {
							width: auto;
							float: none;
						}
						@media screen and (min-width: 1025px) {
							width: auto;
						}
					}

					@include respond(small) {
						width: 100%;
					}

					h1{
						margin: 0;
						@include respond(small) {
							@include font-size(32px);
							line-height: 0.75em;
							width: 100%;
							display: block;
						}
					}

					.cart-promo.cart-promo-approaching,
					p.promotion{
						font-family: $titillium;
						font-weight: bold;
						font-size: 16px;
						color: $red;
						line-height: 100%;
						margin: 0;
						background: none;
						padding-left: 0;
						padding-right: 0;
						font-style: normal;

						@include respond(small) {
							margin-bottom: 14px;
							line-height: 120%;
						}
					}

				}
				.cart-actions-top{
					float: right;
					width: 56%;
					margin-top: 0;
					padding-bottom: rem(2px);
					&.applepaybutton-enabled-cart-actions {
						@media screen and (min-width: 768px) and (max-width: 1024px) {
							width: 100%;
							margin-top: 20px;
							.cart-action-checkout {
								display: block;
    							width: 100%;
							}
						}
						@media screen and (min-width: 1025px) {
							width: 77.2%;
						}
						.applepaybutton-enabled {
							@media screen and (min-width: 768px) and (max-width: 1024px) {
								display: flex;
								justify-content: space-between;
							}
							.go-checkout {
								@media screen and (min-width: 768px) and (max-width: 1024px) {
									order: 3;
								}
							}
							.cart-action-paypal {
								@media screen and (min-width: 768px) and (max-width: 1024px) {
									order: 2;
								}
								&::after,
								&::before {
									display: none;
								}
							}
							.dw-apple-pay-button {
								@media screen and (min-width: 768px) and (max-width: 1024px) {
									order: 1;
								}
							}
							.go-checkout,
							.cart-action-paypal,
							.dw-apple-pay-button {
								@media screen and (min-width: 768px) and (max-width: 1024px) {
									width: 32%;
									margin: 0;
								}
								@media screen and (min-width: 1025px) {
									width: 240px;
								}
							}
						}
					}

					@include respond(small) {
						width: 100%;
						.cart-action-checkout{
							float: left;
							width: 100%;
						}
					}

					button{
						@include redButton;
						float: right;
						@include respond(small) {
							width: 100%;
						}
						@media screen and (min-width: 768px) and (max-width:991px) {
							width: 190px;
						}
						@media screen and (min-width: 992px) {
							width: 250px;
						}
						&.button-text {
							@include line-height(36px);
							background: none;
							float:none;
							border: none;
							border-bottom: 1px solid $gray-light;
							color: $black;
							text-decoration: underline;
							margin: 0 auto;
							padding-top: rem(8px);
							height: auto;
							width: 100%;
							@include respond(largeUp) {
								display: none;
							}
							&:hover {
								background: none;
								color: $black;
							}
						}
					}
				}
			}
			.cart-actions {
				.cart-action-paypal {
					@include respond(small) {
						width: 100%;
						float: left;
					}
					@media screen and (min-width: 768px) and (max-width:991px) {
						min-width: 200px;
					}
					@media screen and (min-width: 992px) {
						min-width: 230px;
					}
					background: $white;
					border: 2px solid $gray-light;
					color: $black;
					margin-top: 10px;
					padding: 0;
					&:hover {
						background-color: transparent;
						&:before,
						&:after {
							display: none;
						}
					}
					span {
						padding-right: 4px;
					}

					img {
						max-height: 30px;
						vertical-align: middle;
					}
				}
				&.cart-actions-top {
					.or-label {
					    float: right;
					    margin-left: 20px;
					    margin-top: 15px;
					    position: relative;
					    margin-right: 10px;
					    @include respond(small) {
							margin-right: 47%;
						}
					}
					.dw-apple-pay-button,
					.cart-action-paypal {
						margin: 0 15px 0 0;

						@include respond(small) {
							margin: 10px 0 0;
						}
					}
				}
				button.dw-apple-pay-button {
					background: -webkit-named-image(apple-pay-logo-white) no-repeat 50% 50% $black;
					background-size: 45% 45%;
					border: 2px solid $black;
					border-radius: 2rem;
					float: left;
					margin: 10px 0 0;
					padding: 0;
					height: 33px;
					&:hover {
						background-color: $black;
					}
					@include respond(small) {
						float: right;
						margin-top: 10px !important;
					}
				}
			}
			.bonus-item-promo{
				float: left;
				width: 100%;
				padding: 15px 0;
				@include respond(small) {
					text-align: center;
					border-bottom: 2px solid $gray-light;
				}
				.bonus-item-details{
					float: left;
					font-size: 14px;
					margin-top: 10px;
					@include respond(small) {
						margin-top: 0;
						float: none;
						display: inline-block;
						margin-bottom: 10px;
					}
					a{
						color: $gray-dark;
						font-size: 14px;
						font-style: normal;
						span{
							text-decoration: underline;
							&:hover{
								text-decoration: none;
							}
						}
					}
				}
				.bonus-products{
					float: right;
					@include respond(small) {
						float: none;
					}
					a{
						font-size: 11px;
						line-height: 30px;
						padding: 0 2em;
						height: 34px;
						margin-top: 5px;
						padding: 0 18px;
					}
				}
			}
			#cart-items-form{
				float: left;
				width: 100%;
				tfoot{
					td.cart-promo{
						padding: 15px 5px;
						span{
							font-size: 14px;

							&.value{
								text-transform: uppercase;
							}
						}
					}
				}
				#bonus-table {
					.cart-promo {
						border-bottom: none;
					}
				}
				#cart-table,
				#bonus-table{
					th{
						background: $gray-white;
						padding: 10px 5px;
						&:first-child{
							padding-left: 20px;
						}
						&:last-child{
							padding-right: 20px;
						}
					}
					tr{
						&.rowbundle{
							&:last-child{
								border-bottom: 1px solid $gray-light;
							}
							td{
								padding-top: 5px;
								padding-bottom: 21px;
								&.item-details{

									.product-list-item{
										@include respond(large) {
											min-width: 250px;
											display: block;
										}
									}
									.item-image{
										width: rem(180px);
										float: left;
									}
									.name{
										margin-top: 10px;
										display: inline-block!important;

										a{
											line-height: 150%;
										}
									}
									@include respond(small) {
										.item-image{
											float: left;
											width: 100%;
										}
										.name{
											float: left;
											width: 100%;
										}
									}
								}
								@include respond(small) {
									&.item-price,
									&.item-total{
										display: none;
									}
								}
								&.item-quantity{
									color: $gray-warm;
    								font-size: 12px;
    								font-weight: normal;

    								@include respond(small) {
										display: none;
									}
    								.bundleqtyincluded{
    									color: $gray-warm;
    									ont-size: 12px;
    									display: block;
    									margin: 10px 0 18px;
    								}
    								.product-availability-list{
	    								.is-in-stock{
	    									padding: 0;
	    									font-weight: normal;
	   									}
   									}
								}
							}
						}
						td{
							padding: 20px 5px 13px;
							@include respond(small) {
								padding: 17px 5px 0;
							}
							&.item-image{
								padding-left: 0;

								@include respond(largeUp) {
									width: rem(200px);
								}
								img{
									width: rem(180px);
									margin-right: 35px;
									max-width: 100%!important;
									@include respond(small) {
										width: 100px;
										margin-right: 20px;
									}
								}
							}
							&.item-details{
								.item-image{
									img{
										width: 135px;
										margin-right: 35px;
										float: left;
										@include respond(small) {
											width: 100px;
											margin-right: 20px;
										}
									}
								}
								.product-list-item{
									div.name,
									a{
										display: block;
										@include font-bold(14px);
										color: $black;
										text-decoration: none;
										font-style: normal;
										@include respond(small) {
											margin-bottom: 3px;
										}
									}
									div.name,
                                    div.promo {
										padding-bottom: 7px;
									}
									.label{
										@include font-size(12px);
										color: $gray-dark;
										font-weight: bold;
										text-transform: none;

										&.grouplabel {
											display: inline-block;
											margin-top: 1em;
										}

										.attribute-iron-label {
											text-transform: none;
										}
									}
									.attribute{
										display: block;
										width: 100%;
										line-height: 148%;
										padding-bottom: 5px;
									}
									.product-option,
                                    .gc-shipline {
										padding-bottom: 5px;
									}
									.item-links{
										padding-top:5px;
										display: block;
										a{
											font-size: 11px;
											text-transform: uppercase;
										}
									}
									.value{
										@include font-size(12px);
										//text-transform: uppercase;
										color: $gray-warm;
										font-weight: normal;
									}
									.sku{
										padding-bottom: 10px;
										span{
											font-size: 11px;
											color: $gray-warm;
											text-transform: none;
											font-weight: normal;
										}
									}
								}
								.logo-order-details {
									margin-top: 1em;
									.label {
										font-weight: bold;
									}
								}
								.item-edit-details{
									padding-top: 10px;
									a {
										text-decoration: underline;
									}
								}
								.bonus-products{
									padding-top: 10px;
									a {
										color: $gray-dark;
										font-size: 12px;
										font-weight: normal;
										height: auto;
										line-height: normal;
										text-transform: capitalize;
										text-decoration: underline;
										&:hover{
											text-decoration: none;
										}
									}
								}
								.item-container {
									&.og {
										.product-list-item {
											.attribute {
												&.option-dropdown {
													display: none;
												}
											}
										}
									}
								}
							}
							&.item-packaging{
								.product-options {
									@include respond(largeUp) {
										min-width: 170px;
									}
								}
								.product-options, ul {
									margin: 0;
									padding: 0;

									li {
										@include font-size(12px);
										margin: 0;
									}

									input[type="radio"] {
										vertical-align: bottom;
										margin: 0.6em 1%;
										background-color: transparent;
									}
								}

								@include respond(small) {
									float: right;
									width: 60%;
									clear: none;
									text-align: left;
								}
							}
							&.item-quantity{
								width: 12%;
								@include respond(small) {
									float: right;
									width: 60%;
									clear: none;
									text-align: left;
								}
								.fixedqty {
									display: block;
									padding: rem(9px) 0;
								}
								.mobile-title{
									display: none;
									@include respond(small) {
										display: block;
										width: 100%;
										font-weight: bold;
										font-size: 11px;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: -4px 0 5px;
									}
								}
								select{
									width: rem(73px);
									@include respond(small) {
										float: left;
									}
								}
								.item-user-actions{
									@include respond(small) {
										float: left;
										width: 100%;
									}
									a.add-to-wishlist,
									button{
										font-weight: normal;
									}
								}
								.product-availability-list{
									display: block;
									font-weight: normal;
									@include respond(small) {
										float: left;
										text-align: left;
									}
									.is-in-stock{
										font-size: 12px;
										color: $gray-warm;
										padding: 11px 0 4px;
										font-weight: normal;
										@include respond(small) {
											padding: 11px 0;
										}
									}
								}
								.button-text, a.add-to-wishlist{
									@include font-size(12px);
									text-decoration: underline;
									line-height: 120%;
									display: table;
									margin-bottom: rem(6px);
									height: auto;

									@include respond(small) {
										margin-bottom: rem(13px);
									}
								}
							}
							&.item-price{
								width: 8%;
								color: $gray-dark;
								& > span:first-child {
									padding-top: rem(9px);
									display: block;
									width: 100%;
								}
								.mobile-title {
									@include respond(largeUp) {
										display: none !important;
										& + span {
											padding-top: rem(9px);
											display: block;
											width: 100%;
										}
									}
									@include respond(small) {
										display: inline-block !important;
										width: initial !important;
										font-weight: bold;
										font-size: 12px;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: 0;
										padding-top: rem(9px) !important;
									}
								}
								.price-promotion{
									& > span:first-child{
										padding-top: 9px;
										display: block;
										width: 100%;
									}
								}
								@include respond(small) {
									float: right;
									width: 60%;
									padding-top: 0;
									& > span:first-child{
										padding-top: 1px;
									}
									.price-promotion{
										& > span:first-child{
											padding-top: 1px;
										}
									}
								}
								.price-promotion{
									span{
										margin: 0;
										display: block;
									}
									.price-standard{
										text-decoration: line-through;
									}
									.price-sales{
										color: $red;
									}
								}
								.price-adjusted {
									color: $color-secondary;
								}
								.price-sales {
									&.adjusted {
										text-decoration: line-through;
									}
								}
							}
							&.item-total{
								padding-right: 20px;
								width: 11%;

								.label {
									display: none;
								}

								& > span{
									padding-top: 9px;
									display: block;
									width: 100%;

									@include respond(small) {
										font-size: 12px;
									}

									&.price-adjusted-total,
									&.price-unadjusted{

										@include respond(small) {
										clear: both;
										padding-top: 3px;
										  	span{
										  		font-size: 12px;
											}
										}
									}
								}

								div.promo-adjustment {
									width: 100%;

									@include respond(small) {
										font-size: 12px;
									}
								}

								.mobile-title{
									display: none;

									@include respond(small) {
										float: left;
										display: block;
										font-weight: bold;
										font-size: 12px;
										color: $gray-dark;
										text-transform: uppercase;
										text-align: left;
										margin: 0 5px 0 0;
									}
								}

								@include respond(small) {
									float: right;
									width: 60%;
									text-align: left;
									padding-top: 10px;
									padding-bottom: 25px;
								}

								.price-total {
									color: $gray-dark;
									font-weight: normal;

									@include respond(small) {
										float: left;
										font-size: 12px;
										padding-top: 0;
										width: auto;
									}
								}

								.price-unadjusted {
									display: none;
								}

								.price-adjusted-total {
									margin-top: 0;

									span {
										font-weight: normal;
										color: $gray-dark;
									}
								}
							}
						}

						.low-inventory-msg {
							margin-top: .3rem;
				   			color: $red;
				   			@include respond(small) {
				   				clear: both;
				   				float: left;
				   			}
				   		}
					}
					.cart-promo{
						background: none;
						font-style: normal;
						td{
							padding: 0;
						}
						@include respond(small) {
							.bonus-item-promo{
								border: 0;
							}
						}
					}

					tfoot{
						tr{
							td.cart-promo{
								padding: 20px 5px;
							}
						}
					}
				}
				.cart-footer{
					float: left;
					width: 100%;
					display: block;
					clear:none;
					margin-top: 7px;

					.cart-coupon-code{
						float: left;
						width: 100%;
						padding-bottom: 11px;
						border-bottom: 2px solid $gray-light;
						@include respond(small) {
							padding-bottom: 13px;
						}
						input{
							float: left;
							width: rem(310px);
							height: rem(34px);
							@include line-height(30px);

							@include respond(small) {
								width: 62%;
							}
						}
						.error{
							width: 75%;
							padding: rem(4px) 0;
							clear: both;
						}
						& > button{
							height: 34px;
							line-height: 30px;
							padding: 0 30px;
						}
						.coupon-header {
							margin: 0;
							button {
								display: block;
								font-family: $sans-serif;
								height: auto;
								width: 100%;
								text-align: left;
								background: none;
								border: 0;
								color: $gray-dark;
								padding: 0;
								margin: 0;
								line-height: rem(35px);
								font-weight: normal;
								transition: all 400ms ease-in-out;
								&::after {
									display: inline-block;
									content: "";
									background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg class='bi bi-chevron-expand' viewBox='2 0 12 10' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M 3.646 6.854 C 3.841 7.05 4.159 7.05 4.354 6.854 L 8 3.207 L 11.646 6.854 C 11.919 7.127 12.384 7.002 12.484 6.63 C 12.53 6.457 12.48 6.272 12.354 6.146 L 8.354 2.146 C 8.159 1.95 7.841 1.95 7.646 2.146 L 3.646 6.146 C 3.45 6.341 3.45 6.659 3.646 6.854 L 3.646 6.854 Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
									background-repeat: no-repeat;
									background-size: 100% 100%;
									width: 1rem;
									height: rem(22px);
									margin: -2px 0 0 6px;
									transform: rotate(180deg);
									transition: all 400ms ease-in-out;
									vertical-align: middle;
									position: relative;
									border: none;
									left: auto;
									z-index: auto;
								}
								&[aria-expanded="true"]::after {
									transform: rotate(0);
								}
								&[aria-expanded="false"] {
									margin-bottom: rem(16px);
								}
							}
						}
						.coupons-form {
							display: none;
							float: left;
							width: 70%;
							@include respond(small) {
								width: 100%;
							}
							&.opened {
								display: block;
							}
						}
						.coupons-wrap{
							float: right;
							width: 25%;
							@include respond(small) {
								width: 100%;
							}
							.promotion-title{
								color: $gray-dark;
								text-transform: uppercase;
								width: 100%;
								display: block;
								font-weight: bold;
								margin-bottom: 0;
								margin-top: 2px
							}
							.name{
								float: left;
								color: $gray-warm;
								width: 70%;
							}
							button{
								@extend .button-text;
								float: left;
								height: 1em;
								line-height: 1em;
								@include font-regular(12px);
								text-decoration: underline;

								/*
								padding: 0;
								margin: 0;
								border-radius: 0;
								border: 0;
								font-size: 12px;
								text-transform: none;
								color: $gray-dark;
								text-decoration: underline;
								font-weight: normal;
								line-height: 120%;
								cursor: pointer;
								margin-top: 2px;
								height: auto;
								&:hover{
									color: $gray-dark;
									text-decoration: none;
									border: 0;
									background: $white;
								}
								*/
							}
						}
						#update-cart{
							display: none;
						}
						#add-coupon{
							float: left;
							margin: 0.5em 0 0.5em rem(20px);

							@include respond(small) {
								float: none;
								line-height: 2.5em;
								padding: 0 18px;
							}
						}
					}
					.action-carousel{
						float: left;
						width: 100%;
						padding-bottom: 15px;
						border-bottom: 2px solid $gray-light;

						.cart-order-totals {
							float: right;
							width: rem(300px);
							margin: rem(20px) 0 0 rem(40px);

							@include respond(small) {
								width: 100%;
								margin: rem(11px) 0 0;
							}

							.or-label {
								margin-left: 47%;
							    margin-top: 10px;
							    display: block;
							    margin-bottom: 10px;
							}

							.cart-action-paypal-incontext{
							    margin-left: 20%;
							}
						}
						.cart-actions{
							float: left;
							width: 100%;
							margin-top: 20px;
							@include respond(small) {
								margin-top: 16px;
							}
							@include respond(largeUp) {
								text-align: right;
							}
							.cart-promo.cart-promo-approaching,
							p.promotion{
								font-weight: bold;
								font-size: 16px;
								color: $red;
								line-height: 20px;
								margin: 0;
								display: block;
								width: 100%;
								margin-bottom: 10px;
								float: left;
								background: none;
								padding: 0;
								font-style: normal;
							}
							button.button {
								@include redButton;
								width: 100%;
							}
							button.button-text{
								float: right;
								margin: rem(20px) 0 0;
								@include font-size(12px);
								line-height: 1em;
								height: rem(28px);
								text-decoration: underline;
								font-weight: normal;
							}
							button#go-checkout {
								@include respond(largeUp) {
									min-width: 230px;
									width: auto;
								}
								@include respond(small) {
									width: 100%;
								}
							}
							button.dw-apple-pay-button {
								width: 230px;
								float: right;
								@include respond(small) {
									width: 100%;
								}
							}
							@include respond(small) {
								fieldset {
									float: right;
									width: 100%;
								}
							}
						}
						.order-totals-table{
							tr{
								td:first-child{
									font-weight: bold;
									text-align: left;
								}
								&.order-shipping{
									span{
										height: 16px;
										line-height: 16px;
										margin-right: 5px;
										display: inline;
										float: none;
									}
									a.shipping-icon{
										background: url('../images/cart-shipping-icon.gif') no-repeat left top;
										width: 16px;
										height: 16px;
										display: inline-block;
										margin-bottom: -3px;
									}
								}
								&.order-shipping-discount,
								&.order-discount{
									td{
										color: $red;
									}
								}
								&.order-sales-tax{
									td{
										padding-bottom: 15px;
									}
								}
								&.order-total{
									td{
										@include font-size(16px);
										border-top: 1px solid $gray-light;
										padding: 13px 0 0;
										white-space: nowrap;
									}
								}
							}
						}
						.cart-footer-slot {
							display: block;
							float: none;
							width: auto;
							margin-top: rem(30px);
							overflow: hidden;

							@include respond(small) {
								clear: both;
							}
						}
					}
				}
			}
		}
		.cart-action-continue-shopping {
            float: none;
        }
		.footer-bottom{
			float: left;
			width: 100%;
			.cart-banner-bottom{
				display: block;
				width: 100%;
				margin-bottom: 43px;
				@include respond(small) {
					display: none;
				}
				img{
					width: 100%;
				}
			}
			.banner-security{
				float: left;
				margin: 25px 0 57px;
				width: 100%;
				text-align: center;
				@include respond(small) {
					margin-bottom: 33px;
				}
				@include respond(largeUp) {
					float: none;
					display: flex;
					justify-content: space-between;
					max-width: 614px;
					box-sizing: border-box;
					margin-right: auto;
					margin-left: auto;
					padding-right: 2px;
				}
				& > span{
					font-weight: bold;
					text-transform: uppercase;
					color: $gray-dark;
					width: 21%;
					display: inline-block;
					vertical-align: middle;
					padding-left: 30px;
					line-height: 120%;
					text-align:left;
					background: url('../images/security-icons.gif') no-repeat left 2px;
					&:not(.secure):not(.returns):not(.phone) {
						display: none!important;
					}
					@include respond(large) {
						width: 24%
					}
					@include respond(largeUp) {
						width: initial;
					}
					@include respond(small) {
						width: 30%;
						padding:30px 1.5% 0;
						float: left;
						font-size: 11px;
						text-align: center;
					}
					&.secure{
						@include respond(small) {
							background: url('../images/secure-tr-icon.gif') no-repeat center top;
						}
					}
					&.returns{
						background-position: left center;
						line-height: 30px;
						@include respond(small) {
							background: url('../images/return-center-icon.gif') no-repeat center top;
							line-height: 120%;
						}
					}
					&.phone{
						background-position: left -55px;
						span{
							@include font-size(11px);
							color: $text-gray;
							line-height: rem(18px);
						}
						@include respond(small) {
							line-height: 120%;
							background-position: center -57px;
							span{
								padding: 0;
								width: 100%;
								float: none;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
	}
	.category-recommendations {
        @extend %clearfix;
        display: none;
        margin-bottom: 40px;
        &.images-loaded {
            display: block;
        }
		header {
			&.text-center {
				text-align: center;
			}
			h2 {
				text-transform: uppercase;
                padding-bottom: 20px;
                border-bottom: 1px solid $color-border-light;
			}
		}
        .category-listing {
            list-style: none;
            padding: 0;
            margin: 0;
            &.more-than-5 {
                .listing-item {
                    @include respond(largeUp) {
                        width: 25%;
                    }
                    &:nth-child(5n+5) {
                        @include respond(largeUp) {
                            clear: left;
                        }
                    }
                }
            }
            .listing-item {
                @include respond(small) {
                    display: table;
                    width: 100%;
                    padding: 0 16px;
                    box-sizing: border-box;
                    border-bottom: 2px solid $bon-jour_approx;
                }
                @include respond(largeUp) {
                    width: 20%;
                    float: left;
                    text-align: center;
                }
                a {
                    display: block;
                    text-decoration: none;
                    @include respond(small) {
                        display: table-row;
                    }
                    .categoty-image {
                        @include respond(small) {
                            display: table-cell;
                            width: 130px;
                        }
                        @include respond(largeUp) {
                            margin: 0 20%;
                            padding: 0 10px 10px;
                            box-sizing: border-box;
                            border-bottom: 5px solid $bon-jour_approx;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    h3 {
                        font-size: rem(14px);
                        @include respond(small) {
                            display: table-cell;
                            vertical-align: middle;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

	}
}
/* Cart only but outside of container */
.premium-membership-required-removal {
	.close-dialog {
		border: 1px #000 solid;
	}
}

#wrapper.pt_cart {
    #main {
        #primary {
            .name-container {
                .cart-name {
                    position: relative;
                    .sp-banner {
                        p {
                            font-size: 12px;
                            line-height: normal;
                            color: $color-secondary;
                        }
                    }
                    .cart-promo.cart-promo-approaching {
                        font-size: 12px;
                    }
                }
            }
            #cart-items-form {
                .cart-footer {
                    .action-carousel {
                        .cart-actions {
                            .cart-promo.cart-promo-approaching {
                                font-size: 12px;
                            }
                        }
                    }
                }

                #cart-table,
                #bonus-table {
                    tr {
                        td.item-quantity {
                            .product-availability-list {
                                @include media-breakpoint-up(md) {
                                    position: absolute;
                                    max-width: 225px;
                                }
                                @include media-breakpoint-up(lg) {
                                    max-width: 295px;
                                    .on-order {
                                        max-width: 191px;
                                        line-height: normal;
                                    }
                                }
                                .is-in-stock,
                                .not-available {
                                    font-weight: bold;
                                    line-height: 21px;
                                }
                            }

                            .item-user-actions {
                                @include media-breakpoint-up(md) {
                                    margin-top: 46px;
                                }
                            }
                        }
                    }
                }
            }
            ul.product-availability-list {
                li.on-order-message {
                    font-size: .9em;
                    line-height: normal;
                    color: $color-secondary;
                    padding-top: 0;
                }
            }
        }
    }
}

#wrapper.pt_cart {
    #main {
        #primary {
			.item-container {
				&.og {
					float: left;
					width: 52%;

					@media screen and (max-width: 799px) {
						float: none;
						width: 100%;
					}

					.product-options {
						.option-dropdown {
							display: none;
						}
					}
				}

				.product-options {
					margin-left: 0;

					ul {
						margin: 0;
						padding: 0;
					}
				}
			}

			.og-cart-container {
				float: right;
				width: 48%;
				padding-right: 40px;

				@media screen and (max-width: 799px) {
					float: none;
					margin: 15px 0 0;
					padding-right: 0;
					width: 100%;
				}

				og-offer {
					padding-top: 2px;
				}

				og-offer[subscribed] {
					.og-frequency {
						display: block;
					}
				}

				.og-offer-incentive {
					display: none;
				}

				.tooltip-label {
					font-size: 12px;
					color: $color-primary;
					margin-top: -10px;
					padding: 0 0 0 25px;
				}

				.og-frequency {
					display: none;
					padding-left: 25px;
				}

				og-select-frequency {
                    appearance: none;
                    background-position: 80px center;
					background-image: url(../images/arrow-drop-down.svg);
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    border-color: $darkGrey;
                    position: relative;

					&::before {
						background-color: $white;
                        content: " ";
                        height: 10px;
                        position: absolute;
                        top: 15px;
                        right: 1px;
                        width: 15px;
                        z-index: 2;
                    }
                }
			}
		}
	}
}

.cart-action-paypal + .go-checkout {
	margin-top: 10px;
}

.pt_cart {
    .cart-actions {
        #express-container {
            margin-top: 10px;
            width: 230px;
            float: right;
            @include respond(small) {
                width: 100%;
                float: none;
            }
        }
    }
}


.club-section-display {
    padding: 12px 0 7px 0;
    border-top: 1px solid $darkGrey;

    &.first {
        padding: 0;
        border: 0;
        margin-bottom: 7px;
    }

    &.last {
        border-bottom: 1px solid $darkGrey;
        margin-bottom: 20px;
    }

    .value {
        span {
            color: $red;
        }
    }

    .product-option {
        padding-bottom: 5px;
        font-size: 12px;
        line-height: 14px;

        .label,
        .value {
            font-size: 12px;
            color: $color-primary;
        }

        .label {
            font-weight: bold;
        }

        .value {
            font-weight: normal;
        }
    }
}

.club-section-display-global {
    max-height: 0;
    transition: max-height 0.25s ease-out;
    overflow: hidden;

    &.club-section-display-global-expanded {
        max-height: 1000px;
        transition: max-height 0.5s ease-in;
    }
}

.club-section-display-global-actions {
    .view-more,
    .view-less {
        cursor: pointer;
        text-decoration: underline;
        display: block;
        color: $black;
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;

        &:hover {
            text-decoration: none;
        }

        &.hide {
            display: none;
        }

        &.show {
            display: inline-block;
        }

        &::after {
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../images/icons/arrow.svg");
            position: absolute;
            top: 2px;
            right: -17px;
            width: 9px;
            height: 14px;
            transition: transform 300ms ease;
        }
    }

    .view-more {
        position: relative;
        top: -7px;

        &::after {
            transform: rotate(90deg);
        }
    }

    .view-less {
        margin-bottom: 10px;
        position: relative;

        &::after {
            transform: rotate(-90deg);
            top: 0;
        }
    }
}

.item-wishlist {

    .club-section-display {
        &.first {
            padding-top: 3px;
        }

        .label,
        .value {
            font-size: 0.813rem;
            color: $black;
        }
    }

    .product-list-item {
        @include media-breakpoint-up(lg) {
            width: 52%;
        }
    }

    .item-edit-details {
        padding-top: 0;
        margin-top: -3px;
        a {
            font-size: 0.813rem;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .view-more,
    .view-less {
        font-size: 0.813rem;
    }
}

.mini-bag-product {

    .mini-bag-attributes {
        .view-more,
        .view-less {
            display: none !important;
        }

        &.opened {
            .club-section-display-global {
                max-height: 1000px;
                transition: max-height 0.5s ease-in;
            }

            &.collapse {
                max-height: 1000px !important;
            }
        }

        &.collapse {
            max-height: 83px !important;
        }

        .club-section-display {
            .product-option {
                .label,
                .value {
                    color: $black;
                }
            }

            &.last {
                margin-bottom: 0;
            }
        }
    }

    .mini-bag-product-info {
        padding-top: 28px !important;
    }

    .media-wrap {
        margin-top: 28px !important;
        display: flex;
        img {
            object-fit: contain;
        }
    }

    .toggle-mini-line {
        top: 4px !important;
    }
}
