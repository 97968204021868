#pdpMain.pdp-main-configurable .product-core-info {
	.product-image-container .product-primary-image.configurator-main-image {
		@include media-breakpoint-down(md) {
			transition: max-height 0.3s ease-in-out;
			max-height: 600px;
			height: auto;
		}
        &:not(.tag-customizer-main-image) {
            &.sticky-mobile {
                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: center;
                    max-height: 392px;
                    min-height: 392px;
                }

                @include media-breakpoint-down(sm) {
                    max-height: 292px;
                    min-height: 292px;
                }

                .slick-arrow {
                    @include media-breakpoint-down(md) {
                        position: fixed;
                        top: 150px;
                        z-index: 101;
                    }
                    @include media-breakpoint-down(sm) {
                        top: 100px;
                    }
                }
                .slick-list {
                    @include media-breakpoint-down(md) {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background: $white;
                        z-index: 100;
                    }
                }

                .primary-image {
                    @include media-breakpoint-down(md) {
                        max-height: 300px;
                    }

                    @include media-breakpoint-down(sm) {
                        max-height: 200px;
                    }
                }
            }
        }

		.slick-arrow.slick-prev,
		.slick-arrow.slick-next {
			@include media-breakpoint-up(lg) {
				//override slick.js:588
				display: none !important;
			}
		}

		.primary-image {
			@include media-breakpoint-down(md) {
				max-height: 600px;
				height: auto;
				transition: max-height 0.3s ease-in-out;
			}
		}
        &.tag-customizer-main-image {
            .product-image,
            .configurator-product-thumbnails li {
                background-color: var(--scene7-bg);
            }
        }
	}

	.product-main-info {
		margin: 0 20px;
		font-size: 14px;

		@include media-breakpoint-up(lg) {
			max-width: 432px;
			margin: 0 auto 18px;
			font-size: 14px;
		}
        .product-main-info-top {
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            justify-content: space-between;
            .product-badge,
            .badge-wrapper {
                margin-bottom: 0;
            }
        }
        .product-name-link {
            font-size: 14px;
            line-height: 14px;
            color: #000000;
            display: inline-block;
            margin-top: 1px;
            font-weight: 400;
            transition: color 0.35s;
            .font-medium {
                font-weight: 600;
            }
            .product-name-link-icon {
                margin-right: 3px;
                position: relative;
                left: 0;
                transition: left 0.25s;
            }
            svg {
                height: 10px;
                transition: fill 0.35s;
            }
            &:hover {
                color: #E41837;
                .product-name-link-icon {
                    left: -2px;
                }
            }
        }
	}

	.product-detail .pdpForm {
		float: none;

		.product-add-to-cart #add-to-cart {
			max-width: none;
		}
	}

	.product-add-to-cart {
		padding: 0;
		margin-top: 44px;
		margin-left: 0;
		margin-right: 0;
	}

	.product-col-1 {
		float: none;
	}

	.product-badge {
		margin-bottom: 10px;
	}
}

#pdpMain.pdp-main-configurable.pdp-tag-customizer {
    .product-image-container {
        @include media-breakpoint-down(md) {
            position: relative;
            top: 0! important;
        }
        .product-primary-image {
            &.configurator-main-image {
                @include media-breakpoint-down(md) {
                    max-height: initial;
                }
            }
            .product-image {
                .zoomImg {
                    background-color: var(--scene7-bg);
                }
            }
        }
    }
}

#pdpMain .product-core-info {
	.product-image-container {
		&.sticky {
			@include media-breakpoint-up(lg) {
				position: fixed;
				width: calc(100% - 475px);
				top: 0;
			}
			@include custom-media-breakpoint-up(xl) {
				width: calc(100% - 614px);
			}
		}
	}
}

#product-content {
	&.loading {
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: $white;
			opacity: 0.75;
		}
	}
}

.pt_configurator {
	#main {
		position: relative;
	}

	.innerWidth {
		padding-left: rem(10px);
		padding-right: rem(10px);

		@include respond(standard) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.modal-barrier {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		background: url("../images/infinite-loading-indicator.gif") center no-repeat rgba(0,0,0,0.15);
		z-index: 1;

		&.done-loading {
			background: rgba(0,0,0,0.1);
		}
	}

	.pdp-main {
		position: relative;
		padding-bottom: 3em;
	}
}

.configurator-productname {
	padding-top: 2em;

	.ball-logo {
		height: rem(14px);
	}

	.product-price {
		float: right;
		margin: 0;
		@include respond(small) {
		    margin-top: -.2em;
		}
	}

	@include respond(largeUp) {
		padding-top: rem(55px);

		.ball-logo {
			height: rem(25px);
		}

		.product-price {
			float: none;
			margin: rem(10px) 0 rem(17px);
		}
	}
}

.mobile-sticky {
	@include respond(small) {
		&.portrait {
			transition: margin-top .5s;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			padding: 0 16px;
			background: $white;
			z-index: 98;
			margin-top: 107px;
			box-sizing: border-box;
			.configurator-images {
				position: relative;
			}
		}
	}

	.promotion {
		&.desktop {
			display: block;
			@include respond(small) {
				display: none;
			}
		}
		&.mobile {
			display: none;
			@include respond(small) {
				display: block;
			}
		}
	}

	.promotion-callout {
		color: $crimson;
		margin: 8px 0 16px;
	}
}

.configurator-images {
	width: 100%;
	max-width: 100%;
	float: none;
	margin: auto;

	&.sticky {
		position: relative;
	}

	@include respond(largeUp) {
		width: 47.5%;
		max-width: 475px;
		float: left;
		padding: rem(60px) 0 1em;
		margin-left: rem(10px); //2%;
		transition: margin-left .5s;

		&.sticky {
			position: fixed;
		}
	}

	@include respond(standard) {
		margin-left: 0;
	}
}

.configurator-main-image {
	visibility: hidden;

	.pdp-main &.product-primary-image {
		display: block; //not sure why it's hidden in mobile by default
		max-width: 100%;
		float: none;
	}

	.product-image {
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: center;
			align-items: center;
			height: calc(100vh - 170px);
		}

		img {
			display: block;
			width: auto;
			max-width: 100%;
			margin: 0 auto;

			@include media-breakpoint-up(lg) {
				height: auto;
			}
		}
	}

	.product-image + .product-image {
		display: none;
	}

	.configurator-product-thumbnails {
		li {
			display: inline-block;
			padding: 0 3px;
			border-bottom: 4px solid $trans;
			background: $img-gray;
			cursor: pointer;
			margin: rem(7px) rem(3px);

			&.slick-active {
				border-color: $red;
			}
		}

		.productthumbnail {
			height: 66px;
			width: auto;

			@include media-breakpoint-up(lg) {
				height: 115px;
			}
		}
	}

	&.slick-slider {
		&.slick-initialized {
			visibility: visible;
		}

		.slick-prev, .slick-next {
			background-image: url("../images/caro-arrow.svg");
			background-repeat: no-repeat;
			background-position: 100% 50%;
		}

		.slick-prev {
			transform: rotate(180deg);
		}

		.slick-dots {
			bottom: -35px;

			li {
				button {
					border: none;
					background-color: $suva-gray;
					opacity: 0.63;
					width: 9px;
					height: 9px;
					border-radius: 50%;
				}

				&.slick-active button {
					background-color: $crimson;
					opacity: 1;
				}
			}
		}

		.product-image + .product-image {
			display: block;

			@include media-breakpoint-up(lg) {
				display: flex;
				justify-content: space-around;
			}
		}

		&.loading-indicator:before {
			content: '';
			display: block;
			position: absolute;
			background: url("../images/infinite-loading-indicator.gif") center no-repeat;
			width: 220px;
			height: 34px;
			top: calc(50% - 17px);
			left: calc(50% - 110px);
			z-index: 1;
		}
	}
}

.configurator-controls {
	width: 100%;
    @include respond(largeUp) {
        padding-bottom: 10px;
    }

	.configurator {
		display: block;
		border-bottom: none;
		&.headwear-customizer {
			.config-tab {
				width: 50%;
			}
		}
	}

	.product-base-info {
		display: none;

		.ball-logo {
			height: 1.5625rem;
		}
	}
}

.config-tab {
	display: block;
	box-sizing: border-box;
	text-align: center;
	transition: background-color $fastSpeed;

	&.selected {
		color: $white;
	}

	&.disabled {
		//opacity: 0.5;
	}

	&#review-tab {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.tab-label {
		font-size: 15px;
		font-weight: 700;
		letter-spacing: -0.07px;
		text-transform: capitalize;
		padding: 12px 0;
		color: $black;
	}
}

.config-tab-content {
	display: none;

	&.open {
		display: block;

		&.review-content {
			display: none;

			@include media-breakpoint-up(lg) {
				display: block
			}
		}
	}
}

.tabs-wrapper {
	border-top: 1px solid $gray-light;
	border-bottom: 1px solid $gray-light;
	margin-bottom: 20px;

	.config-master-tabs {
		display: flex;
		justify-content: space-between;
		margin: 0 20px;

		@include media-breakpoint-up(lg) {
			max-width: 432px;
			margin: 0 auto;
		}

		> .config-tab {
			position: relative;
			text-align: center;
			border-bottom: 4px solid $trans;
			cursor: pointer;
			flex: auto;

			&.selected {
				background-color: $color-accent;
				border-bottom: 4px solid $color-secondary;
				color: $color-secondary;

				.tab-label {
					color: $color-secondary;
				}
			}

			&:hover {
				background-color: $color-accent;

				.tab-label {
					color: $color-secondary;
				}
			}
		}
	}
}

.configurator-content,
.configurator-addtocart {
	margin: 0 20px;

	@include media-breakpoint-up(lg) {
		max-width: 432px;
		margin: 0 auto;
	}
}

.configurator.tag-customizer {
    .config-tab {
        .tab-label {
            text-transform: none;
        }
    }
    #messages-content {
        .configurator-form {
            .configurator-text-inputs {
                .form-field {
                    margin-bottom: 0;
                    input[type="text"] {
                        margin: 6px 0;
                        &.error {
                            border-color: $error-red;
                        }
                    }
                }
            }
        }
    }
    .configurator-form {
        &.configurator-color {
            gap: 4px;
            .configurator-option {
                img {
                    background-color: #ececec;
                    width: 69px;
                }
            }
        }
    }
}

.config-master-tab-contents {
	> .config-tab-content {
		display: block; //implement a fade effect instead...
		position: absolute;
		opacity: 0;
		transition: opacity $fastSpeed ease;
		z-index: -1;
		min-height: 10em;
		padding: 0;

		&.open {
			position: relative;
			opacity: 1;
			z-index: 0;
			top: 0px;
		}

        .tag-customizer-personalize-disclaimer {
            margin-top: -3px;
            & ~ .configurator-form {
                .configurator-text-font-select {
                    height: 42px;
                }
            }
        }
		.error-msg {
			color: $error-red;
			padding: 0;
			margin: 5px 0;

			p {
				font-size: 12px;
				margin: 5px 0;
				a {
					font-size: 12px;
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.config-tab-title {
			padding: 0;
			margin: 10px 0;
			font-size: 14px;
			color: $black;
			line-height: rem(26px);

			//tooltip base styles now live in _base_elements.scss
			.tooltip-icon {
				margin-left: 10px;
				text-decoration: none;

				&.upload-logo-tooltip {
					display: inline-block;
				}
			}

			.selected-color {
				float: right;
				font-size: 12px;
				font-weight: 500;
			}
            &.font-style-option {
                display: flex;
                justify-content: space-between;
                .text-font-value {
                    font-size: 12px;
                }
            }
		}
	}

	.size-guide {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		.label {
			font-size: 14px;
			font-weight: 700;
		}

		.value {
			width: auto;
		}

		.size-guide-link {
			color: $black;
			font-size: 12px;
		}
	}
}

.tooltip-icon {
	&:before {
		color: $black;
		border-color: $black;
		content: '!';
	}
}

.configurator-option {
	@extend %clearfix;
	padding: rem(18px) rem(20px);
	border-top: 1px solid $gray-light;

	&.disabled-for-product {
		display: none;
	}
	.hide-single-input & {
		input[type="radio"] {
			@include visually-hidden;
		}
		.playnumcaption {
			margin-top: 5px;
		}
	}

	input[type="radio"] {
		width: rem(15px);
		margin: rem(8px) rem(5px) rem(8px) 0;
	}
	@include respond(small) {
		input[type="radio"] { border:solid 1px #4499dc; padding:0px; background:#4499dc; }
	}

	label {
		margin-bottom: 0;
	}

	.config-option-label {
		@include font-bold(12px);
	}

	.configurator-option-select,
	.configurator-option-select + label {
		cursor: pointer;
	}

	&.disabled label,
	&.disabled .configurator-option-select,
	&.disabled .configurator-option-select + label,
	& .configurator-option-select.disabled,
	& .configurator-option-select.disabled + label,
	& input[disabled],
	& input[disabled] + label {
		opacity: 0.65;
		cursor: default;
	}

	&.selected .configurator-option-select {
		font-weight: bold;

	}

	&.selected .configurator-option-config {
		display: block;

		&.playnuminput {
			float: right;
		}
	}
}

.configurator-form {
	margin-bottom: 20px;

	&.configurator-color,
	&.configurator-regular {
		display: flex;
		flex-wrap: wrap;
		column-gap: 6px;

		.configurator-option {
			border: none;
			padding: 0;
			flex: 1;

			.config-option-label {
				margin: 0;
			}

			&:first-of-type {
				&.oos-disabled {
					.config-option-label {
						.tooltip-info {
							left: 0;
							transform: none;

							&::before {
								left: 31px;
							}
						}
					}
				}
			}

			&.oos-disabled {
				.config-option-label {
					position: relative;

					img {
						opacity: 0.3;
					}

					.tooltip-info {
						position: absolute;
						z-index: 1;
						top: -34px;
						left: 50%;
						transform: translateX(-50%);
						padding: 5px 10px;
						border-radius: 10px;
						background: $text-gray;
						color: $white;
						font-size: 10px;
						letter-spacing: 0.35px;
						white-space: nowrap;
						display: none;
						text-transform: none;

						&::before {
							border: solid;
							border-color: $text-gray $trans;
							border-width: 4px 4px 0 4px;
							bottom: -4px;
							content: "";
							display: block;
							left: calc(50% - 4px);
							position: absolute;
							z-index: 1;
						  }
					}

					&:hover {
						.tooltip-info {
							display: block;
						}
					}
				}

				&.selected {
					pointer-events: none;
				}
			}
		}

		input.configurator-option-select {
			margin: 0;
			padding: 0;
			width: 1px;
			height: 1px;
			visibility: hidden;
			position: absolute;
		}
	}

	&.configurator-color {
		.configurator-option {
			flex: none;
			img {
				border: 1px solid transparent;
				border-radius: 2px;
				width: 67px;
				border-radius: 0;
				height: auto;
				vertical-align: middle;
				padding: 2px;
			}

			&.selected {
				img {
					border: 1px solid $black;
				}
			}

			&:hover {
				border-color: $black;
			}
		}
	}

	&.configurator-regular {
		.configurator-option {
			.config-option-label {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 2px;
				min-height: 42px;
				width: 100%;
				font-size: 14px;
				font-weight: bold;
				line-height: 12px;
				text-align: center;
				background: $white;
				color: $black;
				border: 1px solid #e2dede;
				border-radius: 2px;
				text-transform: capitalize;

				&:hover {
					border-color: $black;
				}
				span {
					padding-left: 5px;
				}
				.configurator-option-price {
					white-space: nowrap;
				}
			}

			input:checked + .config-option-label {
				background: $black;
				color: $white;
				border-color: $black;
			}

			input:disabled + .config-option-label {
				background: $gray-cirlce;
				color: $text-gray;
				border-color: $text-gray;
				opacity: 1;

				&:hover {
					border-color: $text-gray;
				}
			}
		}

		.configurator-number-input {
			display: none;

			&.selected {
				display: block;
				flex: 0 0 100%;
				width: 100%;
				padding: 0 5px;

				.configurator-option-config {
					display: block;
					padding: 0;
				}

				.config-option-info {
					margin: 10px 0 0;
				}

				.playnumber-text {
					box-shadow: none;
					height: 42px;
					line-height: 42px;
				}

				.configurator-text-inputs {
					margin-top: 15px;
				}

				.error {
					margin-top: 10px;
				}
			}
		}
	}

	&.configurator-single-option {
		display: block;
		margin-left: 0;
		margin-right: 0;

		.configurator-option {
			margin-left: 0;
			margin-right: 0;
		}

		.configurator-option-config {
			padding-top: 0;
			border-top: none;
		}
	}

	&.configurator-message,
	&.configurator-logo,
    &.configurator-mark {
		.configurator-option-config {
			margin-top: 20px;
		}
	}

	.none-message {
		padding: 30px 40px;
		font-size: 14px;
		font-weight: bold;
		color: $black;
		text-align: center;
		letter-spacing: -0.07px;
	}
}

.configurator-option-config {
	padding-top: 1em;
	padding-top: 20px;
	border-top: 1px solid $bon-jour;
	width: 100%;

	&.playnuminput,
	&.playnumcaption {
		border-top: none;
	}

	&.playnumcaption {
		margin: 0;
	}

	p {
		margin: 0;
	}
}

p.config-option-info {
	margin: rem(-4px) 0 rem(8px) rem(20px);
	@include font-size(11px);

	@include respond(largeUp) {
		@include font-size(12px);
	}
}
p.config-option-note {
	margin: 0 0 0 rem(20px);
	padding: 0 rem(4px);
	@include font-size(9px);
	line-height: 1.3em;
}

// Ball Model tab specific styles

#ballModel-content {
	.ball-logo {
		height: rem(14px);
	}
}

// Play Number tab specific styles

#playNumber-content {
	.config-option-info {
		@include line-height(14px);
	}

	.playnuminput {
		float: right;
		width: rem(64px);

		input.playnumber-text {
			text-align: center;
		}
	}
}

.configurator-text-inputs input[type="text"] {
	height: rem(42px);
	@include line-height(42px);
}

.playnumlowhigh-options {
	margin-left: rem(20px);

	.configurator-option-select {
		display: inline-block;
		border-radius: 3px;
		padding: 0 rem(21px);
		margin: rem(18px) rem(9px) rem(18px) 0;
		@include line-height(28px);
		color: $black;
		background: $white;
		@include font-bold(12px);
		text-decoration: none;

		&.selected {
			color: $white;
			background: $black;
		}
	}
}

#messages-content {
	.config-message-label {
		clear: both;
		@include font-bold(14px);
		color: $black;
	}

	.config-message-description {
		font-size: 0.75rem;
		line-height: 1rem;
		margin-bottom: 10px;
	}

	.dividerline {
		width: 100%;
		height: 1px;
		margin: 1em 0;
		background-color: $gray-light;
	}

	.configurator-text-inputs label {
		clear: both;
		display: block;
		@include font-regular(12px);
		@include line-height(26px);

		@include respond(largeUp) {
			display: inline-block;
			clear: none;
			width: 12%;
			margin-right: 2%;
		}
	}

	.configurator-text-inputs {
		.form-field {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 12px;
		}

		input[type="text"] {
			width: 100%;
			text-transform: uppercase;
			box-shadow: none;

			&::placeholder {
				text-transform: none;
			}
		}

		.char-count {
			margin-left: 10px;
			white-space: nowrap;
		}
	}
}

.msg-color-group,
.msg-font-group {
	clear: both;
	padding: 10px 0;

	.items {
		display: flex;
		flex-wrap: wrap;

		.configurator-text-color {
			margin: 5px;
			width: 34px;
			height: 34px;
			border-radius: 50%;
			cursor: pointer;

			&.selected,
			&:hover {
				position: relative;
				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 10px;
					height: 10px;
					left: 50%;
					top: 50%;
					background: url(../images/icons/check.svg) no-repeat center;
					transform: translateX(-50%) translateY(-50%);
				}
			}
			&.color-white {
				border: 1px solid $gray-dark;
				&.selected,
				&:hover {
					&:after {
						background: url(../images/icons/check-blk.svg) no-repeat center;
					}
				}
			}
		}
	}

	.text-color-label,
	.text-font-label {
		@include font-bold(14px);
		color: $black;
		display: flex;
		justify-content: space-between;

		.text-color-value,
		.text-font-value {
			font-size: 12px;
		}
	}
}

.logo-options {
	clear: both;
	display: block;

	&:nth-child(2) {
		border-top: solid 1px $gray-light;
		padding-top: 2em;
	}

	.logo-section-label {
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: -0.07px;
	}

	.logo-top-folder-tabs {
		display: flex;
		justify-content: space-between;
	}

	.logo-top-folder {
		padding: rem(8px) rem(11px);
		color: $black;
		background-color: $white;
		border: 1px solid $gray-light;
		border-radius: 2px;
		width: calc(50% - 5px);
		cursor: pointer;

		&.selected {
			background-color: $black;

			.tab-label {
				color: $white;
			}
		}

		.tab-label {
			@include font-bold(14px);
		}
	}

	.logo-tab-options {
		position: relative;
	}

	.logo-categories {
		margin: 0.5em 0;
	}

	.logo-grid-wrapper {
		padding: 0;
		margin-bottom: 10px;
		max-width: 100%;
		height: auto;
		width: auto;
	}

	.logo-grid {
		display: block;
		opacity: 1;
		transition: opacity $fastSpeed;

		&.loading {
			opacity: 0;
		}

		.item-section {
			float: left;

			.logo-thumbnail {
				float: none;
			}
		}

		.logo-thumbnail {
			position: relative;
			display: block;
			float: left;
			box-sizing: border-box;
			padding: rem(3px);
			cursor: pointer;
			border: 1px solid transparent;

			&.selected, a.selected {
				border-color: #333;
			}

			&.premium:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				height: 0;
				width: 0;
				border-top: 10px black solid;
				border-right: 10px black solid;
				border-bottom: 10px transparent solid;
				border-left: 10px transparent solid;
			}
			&.premium:after {
				content: '\2605';
				display: block;
				color: $white;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 0.9em;
				line-height: 1em;
			}
		}
	}

	.logo-image {
		display: inline-block;
		vertical-align: middle;
		height: rem(100px);
		min-width: rem(100px);
		max-width: rem(100px);
		padding: rem(3px);
		@-moz-document url-prefix() {
			box-sizing: border-box;
			padding: rem(1px);
		}
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			width: 100%;
		}
		@include respond(extra-small) {
			max-width: calc(25vw - 18px);
			height: calc(25vw - 18px);
			min-width: calc(25vw - 18px);
		}
	}

	.premium-logo-option {
		position: relative;
		top: rem(1px);
		display: inline-block;
		background-color: $gray-light;
		padding: rem(4px) rem(6px) rem(6px);
		border-radius: rem(3px);

		.tooltip {
			margin: rem(4px) 0 0 rem(4px);
		}

		&:before {
			content: '\2605';
			display: inline;
			color: $black_approx;
			@include font-size(18px);
			margin-right: 0.25em;
		}
	}

	.logo-selected,
    .mark-selected {
		display: none;
	}
}

.selected-logos-section,
.selected-marks-section {
	display: flex;
	flex-wrap: wrap;

	.logo-selected,
    .mark-selected {
		padding: 0.5em 0;
		margin-right: 3px;

		.logo-image,
        .mark-image {
			height: rem(38px);
			min-width: rem(38px);
			max-width: rem(50px);
			vertical-align: middle;
			padding: 0;
		}
	}

	.logo-selected-label,
	.mark-selected-label {
		vertical-align: middle;
		display: inline-block;
		font-size: 11px;
		font-weight: 500;
		margin: 0 0.35em 0 10px;
	}

	.logo-unselect-clone,
	.mark-unselect-clone {
		display: inline-block;
		height: 7px;
		width: 7px;
		margin-right: 1.25em;
		text-align: center;
		border: 1px solid #e3dede;
		background: $trans url('../images/icons/icon-close-black.svg') no-repeat center;

		&:hover {
			cursor: pointer;
		}
	}
}

.configurator-summary {
	background-color: $gray-white;
	padding: 15px;

	&.mobile {
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	&.desktop {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	.summary-title {
		margin: 0 0 rem(12px);
		font-size: 14px;
	}

	.summary-section-title {
		font-size: 14px;
		font-weight: 700;
		margin: 0;
	}

	.summary-option-price {
		@include font-regular(14px);

		&.upcharge {
			margin: 0;
		}
	}

	.removePremium {
		display: inline-block;
		margin: 0 0 0 0.5em;
	}
}

.configurator-summary-line {
	@extend %clearfix;
	padding: rem(3px) 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&.line {
		padding: rem(10px) 0;
		border-top: 1px solid $gray-light;

		& + .configurator-summary-line:not(.line) {
			padding-bottom: 10px;
		}
	}

	&.total-line {
		padding-bottom: 0;
	}

	.summary-section-title,
	.summary-section-value {
		flex: 1;
		font-size: 14px;
		line-height: 18px;
	}

	.summary-total-price-label,
	.summary-total-price,
	.summary-option-price {
		flex: auto;
		line-height: 18px;
	}

	.summary-total-price,
	.summary-option-price {
		text-align: right;
	}

	.summary-sublabel,
	.summary-lineitem {
		@include font-regular(14px);
		@include line-height(18px);
		margin: 0;

		&.message-text-color,
		&.message-text-font {
			margin-top: 7px;
		}
	}

	.summary-sublabel {
		margin-top: 7px;

		&:first-child {
			margin-top: 0;
		}
	}

	.summary-total-price-label,
	.summary-total-price {
		@include font-bold(14px);
		margin: 0;
		font-weight: bold;
	}
}

.configurator-addtocart {
	margin-bottom: 20px;

	.product-add-to-cart {
		.inventory-container {
			width: rem(128px);
			width: auto;
			margin: 0 rem(10px) 0 0;

			.inventory label {
				display: none;
				@include font-bold(16px);

				@include respond(small) {
					display: none;
				}
			}
		}

		.add-to-cart-wrapper {
			//this should take the remaining space to the right of the inventory-container div
			width: auto;
			display: block;
			float: none;
			overflow: hidden;
		}

		button.add-to-cart {
			float: right;
			//width: calc(100% - #{rem(128px)});
			width: 100%;
			max-width: rem(320px);
			margin: 0;
		}

		.preorder-message {
			float: right;
			width: 100%;
			max-width: rem(320px);
			margin-top: rem(12px);
		}
	}
}

.product-add-to-cart-messages {
	margin-top: 16px;
	padding: 0 10px 0 0;

	.next-button-message {
		margin-right: 10px;
	}

	.next-button-message-inner {
		display: flex;

		.tooltip-icon {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	.clear-wrapper {
		text-align: right;
		float: right;

		.secondary {
			color: $black;
			font-weight: 700;
			white-space: nowrap;
		}
	}
}

.configurator-controls {
	@include respond(small) {
		margin-top: 20px;
	}
}

.hide {
	display: none;
}

.configuration-alert-dialog {
	.ui-dialog-titlebar {
		padding: 1.25rem !important;
		text-transform: none;

	}
	.ui-dialog-title {
		@include font-bold();
		margin-top: 0 !important;
		margin-left: 0 !important;
	}
}

.config-master-tab-contents {
	> .config-tab-content {
		&#logos-content {
			.upload-logo-title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.logo-description-link {
					font-size: 12px;
					font-weight: 700;
					color: $black;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.config-tab-title {
				&.logo-text {
					display: flex;
					align-items: center;

					.tooltip-icon {
						margin-left: 8px;
					}
				}
			}
		}
	}
}

#uploader-file-types {
    position: absolute;
    top: 5px;
    right: 7px;
    width: 42%;
	text-align: left;
    p {
        @include font-size(10px);
		line-height: 1.3;
		margin: 8px 0;
    }
}

#uploader {
    width: 100%;
	max-height: 300px;
	overflow: hidden;
	border-top: 1px solid #e8e8e8;
}

#uploaded {
	border-top: 1px solid #e8e8e8;
	img {
		display: block;
		width: 90%;
		margin: 16px auto;
	}
	div {
		margin: 25px;
		margin-left: 37%;
	}
}

#uploader-wrapper {
	position: relative;

	.browse-box .single-text {
		display: block;
	}

	.upload-popup .panel .foot {
		display: none;
	}
}

#uploader-confirm {
	width: calc(100% - 28px);
	padding: 75px 0 0 35px;
	position: absolute;
	top: 62px;
	left: 10px;
	background-color: #dadadd;
	text-align: center;
	box-sizing: border-box;
    min-height: 73%;

	@include respond(largeUp) {
		height: 287px;
	}
	p {
		font: 14px/1.4 Fira Sans,Arial,Helvetica Neue,Helvetica,sans-serif;
		font-weight: bold;
		margin-top: -20px;
	}
}

ul.uploadlogo {
	width: 100%;
	display: inline-block;
}

.restart {
	margin: 12px auto 20px;
	margin-left: 42%;
}

#selectFile, #selectFile:hover, .restart-btn, .restart-btn:hover {
	margin: 60px auto;
    font-weight: 600;
	font-size: 14px;
	text-overflow: ellipsis;
	background-origin: border-box;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 16px;
	padding-right: 16px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	border-radius: 4px;
	color: #fcfffd;
	background-color: #000000;
	text-decoration: none;
	cursor: pointer;
}

.customlogo-faq {
	float:right;
	text-align: right;
	vertical-align: bottom;
	color: #e31837;
	font-size: 11px;
    font-weight: 300;
}

.config-tab-with-margin-right{
	padding: 20px;
	padding-top: 0;
	border-bottom: none;
}

#pdpMain {
    padding-top: 1px;

	&.logo-upload-allowed,
	&.configurator-ball {
		.product-primary-image {
			.product-image {
				background: $img-gray;
			}
		}
	}
}

.pt_configurator {
    .top-border-pdp {
        @include respond(largeUp) {
            display: none;
        }
    }
    .configurator-images {
        padding-top: 0;
        @include respond(largeUp) {
            width: 50%;
            max-width: 500px;
        }
        &.sticky {
            @include respond(largeUp) {
                width: calc((100% - 1.25rem) / 2);
            }
        }
        .configurator-main-image {
            .product-image img {
                @include respond(small) {
                    width: 50%;
                }
            }
            &.slick-slider {
                .slick-prev, .slick-next {
                    @include respond(small) {
                        top: calc(10vw);
                    }
                }
            }
        }
        .configurator-product-thumbnails {
            margin: 0 auto;
            > li {
                display: inline-block;
                padding: 0;
                margin: 0 4px;
                &.slick-active {
                    border-bottom: 2px solid $crimson;
                }
                .thumbnail-link {
                    .productthumbnail {
                        width: 64px;
                        padding: 0 5px 4px;
                    }
                }
            }
        }
    }
    .configurator-controls {
        .configurator-addtocart {
            .product-add-to-cart {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .inventory-container {
                    flex: 0 0 90px;

                    .quantity {
                        label {
							font-size: 14px;
                            display: block;
                            text-align: left;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                @include respond(small) {
                    .secondary {
                        display: initial;
                    }
                }

                .add-to-cart-wrapper {
                    flex: auto;
                }
            }
        }
    }
    .config-master-tabs {
		.config-tab {
			padding: 9.5px 0;
		}
        &.config-3-tabs {
            .config-tab {
                width: 33.33333333333%;
            }
        }
        &.config-2-tabs {
            .config-tab {
                width: 50%;
            }
        }
		&.config-4-tabs {
			.config-tab {
				width: 25%;
			}
		}
    }
    .configurator-productname {
        padding-top: 0;
        @include respond(small) {
            padding-top: 2em;
            z-index: 1;
        }
        .product-price {
            @include respond(small) {
                position: static;
            }
        }
    }
}

.configurator-product-thumbnails {
    margin: 0 auto;
    float: none;
    .thumb.slick-slide {
        border: none;
        text-align: center;
        &.selected {
            border: none;
        }
        .thumbnail-link {
            float: none;
            outline: none;
            img {
                display: inline-block;
                margin: 0 auto;
                padding: 0 5px 4px;
                width: 64px;
                height: auto;
            }
        }
        &.slick-current img {
            border-bottom: 2px solid $crimson;
        }
    }
}

.promo-details-dialog {
    .ui-button {
        display: block;
    }
}

#uploader {
    min-height: 488px !important;
    height: 488px !important;
    > iframe {
        height: 488px !important;
    }
}

.additional-comment {
    padding: 20px 0;

    label {
        margin-bottom: 18px;
        font-size: 14px;
        font-weight: 700;
        text-transform: none;
    }
    .input-textarea {
        width: 100%;
        min-height: 95px;
        padding: 14px;
        resize: vertical;
        font-size: 13px;
        line-height: normal;
        box-shadow: none;
    }
    .char-count {
        margin-top: 13px;
        font-size: 11px;
        color: $black;
    }
}

#selectFile {
    margin-bottom: 60px;

    a {
        font-size: 14px;
        text-overflow: ellipsis;
        background-origin: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        border-radius: 4px;
        color: #fcfffd;
        background-color: #000000;
        margin-left: 40%;
    }
}

#pdpMain .product-detail .pdpForm .product-add-to-cart .inventory-container .inventory {
	select {
		height: 45px;
		line-height: 1.15;
		padding: 0.5em 2.5em 0.5em 1em;
		display: inline-block;
		background: #fff;
		background-position: right 13px center;
		background-size: 24px 24px;
		background-repeat: no-repeat;
		border-radius: 2em;
		background-image: url(../images/arrow-drop-down.svg);
		margin-bottom: 0;
		width: 90px;
		box-shadow: none;
		border: 2px solid #ccc;

		&:focus {
			border-color: #999;
		}
	}
}

.logo-grid-wrapper,
.play-number__scroll {
	.mCSB_horizontal.mCSB_inside > .mCSB_container {
		margin-bottom: 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal {
		height: 40px;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{
		width: 30px;
		height: 40px;
		opacity: 1;
		background: url('../images/icons/arrow-left-black.svg') no-repeat center;
		background-size: 10px 14px;
		z-index: 1;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
		transform: rotate(180deg);
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
		margin: 0 30px;
		height: 40px;
		z-index: 2;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
		height: 16px;
		background-color: #f6f6f6;
		border-radius: 8px;
		margin: 12px 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
		margin: 12px auto;
		height: 16px;
		border-radius: 8px;
		background-color: #d6d6d6;
	}
}

.logo-grid-wrapper {
	&.mCustomScrollbar {
		height: 273px;

		@include respond(extra-small) {
			height: calc(50vw + 40px);
		}
	}
}

.pdp-main .product-detail .play-number {
	max-width: 364px;

	.play-number__toggle {
		display: block;
		position: relative;
		width: 100%;
		height: 32px;
		margin: 30px 0 0;
		padding: 0 14px;
		border-radius: 2em;
		border: 1px solid #dfdfdf;
		font-size: 14px;
		font-weight: normal;
		line-height: 30px;
		text-align: left;

		&::after {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			position: absolute;
			right: 8px;
			top: 3px;
			background-position: center;
			background-size: 24px 24px;
			background-repeat: no-repeat;
			background-image: url('../images/arrow-drop-down.svg');
			transition: transform $fastSpeed;
		}

		&.active {
			&::after {
				transform: rotate(180deg);
			}
		}
	}

	.play-number__scroll-wrapper {
		max-height: 0;
		height: 100%;
		overflow: hidden;
		transition: max-height $fastSpeed;

		&.active {
			max-height: 400px;
		}
	}

	.play-number__scroll {
		&.mCustomScrollbar {
			height: 170px;
		}
	}

	.play-number__pages {
		padding-top: 25px;

		@include respond(small) {
			display: flex;
		}
	}

	.play-number__page {
		float: left;
		display: flex;
		flex: 0 0 100%;
		flex-wrap: wrap;
		gap: 12px;
		max-width: 364px;

		@include respond(0, 376px) {
			gap: 7px;
			max-width: 332px;
		}

		@include respond(377px, 391px) {
			gap: 9px;
			max-width: 346px;
		}
	}

	.play-number__item {
		position: relative;
	}

	.play-number__checkbox {
		position: absolute !important;
		opacity: 0 !important;
		height: 0 !important;
		width: 0 !important;

		&:checked + .play-number__label {
			background-color: $black;
			border-color: $black;
			color: $white;

			&:hover {
				background-color: $black;
				color: $white;
			}
		}
	}

	.play-number__label {
		display: block !important;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #dfdfdf;
		line-height: 38px;
		text-align: center;
		font-weight: normal;
		font-size: 13px;
		cursor: pointer;

		&:hover {
			border-color: $black;
		}

		&.play-number__label--selected {
			background-color: $black;
			color: $white;

			&:hover {
				background-color: $black;
				color: $white;
			}
		}

		&.play-number__label--disabled {
			border-color: #dfdfdf;
			background-color: #F2F2F2;
			color: #868686;
			cursor: default;

			&:hover {
				position: relative;
				border-color: #999;
			}
		}
	}
}

.pdp-main .product-detail .radio-button-accessory .play-number {
	.play-number__page {
		@include respond(small) {
			max-width: 354px;
			gap: 10px;
		}

		@include respond(0, 376px) {
			max-width: 315px;
			gap: 5px;
    		padding: 0 2px;
		}

		@include respond(377px, 391px) {
			max-width: 330px;
			gap: 6px;
		}

		@include respond(391px, 431px) {
			max-width: 364px;
			gap: 12px;
		}
	}
}

.ui-tooltip.tooltip-content--play-number {
	padding: 4px 6px !important;
	background-color: #a3a3a3 !important;
	box-shadow: none !important;
	border-radius: 10px !important;

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		bottom: -3px;
		width: 6px;
		height: 6px;
		background-color: #a3a3a3;
	}

	.ui-tooltip-content {
		padding: 0 !important;
		color: $white;
		font-size: 10px;
		line-height: normal;
		text-transform: none;
		white-space: nowrap;
	}
    &.play-number-offset {
        left: 0 !important;
        &::after {
            left: 45%;
        }
    }
}

.pdp-main {
	.product-detail {
		.play-number {
			.play-number__toggle {
				background-color: transparent;
				color: $black;
			}
		}
	}
}

body {
    .pt_configurator {
        & ~ .ui-tooltip {
            z-index: 41;
        }
    }
}
