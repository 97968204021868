.footer-media .news-signup-form {
    position: relative;
    width: 100%;
}

.footer-media {
    margin: 0 50px 1.5625em;
    padding: 3.125em;
    overflow: hidden;
    background-color: $lightGrey;
}

@media (max-width: 1023px) {
    .footer-media {
        margin: 1.5625em;
        padding: 1.5625em;
    }
}

@media (max-width: 799px) {
    .footer-media {
        margin: 1.5625em 0;
        padding: 1.5625em 20px;
    }
}

.footer-media .back-to-top {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    max-width: 8em;
    margin: 0 auto;
    font-weight: $footerFontWeight;
    margin-bottom: 9px;
}

footer .legal {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
    font-size: .75rem;
}

.footer-media .back-to-top:hover,
.footer-media .back-to-top:focus {
    background: none;
    border: none;
}

.footer-media .back-to-top .icon-v2 {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto;
}

.footer-media .back-to-top .icon-v2:hover {
    background-color: #E31837;
}

.footer-media .back-to-top .btn-text {
    display: block;
    margin: 0;
    font-size: rem(12.8px);
    color: #757575;
}

.footer-media .locale-terms {
    order: 4;
    display: block;
    width: 100%;
    text-align: center;
    padding: .5em;

    @include respond(largeUp) {
        margin-left: 60px;
        margin-right: 60px;
    }

    label {
        display: inline;
        text-align: center;
        text-transform: none;
        color: $greyText;
        font-size: .875rem;
        margin-right: 0;
    }
    input[type=checkbox] {
        position: relative;
        padding: 0;
        margin: 6px 8px 0 0;
    }
}

.footer-media form .icon-v2:hover {
    background-color: #E31837;
}

.footer-media .footer-media-content .news-signup-form .input-wrap .news-signup-error {
    width: 100%;
    flex-basis: 100%;
    order: 4;
    text-align: center;
    color: #e21837;
    margin: 0;
    padding: 0;
    @media (max-width: 799px) {
        order: 3;
    }
}

.footer-media form .news-signup-error {
    position: absolute;
    bottom: -0.8em;
}

@media (max-width: 799px) {
    .footer-media form .news-signup-error {
        bottom: -1.3em;
    }
}

.footer-media form .news-signup-error p {
    margin: 0 auto;
    font-size: 0.9em;
    padding-left: 1em;
}

.footer-media .footer-media-content .news-signup-form .input-wrap .signup-success {
    width: 100%;
    flex-basis: 100%;
    order: 5;
    text-align: center;
}

.input-button-wrap {
	position: relative;
	width: 100%;
}

.input-button-wrap input {
	padding-right: 2.5em;
}

.input-button-wrap button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: .5em;
	cursor: pointer;
}

.footer-media .footer-media-content .news-signup-form .input-wrap {
    position: relative;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
}

@media (min-width:800px) {
	.inline-input label {
        margin-right: 1em;
        width: auto;
	}
    .inline-input {
        display: flex;
        align-items: center;
    }
}

.footer-media .footer-media-content .news-signup-form .input-wrap label {
    flex: 0 0 auto;
    color: #000;
    text-transform: none;

    &[for=footer-email] {
        @include respond(small) {
            text-align: center;
            flex: auto;
        }
    }
}

.footer-media .news-signup-form .inline-input {
	position: relative;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
    flex-flow: row wrap;
}

#footer-input-wrapper {
    flex: 2 2;
}

.footer-media .news-signup-form .inline-input *,
.footer-media .news-signup-form .inline-input :after,
.footer-media .news-signup-form .inline-input :before {
	box-sizing: inherit;
}

.footer-media .footer-media-content {
    display: flex;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.7;
}

.parsley-error {
	border-color: #e21837!important
}

.parsley-errors-list {
	padding: 0;
	list-style: none;
	margin: -1.25em 0 1.25em;
	color: #e21837;
	line-height: 1.3;
	display: none
}

.titleist-footer .parsley-errors-list {
	padding: 0;
	margin: -.5em 0 1em
}

.parsley-errors-list>li {
	padding-top: 3px
}

.titleist-footer .parsley-errors-list>li {
	padding: 0;
	margin: 0
}

.parsley-errors-list.filled {
	display: block
}

label.parsley-error+.parsley-errors-list>li {
	color: #e21837
}

.m-callout-tertiary .parsley-errors-list>li {
	color: #fff
}

.m-callout-tertiary .enhanced-signup-form .parsley-errors-list>li {
	color: #e31837
}

label+.parsley-errors-list {
	margin-top: -.25em
}

@media (max-width: 799px) {
    .footer-media .footer-media-content {
        flex-wrap: wrap;
        max-width: 375px;
    }

    .footer-media .footer-media-content label {
        text-align: center;
    }

    .footer-media .footer-media-content .news-signup-form .input-wrap label,
    .footer-media .footer-media-content .news-signup-form .input-wrap #footer-input-wrapper {
        flex-basis: 100%;
    }
}

.footer-media .footer-media-social-icons {
    flex: 0 0 auto;
    order: 3;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    @media (max-width: 799px) {
        flex-basis: 100%;
        order: 4;
        padding: 0;
    }
    li svg {
        &:hover {
            path{
                fill: #E31837;
            }
        }
    }
}
.footer-media ul.footer-media-social-icons li {
    padding: 0;
    line-height: normal;
}

.footer-media .footer-media-social-icons .icon-v2 {
    width: 2.5em;
    height: 2.5em;
    margin: 0.1em;
}

.footer-media .footer-media-social-icons .icon-v2:hover {
    background-color: #E31837;
}

.footer-media .footer-media-buttons {
    display: flex;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-media .footer-media-buttons .btn-tertiary,
.footer-media .footer-media-buttons .titleist-btn {
    margin-left: 0.5em;
    margin-right: 0.5em;
    height: rem(37px);
}

@media (max-width: 799px) {
    .footer-media .footer-media-buttons {
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
    }
}

footer {
    font-family: $navFontFamily;
    font-weight: $footerFontWeight;
    font-size: 0.9em;

    ol li, ul li {
        font-family: $navFontFamily;
        font-weight: $footerFontWeight;
    }
}

footer a {
    border: none;
    text-decoration: none;
    line-height: 1.15;
    font-size: $ctaFontSize;
}

footer button {
    font-weight: $footerFontWeight;
}

footer button:hover,
footer button:focus {
    background: none;
    border: none;
}

footer .top-row,
 {
    background-color: #dfdfdf;
    margin: 0 50px 25px 50px;
    padding: 50px;
}

@media (max-width: 1023px) {
    footer .top-row {
        margin: 25px;
        padding: 25px;
    }
}

@media (max-width: 799px) {
    footer .top-row {
        margin: 25px 0;
        padding: 25px 40px;
    }
}

footer .top-row .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

@media (max-width: 799px) {
    footer .top-row .wrapper {
        flex-wrap: wrap;
    }
}

footer .top-row .wrapper .col {
    flex-basis: 15%;
}

@media (max-width: 1023px) {
    footer .top-row .wrapper .col {
        flex-basis: 17%;
    }
}

@media (max-width: 799px) {
    footer .top-row .wrapper .col {
        flex-basis: 100%;
    }
}

footer .top-row .wrapper .title {
    position: relative;
}

footer .top-row .wrapper .title h3 {
    position: relative;
    text-transform: uppercase;
    font-family: $titleFontFamily;
    margin: 0;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #FFFFFF;
    font-size: 18px;
    line-height: normal;
}

@media (max-width: 799px) {
    footer .top-row .wrapper .title h3 {
        padding-top: 10px;
    }
}

footer .top-row .wrapper .title button.mobile-title-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

footer .top-row .wrapper .title button.mobile-title-button:after {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 18px;
    right: 25px;
    width: 5px;
    height: 9px;
    transform: rotate(90deg);
    transition: transform 300ms ease;
}

footer .top-row .wrapper .title button.mobile-title-button.is-open:after {
    transform: rotate(270deg);
}

@media (min-width: 800px) {
    footer .top-row .wrapper .title button.mobile-title-button {
        display: none;
    }

    footer .top-row .wrapper .title button.mobile-title-button:after {
        display: none;
    }
}

@media (max-width: 799px) {
    footer .top-row .wrapper .list-wrapper {
        height: 0;
        overflow: hidden;
    }
}

footer .top-row .wrapper ul {
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
}

footer .top-row .wrapper ul li {
    padding: 15px 0 0;
    line-height: 1.15;
    font-size: $ctaFontSize;
}

footer .top-row .wrapper ul li a {
    color: $black;
}

footer .bottom-row {
    display: flex;
    flex-wrap: nowrap;
    margin: 25px 50px;
}

@media (max-width: 1023px) {
    footer .bottom-row {
        margin: 25px;
    }
}

@media (max-width: 799px) {
    footer .bottom-row {
        flex-wrap: wrap;
        margin: 25px 0;
    }
}

footer .bottom-row .logos {
    padding: 25px 50px;
    background-color: #dfdfdf;
    flex-basis: 50%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin-right: 12px;
}

@media (max-width: 1023px) {
    footer .bottom-row .logos {
        padding: 25px;
    }
}

@media (max-width: 799px) {
    footer .bottom-row .logos {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 25px;
    }
}

footer .bottom-row .logos .vokey {
    width: 198px;
    height: 41px;
}

@media (max-width: 499px) and (min-width: 250px) {
    footer .bottom-row .logos .vokey {
        width: 39.6vw;
        height: 8.2vw;
    }
}

footer .bottom-row .logos .scotty-cameron {
    width: 173px;
    height: 41px;
}

@media (max-width: 499px) and (min-width: 250px) {
    footer .bottom-row .logos .scotty-cameron {
        width: 34.6vw;
        height: 8.2vw;
    }
}

footer .bottom-row .locale-selector {
    position: relative;
    background-color: #dfdfdf;
    flex-basis: 50%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 13px;
}

@media (max-width: 799px) {
    footer .bottom-row .locale-selector {
        flex-basis: 100%;
        margin-left: 0;
        padding: 2em 0;
        margin-bottom: 25px;
    }
}

footer .bottom-row .menu-global {
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .bottom-row .menu-global>button {
    color: #000000;
    font-weight: $footerFontWeight;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: .9rem;
}

footer .bottom-row .menu-global>button .icon-v2 {
    width: 2.25em;
    height: 2.25em;
    margin: 0 1em;
}

footer .bottom-row .menu-global>button:hover,
footer .bottom-row .menu-global>button:focus {
    color: #e32338;
    background: none;
    border: none;
}

footer .bottom-row .menu-global>button:hover .icon-v2,
footer .bottom-row .menu-global>button:focus .icon-v2 {
    background-color: #e32338;
}

footer .bottom-row .menu-global.is-open .menu-content {
    display: block;
    ul li a {
        font-size: rem(12.6px);
    }
}

footer .bottom-row .menu-content {
    box-shadow: 0 0 5px 2px #E2DEDE;
    background-color: #FFFFFF;
    box-sizing: border-box;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    padding: 1em;
    display: none;
}

footer .bottom-row .menu-content ul {
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 4em;
    line-height: 1.15;
}

@media (max-width: 1023px) {
    footer .bottom-row .menu-content ul {
        padding: 0;
    }
}

footer .bottom-row .menu-content ul li {
    flex-basis: 50%;
    padding: 0.25em 0;
    font-size: 0.9em;
    line-height: 1.15;
}

footer .bottom-row .menu-content a {
    color: #000000;
}

footer .bottom-row .menu-content a:hover {
    color: #e32338;
}

footer .bottom-row .menu-content a.is-active {
    color: #e32338;
}

footer .bottom-row .menu-content-header {
    position: relative;
    text-align: center;
    padding: 2em 0 1em;
}

footer .bottom-row .menu-content-header h4 {
    font-family: $navFontFamily;
    font-weight: normal;
    border-bottom: solid 1px #E2DEDE;
    padding-bottom: 1em;
    font-size: 1rem;
    text-transform: uppercase;
}

footer .bottom-row .menu-content-header .icon-close {
    position: absolute;
    top: -0.5em;
    right: 0;
    width: 2.25em;
    height: 2.25em;
    color: #000000;
}

footer .bottom-row .menu-content-header .icon-close:hover {
    background-color: #e32338;
}

footer .bottom-row .icon-arrow-drop-down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1.75em;
    width: 3em;
    height: 3em;
    background-color: #fff;
}

@media (max-width: 499px) and (min-width: 250px) {
    footer {
        .bottom-row {
            .logos {
                .vokey {
                    width: 39.6vw;
                    height: 8.2vw;
                }

                .scotty-cameron {
                    width: 34.6vw;
                    height: 8.2vw;
                }
            }
        }
    }
}

@media (max-width: 799px) {
    footer .top-row .wrapper .title button.mobile-title-button:after {
        background-image: url("../images/icons/arrow.svg");
    }
}

.is-hidden {
    display: none !important;
}

.footer-media .footer-media-content .news-signup-form .locale-terms label {
    text-align: center;
}

.footer-media.footer-sms-channel {
    padding-bottom: rem(62px);
    @media screen and (max-width: 800px) {
        padding-bottom: rem(32px);
    }
    .footer-media-content {
        border-bottom: rem(1px) solid #E3DEDE;
        border-top: rem(1px) solid #E3DEDE;
        display: flex;
        flex-direction: row;
        max-width: rem(708px);
        text-align: left;
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
        .news-signup-form {
            .input-wrap {
                padding: 0;
                & > label {
                    @include font-bold();
                    color: #000000;
                    display: block;
                    font-size: rem(14px);
                    line-height: rem(20px);
                    text-align: left;
                    width: 100%;
                }
                .locale-terms {
                    align-items: flex-start;
                    display: flex;
                    margin: 0;
                    padding-left: rem(15px);
                    padding-right: rem(36px);
                    width: inherit;
                    input[type=checkbox] {
                        margin-right: rem(12px);
                    }
                    label {
                        color: #4C4C4C;
                        flex: initial;
                        font-size: rem(12px);
                        line-height: rem(16px);
                        text-align: left;
                        font-weight: 400;
                    }
                }
                .news-signup-error,
                .invalid-email-error {
                    text-align: left;
                    p {
                        padding-left: 0;
                    }
                }
                .input-button-wrap {
                    button {
                        cursor: pointer;
                        height: rem(24px);
                        position: absolute;
                        right: .5em;
                        top: 50%;
                        transform: translateY(-50%);
                        width: rem(24px);
                    }
                }
            }
        }
    }
    .label {
        @include font-bold();
        color: #000000;
        font-size: rem(14px);
        line-height: rem(20px);
    }
    .footer-col {
        padding-top: rem(26px);
        padding-bottom: rem(13px);
    }
    .footer-left-col {
        max-width: rem(417px);
        padding-right: rem(21px);
        @media screen and (max-width: 767px) {
            max-width: initial;
            padding-right: 0;
        }
    }
    .footer-right-col {
        max-width: rem(291px);
        padding-left: rem(21px);
        @media screen and (max-width: 767px) {
            max-width: initial;
            padding-bottom: rem(17px);
            padding-left: 0;
        }
    }
    .footer-media-heading {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(420px);
        .footer-media-heading-label {
            @include font-bold();
            color: #000000;
            font-size: rem(18px);
            line-height: rem(22px);
            margin-bottom: rem(21px);
            margin-top: rem(2px);
        }
    }
    #footerSignUpBySMS {
        span {
            color: #000000;
            font-size: rem(12px);
            line-height: rem(16px);
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .footer-media-social-list {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(14px);
        max-width: rem(708px);
        .label {
            position: relative;
            top: rem(-4px);
            @media screen and (max-width: 800px) {
                margin-right: rem(10px);
            }
        }
        .footer-media-social-icons {
            @media screen and (max-width: 800px) {
                flex-basis: initial;
            }
        }
    }
}

.sms-landing-page {
    .sing-up-section {
        display: none;
    }
}

.footer-media-another {
    .footer-media-heading {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(446px);

        .footer-media-heading-label {
            @include font-bold();
            color: $black;
            font-size: rem(18px);
            line-height: rem(22px);
            margin-bottom: rem(21px);
            margin-top: rem(2px);
        }
    }
}

.footer-media {
    .footer-media-content-another {
        border-top: 1px solid $bon-jour_approx;
        @media (max-width: 799px) {
            max-width: 800px;
        }

        .news-signup-form {
            .input-wrap {
                padding: 16px 0 0 0;
            }
        }

        .footer-media-social-icons {
            padding-right: 0;
        }

        .locale-terms {
            label {
                font-weight: normal;
                color: $black;
                font-size: rem(14px);
                position: relative;
                top: -2px;
            }
        }
    }
}
