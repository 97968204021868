
nav.secondary {
	position: relative;
	font-family: $sans-serif;
	font-weight: 400;
	font-size: .8em;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	background-color: #fff
}

nav.secondary button {
	color: #4c4c4c;
	font-weight: 400
}

nav.secondary button:focus,
nav.secondary button:hover {
	background: 0 0
}

nav.secondary .scroll-container {
	text-align: center;
	width: 100%;
	margin: 0 auto;
	padding: 10px 0;
	overflow-x: scroll;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	scrollbar-width: none;
	border-bottom: 1px solid #f2f2f2
}

nav.secondary .scroll-container::-webkit-scrollbar {
	display: none
}

nav.secondary .controls {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 120px;
	pointer-events: none;
	z-index: 2;
	overflow: hidden
}

nav.secondary .controls .arrow {
	position: absolute;
	background-color: rgba(255, 255, 255, .9);
	width: 30px;
	height: 100%;
	pointer-events: all;
	transition: all .3s ease
}

nav.secondary .controls .arrow.left {
	border-right: 2px solid #f2f2f2;
	top: 0;
	left: -35px;
	transform: rotateY(180deg)
}

nav.secondary .controls .arrow.left.is-active {
	left: 0
}

nav.secondary .controls .arrow.right {
	border-left: 2px solid #f2f2f2;
	top: 0;
	right: -35px
}

nav.secondary .controls .arrow.right.is-active {
	right: 0
}

nav.secondary .controls .arrow img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}

nav.secondary ul {
	height: 100px;
	margin: 0 auto;
	padding: 0 20px;
	list-style-type: none;
	display: inline-flex;
	flex-wrap: nowrap;
	justify-content: center;
	vertical-align: bottom
}

nav.secondary a,
nav.secondary ul li {
	display: flex;
	flex-direction: column;
	justify-content: flex-end
}

nav.secondary ul li {
	height: 100%;
	line-height: 1.15;
	font-size: $navSecondaryFontSize;
	flex: 0 0 auto;
	min-width: 100px;
	margin: 0 10px
}

nav.secondary ul li img {
	width: 100px;
	height: 70px;
	margin: 0 auto
}

nav.secondary ul li.promo {
	width: 155px
}

nav.secondary ul li.promo img {
	width: 155px;
	height: 70px;
	margin: 0 auto
}

nav.secondary ul li.line {
	min-width: auto;
	width: 1px;
	background-color: #000;
	height: 70px;
	position: relative;
	top: 8px
}

nav.secondary ul li.see-all {
	min-width: 75px
}

nav.secondary ul li.see-all a,
nav.secondary ul li.see-all button {
	width: 100%;
	height: 100%
}

nav.secondary ul li.see-all .label {
	width: 100%;
	text-align: center
}

nav.secondary .label {
	text-align: center;
	margin-top: 7px
}

nav.secondary a {
	position: relative
}

nav.secondary a:after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 0;
	height: 1px;
	background-color: #e31837;
	bottom: -3px;
	transition: all 150ms ease-out;
	opacity: 0
}

nav.secondary img.box,
nav.secondary picture.box {
	position: relative;
	left: -8px
}

nav.secondary img.see-all-right {
	width: 21px;
	height: 21px;
	transform: rotate(180deg);
	margin: 20px auto
}

nav.secondary img.hide-all-right {
	width: 16px;
	height: 16px;
	margin: 24px auto
}
