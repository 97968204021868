//////////////////////////////////////////////
// Styles for Checkout Header and Footer
// Override of core's _checkout_header_footer.scss
//////////////////////////////////////////////


//////// Checkout Header Footer extentions for Titleist /////////////////////////////

#wrapper.pt_order-confirmation,
#wrapper.pt_checkout {
    .top-banner {
        .menu-utility-user {
            @include respond(small) {
                float: right;
            }
            li {
                &#mini-cart {
                    a.mini-cart-link {
                        @include respond(small) {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
    footer {
        background: none;
        + footer {
            padding-top: rem(28px);
        }
        .footer-container {
            .innerWidth {
                max-width: rem(1200px);
            }
            .footer-bottom {
                .left-checkout-footer {
					@include respond(small) {
                        float: none;
                        margin: rem(4px) 5% rem(24px);
                        width: auto;
					}
					@include respond(largeUp) {
						float: none;
						margin-left: auto;
						margin-right: auto;
						padding-left: 24px;
						padding-right: 24px;
					}
                    .content-asset {
                        ul {
							@include respond(largeUp) {
								text-align: center;
							}
                            li {
                                &:first-of-type {
                                    padding-left: 0;
                                }
                                a {
                                    color: $text-gray;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                .banner-security {
                    > span {
                        &.phone {
                            span {
                                @include font-size(11px);
                                color: $text-gray;
                                line-height: rem(18px);
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-copy {
        .menu-global {
            display: none;
        }
        .copyright {
            margin-top: rem(30px);
        }
    }
}
