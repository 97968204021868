.parcellab-portal-delivery-section {
    background-color: #f2f2f2;
    margin: rem(42px) rem(50px) rem(36px);
    overflow: hidden;
    padding: rem(29px) rem(37px) rem(28px);
    @media screen and (max-width: 800px) {
        margin-bottom: 0;
        padding-left: rem(20px);
        padding-right: rem(20px);
        margin-left: rem(20px);
        margin-right: rem(20px);
    }
    #errorPlaceHolder {
        color: #E31837;
    }
    .parcellab-portal-delivery-section-container {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(552px);
        padding-left: rem(15px);
        padding-right: rem(15px);
        text-align: center;
    }
    .parcellab-portal-subscribed-heading,
    .parcellab-portal-page-heading {
        .parcellab-portal-page-header {
            color: #000000;
            font-size: rem(21px);
            font-weight: 700;
            line-height: 1;
        }
        .parcellab-portal-page-subheader {
            color: #4C4C4C;
            font-size: rem(14px);
            line-height: rem(20px);
            margin-top: rem(5px);
        }
        .parcellab-portal-page-image {
            margin-top: rem(10px);
            margin-bottom: rem(8px);
        }
    }
    .parcellab-portal-page-header-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .titleist-btn {
            margin-top: rem(4px);
        }
        .titleist-link {
            font-size: rem(14px);
            line-height: rem(16px);
            color: #E31837;
            text-decoration: underline;
            margin-top: rem(16px);
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .parcellab-portal-delivery-form {
        margin-bottom: rem(15px);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(2px);
        max-width: rem(260px);
        width: 100%;
        .titleist-btn {
            width: 100%;
        }
        .form-row {
            margin-bottom: 0;
            .field-wrapper {
                span.error ~ span.error {
                    display: none !important;
                }
            }
        }
        input {
            text-align: center;
        }
    }
    .parcellab-portal-page-notification {
        color: #7E7E7E;
        p {
            font-size: rem(12px);
            line-height: rem(16px);
            margin: 0;
            color: #7E7E7E;
            & + p {
                margin-top: rem(12px);
            }
        }
        a {
            color: #E31837;
            font-size: rem(12px);
            line-height: rem(16px);
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .parcellab-portal-page-header-unsubscribed-message {
        .parcellab-portal-page-header-actions {
            margin-top: rem(16px);
        }
    }
}

#main {
    .parcellab-portal-container {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
    .parcellab-portal-delivery-section {
        margin-bottom: 0;
        & ~ .parcellab-portal-main-section {
            margin-left: rem(50px);
            margin-right: rem(50px);
            @media screen and (max-width: 800px) {
                margin-left: rem(20px);
                margin-right: rem(20px);
            }
            #parcellab-track-and-trace {
                padding-top: rem(20px);
                .pl-container {
                    padding-left: 0;
                    padding-right: 0;
                    max-width: 100%;
                }
            }
        }
    }
}



