.pt_product-details {
    .product-col-1 {
        @include respond(small) {
            margin-top: 10px;
        }
    }
}

.gear-product-pdp {
    .product-info-mobile {
        .product-name {
            display: block;
            position: unset;
            max-width: 85%;
            font-size: 1.2rem;
            line-height: normal;
            margin: 10px 0 0;
        }
    }
    .product-name {
        margin-top: 0;
    }
    .product-price {
        @include respond(small) {
            line-height: rem(24px);
        }
    }
    .customizer-link {
		.content-asset {
            margin: 10px 0;
            color: $color-secondary;
		}
        a {
            @include respond(small) {
                font-size: 10px;
                display: inline-block;
            }
        }
    }
    .product-number {
        color: $suva-gray;
        font-size: rem(13px);
        @include respond(small) {
            position: absolute;
        }
    }
    .product-image-container {
        .product-thumbnails.slick-initialized {
            .slick-arrow {
                background: url("../images/caro-arrow.svg") no-repeat center;
                position: absolute;
                top: 30%;
                &.slick-prev {
                    left: -35px;
                    transform: rotate(180deg);
                }
                &.slick-next {
                    right: -35px;
                    transform: rotate(0);
                }
            }
        }
    }
    .product-detail {
        .pdpForm {
            .product-variations {
                .attribute {
                    .value {
                        .show-colors {
                            display: block;
                            clear: both;
                            position: relative;
                            top: 21px;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
