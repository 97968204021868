.pdp-main {
    color: $black;
    h1, h2, .ui-dialog .ui-dialog-title, h3, h4, h5 {
        color: $black;
    }
	.product-info {
		@include media-breakpoint-up(lg) {
			margin-top: rem(10px);
		}

		ul {
			list-style: disc;
			li {
				color: $black;
			}
		}
	}

	.product-price {
		@include font-bold(14px);
		letter-spacing: 0;
		margin: rem(10px) 0 rem(17px);

		&.employee-price {
			.price-standard {
				display: inline-block;
				text-decoration: line-through;
				border: none;
				& + .price-sales {
					display: inline-block;
					font-size: 14px;
				}
			}
		}

		.price-sales {
			color: $black;
		}
        .price-standard {
            display: block;
            color: $black;
            font-weight: 600;
            text-decoration: none;
			border: 0 none;
            & + .price-sales {
                display: none;
            }
        }
	}
	.product-name {
		color: $black;
		font-size: 30px;
		letter-spacing: -.06rem;
	}
	.product-number {
		color: $suva-gray;
		letter-spacing: 0;
	}
	.giftcard-detail {
		.form-row__label {
			display: flex;
			gap: 7px;
		}

		.content-asset {
			a {
				color: $black;
			}
		}
		.gift-svs-select-amount {
			width: 213px;
			@media screen and (max-width: 441px) {
				width: 160px;
			}
		}
		.gift-svs-amount {
			width: 63px;
            padding: 0.5em 0.7em;
            text-align: right;
		}
		.bold {
			font-weight: 700;
		}
		.pdpForm .product-add-to-cart {
			margin-top: 0 !important;
			.add-to-cart {
				width: 100%;
				max-width: none;
				text-transform: capitalize;
				margin-top: 0;
			}
		}
		.gift-card-data {
			input[type="text"],
			input[type="number"],
			select,
			input[type="email"],
			textarea {
				box-shadow: none;
			}
			label {
				text-transform: none;
			}
            .date-msg {
                padding: 0 0 20px;
            }
            .gift-later {
                margin: -10px 0 -8px;
            }
            .expected-date {
                display: none;
                &.later-date {
                    display: block;
                }
            }
            .datepicker-toggle {
                display: inline-block;
                position: relative;
                margin-top: 10px;
            }
            .datepicker-toggle-button {
                position: absolute;
                right: 12px;
                top: 14px;
                background: url(../images/icons/ico_cal.png) no-repeat;
                width: 20px;
                height: 20px;
                pointer-events: none;
                z-index: 9;
            }
            .datepicker-placeholder {
                font-size: 12px;
                font-weight: 400;
            }
            .datepicker-overlay {
                position: absolute;
                background-color: $white;
                width: 91%;
                left: 16px;
                top: 8px;
                padding: 0;
                font-size: 14px;
                line-height: 2.2;
                &.overlay-hide {
                    display: none;
                }
            }
			.amount {
				display: flex;
				align-items: baseline;
				@media screen and (max-width: 991px) {
					max-width: 400px;
				}
			}
			.or-divider {
				margin: 0 18px;
                @include respond(extra-small) {
                    margin: 0 10px;
                }
			}
			.or-divider,
			.gift-svs-select-amount,
			.gift-svs-amount {
				font-weight: 700;
				text-transform: uppercase;
				&.error {
					background-color: #fff;
				}
			}
			textarea {
				& + span.error {
					display: none;
				}
				&.error-text {
					& + span.error {
						display: block;
					}
				}
			}
		}
	}

	.notify-me-link-wrapper {
		margin-top: -10px;
		padding-bottom: 24px;
		padding-left: 30px;
		padding-right: 30px;
		position: relative;
		text-align: right;
		z-index: 1;
		display: none;
		@include media-breakpoint-up(lg) {
			margin: -30px auto 0;
			max-width: 475px;
			padding-bottom: 24px;
			padding-left: 50px;
			padding-right: 50px;
		}
		&.retail-link {
			margin-top: 15px;
		}
		&.addtocart-gear {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			margin: 0;
			padding: 0;
		}
		&.notify-me-oos {
			top: 10px;
			@include respond(small) {
				right: 35px;
			}
		}
	}
	.notify-me-link {
		color: $black;
		font-size: 13px;
		font-weight: bold;
		text-decoration: underline;
		display: inline-block;
	}
}

.ui-dialog.promo-details-dialog .ui-widget-header .ui-button .ui-button-icon-primary {
    margin-top: 0;
}

.description-body {
	h3 {
		 line-height: 1.15em;
	}
}

.description-container .pdp-description {
	.description-body h3 {
		color: $black;
		font-size: 28px;
		letter-spacing: 0;
		text-align: left;
	}
	p, span.description-body {
		color: #4c4c4c;
		font-size: 14px;
		line-height: 1.9;
	}
}

.pdp-content-assets {
	.pdp-content-header {
		width: 100%;
	}
}

.custom-select-field {
	.select-header {
		@extend %clearfix;
		label {
			float: left;
			margin: 0 0.5rem 0 0;
			font-weight: bold;
			line-height: 36px;
		}
	}
	&.has-error {
		.must-select {
			margin-top: 16px;
			@include respond(small) {
				margin-top: 0;
			}
		}
	}
	.must-select {
		position: relative;

		&.not-selected {
			margin-top: 16px;

			.required-error {
				display: block;
			}
		}
	}
}

.product-primary-image {
	.slick-track {
		overflow: hidden;
	}

    &.slick-initialized .slick-slide {
        outline: none;
	}

	.product-image {
		.zoomImg {
			display: none !important;
		}
		&:hover {
			.zoomImg {
				display: inline-block !important;
			}
		}
	}
	.product-badge {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 13px;
        max-width: calc(100% - 26px);
        @include media-breakpoint-up(lg) {
            top: 25px;
            left: 20px;
            max-width: calc(100% - 40px);
        }
        &.secondary-badge {
        	right: 13px;
			left: unset;
			background-color: transparent;
			z-index: 95;

	        &.members-only-lock-badge {
		        z-index: 1;
	        }

        	@include media-breakpoint-up(lg) {
            	right: 20px;
			}
			.badgeIcon {
				width: auto;
				height: 30px;
				vertical-align: middle;
				img {
					width: auto;
                    height: 100%;
				}
			}
			span {
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
			}
			span:not(.badgeIcon) {
				max-width: 300px;
			}

        }
    }
	&.members-only-product {
		.unlocked-icon {
			display: block !important;
		}
		.locked-icon {
			display: none !important;
		}
		&.locked {
			.unlocked-icon {
				display: none !important
			}
			.locked-icon {
				display: block !important;
			}
		}
	}
}

.product-thumbnails {
	img {
		width: 60px;
    	height: 60px;
	}
}

.pdp-main .product-add-to-cart {
	.inventory-container {
		width: auto;
	    float: left;
	    margin: 0 0.625rem 0 0;
	    .quantity {
	    	font-weight: bold;
	    	color: $black;
	    	@include respond(extra-small) {
	    		label {
	    			display: none;
	    		}
	    	}
	    }
		.select-pdp {
			width: 4.5rem;
		    height: 2.625rem;
		    line-height: rem(42px);
		    display: inline-block;
		    background: #fff;
			background-position: right 8px center;
			background-size: 24px 24px;
			background-repeat: no-repeat;
			border-radius: 2em;
			background-image: url(../images/arrow-drop-down.svg);
    		margin-bottom: 0;
		}
		.inventory {
			margin-bottom: 0;
		}
		.low-inventory-msg {
			margin-bottom: 0;
		}
	}
	.add-to-cart-wrapper {
		width: auto;
	    display: block;
	    float: none;
	    overflow: hidden;
	}
	.add-to-cart {
	    float: right;
	    width: 100%;
	    max-width: 16.2rem;
	    margin: 0;
		@include redButton;
		&:hover, &:focus {
			color: $white;
			background-color: $crimson;
			border-color: $crimson;
		}
		@include respond(small) {
			margin-top: 4px;
		}
		@include respond(standard) {
			max-width: 13.2rem;
		}

		&.custom-disabled {
            background-color: $black;
            border-color: $black;

            &::before {
                background-color: $color-primary;
            }
            &::after {
                border-left-color: $color-primary;
            }

            &:hover,
            &:focus {
                background-color: $black;
                border-color: $black;
                color: $white;
                &::before {
                    background-color: $color-primary;
                }
                &::after {
                    border-left-color: $color-primary;
                }
            }

            &:disabled {
                opacity: 1;
                background-color: $text-gray;
				border: none;

                &::before,
                &::after {
                    content: none;
                }
            }
		}
	}
	@include respond(large) {
		#add-to-cart {
			width: 100%;
			max-width: 19rem;
		}
	}
}

#pdpMain {
	overflow: hidden;
	.product-detail {
		.pdpForm {
			.product-add-to-cart {
				background-color: #f6f6f6;
    			border: 1px solid #e8e8e8;
    			padding: 12px;
				position: relative;
				@include respond(large) {
					#add-to-cart {
						max-width: 11.3rem;
						width: 100%;
					}
				}
				.inventory-container {
					margin-bottom: 0;
				}
				@include respond(small) {
					.secondary-actions {
						display: none;
					}
				}
			}
			.add-to-cart {
				padding: 11px 20px;
			}
		}
	}
	&.giftcard-pdp {
		.mobile-product-info,
		.giftcard-top-content {
			.product-category {
				color: #4c4c4c;
				font-size: 14px;
				font-weight: normal;
				font-family: $gotham-book;
			}
		}
		.mobile-product-info .product-category {
			margin-bottom: 10px;
		}
		.giftcard-detail {
			.product-section + .product-section {
				border-top: none;
			}
			.gift-card-data {
				.field-wrapper.amount-input {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;
				}
				.gift-svs-amount {
					margin-left: 4px;
    				margin-right: 4px;
				}
				#giftcardPurchaseAmount-error {
					order: 4;
					display: block;
				}
			}
			.giftcard-top-content {
				.gift-variants {
					border-top: 1px solid #e2dede;
					ul.swatches.color {
						li.selectable {
							.swatchanchor {
								color: black;
								width: auto;
								padding: 3px 6px;
								box-sizing: border-box;
								display: inline-block;
							}
						}
					}
				}
                #reviewssnippet {
                    display: none;
                }
			}
		}
	}
	&.pdp-global-detail {
		.giftcard-detail {
			.product-section-inner,
			.product-bottom {
				@media screen and (min-width: 992px) {
					max-width: 400px;
				}
			}
		}
	}
	#design-your-own-ball {
		&.visually-hidden {
			+ .preorder-message {
				display: none;
			}
		}
	}
	.preorder-message {
		color: $color-secondary;
	}
    .add-to-cart-custom-message {
        color: #E31837;
        margin: 20px 0;
    }
    #clearGolfBall {
        font-weight: 700;
        color: $black;
    }
	.availability-msg {
		&.pre-msg {
			display: none;
		}
	}
	.sku-curr {
		display: none;
		font-size: 10px;
		color: #4C4C4C;
	}
	.sku-on {
		padding-top: 10px;
	}
	.product-variations .attribute .value {
		.color {
			&.sku-on .sku-curr {
				display: block;
			}
		}
	}
	.swatchanchor.members-only-product {
		position: relative;
		.locked-icon,
		.unlocked-icon {
			position: absolute;
			width: 18px;
			height: 18px;
			top: 2px;
			right: 2px;
			img {
				width: 100% !important;
			}
		}
		.locked-icon {
			display: none;
		}
		.unlocked-icon {
			display: block;
		}
		&.locked {
			.locked-icon {
				display: block;
			}
			.unlocked-icon {
				display: none;
			}
		}
	}
	.must-select {
		&.not-selected {
			&:not(.accessories-shown)  {
				* {
					color: $crimson !important;
				}

				.radio-buttons-group {
					display: inline-flex !important;

					&::before {
						content: "";
						border: 1px solid $crimson;
						position: absolute;
						width: calc(100% + 2px);
						height: calc(100% - 5px);
						top: -1px;
						left: -1px;
					}
				}

				.configurator-number {
					border: 1px solid $crimson;
				}
			}
			&.accessories-shown ~ .radio-button-accessory {
				* {
					color: $crimson !important;
					border-color: $crimson !important;
				}
			}
		}
	}
}
.pdp-recommendations {
	border: none;
	padding: 0;
	margin: 0;
	h3 {
		font-size: 1.5rem;
        padding-bottom: 20px;
        border-bottom: $default-border;
	}
	.product-listing-1x4 {
        .search-result-items {
            @include respond(large) {
                padding: 0 16px;
            }
            @include respond(small) {
                margin: 0 auto;
				padding: 0 16px;
            }
            .slick-arrow {
                top: 50%;
                background-position: center;
                background-repeat: no-repeat;
                @include respond(large) {
                    background-size: 12px;
                    transform: translateY(-50%);
                }
                @include respond(small) {
                    background-size: 12px;
                }
            }
            .grid-tile {
                @include respond(large) {
                    margin: 0 16px;
                }
            }
            .product-tile {
                .product-pricing {
                    margin: 0;
                }
            }
        }
		.grid-tile {
			.product-tile {
				.product-name {
					a.name-link {
						font-size: 14px;
						font-weight: 700;
						text-decoration: none;
						letter-spacing: normal;
					}
				}
			}
		}
	}
}

#selectFile {
	margin: 0 auto;
	padding: 20px;
	border: solid 3px black;
}


.product-variations,
.product-options {
	.attribute {
		.value {
			width: 100%;
			@include respond(four-two) {
				width: 230px;
			}
		}
		&.error {
			overflow: visible;

			.label {
				color: $crimson !important;
				float: none;
			}
			.must-select.not-selected {
				display: inline-flex !important;
				width: auto;
				position: relative;
				border: 0 none;
				padding: 0;

				&::before {
					content: "";
					border: 1px solid $crimson;
					position: absolute;
					width: calc(100% - 3px);
					height: calc(100% - 3px);
					top: -1px;
					left: -1px;
				}
				.selectable {
					position: relative;
				}

				.selected-value {
					display: none;
				}
			}
		}
	}
}

.members-only-modal,
.members-only-modal-another {
    display: none;
}

.password-reset-dialog.ui-dialog,
.privacy-policy-dialog.ui-dialog {
	position: fixed !important;
	top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%)!important;
	max-height: 95vh;
	max-height: calc(var(--app-height) * 0.95);
	border-radius: 0!important;
	margin: 0!important;
}

#pdpMain.pdp-global-detail {
	.product-section.product-section--learnmore {
		+ .product-section {
			margin-top: 0;
		}
	}
}

.product-learnmore--container:not(.d-lg-none, .club-learnmore) {
	display: none;

	@media screen and (min-width: 991px) {
		display: block;
	}
}

.club-pdp-main {
	.club-desktop-learnmore {
		display: none;
		@media screen and (min-width: 992px) {
			display: block;
		}
	}

	.product-section--learnmore {
		.ui-accordion {
			max-width: 375px;
			@media screen and (max-width: 991px) {
				margin: 0 20px;
				max-width: none;
			}
		}
	}
}

.gear-product-pdp,
.pdp-balls-detail {
	.product-section--learnmore {
		border-bottom: 1px solid #e2dede;
	}
}

.product-section--learnmore {
	border-top: 1px solid #e2dede;
}

.pdp-learn-more--top {
	&.ui-accordion {
		max-width: 400px;
		margin: 0 auto;
		padding: 12px 0;

		@media screen and (max-width: 991px) {
			max-width: none;
			margin: 0 20px;
		}

		.ui-accordion-content {
			padding-left: 0;
			padding-right: 0;
			font-size: 14px;
			line-height: 24px;

			a {
				color: $black;
				text-decoration: underline;
			}
		}

		.ui-accordion-header {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 0;
			font-size: 14px;

			&.ui-state-active .ui-accordion-header-icon {
				transform:  translateY(-50%) rotate(180deg);
			}

			.ui-accordion-header-icon {
				left: auto;
				right: 0;
				margin-top: 0;
				transition: transform ease .3s;
				transform: translateY(-50%);
				width: 13px;
				height: 10px;
				background-position: center;
				background-image: url("../images/down-arrow.png");
				background-size: 13px;
			}
		}
	}
}

.members-only-dialog.ui-dialog {
    padding: 0 !important;
	position: fixed !important;
	top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%)!important;
	max-height: 95vh;
	max-height: calc(var(--app-height) * 0.95);
	overflow: auto;
	border-radius: 0!important;

    @include respond(extra-small) {
        width: calc(100% - 10px) !important;
    }

    .ui-dialog-titlebar {
        height: 0;
        padding: 0;
    }
    .ui-dialog-title {
        display: none;
    }
    .ui-dialog-titlebar-close {
        right: rem(10px) !important;
	    top: rem(10px);
        left: auto;
	    margin: 0 !important;
	    opacity: 1;

	    @include respond(largeUp) {
		    left: rem(10px);
		    right: auto;
	    }
    }

	.ui-icon-closethick {
		&::before {
			color: $black;
			font-weight: 700;
		}
	}

    .members-only-modal,
    .members-only-modal-another {
        padding: 0 !important;
        width: 100% !important;

        @include respond(largeUp) {
            display: flex !important;
            justify-content: space-between;
	        min-height: 630px !important;
        }
    }

	.members-only-content,
	.members-only-account {
		box-sizing: border-box;
	}

	.members-only-content {
		min-height: 40px;
		padding-top: 30px;
		padding-bottom: 25px;

		@include respond(largeUp) {
			padding: 30px;
			width: calc(100% - 295px);
		}

		.content-asset {
			height: 100%;
		}

		.members-only-modal-free {
			text-align: center;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;

			img {
				max-width: 100px;
				max-height: 100px;
				margin-bottom: 15px;

				@include respond(largeUp) {
					max-width: 146px;
					max-height: 146px;
					margin-bottom: 30px;
				}
			}

			h2 {
				margin-top: 0;
				margin-bottom: 20px;
				font-family: $titleCondensedFontFamily;
				font-size: 40px;
				line-height: normal;

				br {
					@include respond(largeUp) {
						display: none;
					}
				}
			}

			p {
				margin: 0;
				font-family: $sans-serif;
				font-size: rem(14px);
				line-height: 1.8;
				color: $black;
				padding: 0 35px;

				@include respond(largeUp) {
					padding: 0;
				}
			}
		}
	}

	.members-only-account {
		background: $lightGrey;
		margin: 5px;
		min-height: 630px;

		@include respond(largeUp) {
			margin: 0;
			width: 295px;
			min-height: 0;
		}

		.dialog-required {
			display: flex;
			justify-content: flex-end;
			padding: 0;

			.required-indicator {
				color: $primary-color;
				@include font-size(12px);
				text-decoration: none;
			}
		}

		&.ui-accordion {
			.ui-accordion-content {
				padding: 0 20px 20px;
			}

			.ui-accordion-header {
				padding-left: 35px;
				padding-top: 15px;
				padding-bottom: 15px;

				&.ui-state-active .ui-accordion-header-icon {
					transform: translateY(-50%);
				}

				.ui-accordion-header-icon {
					left: auto;
					right: 30px;
					margin-top: 0;
					transform: translateY(-50%) rotate(270deg);
					width: 17px;
					height: 10px;
					background: url("../images/down-arrow.png") no-repeat center;
				}
			}
		}

		.password-link {
			margin-top: 15px;
			margin-left: 15px;

			#password-reset {
				text-decoration: underline;
				white-space: normal;
			}
		}

		button.button {
			background: $crimson;
			color: $white;
			width: 150px;
			display: block;
			margin: 20px auto 0 !important;
			padding: 10px;
			line-height: normal;

			&:hover {
				color: $white;
			}
		}

		button.account-header {
			background: none;
			@include font-size(18px);
			line-height: normal;
			color: $black;
			font-family: $gotham-book;
			font-weight: 700;
			margin: 0;
			padding-top: 10px;
			padding-bottom: 10px;
			text-align: left;
			width: 100%;
			border: 0;

			&.second-header {
				border-top: 4px solid $white;
			}
		}

		.account-link:not(.password-link) {
			font-weight: normal;

			span {
				color: #989898;
				line-height: 16px;
			}

			a {
				text-decoration: underline;
			}
		}

		.dialog-required + .form-row {
			margin-top: -22px;

			span.error {
				margin-top: 0;
			}
		}

		.form-row {
			margin-bottom: 0;
			margin-top: 0;
			position: relative;
			padding-top: 25px;

			input {
				margin-top: 0;
				margin-bottom: 0;
			}

			span.error {
				position: static;
				padding-left: 15px;
				margin-top: -19px;
				display: block;
				padding-bottom: 3px;
			}

			.input-select {
				margin-top: 0;
			}

			.form-caption {
				font-size: rem(11px);
				padding-left: 15px;
				padding-top: 3px;
			}
		}

		label[for='dwfrm_profile_personalinformation_fiCountry'] {
			display: none;
		}

		.label-inline {
			display: flex;
			align-items: start;
			padding-top: 10px;
			padding-left: 15px;

			input {
				margin-top: 1px;
				margin-right: 8px;
				width: 13px;
				height: 13px;
				flex: 0 0 13px;
			}
		}
	}

	.opt-in-address {
		font-size: 12px;
		line-height: 19px;
		margin-bottom: 0;
		color: #4C4C4C;

		@include respond(largeUp) {
			text-align: center;
		}
	}

	.opt-in-address__link {
		color: #4C4C4C;
		font-size: 12px;
		line-height: 19px;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

#pdpMain {
	.product-core-info {
		padding-bottom: 50px;
		@media screen and (max-width: 991px) {
			padding-bottom: 50px;
		}
		& + .product-info {
			margin-top: -50px;
			@media screen and (max-width: 991px) {
				margin-top: 0;
			}
		}
		.product-col-1 {
			.product-image-container .product-thumbnails {
				margin: 0 auto;
				.thumb.slick-slide {
					a {
						img {
							object-fit: contain;
							@media screen and (min-width: 992px) {
								object-fit: unset;
							}
						}
					}
				}
				& > ul {
					padding-right: 10%;
					padding-left: 10%;
					@media screen and (min-width: 992px) {
						padding-right: 0;
						padding-left: 0;
					}
				}
				.carousel .slick-list {
					padding-right: 0;
					padding-left: 0;
				}
			}
			.product-image-container-height {
				display: none !important;
				&.show {
					@include media-breakpoint-up(lg) {
						display: block !important;
					}
				}
			}
		}
		.disable-commerce {
			&.nocomm-wide {
				.product-name {
					margin-bottom: 20px;
				}
				.content-asset {
					max-width: 400px;
				}
				.product-top {
					margin-bottom: 20px;
				}
			}
		}
	}
	.not-available {
		&.disable-commerce {
			.product-bottom {
				display: none;
			}
		}
	}
	.disable-commerce {
		.configurator-controls,
		.product-main-info .promotion,
		.product-top .promotion,
		.product-bottom .promotion,
		.product-section,
		.product-add-to-cart,
		#configurator {
			display: none;
		}
		.product-main-info,
		.product-top,
		.product-bottom {
			border-bottom: 1px solid #e3dede;
			padding-bottom: 9px;
			.product-price,
			.promotion {
				display: none;
			}
		}
		.ball-logo {
			margin: 5px 0 30px;
		}
		.content-asset {
			margin: 0 auto;
			@include media-breakpoint-down(md) {
				padding: 20px;
			}
			@include media-breakpoint-up(lg) {
				max-width: 368px;
			}
			ul {
				padding: 5px 0 0 25px;
				list-style-type: disc;
				li {
					padding: 0;
					color: #4c4c4c;
				}

			}
			ul,
			p {
				line-height: 1.9;
    			margin: 0 0 1em;
				color: #4c4c4c;
			}
		}
	}

	.selection-error {
		font-size: 12px;
	}
}

#QuickViewDialog {
	width: 100% !important;
	padding: 0px 20px 20px !important;
	@media screen and (min-width: 992px) {
		padding: 15px 18px 18px 23px !important;
	}
	@media screen and (min-width: 1200px) {
		padding: 15px 26px 33px 43px !important;
	}
	#pdpMain {
		.mobile-breadcrumb {
			.breadcrumb {
				margin-top: 0;
			}
		}
		&.pdp-global-detail {
			.product-section-inner {
				max-width: 100%;
				margin: 0;
			}
			.product-top {
				padding: 0;
				.product-base-info {
					margin-left: 8px;
				}
			}
		}
		.product-core-info {
			.product-add-to-cart {
				margin: 26px 0 0;
				max-width: 100%;
			}
			.product-image-container {
				&.sticky {
					width: 100%;
				}
			}
		}
		.mobile-product-info {
			@include respond (large) {
				float: right;
				max-width: 42%;
    			width: 100%;
			}
		}
		.product-col-1 {
			max-width: 100%;
			width: 100%;
			@media screen and (min-width: 768px) {
				width: 58%;
			}
		}
		.product-col-2 {
			width: 100%;
			@media screen and (min-width: 768px) {
				width: 42%;
			}
			&.product-detail {
				@include respond (large) {
					width: 42% !important;
					margin: 0;
					padding-left: 0;
				}
				#add-to-cart {
					height: 45px !important;
					line-height: 21px !important;
					margin-bottom: 0 !important
				}
			}
			.pdpForm {
				width: 100%;
				@media screen and (min-width: 768px) {
					padding: 0 0 0 22px;
				}
				@media screen and (min-width: 1200px) {
					padding: 0 10px 0 45px;
				}
				.view-full-details {
					display: inline-block;
					margin-top: 14px;
				}
			}
		}
		.product-core-info {
			.product-image-container {
				.product-thumbnails {
					.thumb.slick-slide {
						a {
							@media screen and (min-width: 1200px) {
								height: 99px;
							}
						}
					}
				}
			}
		}
	}
}

.notify-overlay {
	z-index: 1000 !important;
}

.ui-dialog.notifyme-dialog {
	max-width: 594px;
	width: 100% !important;
	transform: translate(-50%, -50%);
	overflow-y: auto;
	z-index: 1001 !important;
	.ui-dialog-title {
		float: none;
	}
	.ui-dialog-titlebar-close {
		top: 8px;
		@media screen and (min-width: 768px) {
			right: 20px;
			top: 38px;
		}
		&:hover {
			background-color: transparent;
		}
	}
	.dialog-content {
		width: 100% !important;
		padding: 13px 37px 15px 40px !important;
		float: none;
		margin-top: 0;
	}
	.notifyme-header-wrapper {
		margin-bottom: 27px;
		.notifyme-title {
			@include font-size(36px);
			@include line-height(40px);
			color: $black;
			font-family: $gotham-book;
			font-weight: 700;
			margin-bottom: 7px;
		}
		.notifyme-subtitle {
			@include font-size(14px);
			@include line-height(18px);
			color: $color-primary;
			font-family: $gotham-book;
			font-weight: 400;
		}
	}
	.notify-me-form {
		.notify-me-form-container {
			max-width: 377px;
			width: 100%;
		}
	}
	.radio-buttons-group-wrapper {
		display: inline-block;
		margin: 0 -5px;
		position: relative;
		vertical-align: middle;
		width: 100%;
		&.orientation {
			padding: 0;
			.label-container {
				padding: 0 12px 12px;
			}
			.radio-button {
				width: calc(50% - 22px);
				margin: 0 10px 10px 5px;
			}
		}
		.radio-buttons-container {
			position: relative;
			padding-bottom: 12px;
			.selected-value-row {
				position: absolute;
				top: -27px;
				left: 5px;
				display: flex;
				justify-content: flex-end;
				width: 512px;
				text-align: right;
				pointer-events: none;
				@include respond(small) {
					width: 564px;
				}
				@media screen and (min-width: 320px) and (max-width: 614px) {
					width: calc(100vw - 46px);
				}
				.selected-value {
					display: inline-block;
					max-width: 120px;
					pointer-events: all;
					color: $black;
					.current-sku {
						font-size: 10px;
						color: $color-primary;
						display: block;
						pointer-events: all;
					}
				}
			}
		}
		.label-container {
			padding: 0 7px 12px;
		}
		.label {
			display: block;
			margin-bottom: 0;
			@include font-size(14px);
			font-weight: bold;
			letter-spacing: -.04rem;
			color: $black;
		}
		.radio-button {
			float: none;
			position: static;
			text-transform: none;
    		margin: 0 5px 10px;
			float: none;
			display: inline-block;
			min-width: 68px;
			.radio-button-label {
				@include font-size(14px);
				border: 1px solid #e8e8e8;
				color: #292929;
				display: block;
				padding: 12px 0;
				text-align: center;
				transition: box-shadow .2s;
				border-radius: 2px;
				font-weight: 700;
			}
			input[type=radio] {
				position: absolute;
				left: -999em;
				font-size: 16px;
				&:checked ~ .radio-button-label {
					color: $white;
					box-shadow: none;
					border-color: $black;
					background-color: $black;
					font-weight: 700;
				}
			}
			&:hover {
				input[type=radio]:not(:checked) ~ .radio-button-label {
					box-shadow: inset 0 0 0 1px $black;
				}
			}
			span {
				&.error {
					position: absolute;
    				bottom: 0;
					left: 0;
					@include font-size(12px);
					font-weight: 400;
				}
			}
			&.swatchanchor {
				cursor: pointer;
				padding: 3px;
				border: 1px solid $trans;
				border-radius: 2px;
				background: $gray-white;

				&.selected,
				&:hover {
					border-color: $black;
				}

				&.not-selectable {
					opacity: 0.5;

					&:hover {
						border-color: $trans;
					}
				}
			}
		}
		.club-setcomp .radio-button {
			width: calc(25% - 10px);
			@include respond(small) {
				width: calc(25% - 6px);
				margin: 0 3px 10px;
			}
		}
		& + .radio-buttons-group-wrapper {
			margin-top: 4px;
		}
		&.error-notify {
			.label-container span,
			.radio-buttons-container .radio-button {
				color: $crimson;
			}
			.radio-buttons-container {
				&::before {
					content: '';
					display: block;
					position: absolute;
					width: calc(100% - 6px);
					height: calc(100% - 18px);
					top: -2px;
					left: 50%;
					transform: translateX(-50%);
					border: 1px solid $crimson;
					z-index: -1;
				}
				.radio-button-label {
					color: $crimson;
				}
			}
		}
	}
	.input-checkbox {
		display: inline;
		float: left;
		margin-left: 0;
		& + label {
			background: none;
			width: auto;
			float: none;
			overflow: hidden;
			padding-left: 0.375rem;
			display: block;
			span {
				@include font-size(12px);
				color: #4C4C4C;
				text-transform: none;
				font-weight: normal;
				float: left;
				line-height: 120%;
				margin-left: 0;
			}
		}
	}
	.notifyme-email-confirm {
		background: none;
		width: auto;
		float: none;
		overflow: hidden;
		padding-left: 0.375rem;
		display: block;
	}
	.notifyme-submit {
		@include redButton;
		font-weight: 700;
		padding-top: 13px;
		padding-bottom: 13px;
	}
	&.notifyme-success {
		padding: 0 !important;
		.ui-widget-header {
			background-color: #f6f6f6;
		}
		.ui-dialog-title {
			float: none;
		}
		#dialog-container {
			float: none;
			padding: 0 !important;
			width: 100% !important;
		}
		.notifyme-confirmation-header {
			background-color: #f6f6f6;
			padding: 30px 24px 48px;
			text-align: center
		}
		.notifyme-confirmation-reco {
			min-height: 470px;
			@include respond(largeUp) {
				min-height: 490px;
			}
			.product-tile .product-swatches li {
				@include respond(small) {
					margin-right: 1px;
				}
			}
			.club-pdp-recommendations .tiles-container .slick-arrow {
				width: 30px;
				min-width: auto;
				border: none;
				padding: 0;
			}
		}
		.notifyme-confirmation-title {
			@include font-size(36px);
			@include line-height(36px);
			color: $black;
			font-family: $gotham-book;
			font-weight: 700;
			margin-bottom: 23px;
		}
		.notifyme-confirmation-subtitle {
			@include font-size(14px);
			@include line-height(20px);
			color: $black;
			font-family: $gotham-book;
			font-weight: 400;
			margin-bottom: 40px;
			margin-left: auto;
			margin-right: auto;
			max-width: 305px;
			width: 100%;
			.strong {
				font-weight: 700;
			}
		}
		.notifyme-confirmation-continue-shopping {
			.continue-shopping {
				@include redButton;
				font-weight: 700;
				padding-top: 13px;
				padding-bottom: 13px;
			}
		}
		.club-pdp-recommendations {
			margin: 0 auto 0 auto;
			padding: 24px 0;
			@media screen and (min-width: 768px) {
				padding: 24px 12px;
			}
			.product-content-headers {
				margin-bottom: 28px;
			}
			.product-tile {
				max-height: 374px;
				@include respond(small) {
					max-height: 403px;
				}
				.product-swatches {
					@include respond(small) {
						margin: 0 0 5px;
					}
				}
			}
			.tiles-container {
				padding: 0;
				margin-bottom: 0;
				.slick-list {
					padding: 0 !important;
					margin: 0;
					.slick-track {
						margin: 0 auto;
					}
				}
				.slick-arrow {
					height: 30px;
					width: 30px;
					&:hover,
					&:focus {
						background-color: $white !important;
					}
					&.slick-prev {
						background: $white url(../images/icons/arrow-left-grey.svg) center no-repeat;
						background-size: 10px 10px;
						left: 0px;

					}
					&.slick-next {
						background: $white url(../images/icons/arrow-right-grey.svg) center no-repeat;
						background-size: 10px 10px;
						right: -10px;
						@media screen and (min-width: 768px) {
							right: -15px;
						}
					}
				}
				.grid-tile {
					margin: 0 10px;
					@include media-breakpoint-up(xl) {
						margin: 0 11px;
					}
				}
			}
		}
	}
}

.klarna-placement--wrapper {
	margin: 10px 30px 0;

	@include respond(largeUp) {
		width: 100%;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
}

.klarna-placement--wrapper-configurator {
	margin: 10px 0 0;

	@include respond(largeUp) {
		width: 100%;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
}

#QuickViewDialog .klarna-placement--wrapper {
	clear: both;
	float: left;
    width: 100%;
	max-width: none;
	padding: 0 0 0 22px;

	@media screen and (min-width: 1200px) {
		padding: 0 10px 0 45px;
	}
}

.klarna-placement--cart {
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}

.ui-dialog.ui-widget.zoom-image-dialog {
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    padding: 0 !important;
    width: 100% !important;
    position: fixed !important;
    border-radius: 0 !important;

    .zoom-image-wrapper {
        margin-top: 6px;
        background-color: $img-gray;
        img {
            width: 100%;
            max-width: 100%;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }

    #js-zoom-content {
        padding: 0 !important;
        float: none !important;
        position: static !important;
        height: 100% !important;
        min-height: 100% !important;
        max-height: 100% !important;
    }

    .ui-dialog-title {
        display: none !important;
    }

    .ui-dialog-titlebar {
        height: 40px !important;
        position: fixed !important;
        background: $trans !important;
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 1001 !important;

        .ui-dialog-titlebar-close {
            right: 44px !important;
            top: 34px !important;
            margin: 0 !important;
            display: block !important;
            height: 24px !important;
            width: 24px !important;
            .ui-button-icon-primary {
                margin: 0 !important;
                top: 0 !important;
                right: 20px !important;
                display: block !important;

                &::before {
                    display: block !important;
                }
            }

            &:hover {
                background-color: $trans !important;
            }
        }
    }
}

.zoom-enabled {
	.product-primary-image {
		.product-image {
			&:hover {
				cursor: zoom-in;
			}
		}

		.glass-icon {
			position: absolute;
			width: 24px;
			height: 24px;
			background: url('../images/icons/zoom.svg') top left no-repeat;
			bottom: 20px;
			right: 20px;
			@include respond(largeUp) {
				right: 40px;

			}
		}
	}
}

.fixedPosition {
    overflow: hidden;
    height: 100vh;
}

.customizer-unavailable-dialog {
    max-width: 454px;
    padding: 0 !important;

    .ui-dialog-title {
        display: none;
    }

    &.ui-dialog.ui-widget {
        border-radius: 0;
    }

    .customizer-unavailable-modal {
        &.ui-dialog-content {
            padding: 0 40px !important;
            text-align: center;
        }
    }

    .customizer-unavailable-modal-content {
        h2 {
            margin: 0;
            padding: 32px 0 12px 0;
            color: $black;
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
        }

        p {
            margin: 0;
            color: $black;
            font-size: 14px;
            line-height: 20px;
            font-family: $gotham-book;
            padding: 0 10px 49px 10px;
            font-weight: normal;
        }
    }
}


.members-only-pdp-global {
    padding: 17px;
    background: $img-gray;
    color: $black;
    width: 100%;
    margin-top: 40px;
    font-size: 14px;

    p {
        margin: 0;
        font-size: 14px;
        line-height: 14px;

        &.members-only-pdp-title {
            margin-bottom: 12px;
            font-weight: bold;
        }
    }

    a {
        font-size: 14px;
        text-decoration: underline;
        color: $red;
        &:hover {
            text-decoration: none;
        }
    }

    .members-only-pdp-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            justify-content: flex-start;
        }

        p {
            font-size: 13px;
            margin-bottom: 12px;
            @include respond(largeUp) {
                font-size: 14px;
            }
            @media screen and (max-width: 375px) {
                display: flex;
            }

            span {
                display: inline-block;
                min-width: 20px;
                margin: 0 5px 0 0;
                text-align: right;
                position: relative;
                top: 3px;

                &.members-second {
                    margin-right: 0;
                    top: 0;
                    @media screen and (max-width: 375px) {
                        text-align: left;
                        top: 3px;
                    }
                }
            }
        }

        .odd {
            width: 45%;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: auto;
                padding-right: 18px;
            }
        }

        .even {
            width: 55%;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                width: auto;
                padding-right: 18px;
            }
        }
    }

    span {
        display: inline-block;
        margin: 0 5px;
    }
}

#QuickViewDialog {
    .members-only-pdp-global {
        display: none;
    }
}
