/////////////////////
//////Styles for Account My info Page
/////////////////////
#wrapper.pt_account{
	#main{
		@include respond(small) {
			#secondary,
			#primary{
				padding: 0;
			}
		}
	}
	#primary{
        &.account-login-wrap {
            padding: 10px !important;
        }
		h1 {
			margin-top: 0;
		}
		#RegistrationForm{
			.side-form{
				width: 100%;
				display: inline-block;
				.form-row{
					width: auto;
				    float: left;
				    display: block;
				    @include respond(small) {
				    	width: calc(100% - 80px);
				    }
				}
				.field-wrapper{
					width: 270px;
					@include respond(small) {
						width: 100%;
					}
				}
				button{
					font-size: 11px;
				    padding: 0 20px;
				    height: 34px;
				    line-height: 30px;
				}
			}
			.form-row{
				display: inline-block;
				width: 100%;
				label {
					width: 100%;
					&::after {
						content: "";
					}
				}
				&.long-label {
					label {
						width: 300px;
					}
				}
				&.required{
					label::after{
						content: " *";
						color: $gray-dark;
					}
				}
				&.error{
					&.required{
						label::after{
							color: $error-red;
						}
					}
					.error-message{
						color: $white !important;
                        height: 1px;
                        overflow: hidden;
					}
				}
				input:not(.input-checkbox){
					width: 100%;
				}
			}
            .user-taken {
                display: none;
                margin: -15px 0 20px;
                color: $error-red;
                a {
                    font-size: 12px;
                }
            }
			& > div{
				h2{
					border-top: 2px solid $clp-border;
					font-size: 16px;
					margin: 0 0 4px;
					padding-top: 28px;
					margin-top: 20px;
					@include respond(small) {
						font-size: 14px;
						padding-top: 13px;
						margin-bottom: 13px;
					}
				}
				&.my-general-info{
					h2{
						border-top: 0;
						margin-top: 0;
					}
				}
				&.my-newsletter{
					@include respond(small) {
						h2{
							padding-top: 28px;
							margin-bottom: 0;
						}
					}
					.input-checkbox {
						margin-left: 0;
					}
					.form-row.label-inline.form-indent{
						margin-bottom: 12px;
    					margin-top: 16px;
    					@include respond(small) {
    						margin-top: 10px;
    					}
						label {
							margin-left: 0;
							width: 80%;
							a{
								display: table;
							}
						}
					}
				}
				&.my-information{
					h2{
						margin-bottom: 20px;
						@include respond(small) {
    						margin-top: 0;
    					}
					}
					.form-row{
						margin: 7px 0 0;
						width: 100%;
						.form-row{
							margin: 0;
							width: 130px;
							margin-right: 15px;
							.field-wrapper{
								width: auto;
							}
							&:first-child{
								margin-right: 20px;

							}
						}
						&.state{
							margin-top: 10px;
							margin-bottom: 15px;

							label{
								width: 158px;
								@include respond(small) {
									width: 100%;
								}
							}
							.city{
								color: $gray-dark;
								font-size: 12px;
								padding-top: 7px;
							}
							.link{
								color: $gray-warm;
								font-size: 12px;
								a{
									color: $gray-dark;
									font-size: 12px;
									text-decoration: underline;
									&:hover{
										text-decoration: none;
									}
								}
							}
						}
						select{
							width: 130px;
							box-sizing: border-box;
						}
					}
				}
				&.my-game{
					.form-row{
						margin-top: 18px;
						margin-bottom: 2px;
						.form-row{
							margin-top: 6px;
							.field-wrapper{
								margin-bottom: 0;
								padding-left: 0;
							}
						}
						label{
							width: 100%;
							display: block;
							line-height: 16px;
							margin-bottom: 5px;
							@include respond(small) {
								margin-bottom: 0;
							}
						}
					}
				}
				&.my-bag{
					& > div{
						display: block;
						width: 100%;
						.form-row{
							float: left;
							width: 344px;
							margin-right: 20px;
							margin-top: 19px;
							@include respond(small) {
								width: 100%;
							}
							.field-wrapper{
								padding-left: 0;
								margin-bottom: 6px;
							}

							label{
								display: block;
								width: 100%;
								line-height: 150%;
								margin-bottom: 5px;
							}
						}
					}
				}

			}
			.form-row.form-row-button{
				border-top: 2px solid $clp-border;
				margin-top: 20px;
				padding-top: 20px;
				display: inline-block;
				width: 100%;
				margin-bottom: 10px;
				&.register{
					border-top: 0;
					width: auto;
					display: block;
					padding-top: 10px;
					margin: 0;
					@include respond(small) {
						margin: 0 0 20px;
					}
				}
				button{
					margin-left: 0;
				}
				&.side-button {
					border: none;
				    width: auto;
				    float: left;
				    display: block;
				    padding-top: 0;
				    clear: none;
				    margin: 22px 0 0 5px;
				}
			}
		}
	}
}
