.pt_product-details {
    #main.full-width {
        padding: 0 .625rem;
        @include respond(largeUp) {
            padding: 0;
        }
    }
}

.ball-main-image {
    .pdp-main &.product-primary-image {
        display: block; //not sure why it's hidden in mobile by default
        max-width: 100%;
        margin: 0 auto 30px;
        float: none;
        @include respond(small) {
            margin-bottom: 0;
        }
    }

    .product-image img {
        width: 100%;
        max-width: 420px;
        display: inline-block; // to make it center in the <a>
        @include respond(small) {
            width: 50%;
        }
    }

    .product-image + .product-image {
        display: none;
    }

    &.slick-slider {
        .slick-prev, .slick-next {
            background-image: url("../images/caro-arrow.svg");
            background-repeat: no-repeat;
            background-position: 100% 50%;
            @include respond(small) {
                top: 32%;
            }
        }

        .slick-prev {
            transform: rotate(180deg);
        }

        .slick-dots {
            bottom: -35px;

            li {
                button {
                    border: none;
                    background-color: $suva-gray;
                    opacity: 0.63;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                }

                &.slick-active button {
                    background-color: $crimson;
                    opacity: 1;
                }
            }
        }

        .product-image + .product-image {
            display: block;
        }

        &.loading-indicator:before {
            content: '';
            display: block;
            position: absolute;
            background: url("../images/infinite-loading-indicator.gif") center no-repeat;
            width: 220px;
            height: 34px;
            top: calc(50% - 17px);
            left: calc(50% - 110px);
            z-index: 1;
        }
    }

}

.product-thumbnails {
    width: 80%;
    margin: 0 auto;
    float: none;
    &.slick-initialized {
        display: block;
    }
    .thumb.slick-slide {
        border: none;
        text-align: center;
        &.selected {
            border: none;
        }
        .thumbnail-link {
            float: none;
            outline: none;
            img {
                display: inline-block;
                margin: 0 auto;
                padding: 0 5px 4px;
                width: 64px;
                height: auto;
            }
        }
    }
}

.pdp-main {
    .radio-buttons {
        width:100%;
        legend {
            padding-top: 12px;
        }
    }
    .configurableForm fieldset {
        width:100%;
    }
    .product-thumbnails {
        display: none;
        &.slick-initialized {
            display: block;
        }
    }
    .product-detail {
        .pdpForm {
            .product-variations {
                .attribute {
                    &.variant-dropdown {
                        display: none;
                    }
                    div.label {
                        font-size: 13.2px;
                        line-height: normal;
                        padding: 12px 2% .83em 0;
                        margin: 0;
                    }
                }
                .label {
                    font-size: 12px;
                    letter-spacing: 0;
                }
                .value {
                    @include respond(small) {
                        width: 100%;
                    }
                }
                ul {
                    @extend %clearfix;
                    float: none;
                    clear: both;
                    .swatches {
                        &.color {
                            li.selected-value {
                                display: block;
                            }
                        }

                        &.hand,
                        &.size,
                        &.headcover {
                            display: flex;
                            flex-wrap: wrap;

                            li {
                                margin-right: 5px;
                                float: none;
                            }
                        }

                        &.size {
                            li {
                                flex: none;
                                min-width: 68px;

                                .swatchanchor {
                                    font-size: 14px;
                                }
                            }
                        }

                        li {
                            width: auto;
                            height: auto;
                            margin-right: 5px;
                            margin-bottom: 5px;
                            padding: 0;
                            .swatchanchor {
                                display: block;
                                margin: 0;
                                padding: 12px 10px;
                                border: 1px solid #e1dede;
                                line-height: normal;
                                border-radius: 2px;
                                font-size: 14px;
                                font-weight: 700;
                                letter-spacing: -0.07px;
                                color: $black;
                                text-decoration: none;

                                @include media-breakpoint-up(md) {
                                    padding: 12px;
                                }
                            }

                            .swatchanchor.oos-disabled {
                                color: #999;
                                background-color: #f0f0f0;
                                border-color: #999;

                                &:hover {
                                    box-shadow: inset 0 0 0 1px #000000;
                                }
                            }

                            .swatchanchor.disabled {
                                background-color: #f6f6f6;
                                min-height: 0;
                                pointer-events: none;
                                cursor: default;
                            }
                            &.selected {
                                position: relative;
                                z-index: 1;

                                .oos-disabled {
                                    box-shadow: inset 0 0 0 1px #000000;
                                }

                                .swatchanchor:not(.oos-disabled) {
                                    outline: none;
                                    background: $black;
                                    color: $white;
                                    border-color: $black;
                                }
                            }
                            &.selected-value {
                                left: auto;
                                right: 0;
                                margin: 0;
                                padding: 11px 0;
                                font-size: 12px;
                                font-weight: 500;
                                letter-spacing: -0.07px;
                                text-transform: capitalize;
                                color: $black;
                                text-align: right;
                            }


                            &.oos-color-swatch {
                                &.selected {
                                    .swatchanchor {
                                        border-color: $text-gray !important;
                                    }
                                }
                                .swatchanchor {
                                    position: relative;

                                    img {
                                        opacity: .3;
                                    }

                                    &:hover {
                                        border-color: $text-gray !important;

                                        .tooltip-info {
                                            display: block;
                                        }
                                    }

                                    .tooltip-info {
                                        position: absolute;
                                        z-index: 1;
                                        top: -34px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        padding: 5px 10px;
                                        border-radius: 10px;
                                        background: $text-gray;
                                        color: $white;
                                        font-size: 10px;
                                        letter-spacing: 0.35px;
                                        white-space: nowrap;
                                        display: none;

                                        &::before {
                                            border: solid;
                                            border-color: $text-gray $trans;
                                            border-width: 4px 4px 0 4px;
                                            bottom: -4px;
                                            content: "";
                                            display: block;
                                            left: calc(50% - 4px);
                                            position: absolute;
                                            z-index: 1;
                                        }
                                    }
                                }
                            }

                            &:first-of-type {
                                &.oos-color-swatch {
                                    .swatchanchor {
                                        .tooltip-info {
                                            left: 0;
                                            transform: none;

                                            &::before {
                                                left: 31px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .radio-button-accessory {
                    &:not(.custom-only) {
                        margin-top: 27px;
                    }
                    .play-number {
                        font-size: rem(12px);
                        input[type=number].play-number {
                            &::placeholder {
                                font-size: rem(12px);
                                letter-spacing: $globalLetterSpacing;
                            }
                        }
                    }
                    label {
                        display: none;
                        &.error {
                            background: none;
                            text-transform: unset;
                            margin-top: 4px;
                        }
                    }
					label#special-number-error {
                        display: block;
                        margin-top: 10px;
					}
                    input {
                        max-width: 120px;
                        height: auto;
                        padding: 13px;
                        font-size: 14px;
                        line-height: normal;
                    }
                    .radio-button-accessory-desc {
                        color: $tundora_approx;
                        font-style: italic;
                        font-weight: 700;
                        font-size: 12px;
                    }
                }
            }
            legend {
                background: none;
                border: none;
                padding-left: 0;
            }
            .radio-buttons-group {
                display: inline-block;
                position: relative;
                vertical-align: middle;
                .radio-button {
                    float: left;
                    margin-right: 5px;
                    position: relative;
                    text-transform: none;
                    width: auto;
                    &:first-child .radio-button-label {
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                    }
                    .radio-button-label {
                        font-size: 12px;
                        border: 1px solid $color-highlight;
                        display: block;
                        padding: 16px 18px;
                        text-align: center;
                        transition: box-shadow .2s;
                    }
                    input[type=radio] {
                        position: absolute;
                        left: -999em;
                        font-size: 16px;
                        &:checked + .radio-button-label {
                            box-shadow: inset 0 0 0 2px $black;
                        }
                    }
                    &:hover {
                        input[type=radio]:not(:checked) + .radio-button-label {
                            box-shadow: inset 0 0 0 1px $black;
                        }
                    }
                }
            }
        }
    }
}

.pdp-main {
    @include respond(small) {
        position: relative;
    }

    .product-info {
        margin-top: 0;
        float: none;
    }

    .product-info-mobile {
        display: none;
        @include respond(small) {
            display: block;
        }
        .ball-product-name {
            display: block;
            .ball-logo {
                @include respond(small) {
                    display: block;
                    margin: 14px 0 .2rem;
                }
            }
            .product-name {
                @include respond(small) {
                    max-width: 70%;
                    font-size: 1.2rem;
                    line-height: normal;
                    margin: 10px 0 0;
                }
            }
        }
    }
    .product-col-1 {
        @include respond(largeUp) {
            width: 50%;
            max-width: 500px;
        }
        @include respond(large) {
            margin-left: 0.625rem;
            transition: margin-left .5s;
        }
        @include respond(small) {
            width: 100%;
            margin-top: 36px;
            float: none;
        }
    }
    .product-col-2 {
        box-sizing: border-box;
        @include respond(largeUp) {
            width: 46.5%;
        }
        @include respond(large) {
            padding-left: .625rem;
            margin-right: 0.625rem;
            transition: margin-right .5s;
        }
        @include respond(small) {
            width: 100%;
            float: none;
        }
    }
    .ball-product-name {
        @include respond(small) {
            display: none;
        }
        .product-name {
            margin-top: 0;
        }
        .ball-logo {
            height: rem(14px);
            vertical-align: middle;
            @include respond(largeUp) {
                height: rem(25px);
            }
        }
    }
    .product-name {
        line-height: .9;
    }
    .product-price {
        margin-bottom: 8px;
        @include respond(small) {
            position: absolute;
            margin: 0;
            top: 0;
            right: 0;
        }
        .price-sales {
            font-size: 1rem;
            font-weight: 600;
            color: $black;
            @include respond(small) {
                font-size: rem(14px);
            }
        }
		.price-standard {
			display: inline-block;
			font-size: 1rem;
			color: $black;
			font-weight: 600;
			text-decoration: line-through;
			padding-right: 0;
			& + .price-sales {
				display: inline;
				color: $color-secondary;
			}
			@include respond(small) {
				font-size: rem(14px);
			}
		}
	}
	.promotion {
		padding: 0 0 1px 0;
		margin: 0;
		display: block;
		border: 0;
		overflow: visible;
		.promotion-title {
			display: none;
		}
		.promotion-callout {
			color: $red;
			font-size: 14px;
			font-family: $titillium;
			line-height: 20px;
			span {
				padding: 0;
				margin: 0 0 5px 0;
				display: block;
				width: 100%;
			}
			a {
				text-decoration: underline;
			}
			.promotion-callout-custom {
				display: inline;
			}
		}
	}
    .product-add-to-cart {
        padding-right: 0;
        .inventory-container {
            display: block;
            margin-bottom: 8px;
            float: none;
            @include respond(small) {
                height: auto;
            }
            .quantity {
                label {
                    display: inline-block;
                    margin-bottom: .5em;
                    padding-bottom: .33em;
                    font-size: 1.1em;
                }
                .ui-spinner {
                    display: block;
                    padding: 0 62px;
                    border: 1px solid $gray-nurse;
                    background-color: $white;
                    border-radius: 2px;
                    .qty-spinner {
                        box-shadow: none;
                        font-size: 12px;
                        line-height: normal;
                        height: auto;
                        padding: 16px 0;
                        margin: 0;
                        border: none;
                        &:disabled {
                            color: $black;
                            -webkit-text-fill-color: $black;
                            opacity: 1;
                        }
                    }
                    .ui-spinner-button {
                        height: 100%;
                        width: 62px;
                        position: absolute;
                        &::after {
                            position: relative;
                            font-size: 16px;
                            font-weight: bold;
                            color: $black;
                            top: 15px;
                        }
                        &.ui-spinner-up {
                            right: 0;
                            &::after {
                                content: '+';
                            }
                        }
                        &.ui-spinner-down {
                            left: 0;
                            &::after {
                                content: '-';
                            }
                        }
                        .ui-button-text {
                            display: none;
                        }
                    }
                }
            }
        }
        .add-to-cart {
            float: none;
            margin: rem(20px) 0;
            height: auto;
            width: auto;
            line-height: normal;
            padding: rem(12.5px) rem(20px);
        }
    }
    #product-content {
        .design-your-own {
            margin-top: 8px;
            margin-bottom: 18px;
            font-size: 1.1em;
            &.fake-design-your-own {
                margin-top: 1.25rem;
            }
        }
    }

    .button-fancy-large {
        margin: rem(20px) 0;
    }
}

#QuickViewDialog {
    #pdpMain {
        .product-col-1 {
            max-width: 600px;
        }
    }
}

.ball-product-pdp-content-assets {
    display: none;
    &.active {
        display: block;
    }
}

#pdpMain {
    &.pdp-balls-detail,
    &.gear-product-pdp {
        .club-pdp-compare .compare-tables .compare-product-image::after {
            display: none;
        }
    }

    .product-detail {
        .custom-select-field {
            margin-bottom: 0;
            .must-select.not-selected {
                margin-top: 0;
            }
            &.custom-only {
                .radio-buttons-group {
                    display: none;
                }
            }
        }
        .pdpForm {
            float: none;

            .product-add-to-cart {
                background: none;
                border: none;
                padding-left: 0;
            }
        }
    }

    .no-customize-option {
        display: none;
    }
}

#wrapper.pt_product-details #main.full-width {
    padding-left: 0;
    padding-right: 0;
}

#pdpMain.pdp-global-detail {
    &.gear-product-pdp {
        .product-section + .product-section {
            @include media-breakpoint-up(lg) {
                border-top: 0;
            }
        }
    }
    .product-top {
        padding: 0 20px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    .product-section + .product-section {
        @include media-breakpoint-up(lg) {
            margin-top: 18px;
            border-top: $base-border;
        }
    }

    .product-section-inner,
    .product-bottom {
        position: relative;
        @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .top-content-container {
        .p1-header-right {
            .button {
                @include media-breakpoint-down(lg) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .product-base-info {
        display: none;
    }

    .product-detail .pdpForm {
        .product-variations .attribute .value,
        .product-options .attribute .value {
            width: 100%;
        }

        .product-variations ul .swatches.color {
            li {
                margin: 0 5px 5px 0;
                width: auto;

                &.selected-value {
                    margin: 0;
                }

                &.selected {
                    .swatchanchor {
                        border-color: $black;
                        background: $color-gray5;
                    }
                }

                &.design-your-own-list {
                    .design-your-own {
                        position: relative;
                        display: block;
                        margin: 0;
                        padding: 0;
                        border: 1px solid $trans;
                        background: none;
                        min-height: auto;
                        min-width: auto;
                        white-space: normal;
                        line-height: normal;
                        width: auto;
                        height: auto;

                        &:hover {
                            border-color: $black;
                        }
                    }

                    .design-your-own-icon {
                        width: 68px;
                        height: 68px;
                        vertical-align: middle;
                        border-radius: 0;
						&.german,
						&.french {
							width: 98px;
    						height: 65px;
						}
                        &.jp {
                            width: 90px;
                            height: 65px;
                        }
                    }

                    .design-your-own-label {
                        position: absolute;
                        font-size: 10px;
                        font-weight: 700;
                        letter-spacing: -0.05px;
                        color: $black;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        text-transform: capitalize;
                        padding: 0 5px;
                        transform: translateY(-50%);
                    }
                }

                .swatchanchor {
                    width: 67px;
                    min-height: auto;
                    height: auto;
                    padding: 2px;
                    border-radius: 2px;
                    border: 1px solid $trans;
                    background: $color-gray5;
                    box-shadow: none;

                    &:hover {
                        border-color: $black;
                    }

                    img {
                        width: 61px;
                        border-radius: 0;
                        height: auto;
                        vertical-align: middle;
                    }
                }
            }
        }

        .radio-buttons-group {
            display: flex;
            flex-wrap: wrap;

            .radio-button {
                margin: 0 7px 7px 0;
                float: none;

                &:last-child {
                    margin-right: 0;
                }

                .radio-button-label {
                    border: $base-border;
                    border-radius: 2px;
                    box-shadow: none;
                    border-radius: 0;
                    padding: 11px 20px;
                    font-weight: 700;
                    font-size: 14px;
                    letter-spacing: -0.07px;

                    @include media-breakpoint-up(lg) {
                        padding: 12px 23px;
                    }
                }

                input[type="radio"]:checked + .radio-button-label {
                    background: $black;
                    color: $white;
                    border-color: $black;
                }

                input[type="radio"]:disabled + .radio-button-label,
                input[type="radio"]:disabled + .radio-button-label:hover {
                    background: $gray-cirlce;
                    color: $text-gray;
                    border-color: $text-gray;
                    cursor: auto;
                }

                &:hover input[type="radio"]:not(:checked):not(:disabled) + .radio-button-label {
                    border-color: $black;
                }
            }
        }

        legend {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.07px;
            text-transform: capitalize;
            padding: 12px 0;
            color: $black;
        }

        .product-variations {
            .attribute {
                &.size-guide {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .value {
                        width: auto;
                    }

                    .size-guide-link {
                        color: $black;
                        font-size: 12px;
                    }
                }

                div.label {
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: -0.07px;
                    text-transform: capitalize;
                    padding: 12px 0;
                    color: $black;
                }
            }

            .radio-button-accessory:not(.custom-only) {
                margin-top: 20px;
            }

            .radio-button-accessory {
                input {
                    width: 100%;
                    max-width: none;
                    margin-right: 0;
                    box-shadow: none;
                    font-size: 13px;
                    padding-top: 12.5px;
                    padding-bottom: 12.5px;
                }

                .radio-button-accessory-desc {
                    margin: 10px 0 4px;
                    font-size: 12px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 16px;
                    letter-spacing: -0.06px;
                }

                .error {
                    margin-top: 10px;
                    display: block;
                }
            }
        }

        .product-add-to-cart {
            padding: 0;
            margin-top: 40px;

            .product-add-to-cart-inner {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
				flex-wrap: wrap;

                .button-wrapper {
                    width: calc(100% - 106px);

					.selection-error.error {
						display: none !important;
					}
                }
				& > .selection-error.error {
					width: 100%;
				}
				.product-add-to-cart-messages {
					width: 100%;
				}
            }

            .inventory-container {
                margin-right: 10px;
                margin-bottom: 0;

                .inventory {
                    float: none;

                    .select-pdp {
						width: 90px;
                        height: 45px;
                        box-shadow: none;
						border: 2px solid #ccc;
						background-position: right 13px center;

						&:focus {
							border-color: #999;
						}
                    }
                }

                .quantity label {
                    font-size: 14px;
                    display: block;
                    margin-bottom: 6px;
                    padding-bottom: 0;
                    margin-right: 0;
                    text-align: left;
                }
            }

            #add-to-cart {
                margin: 0;
                width: 100%;
                max-width: none;
                text-transform: capitalize;
                &.coming-soon {
                    margin-bottom: 10px;
                }
            }

            .selection-error {
                margin-top: 10px;
            }

            .secondary {
                margin-top: 0;
            }

            .in-stock-msg {
                display: none;
            }
            &.product-noadd-to-cart {
                margin-top: 10px;
                .product-add-to-cart-inner {
                    .button-wrapper {
                        width: 100%;
                        margin-top: 30px;
                    }
                }
            }
        }

        .selection-error {
            text-align: left;
        }

        .low-inventory-msg {
            margin-top: 10px;
        }

        .preorder-message {
            margin-bottom: rem(8px);
        }

        .buttons-wrapper {
            @include media-breakpoint-down(md) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        #continue-shopping-oos,
        .coming-soon {
            margin: 40px 0 10px;
            padding-top: 11px;
            padding-bottom: 11px;
            width: 100%;
            max-width: none;
            text-transform: capitalize;
            background-color: $black;
            border-color: $black;
            color: $white;
            border: none;
            &::before {
                background-color: #4c4c4c;
            }
            &::after {
                border-left-color: #4c4c4c;
            }

            &:hover,
            &:focus {
                background-color: $black;
                border-color: $black;
                color: $white;
                &::before {
                    background-color: #4c4c4c;
                }
                &::after {
                    border-left-color: #4c4c4c;
                }
            }

            &:disabled {
                opacity: 1;
                background-color: $text-gray;

                &::before,
                &::after {
                    content: none;
                }
            }
        }

        #continue-shopping-oos + .availability-web {
            .not-available-msg {
                margin: 0;
                font-size: 12px;
            }
        }

        .btn-find-retail {
            display: block;
            border-radius: 2rem;
            padding: 0.5em 3em;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: -0.07px;
            text-transform: capitalize;
            background-color: $white;
            border: 1px solid $black;
            color: $black;
            text-align: center;
            text-decoration: none;

            &:hover,
            &:focus {
                background-color: $black;
                border-color: $black;
                color: $white;
            }
        }
    }

    .product-variations {
        .attribute {
            margin: 0;
            overflow: visible;
        }
    }
}

#pdpMain.pdp-global-detail,
#pdpMain.gear-product-pdp,
#pdpMain.pdp-balls-detail {
    .pdp-p1-module .p1-header .p1-header-left .prod-name {
        @include media-breakpoint-up(lg) {
            width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .product-detail .pdpForm .product-add-to-cart .inventory-container {
        margin-top: 0;
    }
}

// Order Groove
#pdpMain.pdp-global-detail,
#pdpMain.configurator-ball {
    .product-section-inner,
    .configurator-controls {
        .og-offer-custom {
            &.subscribed {
                .og-regular-offer-content {
                    .optout-btn {
                        &:before {
                            display: none;
                            background-color: $white;
                            border-radius: 50%;
                            border: 1px $ogBtn solid;
                            height: 11px;
                            content: "";
                            left: 9px;
                            position: absolute;
                            top: 4px;
                            width: 11px;
                            z-index: 2;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .optin-btn {
                        &:before {
                            display: none;
                            background-color: $white;
                            border-radius: 50%;
                            border: 1px $crimson solid;
                            height: 11px;
                            content: " ";
                            left: 9px;
                            position: absolute;
                            top: 4px;
                            width: 11px;
                            z-index: 2;
                        }

                        &::after {
                            display: none;
                            background-color: $crimson;
                            border-radius: 50%;
                            border: 1px $crimson solid;
                            height: 5px;
                            content: " ";
                            left: 12px;
                            position: absolute;
                            top: 7px;
                            width: 5px;
                            z-index: 3;
                        }

                        og-optin-button {
                            box-sizing: border-box;
                        }
                    }
                }

                .og-frequency {
                    og-select-frequency,
                    .og-select-label {
                        display: inline-block;
                    }

                    og-text {
                        display: block;
                    }
                }
            }

            .og-regular-offer-content {
                position: relative;

                .optout-btn {
                    &:before {
                        display: none;
                        background-color: $white;
                        border-radius: 50%;
                        border: 1px $crimson solid;
                        height: 11px;
                        content: " ";
                        left: 9px;
                        position: absolute;
                        top: 4px;
                        width: 11px;
                        z-index: 2;
                    }

                    &::after {
                        display: none;
                        background-color: $crimson;
                        border-radius: 50%;
                        border: 1px $crimson solid;
                        height: 5px;
                        content: " ";
                        left: 12px;
                        position: absolute;
                        top: 7px;
                        width: 5px;
                        z-index: 3;
                    }
                }

                .optin-btn {
                    position: relative;

                    &:before {
                        display: none;
                        background-color: $white;
                        border-radius: 50%;
                        border: 1px $ogBtn solid;
                        height: 11px;
                        content: "";
                        left: 9px;
                        position: absolute;
                        top: 4px;
                        width: 11px;
                        z-index: 2;
                    }
                }
            }



            .og-frequency {

                og-select-frequency {
					display: none;
                    background-position: 49px center;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-image: url(../images/arrow-drop-down.svg);
                    position: relative;
					border: 2px solid #ccc;
					width: 79px;
					height: 29px;

					&:focus {
						border-color: #999;
					}

                    &::before {
                        background-color: $white;
                        content: " ";
                        height: 10px;
                        position: absolute;
                        top: 15px;
                        right: 1px;
                        width: 17px;
                        z-index: 2;
                    }

                    og-select {
                        width: 100%;

					}

					span {
						display: none !important;
					}
                }
				.og-select-label,
				og-text {
					display: none;
				}
            }

            og-offer og-optout-button {
                background-color: #ebebeb;;
            }

            og-offer og-optin-button {
                background-color: transparent;
            }

            og-offer[subscribed] og-optout-button {
                background-color: transparent;
            }

            og-offer[subscribed] og-optin-button {
                background-color: #ebebeb;
            }

			og-offer[subscribed] {
				og-select-frequency,
				.og-select-label {
					display: inline-block;
				}

				og-text {
					display: block;
				}
			}
        }
    }
}

#pdpMain.configurator-ball {
    .configurator-controls {
        .og-offer-custom {
            margin: 0 20px -30px;

            @include media-breakpoint-up(lg) {
                max-width: 432px;
                margin: 0 auto -30px;
            }
        }
    }
}


#pdpMain.gear-product-pdp {
    .og-offer-custom {
        margin-bottom: -30px;
    }
}

#pdpMain.pdp-balls-detail {
    .product-detail {
        .pdpForm {
            .product-add-to-cart {
                margin-top: 40px;
            }

            .og-offer-custom {
                margin-bottom: -30px;
            }
        }
    }
    .quickview-nav {
        display: none;
    }
}

.ui-dialog.ui-widget {
    &.og-modal {
        #dialog-container {
            h3 {
                color: $black;
                font-size: 36px;
                line-height: 38px;
                margin: 0 0 5px;
                padding: 0;
            }

            h4 {
                color: $black;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 5px;
                padding: 0;
            }

            p {
                color: $greyText;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 20px;
                padding: 0;
            }

            li {
                color: $greyText;
                font-size: 14px;
                line-height: 20px;
                margin: 0;
                padding: 0;
            }
        }
    }
}

