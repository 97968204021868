.breadcrumb {
	@include respond(small){
		padding: 0;
	}
}

.breadcrumb {
    padding: 0;
    li {
        padding: 0;
        line-height: 1em;
        .breadcrumb-element {
            @include font-regular(11px);
            &:not(:hover) {
                color: $suva-gray;
            }
            &:after {
                color: $suva-gray;
                content: '/';
                margin-left: .4em;
            }
            &:hover:after {
                text-decoration: none;
            }
        }
        &:last-child {
            .breadcrumb-element {
                font-weight: 400;
                &:after {
                    content: '';
                }
            }
        }
    }
}

#wrapper {
	&.pt_product-search-result {
		.breadcrumb {
			margin-top: 14px;
			margin-bottom: 2rem;
		}
	}
}

.breadcrumb {
    @include respond(small) {
        position: relative;
    }
    a.breadcrumb-element {
        text-decoration: none;
        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }
}