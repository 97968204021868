//////////////////////////////////////////////
// Checkout Shipping Step
// Overrides of core's _checkout_shipping.scss
//////////////////////////////////////////////


//////// Global checkout extentions for Titleist /////////////////////////////

%normalCase {
	//use this for styles that only need a text-transform override.
	text-transform: none;
}

%titleistFormWidths {
	.form-row {
		label {
			float: none;
			width: auto;
			font-size: rem(14px);
			@include font-bold();
			text-transform: none;
			line-height: 1.7;
		}

		.field-wrapper {
			width: 100%;
			select,
			input[type="password"],
			input[type="text"],
			input[type="email"],
			input[type="tel"],
			input[type="number"]{
				width: 100%;
			}
		}
	}
}

%titleistInlineRadioButtons {
	background: none;
	width: auto;
	float: none;
	overflow: hidden;
	padding-left: rem(6px);
	display: block;
}

//////// Global checkout styles for Titleist //////////////////////////////

#wrapper.pt_order-confirmation, #wrapper.pt_checkout {
	.top-banner .menu-utility-user {
		width: auto;

		li#mini-cart a.mini-cart-link {
			background: none;
		}
	}
}

#wrapper.pt_checkout {
	#main {
		@include respond(largeUp) {
			overflow: hidden;
		}
		.checkout-name {
			@include font-size(24px);
			text-transform: none;
		}

		#primary{
			width: 60%;

			@include respond(small) {
				width: 100%;
			}

			.checkout-progress-indicator {
				> div {
                    display: block;
                    float: left;
                    text-align: center;
                    width: 33%;
                    padding: 0 8px;
                    box-sizing: border-box;
                    @include respond(large) {
                        padding: 0 5%;
                    }
                    > span {
                        display: block;
                        margin: 0 auto 8px;
                    }
                    .name {
                        padding: 0;
                    }
				}
				.name, a {
					@extend %normalCase;
					@include font-bold(13px);
					text-decoration: none;
				}

				.inactive {
					span, .name, a {
						color: $gray-warm-old;
					}
				}
			}

			h2 {
				font-size: 20px;
				letter-spacing: $globalLetterSpacing;
				border-width: 1px;

				.dialog-required .required-indicator {
					@include font-regular(11px);
					color: $gray-warm-old;
					cursor: default;
				}
			}

			input[type="checkbox"], input[type="radio"] {
				display: inline;
				float: left;
				margin-left: 0;
			}

			label.radio + input[type="radio"] {
				margin-left: rem(15px);
			}

			.form-row {
				position: relative;
				&.label-inline {
					&.form-indent {
						margin-left: 0;
					}

					@include respond(small) {
						margin: 0;

						&.form-indent {
							margin: 0;
						}
					}
				}

				label {
					width: 100%;
				}

				.form-field-tooltip {
					margin: rem(7px) 0 0;
					left: auto;
					right: 0;
					top: 0;
					width: auto;
					position: absolute;
					padding-top: 0;
				}

				.form-field-tooltip {
					a {
						color: $gray-warm-old;
					}
				}

				.field-wrapper {
					width: 78%;

					@include respond(small) {
						width: 100%;
					}
				}
			}

			.gift-message-row {
				margin-top: 20px;
			}

			.continue-btn {
				@include respond(largeUp) {
					padding-bottom: 58px;
   					position: relative;
				}
				.form-row.form-row-button {
					@include respond(largeUp) {
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						position: absolute;
						text-align: center;
						width: 166.5%;
					}
					button {
						@include redButton;
						height: inherit;
						line-height: 1.2;
						margin-top: 21px;
						float: left;
						padding: 0.6em 3em;
						@include respond(small) {
							width: auto;
						}

						&:hover {
							border-color: inherit;
							color: $white;
							background-color: $color-secondary;
						}
					}
				}
			}
			.banner-security {
				float: left !important;
			}
		}
	}
}

////////  Shipping Page styles for Titleist  ////////////////////////////

#wrapper.pt_checkout {
	#main {
		#primary {
            .checkout-shipping {
                &.sms-enabled {
                    .form-row[class$="_shippingAddress_addressFields_phone"] {
                        border: 0;
                        clip: rect(0 0 0 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                }
                .form-col {
                    display: inline-block;
                    width: 100%;
                    .sms-channel-options {
                        background-color: #F6F6F6;
                        border: rem(1px) solid #DFDFDF;
                        margin-bottom: rem(9px);
                        margin-top: rem(16px);
                        padding: rem(7px) rem(20px) rem(16px);
                        @media screen and (max-width: 767px) {
                            margin-bottom: rem(11px);
                            margin-top: rem(8px);
                            padding-top: rem(15px);
                        }
                    }
                    .field-label {
                        color: #4C4C4C;
                        font-size: rem(14px);
                        font-weight: 700;
                        line-height: rem(20px);
                    }
                    .form-row {
                        margin-bottom: 0;
                        input[type="checkbox"] {
                            margin-bottom: rem(8px);
                            margin-top: rem(6px);
                        }
                        & + .form-row {
                            margin-top: rem(9px);
                        }
                        &.checkbox-custom {
                            input[type="checkbox"] {
                                margin-top: rem(1px);
                            }
                            .field-wrapper {
                                display: flex;
                                align-items: flex-start;
                                margin-top: rem(5px);
                                .lbl-check {
                                    margin-top: 0;
                                }
                            }
                        }
                        .lbl-check {
                            .content-asset {
                                display: inline-block;
                            }
                        }
                    }
                    label:not(.input-label) {
                        span {
                            color: #4C4C4C;
                            font-size: rem(12px);
                            font-weight: 400;
                            line-height: rem(16px);
                        }
                    }
                    .sms-channel-checkout-content {
                        color: #989898;
                        font-style: italic;
                        p {
                            font-size: rem(11px);
                            line-height: rem(16px);
                            margin: 0;
                            & + p {
                                margin-top: rem(12px);
                            }
                        }
                        a {
                            color: #E31837;
                            font-size: rem(11px);
                            line-height: rem(16px);
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
			.checkout-shipping .form-row .field-wrapper label.radio,
			.login-box .form-row .field-wrapper label.radio,
			.form-row.label-inline.form-indent label {
				@extend %titleistInlineRadioButtons;
			}

			#cart-table td.item-details .product-list-item .label {
				@extend %normalCase;
			}

			.login-box {
				@extend %titleistFormWidths;

				.login-box-content .form-row.form-row-button {
					button {
						@extend .btn-secondary;
						height: inherit;
						line-height: 1.2;
						float: none;
						padding: 0.6em 3em;
						font-size: rem(14px);
						margin-top: 20px;
						width: auto;
						display: block;
					}

					#password-reset {
						margin-top: 0.5em;
						color: $crimson;
						float: none;
					}
				}

				&.login-account {
					h2 {
						padding-top: rem(14px);
						a {
							@include font-size(20px);
							color: $primary-color;
							font-weight: bold;
							text-decoration: none;
						}
						img {
							padding-top: rem(4px);
							vertical-align: top;
							@include respond(largeUp) {
								padding-right: rem(6px);
							}
						}
						span {
							display: inline-block;
							vertical-align: top;
							padding-right: 36px;
							@media screen and (min-width: 900px) {
								padding-right: 0;
							}
							&.login-now {
								background: none;
								display: inline;
								color: $color-secondary;
								text-decoration: underline;
								padding-right: 0;
								white-space: nowrap;
								@include respond(largeUp) {
									margin-left: rem(6px);
								}
							}
						}
						.content-asset {
							display: inline;
						}
					}
				}
			}

			.checkout-shipping,
			.checkout-billing {
				@extend %titleistFormWidths;

				.form-row .field-wrapper label.radio {
					float: left;

					@include respond(small) {
						margin-top: 1em;
					}
				}

				.email-fiels {
					margin-bottom: 23px;
				}

				&.no-multi-country {
					.input-select.country:not(.dropdown-list) {
						padding-left: 1em;
					}
				}

				.form-field-tooltip, .form-caption {
					color: $gray-warm-old;
					margin: rem(7px) 0 0;
					font-size: rem(11px);
				}

				.form-row.label-inline {
					margin-top: 0;
					margin-bottom: 0;
					label span {
						font-size: 12px;
						font-weight: normal;
						float: left;
						line-height: 120%;
						margin-left: 0;
					}
				}

				.form-row label .required-indicator {
					display: none;
				}

				.form-row.required label span {
					&::after {
						content: " *";
						color: #4C4C4C;
					}
				}

				.form-caption {
					a.privacy-policy {
						color: $crimson;
						margin-bottom: 20px;
						display: inline-block;
						margin-left: 23px;
						margin-top: -10px;
						font-size: 12px;
						text-decoration: underline;
					}
				}
				select {
					height: inherit;
					line-height: inherit;
				}
			}
			#shipping-method-list .form-row.form-indent label span {
				@include font-regular(12px);
				text-transform: none;

				.tooltip {
					color: $crimson;
				}
			}
			#multi-shipment-shipping-method-list .shipping-method-list .form-row.form-indent label {
				width: auto;
			}
		}
	}
}

#wrapper.pt_checkout {
	#main {
		#primary {
			#multi-shipment-shipping-method-list {
				.checkout-multi-shipment {
					button {
						color: #fff;
						background-color: #e31837;
						border-color: #e31837;
						text-transform: none;
						&:hover {
							background-color: #b51029;
							color: #fff;
							border-color: #b51029;
						}
					}
				}
			}
			.checkout-progress-indicator {
				> div {
					padding: 0 8px;
				}
				&.multiship {
					> div {
						width: 25%;
						a, .name {
							@include respond(small) {
								font-size: 10px;
								letter-spacing: -.025rem;
							}
							@include respond(iphone) {
								font-size: 10px;
								letter-spacing: -.025rem;
							}
						}
					}
				}
			}
			.summary {
				.summary-address {
					> div {
						width: 100%;
					}
				}
			}
		}
	}
}

#wrapper.pt_order-confirmation {
	#main .order-confirmation-details table.order-shipment-table {
		tr td.line-item-details .product-list-item div.attribute span {
			text-transform: none;
		}
		.gc-to-email .value {
			text-transform: none;
		}
		.order-shipment-details {
			.egc-to-email {
				text-transform: none;
			}
		}
	}
}

#wrapper.pt_order-confirmation footer .footer-container .footer-bottom .banner-security,
#wrapper.pt_checkout footer .footer-container .footer-bottom .banner-security {
	float: left;
    margin: 25px 0 57px;
    width: 100%;
    text-align: center;
	@include respond(largeUp) {
		float: none;
		max-width: 1016px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		padding-right: 28px;
		padding-left: 24px;
		box-sizing: border-box;
		span {
			width: initial;
		}
	}
}
