.product-family-tabs-section {
    margin-top: 90px;
    margin-bottom: 20px;
    .product-family-tabs-section-header {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
        margin-bottom: 42px;
        .product-family-tabs-section-heading {
            color: $black;
            font-size: 30px;
            line-height: 30px;
            font-weight: 700;
            letter-spacing: 0;
            text-align: center;
            text-transform: none;
            @include respond(largeUp) {
                font-size: 30px;
                line-height: 42px;
            }
        }
        .product-family-tabs-section-subheading {
            color: $black;
            font-size: 14px;
            letter-spacing: 0.42px;
            line-height: 20px;
            margin-top: 24px;
            text-align: center;
        }
    }
    .product-family-tabs-header {
        border-top: 1px solid $darkGrey;
        border-bottom: 1px solid $darkGrey;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 55px;
        @include respond(largeUp) {
            margin-bottom: 60px;
        }
        .product-family-tabs-tab-label {
            background-color: $white;
            border-bottom: 5px solid $white;
            color: $black;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.48px;
            line-height: 22px;
            text-align: center;
            height: 64px;
            box-sizing: border-box;
            min-width: 132px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            cursor: pointer;
            transition: backgroung-color 0.35s, color 0.35s, border-color 0.35s;
            @media screen and (max-width: 480px) {
                min-width: 106px;
                flex-grow: 1;
            }
            &:hover {
                color: $color-secondary;
                background-color: $color-accent;
                border-bottom: 5px solid $color-accent;
            }
            &.active {
                background-color: $color-accent;
                border-bottom: 5px solid $color-secondary;
                color: $color-secondary;
            }
        }
    }
    .product-family-tabs-tab-content {
        display: none;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        max-width: 1152px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 19px;
        padding-right: 19px;
        @include respond(largeUp) {
            padding-left: 14px;
            padding-right: 14px;
        }
        &.active {
            display: flex;
        }
        .product-family-tabs-tab-item {
            box-sizing: border-box;
            padding: 0 5px 33px;
            width: 50%;
            @include respond(largeUp) {
                padding: 0 10px 46px;
                width: 25%;
            }
            .product-tile {
                height: 100%;
            }
            .thumb-link {
                color: $black;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                &:hover {
                    color: $black;
                }
            }
            .product-image {
                background-color: $color-accent;
                padding-top: 34px;
                padding-bottom: 6.5%;
                position: relative;
                @include respond(largeUp) {
                    padding-top: 40px;
                    padding-bottom: 10.5%;
                }
                .product-badge {
                    height: 9px;
                    padding: 0;
                    position: absolute;
                    top: 18px;
                    left: 50%;
                    @include transform(translateX(-50%));
                    z-index: 1;
                    @include respond(largeUp) {
                        top: 28px;
                        height: 14px;
                    }
                }
                .product-model-type {
                    height: 100%;
                    display: inline-block;
                    width: 100%;
                    max-width: 100%;
                }
                .product-image-wrapper {
                    position: relative;
                    height: 0;
                    width: 100%;
                    padding-bottom: 100%;
                }
                .product-img {
                    max-width: 100%;
                    height: auto;
                    line-height: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                }
            }
            .product-tile-name {
                color: $black;
                font-size: 16px;
                line-height: 20px;
                margin-top: 6px;
                margin-bottom: 6px;
                font-weight: 600;
                text-align: left;
                @include respond(largeUp) {
                    margin-top: 10px;
                }
            }
            .product-tile-description {
                color: $black;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
            }
        }
    }
}
