
nav.mobile-overlay {
	display: none;
	width: 100%;
	background-color: #fff;
	height: 0;
	position: absolute;
	top: 70px;
	z-index: 100
}

nav.mobile-overlay.is-open {
	display: flex
}

nav.mobile-overlay.is-fixed {
	position: fixed
}

nav.mobile-overlay .scroll-container {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll
}

nav.mobile-overlay ul {
	margin: 0 auto 200px;
	padding: 0;
	list-style-type: none;
	width: 70%
}

nav.mobile-overlay ul li {
	width: 100%;
	text-align: left;
	margin: 0 auto;
	padding: 10px 0;
	border-bottom: 1px solid #f2f2f2
}

nav.mobile-overlay a {
	display: inline-block;
	color: #000;
	text-transform: uppercase;
	text-decoration: none;
	border: 0;
	line-height: 1.15;
	position: relative;

	.beta {
		position: absolute;
		color: $red;
		font-size: 8px;
		transform: translate(-5px, -5px);
		text-transform: uppercase;
	}
}
