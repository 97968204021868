.tabbed-recommendations {
    display: none;
    clear: both;
    .tabbed-hdr {
        font-family: $gotham-cond;
        font-size: 48px;
        text-align: center;
    }

    .tabs-wrapper {
        position: relative;
        .config-master-tabs {
            max-width: 417px;
            flex-flow: row;
			justify-content: left;

            li {
                &:first-child {
                    @include respond(largeUp) {
                        margin-left: 15px;
                    }
                }
            }
            margin-left: 0;
            padding-top: 0;
            padding-left: 0;
            @include respond(small) {
                margin-right: 0;
            }
            @include respond(largeUp) {
                padding-left: 2%;
            }
            > .config-tab {
                padding: 15px 0 10px;
                width: 139px;
                flex: none;
                @include respond(small) {
                    flex: auto;
                    width: auto;
                }
            }
            .tab-ttexclusives {
                padding-left: 10px;

                span {
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        position: relative;
                        left: -10px;
                        top: 3px;
                        background: $trans url('../images/icons/tt_exclusive_icon.svg') no-repeat center;
                        background-size: 19px;
                    }
                }
            }
            .nav-tab-active {
                background-color: #f6f6f6;
                color: $color-secondary;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    background-color: $color-secondary;
                }
            }

			.tab-label {
				display: block;
				padding: 0;
			}
        }
    }
    .tabs-toggle,
    .tabs-view {
        display: none;
        &.active {
            display: block;
        }
    }
    .tabs-toggle {
        .tabbed-recommender {
            padding-top: 35px;
            @include respond(small) {
                margin-bottom: 45px !important;
            }
        }
    }
    .tabs-view {
		border: none;
		text-decoration: underline;
		font-size: 14px;

		@include respond(largeUp) {
            position: absolute;
            right: 3%;
            top: 20px;
        }
        &::after {
            content: ">";
            position: absolute;
            top: unset;
            right: unset;
            margin-left: 5px;
            text-decoration: none;
        }
    }

    .tabs-wrapper {
        @include respond(small) {
            .tabs-view {
                display: none !important;
            }
        }
    }

    .view-all-mobile {
        text-align: center;
        .tabs-view {
            @include respond(largeUp) {
                display: none !important;
            }
        }
    }


    .tiles-container {
        .slick-list {
            width: 100vw;
            padding: 0 2% !important;
            @include respond(small) {
                padding: 0 24.75% 0 15px !important;
            }
        }
        &.tab-slider-small {
            ul.slick-dots {
                @include respond(largeUp) {
                    display: none !important;
                }
            }
            .slick-list .slick-track {
                margin: 0 !important;
            }
        }
    }
    .product-tile {
        max-height: none;
        .product-name {
            padding-right: 60px;
        }
        .product-pricing {
            @include respond(small) {
                position: absolute !important;
                top: 0 !important;
                right: 0 !important;
            }
        }
    }
    .slick-prev:before {
        content: '';
    }
    .slick-dots {
        position: relative;
        width: 37%;
        text-align: center;
        padding: 0;
        height: 5px;
        background: #f6f6f6;
        margin: auto;
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        @include respond(small) {
            margin-top: 20px;
        }
        li {
            flex-grow: 1;
            margin: 0;
            padding: 0;
            button {
                border: none !important;
                width: 100% !important;
                border-radius: 10px !important;
                background-color: #f6f6f6 !important;
                height: 5px !important;
            }
            &.slick-active button {
                background-color: $red !important;
            }
        }
    }
    .slick-arrow {
        &.slick-next {
            background: $white url(../images/icons/arrow-right-red.svg) center no-repeat !important;
            background-size: 24px 24px !important;
            right: calc(5% - 30px) !important;
        }
        &.slick-prev {
            background: $white url(../images/icons/arrow-left-red.svg) center no-repeat !important;
            background-size: 24px 24px !important;
            left: calc(5% - 30px) !important;
        }
    }
    @include respond(small) {
        .slick-arrow {
            &.slick-next {
                right: 0 !important;
            }
            &.slick-prev {
                left: 0 !important;
            }
        }
    }
}

body.enable-clp-recommender {
    .tabbed-recommendations {
        display: block;
    }
}

.plp-noproducts-wrapper,
.homepage-tabbed-recomender {
	.tabs-wrapper {
		.config-master-tabs {
			@include respond(largeUp) {
				padding-left: 34px;
			}
            @media screen and (min-width: 1024px) {
                padding-left: 36px;
            }
            @media screen and (min-width: 1025px) {
				padding-left: 75px;
			}

			li {
                &.config-tab {
                    min-width: 130px;
                    padding: 18px 0 15px;
                    @media screen and (max-width: 389px) {
                        min-width: initial;
                    }
                    @include respond(largeUp) {
                        min-width: 148px;
                    }
                    span {
                        font-size: 16px;
                    }
                    &.nav-tab-active {
                        span {
                            color: $color-secondary;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }
            }

		}
	}

	.tabbed-recommendations {
		.tabbed-recommendations-1x5 {
			width: 100%;
			.tiles-container {
				.slick-list {
					width: calc(100% + 10px);
					margin: 0 0 0 -10px;
					padding: 0 47.75% 0 0 !important;
					@include respond(largeUp) {
						padding: 0 34px 30px 14px !important;
						margin: 0;
						width: 100%;
					}
                    @media screen and (min-width: 1024px) {
                        padding: 0 36px 10px 16px !important;
                    }
                    @media screen and (min-width: 1025px) {
						padding: 0 75px 10px 55px !important;
						margin: 0;
						width: 100%;
					}

					.grid-tile {
						margin: 0;
						padding: 0 0 0 20px;
                        .product-image {
                            .thumb-link {
                                padding-bottom: 20.75%;
                                @include respond(largeUp) {
                                    padding-bottom: 4.2%;
                                }
                                @media screen and (min-width: 1024px) {
                                    padding-bottom: 7.1%;
                                }
                                @media screen and (min-width: 1025px) {
                                    padding-bottom: 14.6%;
                                }
                            }
                        }
					}
				}
			}
		}
	}
}
