///////////////////////
/////Order Confirmation
///////////////////////
#wrapper.pt_order,
#wrapper.pt_order-confirmation {
	#main {
        /////Order header
        .order-header,
        .order-content,
        .order-content li {
            display: flex;
            position: relative;
        }
        .order-content li {
            &:hover {
                .tooltiphover-content {
                    display: block;
                }
            }
        }
        .order-header {
            @include respond(small) {
                display: block;
            }
        }
        .order-tlt {
            flex: 1;
            align-self: end;
        }
        .order-tlt,
        .order-search {
            @include respond(small) {
                margin: 0 10px;
            }
        }
        .order-content-wrap {
            padding-bottom: 20px;
        }
        .order-cancel-error {
            font-size: 18px;
            font-weight: 700;
            color: $red;
        }
        .order-block-interface {
            @include respond(small) {
                padding-bottom: 15px;
            }
        }
        .contactus-asset {
            @media (min-width: 900px) {
                padding-top: 10px;
            }
            @media (max-width: 899px) {
                clear: both;
                .content-asset {
                    margin-top: -9px;
                }
                &.contact-od {
                    .content-asset {
                        margin-top: -30px;
                    }
                }
            }
            .contact h3 {
                @include respond(small) {
                    line-height: 1;
                }
            }
        }
        .order-content {
            position: relative;
            clear: both;
            @include respond(largeUp) {
                align-items: center;
                margin-bottom: 15px;
                .contact {
                    margin-bottom: 25px;
                }
            }
            @include respond(small) {
                &.cancel-content {
                    margin: -15px 0 58px;
                }
            }

            &.order-content {
                &.cancelbtn-off {
                    align-self: flex-end;
                }
                &.cancel-tlt {
                    @include respond(small) {
                        margin: -20px 0 -15px;
                    }
                }
            }
            &.order-det-cancel {
                align-self: end;
            }
            .order-details-cancel {
                position: absolute;
                left: 0;
                bottom: -47px;
                @include respond(largeUp) {
                    bottom: 7px;
                    right: 0;
                }
                .mob-override {
                    padding: 0.5em 2em
                }
            }
            li {
                margin-left: 25px;
                align-items: center;
                &:first-child {
                    margin-left: 0;
                }
                img {
                    height: 18px;
                    margin-right: -26px;

                    &.icon-msg {
                        height: 22px;
                    }
                }
                a {
                    padding-left: 30px;
                }
            }
        }
        .alert {
            color: $red;
        }
        .no-hits {
            padding: 0 15px 25px;
        }
        .contshop-link {
            text-decoration: underline;
            font-size: 14px;
            margin-left: 15px;
        }
        .order-search {
            @include respond(largeUp) {
                width: 300px;
                float: right;
                margin-top: 30px;
            }
            .row {
                margin: -13px 0 30px;
            }
            .legacy-box {
                position: relative;
                label {
                    padding-bottom: 4px;
                    letter-spacing: normal;
                    font-size: 16px;
                }
                .order-search-btn {
                    position: absolute;
                    top: 37px;
                    right: 11px;
                    background: transparent;
                    border: none;
                }
            }
        }

        /////Order list
        .number-hdr {
            background-color: #E3E3E3;
            margin-bottom: 2px;
            padding: 6px 10px 6px 15px;
            font-size: .813rem;
            .label {
                text-transform: uppercase;
            }
        }
        .order-image {
            margin-right: 22px;
            position: relative;
            @include respond(small) {
                margin-right: 13px;
                padding-bottom: 180px;
                img {
                    width: 100%;
                }
            }
            .lineitem-count {
                position: absolute;
                right: 7px;
                top: 50px;
            }
            @include respond(largeUp) {
                padding: 5px 0 5px 15px;
                padding-left: 42px;
            }
        }

        .order-image,
        .order-image img {
            @include respond(largeUp) {
                width: 200px;
            }
        }

        .order-image,
        .order-products {
            padding: 20px;
            @include respond(small) {
                float: left;
            }
        }

        .order-wrap {
            border-top: solid 1px #e8e8e8;
            display: flex;
            @include respond(largeUp) {
                width: 75%;
                margin: 0 20px;
            }
        }

        .order-wrapper {
            border: 1px solid #e6e6e6;
            border-top: none;
            margin-bottom: 20px;
            font-size: 14px;
            .label {
                font-weight: 700;
                a {
                    color: $black;
                }
            }
        }

        .order-products {
            color: $black;
            padding-bottom: 15px;
            @include respond(standard) {
                width: calc(100% - 222px);
                padding-top: 7%;
                .label {
                    line-height: 1.4;
                }
            }
            @include respond(extra-small) {
                max-width: 201px;
            }
            @include respond(0, 880px) {
                padding: 14% 0 15px 0;
            }

            .name,
            .pli-wrapper a {
                display: none;
            }

            .pli-wrapper {
                margin: 10px 0;
                &.pli-wrapper-og {
                    margin-top: -10px;
                }
            }

            .pli-wrapper-hide {
                display: none;
            }

            .line-item-attributes {
                line-height: normal;
                font-size: 12px;
                color: #4C4C4C;
                .label {
                    text-transform: capitalize;
                }
            }
            .order-total {
                font-weight: 700;
                line-height: normal;
                margin-top: 4px;
                font-size: 12px;
                color: #4C4C4C;
            }

            .view-less,
            .view-more {
                font-size: 14px;

                &.show {
                    display: inline-block !important;
                }
            }

            .club-section-display {
                .label,
                .value {
                    color: $black;
                    font-size: 14px;
                }

                .label {
                    font-weight: 700;
                }
            }
        }
        .order-products-list {
            p {
                margin: 0;
                line-height: 1.4;
                font-size: 0.813rem;
            }
        }
        .som-attributes-mobile {
            padding-bottom: 20px;
            div,
            span {
                line-height: 1.5;
            }
            .name {
                display: none;
            }
            .og-cart-container {
                display: none;
            }
        }
        .order-block-numbering {
            float: left;
        }
        .order-block-buttons {
            float: right;
            cursor: pointer;
            display: flex;
            margin-top: 10px;
            @include respond(largeUp) {
                padding-right: 2rem;
            }
            .order-block-right {
                &:before {
                    content: '';
                    display: block;
                    top: 50%;
                    left: calc(50% - 4px);
                    width: 13px;
                    height:13px;
                    border-top: 3px solid #000;
                    border-right: 3px solid #000;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    box-sizing: border-box;
                }
            }
            .order-block-left{
                &:before {
                    content: '';
                    display: block;
                    top: 50%;
                    left: calc(50% - 4px);
                    width: 13px;
                    height:13px;
                    border-top: 3px solid #000;
                    border-right: 3px solid #000;
                    transform: translateX(-50%) translateY(-50%) rotate(225deg);
                    box-sizing: border-box;
                    margin-right: 1rem;
                }
            }
        }
        .order-info {
            display: flex;
            align-items: center;
            @include respond(small) {
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 15px;
            }
            span {
                display: block;
                line-height: normal;
                &.label {
                    text-transform: uppercase;
                }
            }
            div {
                font-size: .813rem;
                @include respond(small) {
                    padding: 12px 0 12px 0;
                }
                &:first-child {
                    flex-basis: 45%;
                    @include respond(largeUp) {
                        padding-left: 15px;
                        flex-basis: calc(30% - 15px);
                    }

                }
                &:nth-child(2) {
                    @include respond(small) {
                        flex-basis: 25%;
                    }
                }
                &:nth-child(2),
                &:nth-child(3) {
                    @include respond(largeUp) {
                        flex-basis: 22%;
                    }
                }
                &:nth-child(3) {
                    @include respond(small) {
                        flex-basis: 30%;
                    }
                }
                &:nth-child(4) {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    @include respond(largeUp) {
                        padding: 10px 0px 10px 0px;
                    }
                    line-height: 1.4;
                    flex-basis: 28%;
                    @include respond(small) {
                        flex-direction: row;
                        flex-basis: 100%;
                    }
                }
            }

        }

        span.label {
            font-weight: 700;
            line-height: 1.4;
        }

        span.value {
            text-transform: capitalize;
        }

        .flex-1 {
            flex: 1;
        }
        .order-det-btn {
            font-size: 0.813rem;
            padding: 0.6em 3em;
            line-height: 1.2;
            white-space: nowrap;
            @include respond(small) {
                font-size: 0.875rem;
                margin: 0.5em auto;
            }
        }

        .details-box {
            @include respond(largeUp) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            @include respond(small) {
                margin-bottom: 30px;
            }
            .order-shipping {
                .order-shipment-address {
                    display:block;
                }
                .order-shipment-address ~ .order-shipment-address {
                    display: none;
                }

            }
            .flex-details {
                flex: 1;
                border: solid 1px #e6e6e6;
                padding: 20px 25px;
                @include respond(largeUp) {
                    margin-right: 20px;
                    .arrow-tog {
                        display: none;
                    }
                }
                @include respond(small) {
                    margin-bottom: 20px;
                    position: relative;
                    overflow: hidden;
                    max-height: 60px;
                    transition: all 0.15s ease-out;
                    .arrow-tog {
                        transition: all 0.15s ease-out;
                        transform: rotate(0deg);
                        position: absolute;
                        right: 20px;
                        top: 23px;
                        width: 17px;
                        height: 10px;
                    }
                    &.opened {
                        max-height: 800px;
                        transition: all 0.25s ease-in;
                        .arrow-tog {
                            transition: all 0.25s ease-in;
                            transform: rotate(180deg);
                        }
                    }
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .order-title {
                    margin-bottom: 25px;
                }
                .payment-summary {
                    margin-top: 20px;
                }
            }
            .order-payment-summary {
                background-color: #f7f7f7;
                .total-rows {
                    display: flex;
                    margin: 5px 0;
                    .free-shipping-color {
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    .label {
                        flex: 1;
                    }
                    .value {
                        text-align: right;
                    }
                }
                .grand-total {
                    border-top: solid 1px #e6e6e6;
                    padding: 15px 0;
                    font-size: 14px;
                    font-weight: 700;
                    margin-top: 15px;
                }
                .order-totals-table {
                    tr td {
                        &:first-child {
                            text-align: left;
                        }
                        &:nth-child(even) {
                            white-space: nowrap;
                        }
                    }
                }
            }
            .billing-address-details,
            .payment-details,
            .order-reg {
                div {
                    margin: 5px 0;
                }
            }

            ///cancel and summary
            .order-reg {
                font-size: 13px;
            }
            .order-subtlt,
            .order-title {
                font-weight: 700;
                color: $black;
            }
            .subtlt-margin {
                margin-top: 20px;
            }
            .order-title {
                font-size: 16px;
            }
        }
        .order-cancel {
            &.orderlevel {
                @include respond(largeUp) {
                    margin-top: -20px;
                }
            }
            .order-shipment-table {
                &.orderlevel {
                    td, th {
                        &:nth-child(2),
                        &:nth-child(3) {
                            display: none;
                        }
                    }
                    .order-cancel-actions {
                        @include respond(small) {
                            &.cancel-buttons {
                                margin-top: 0;
                            }
                        }
                    }
                }
                td.total-item-price {
                    width: 17%;
                }
            }
            .order-details-box {
                @include respond(small) {
                    display: none;
                }
            }
            .order-cancel-actions {
                display: flex;
                width: 100%;
                border: solid 1px #e8e8e8;
                padding: 0 20px;
                @include respond(largeUp) {
                    width: 380px;
                    float: right;
                }
                &.cancel-totals {
                    background-color: #f7f7f7;
                    margin-top: 25px;
                    h2 {
                        flex: 1;
                        font-size: 20px;
                        &:last-child {
                            text-align: right;
                        }
                    }
                    @include respond(small) {
                        display: none;
                    }
                }
                &.cancel-buttons {
                    padding: 0;
                    border: none;
                    margin: 30px 0 0;
                    a {
                        flex: 1;
                        border: none !important;
                    }
                    button {
                        margin-left: 25px;
                        flex: 1;
                    }
                    @include respond(largeUp) {
                        width: 420px;
                    }
                }
                &.item-cancel-btn {
                    @include respond(small) {
                        margin-top: 125px;
                    }
                }
                .contact-asset {
                    padding: 30px 10px 0;
                }
            }
        }
        .order-details-box {
            @include respond(largeUp) {
                margin-top: 15px;
                &.ordersum-box {
                    margin-top: 15px;
                }
            }
            .ordersom-info {
                position: relative;
                .order-date {
                    margin-bottom: 7px;
                    @include respond(small) {
                        width: 52%;
                    }
                }
                .content-detail-header {
                    .detail-header-h3 {
                        line-height: 1.5;
                        font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 8px;
                    }
                }
                margin-bottom: 20px;
                @include respond(largeUp) {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #E3DEDE;
                }
                .tracking-button {
                    position: absolute;
                    top: 19px;
                    right: 0;
                    @include respond(small) {
                        padding: 0.6em 2em;
                    }
                }
                .h2 {
                    margin-bottom: 8px;
                }
            }

            .order-shipment-table {
                @include respond(small) {
                    .line-item-quantity,
                    .total-item-price,
                    .order-item-price {
                        display: none;
                    }
                    tr {
                        border-top: solid 1px $clp-border;
                    }
                    .line-item-details {
                        padding-top: 20px;
                    }
                }
                .total-item-price,
                .hdr-totalprice {
                    text-align: right;
                    padding-right: 20px;
                }
            }
        }
        .order-details-box,
        .order-payment-summary {
            .label {
                text-transform: none;
            }
        }
        .order-confirmation-details {
            table.order-shipment-table {
                tr td.line-item-details {
                    @include respond(small) {
                        padding-top: 20px;
                    }
                }
                @include respond(largeUp) {
                    border: solid 1px $clp-border;
                }
            }
            &.order-conf-page {
                .details-box .flex-details {
                    max-height: none;
                }
            }
        }
        .contact {
            @include respond(0, 1024px) {
                margin: 30px 10px 10px;
            }
            .contact-tlt {
                line-height: 0.6;
                margin: 0 0 -9px;
                color: #4C4C4C;
                text-transform: uppercase;
                font-size: .688rem;
                font-weight: 700;
            }
            p,
            span,
            p a {
                display: inline-block;
                font-size: .688rem;
            }
            p {
                background: url(../images/icons/icon-phone.png) no-repeat left center;
                padding-left: 20px;
                padding-right: 5px;
                background-size: 18px;
                margin-bottom: 0;
                color: #000;
                line-height: 1.9;
            }
            span {
                color: #787878;
                line-height: 1.5;
            }
            em {
                display: block;
                font-style: normal;
                color: #787878;
                font-size: .688rem;
            }
        }
        .product-list-item {
            @include respond(small) {
                .line-item-attributes {
                    margin-bottom: 4px;
                }
                .sku {
                    margin-top: -5px;
                }
                .mobile-on {
                    div,
                    span {
                        line-height: 1.4;
                    }
                }
            }
            .item-actions {
                a {
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }
        .cancelbtn-false {
            @include respond(small) {
                margin-top: 15px;
            }
        }
        .cancelbtn-true {
            @include respond(small) {
                margin-top: -20px;
            }
        }
        .mobile-on{
            display: none;
            @include respond(small) {
                display: block;
            }
        }
        .order-cancel-items {
            .cancel-item-quantity {
                text-align: center !important;
                @include respond(largeUp) {
                    padding: 14px 0 !important;
                }
                select {
                    width: 105px;
                    @include respond(largeUp) {
                        margin: auto;
                    }
                }
                &.cancel-item-select {
                    @include respond(small) {
                        position: absolute;
                        bottom: -100px;
                        left: -20px;
                        select {
                            width: 143px;
                            position: absolute;
                            bottom: 0;
                            left: 26px;
                        }
                    }
                }
            }
            .total-cancel-price {
                text-align: right !important;
            }
            .cancel-item-price,
            .total-cancel-price {
                @include respond(small) {
                    display: none;
                }
            }
            .tbl-cancel {
                .line-item-details {
                    @include respond(small) {
                        flex-wrap: wrap;
                        .item-image,
                        .product-list-item {
                            flex: 1;
                        }
                        .item-actions {
                            flex: 0 0 100%;
                            padding: 0 0 20px 25px;
                            &.mobile-select {
                                @include respond(largeUp) {
                                    padding-bottom: 68px;
                                }
                                @include respond(small) {
                                    position: absolute;
                                    bottom: -70px;
                                    left: -19px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .total-item-price {
            .label {
                color: #4C4C4C;
                font-weight: 700;
            }
        }
	}
}

