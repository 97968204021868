//------------------------------------
//  MIXINS
//------------------------------------

// A useful mixin to output a list of CSS styles passed in as a map
//
// Example:
//
//	//set a map variable
//	$primary-nav: (
//		padding-top: .2em,
//		margin-top: .2em,
//		line-height: 1.3,
//	);
//
//	//now output with the mixin
//	@include output-styles($primary-nav);
//
// Compiles to:
//
//	//Outputted CSS:
//	.primary-nav {
//		padding-top: .2em;
//		margin-top: .2em;
//		line-height: 1.3;
//	}
//
$breakpoint-two-swatch: 1000px;
@mixin output-styles($map) {
    @each $property, $value in $map {
        #{$property}: $value;
    }
}

// Generate a media query based on the given option
// Possible options:
//      print - styles only apply to printing
//      iphone - between 320px and 374px
//      extra-small - below 480px
//      extraSmallUp - 480px and above
//      small - below 768px
//      large - between 768px and 1199px
//      largeUp - 768px and above
//      four-two - 1000x and below
//      not-standard - below 1200px
//      standard - 1200px and above
//      [min-width] - number value (with units) is applied as the min-width for a custom media query
//      [min-width], [max-width] - values (with units) are applied as min-width & max-width for a custom mediaquery

@mixin respond($media, $max: null) {
	@if $media == 'iphone' { //iphone specific (fluid)
		@media only screen and (min-width: $iphone5) and (max-width: $iphone6 - 1 ){ @content; }
	}
    @if $media == 'extra-small' { //480 on down (fluid)
		@media only screen and (max-width: $extra-small-breakpoint - 1 ) { @content; }
	}
    @if $media == 'extraSmallUp' { //440+ (this matches Titleist.com's mobile breakpoint)
        @media only screen and (min-width: $extra-small-breakpoint) { @content; }
    }
    @if $media == 'small' { //767 on down (fluid)
		@media only screen and (max-width: $small-breakpoint - 1 ) { @content; }
	}
	@else if $media == 'large' { //768 to 1199 (fixed to 768)
		@media only screen and (min-width: $small-breakpoint) and (max-width: $standard-breakpoint - 1 ){ @content; }
	}
    @else if $media == 'extraLarge' { //1200 to 1384 (fixed to 1200)
        @media only screen and (min-width: $standard-breakpoint) and (max-width: $large-breakpoint - 1 ){ @content; }
    }
	@else if $media == 'largeUp' { //768+ (this is mostly on the homepage)
		@media only screen and (min-width: $small-breakpoint) { @content; }
    }
    @else if $media == "four-two" {
        @media only screen and (max-width: $breakpoint-two-swatch - 1) {
            @content;
        }
    }
	@else if $media == 'not-standard' { // less than 1200
		@media only screen and (max-width: $standard-breakpoint - 1 ) { @content; }
	}
    @else if $media == 'standard' { //1200+ (fixed to 1200)
		@media only screen and (min-width: $standard-breakpoint) { @content; }
	}
	@else if $media == print {
		@media only print{ @content; }
	}
    @else if type-of($media) == number and $max == null {
        @media only screen and (min-width: $media) {
            @content;
        }
    }
    @else if type-of($media) == number and type-of($max) == number {
        @media only screen and (min-width: $media) and (max-width: $max - 1) {
            @content;
        }
    }
}

// inline mixin for visually-hidden elements, when they only need these styles per specific contexts, like viewport.
@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// can extend directly in SCSS
%visually-hidden {
    @include visually-hidden;
}

// can apply directly in ISML as a class
.visually-hidden {
    @include visually-hidden;
}

// can apply directly in SCSS nested within another mixin if nec, such as respond()
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// can extend directly in SCSS
%clearfix {
    @include clearfix;
}

// can apply directly in ISML as a class
.clearfix {
    @extend %clearfix;
}

//menu extension
%menu {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

// can apply directly in SCSS nested within another mixin, like respond()
@mixin menu {
    @extend %menu;
}

// can apply directly in ISML as a class
.menu {
    @extend %menu;
}

// Full-Bleed (using VW's)
//---------------------------------------

// can apply directly in SCSS nested within another mixin, like respond()
@mixin full-bleed-width {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc((100vw - 100%)/-2);
    // just in case
    @media only screen and (max-width: $small-breakpoint - 1) {
        width: 100%;
        margin-left: 0;
    }
}
@mixin full-bleed {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc((100vw - 100%)/-2);
    // just in case
}

// can extend directly in SCSS
%full-bleed-width {
    @include full-bleed-width;
}

// can apply directly in ISML as a class
.full-bleed-width {
    @include full-bleed-width;
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    }
    @else if $orientation == horizontal {
        // horizontal
        background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
    @else {
        // radial
        background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
    }
}

// Font Sizer
// ex: @include font-size(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-size(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-size($size, $breakpoint: "") {
    @if $breakpoint == "" {
        font-size: $size;
        font-size: calculateRem($size);
    }
    @else {
        // Get a font size in VWs that will match the given pixel font-size in the given viewport.
        font-size: $size;
        font-size: calculateVw($size, $breakpoint);
    }
}

// Line Height Sizer
// ex:  @include line-height(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include line-height(14px, $medium-breakpoint);  //sets a scaling line-height value in vw's with a fixed fallback in pxs

@mixin line-height($size, $breakpoint: "") {
    @if $breakpoint == "" {
        line-height: $size;
        line-height: calculateRem($size);
    }
    @else {
        // Get a font size in VWs that will match the given pixel line-height in the given viewport.
        line-height: $size;
        line-height: calculateVw($size, $breakpoint);
    }
}

@mixin transform($transformation){
	transform: $transformation; /* W3C */
	-webkit-transform: $transformation; /* Safari and Chrome */
	-moz-transform: $transformation; /* Firefox */
	-ms-transform: $transformation; /* IE 9 */
	-o-transform: $transformation; /* Opera */
}

@mixin transition($transition) {
	transition: $transition;
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
}

@mixin borderRadius($value){
	-webkit-border-radius: $value;
	-moz-border-radius: $value;
	border-radius: $value;
}
@mixin borderbox{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
@mixin opacity($opacity) {
    $opacity-ie: $opacity * 100;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity-ie + ")";
    /* IE 5-7 */
    filter: alpha(opacity = $opacity-ie);
    /* Good browsers */
    opacity: $opacity;
}
@mixin background-image($name, $size:false, $position:false){
	background: url(../images/svg-icons/#{$name}.svg) no-repeat;
	@if($size){
		background-size: $size;
	}
	@if($position){
		background-position: $position;
	}
	.no-svg &{
		background-image: url(../images/#{$name}.png});
	}
}
/*
	usage of mixin above
	@include background-image('name', 31px 31px, left center);
	.no-svg{
		background: url(../images/name.png) no-repeat left center;
	}
*/
@mixin svg-fill($name, $color, $size:false, $position:false){
	background: none;
	-webkit-mask: url(../images/svg-icons/#{$name}.svg) no-repeat;
	mask: url(../images/svg-icons/#{$name}.svg) no-repeat;
	background-color: $color;
	@if($size){
		-webkit-mask-size: $size;
		mask-size: $size;
	}
	@if($position){
		-webkit-mask-position: $position;
		mask-position: $position;
	}
}

/////////////////////////////////
///Row Machine
/////////////////////////////////
@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $margin;
    margin-right: $margin;
  }
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: 0;
  }
}

// not sure why this is in _mixins.scss...
.relax {
	*zoom: 1;
	height:0;
	line-height:0;
	clear:both;
	&:before, &:after {
		display: table;
		content: "";
		line-height: 0;
		clear: both;
	}
}

@mixin countries-box {
  width: 100%;
  display: block;
  text-align: center;

  img {
    margin: 20px 0;
  }

  h2 {
    padding-bottom: 20px;
  }

  .location {
    display: inline-block;
    vertical-align: top;
    min-width: 200px;
    text-align: left;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        list-style: none;
        margin-bottom: 15px;

        &.title {
          font-family: $oswald;
          font-size: 16px;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          font-weight: bold;
          margin-bottom: 20px;
        }
        &.divider {
          display: block;
          height: 20px;
        }
        a {
          text-decoration: none;
          display: inline-block;
          color: $gray-dark;
          &:hover {
            text-decoration: underline;
          }
        }
        .locale {
          padding-left: 30px;
          margin-top: 2px;
          height: 14px;
          a {
            font-style: italic;
            & + a {
              margin-left: 2px;
              padding-left: 5px;
              border-left: 1px solid $gray-dark;
            }
          }
        }
        .country {
          color: $gray-dark;
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          height: 18px;
          line-height: 18px;
          background: url("../images/countries-icons.gif") left center no-repeat;
          padding-left: 30px;
          i {
            width: 18px;
            height: 15px;
            display: inline-block;
            position: relative;
            top: 2px;
            background-image: url(../images/cart-sprite.png);
            background-repeat: no-repeat;
          }
          &.austr {
            background-position: 0 1px;
          }
          &.canada {
            background-position: 0 -17px;
          }
          &.china {
            background-position: 0 -35px;
          }
          &.france {
            background-position: 0 -53px;
          }
          &.germany {
            background-position: 0 -71px;
          }
          &.japan {
            background-position: 0 -89px;
          }
          &.korea {
            background-position: 0 -107px;
          }
          &.malas {
            background-position: 0 -125px;
          }
          &.singapore {
            background-position: 0 -143px;
          }
          &.spain {
            background-position: 0 -161px;
          }
          &.sweden {
            background-position: 0 -179px;
          }
          &.thai {
            background-position: 0 -197px;
          }
          &.uk {
            background-position: 0 -215px;
          }
          &.usa {
            background-position: 0 -233px;
          }
          &.southafrica {
            background: url("../images/southafrica_off.gif") 0 center no-repeat;
          }
          &.latin {
            background: url("../images/latin.gif") 0 center no-repeat;
          }
          &.ireland {
            background: url("../images/ireland.gif") 0 center no-repeat;
          }
        }
      }
    }
    @include respond(small) {
      min-width: 165px;
      margin-bottom: 24px;
    }
  }
}
@mixin buttonstyle{
  background-color: transparent;
  border: 2px solid $black;
  line-height: 40px;
  padding: 0 30px;
  height: 44px;
  border-radius: 2em;
  color: $black;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  font-family: $titillium;
  text-align: center;
  transition: all $quickSpeed;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  outline: none;
  @include respond(large){
    font-size: 11px;
    padding: 0 20px;
    height: 34px;
    line-height: 30px;
  }
  @include respond(small){
    font-size: 11px;
    padding: 0 20px;
    height: 34px;
    line-height: 30px;
  }

  &.black, %blackButton{
    color: $black;
    border-color: $black;
    &:hover{
      color: $white;
      background-color: $black;
    }
  }
  &.white, %whiteButton{
    color: $white;
    border-color: $white;
    &:hover{
      color: $black;
      background-color: $white;
    }
  }

  &:hover {
    background-color: $black;
    color: $gray-white;
  }

  &.large {
    font-size: 1.35em;
    text-transform: uppercase;
  }
  &[disabled],
  &.disabled {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $gray-dark;
    opacity: 0.5;
  }
  a {
    color: $white;
    &:hover {
      color: $red;
    }
  }
}
