.parcel-lab {
    overflow-x: hidden;

    .header {
        padding: 40px 0;
    }

    .primary-logo {
        text-align: center;

        img {
            margin: 0 auto;
            width: 214px;
            height: 61px;
        }
    }

    .footer__bottom__links {
        justify-content: center;
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @include respond(small) {
            padding: 0 16px !important;
        }

        .footer__bottom__links__item__link {
            position: relative;
            color: $red;
            font-size: 14px;
            padding: 0 15px;

            &::after {
                content: '|';
                position: absolute;
                display: block;
                top: 1px;
                right: -2px;
                color: $red;
            }
        }

        li {
            padding: 0;
        }

        li:last-child {
            .footer__bottom__links__item__link {
                &::after {
                    display: none;
                }
            }
        }
    }
}

#pl-returns-plugin {
    margin: 0 !important;
    padding: 0 16px 77px 16px !important;
    color: $mine-shaft !important;
    min-height: calc(100vh - 218px) !important;
    position: relative;

    #pwrd-by-pl {
        position: absolute !important;
        bottom: 12px !important;
        margin: 0 !important;
        padding: 0 !important;
        width: calc(100% - 32px) !important;
    }

    span,
    p {
        color: $mine-shaft !important;
    }

    * {
        font-family: $titleFontFamily !important;
        letter-spacing: 0 !important;
    }

    p {
        font-size: 16px !important;
        line-height: 22px !important;
        color: $black !important;
    }

    label {
        color: $black !important;
        font-size: 14px !important;
        line-height: 19px !important;
        font-weight: bold !important;
    }

    .pl-header-text {
        margin: 0 0 24px 0 !important;
        @media screen and (min-width: 768px) {
            margin: 0 0 32px 0 !important;
        }
    }

    input {
        border-radius: 2em !important;
        border: 1px solid $darkGrey !important;
        background: #fff !important;
        @include font-regular(14px);
        line-height: 1.15 !important;
        padding: 0.3em 2.5em 0.3em 1em !important;
        margin: 0.5em 0 !important;
        color: $primary-color !important;
        width: 100% !important;
        box-sizing: border-box !important;
        outline: none !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        height: 32px !important;

        &:focus {
            outline: none !important;
        }

        &::-ms-clear {
            display: none !important;
        }

        &::placeholder {
            opacity: 0 !important;
        }
    }

    button:not(.pl-dropdown-border),
    .pl-button {
        color: $white !important;
        background-color: $crimson !important;
        border-color: $crimson !important;
        font-size: rem(14px) ! important;
        font-weight: 400 !important;
        display: inline-block !important;
        position: relative !important;
        overflow: hidden !important;
        box-sizing: border-box !important;
        line-height: 1.2 !important;
        padding: 0.6em 3em !important;
        border-radius: 2rem !important;
        transform: translateZ(0) !important;
        -webkit-appearance: none !important;
        cursor: pointer !important;
        text-align: center !important;
        outline: none !important;
        border: none !important;
        text-decoration: none !important;
        opacity: 1 !important;

        &:before,
        &:after {
            content: ' ' !important;
            position: absolute !important;
            top: 50% !important;
        }

        &:before {
            transform: translate(calc(-100% - 4.5em), -50%) !important;
            left: 0 !important;
            width: 100% !important;
            height: 6em !important;
            transition: 250ms ease transform !important;
            background-color: $crimson2 !important;
            z-index: -1 !important;
        }

        &:after {
            left: -6em !important;
            width: 0 !important;
            height: 100% !important;
            border-top: 3em solid transparent !important;
            border-bottom: 3em solid transparent !important;
            border-left: 3em solid $crimson2 !important;
            box-sizing: border-box !important;
            z-index: -2 !important;
        }

        span {
            color: $white !important;
        }

        &.pl-button--loading {
            &:after {
                content: "" !important;
                border: 4px solid transparent !important;
                border-top-color: #fff !important;
                border-radius: 50% !important;
                width: 25px !important;
                height: 25px !important;
                margin: auto !important;
                animation: 1s infinite button-loading-spinner !important;
                position: absolute !important;
                top: 0 !important;
                bottom: 0 !important;
                left: 0 !important;
                right: 0 !important;
            }

            &:before {
                display: none !important;
            }
        }
    }

    .pl-button:not(.pl-button--loading) {
        &:after {
            transform: translateY(-50%) !important;
            transition: 250ms ease all !important;
        }

        &:hover {
            &:before {
                transform: translate(calc(-4em), -50%) !important;
            }

            &:after {
                left: calc(100% - 4em) !important;
            }
        }
    }

    button.pl-dropdown-border {
        border-radius: 2em !important;
        border: 1px solid $darkGrey !important;
        background-color: $white !important;
        background-position: right 8px center !important;
        background-size: 24px 24px !important;
        background-repeat: no-repeat !important;
        background-image: url(../images/arrow-drop-down.svg) !important;
        @include font-regular(14px);
        line-height: 1.15 !important;
        padding: 0.5em 2.5em 0.5em 1em !important;
        margin: 0.5em 0 !important;
        color: $primary-color !important;
        width: 100% !important;
        box-sizing: border-box !important;
        outline: none !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
    }

    .pl-dropdown-border--error {
        background-color: $trans !important;

        button.pl-dropdown-border {
            border-color: $crimson !important;
        }
    }

    .pl-text--error {
        color: $crimson !important;
    }

    .pl-input-wrapper--error {
        label {
            color: $crimson !important;
        }

        input {
            border-color: $crimson !important;
        }
    }

    .pl-error-message {
        border-color: $crimson !important;
    }

    .pl-icon-clear {
        display: none !important;
    }

    h1 {
        font-weight: 600 !important;
        font-size: 28px !important;
        line-height: 38px !important;
        margin: 0 0 8px 0 !important;
        color: $black !important;
        @media screen and (min-width: 768px) {
            font-size: 32px !important;
            line-height: 43px !important;
        }
        @media screen and (max-width: 767px) {
            text-align: center !important;
        }
    }

    .pl-page-signin {
        form {
            max-width: 548px !important;
            padding: 40px !important;
            border: 1px solid $darkGrey !important;
        }

        .pl-space-top--double {
            margin-top: 0 !important;
        }

        .pl-space-top {
            margin: 0 0 19px 0 !important;

            &.pl-text-center {
                margin: 0 !important;
            }
        }

        .pl-signin-button {
            width: auto !important;
            margin-top: 13px !important;
        }

        .pl-input-message--error {
            display: none !important;
        }

        .pl-input-wrapper--error {
            .pl-input-message--error {
                display: block !important;
            }
        }

        .pl-input {
            margin: 9px 0 0 0 !important;
        }

        .pl-split-return-selection {
            .pl-split-return-selection__heading {
                text-align: center !important;
                margin: 0 0 24px 0 !important;

                div {
                    &:first-of-type {
                        margin-bottom: 21px !important;
                    }
                }
            }

            .pl-radio {
                .pl-radio-text {
                    display: none !important;
                }
            }

            .pl-split-return-ref {
                display: flex !important;

                .pl-space-top--half,
                .pl-split-return-ref__item_list {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
            }

            .pl-radio-wrapper {
                display: flex !important;
                padding-left: 16px !important;
                padding-right: 8px !important;
                @media screen and (max-width: 450px) {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                .pl-radio .pl-radio-icon {
                    border-radius: 0 !important;
                    width: 25px !important;
                    height: 25px !important;
                }

            }

            .pl-space-top--half {
                @media screen and (max-width: 450px) {
                    padding-left: 7px !important;
                }
            }

            .pl-split-return-ref__image {
                border: 1px solid $darkGrey !important;
            }

            .pl-split-return-ref__description {
                padding: 0 !important;

                p {
                    margin: 0 0 5px 0!important;
                }

                .pl-split-return-ref__description__quantity {
                    font-weight: bold !important;
                }

                .pl-split-return-ref__description__price {
                    font-weight: normal !important;
                }
            }

            .pl-space-top--half {
                margin: 0 !important;
            }
        }
    }

    .pl-card {
        background: none !important;
        border: 1px solid $darkGrey !important;
        box-shadow: none !important;
    }

    .pl-checkbox input[type=checkbox]:checked + .pl-checkbox-icon,
    .pl-radio input[type=radio]:checked ~ .pl-checkbox-icon,
    .pl-radio input[type=radio]:checked ~ .pl-radio-icon {
        box-shadow: 0 0 0 15px #000 inset !important;
        border: 1px solid #DFDFDF !important;
        position: relative !important;
        border-radius: 0 !important;

        &::after {
            width: 14px !important;
            right: 1px !important;
            bottom: 9px !important;
            transform: rotate(-45deg) !important;
            content: "" !important;
            position: absolute !important;
            height: 3px !important;
            background: #fff !important;
            display: block !important;
        }

        &::before {
            content: "" !important;
            position: absolute !important;
            height: 3px !important;
            background: #fff !important;
            display: block !important;
            width: 8px !important;
            bottom: 7px !important;
            left: 4px !important;
            transform: rotate(45deg) !important;
        }
    }

    .pl-style-article-checkbox .pl-article-list .pl-card-article .pl-article-select-toggle-wrapper .pl-checkbox .pl-checkbox-icon,
    .pl-page-courier .pl-radio .pl-checkbox-icon,
    .pl-radio .pl-radio-icon {
        border-radius: 0 !important;
        border: 1px solid #DFDFDF !important;
        transition: none !important;

        &::after {
            transition: none !important;
        }
    }

    .pl-register-button,
    .pl-register-another {
        padding: 0 32px 32px 32px !important;

        .pl-missing-fields-error {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .pl-register-another {
        padding: 0 28px 32px 28px !important;
    }

    .pl-input-message--error {
        color: $crimson !important;
    }

    .pl-dropdown {
        &.isOpen {
            .pl-dropdown-menu {
                @include respond(small) {
                    top: 45px !important;
                }
            }
        }

        .selected {
            background: $white !important;
            color: $black !important;
        }
    }

    .pl-article-list-notreturnable .pl-card-article .pl-article-details {
        opacity: 0.7 !important;
    }

    .pl-toogle-subheading {
        a {
            color: $red !important;
            text-decoration: none !important;
            font-size: 16px !important;

            &:hover {
                color: $color-primary !important;
            }
        }
    }

    .pl-toggle-icon {
        background-image: none !important;
        padding: 0 !important;
        @media screen and (max-width: 767px) {
            text-align: center !important;
        }
    }

    .pl-toogle-title {
        h3 {
            font-weight: 600 !important;
            font-size: 28px !important;
            line-height: 38px !important;
            margin: 0 0 8px 0 !important;
            color: $black !important;
            @media screen and (min-width: 768px) {
                font-size: 32px !important;
                line-height: 43px !important;
            }
        }
    }

    .pl-page-return {
        max-width: 964px !important;
        margin: 0 !important;

        .pl-header-text {
            margin: 0 0 24px 0 !important;
            text-align: center !important;
            @media screen and (min-width: 768px) {
                margin: 0 0 32px 0 !important;
                text-align: left !important;
            }
        }

        p,
        .pl-space-top--half {
            margin: 0 !important;
        }

        .pl-article-img {
            border: 1px solid $darkGrey !important;
        }

        .pl-checkbox {
            padding: 0 20px 0 16px !important;
        }

        .pl-desc-term,
        .pl-desc-text {
            font-size: 16px !important;
            color: $black !important;
            line-height: 24px !important;
            opacity: 1 !important;
        }

        .pl-desc-term {
            flex: 0 !important;
            font-weight: bold !important;
        }

        .pl-desc-item {
            margin-bottom: 0 !important;
        }

        .pl-article-desc-list {
            padding: 0 0 0 20px !important;
        }

        .pl-customer-info {
            background: $lightGrey !important;
            padding: 20px 22px 25px 22px !important;
            border: 0 !important;

            .pl-card-section--center,
            .pl-register-button {
                padding: 0 !important;
            }
        }

        .pl-order-information {
            h3 {
                margin: 0 0 12px 0 !important;
                font-weight: bold !important;
                font-size: 24px !important;
                line-height: 24px !important;
            }

            span {
                color: $black !important;
                line-height: 24px !important;
            }
        }

        .pl-customer-info-selectors {
            margin: 0 !important;
        }

        .pl-customer-info-address-form {
            margin: 0 !important;

            .pl-customer-form {
                margin: 16px 0 !important;

                span {
                    color: $black !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                }

                .pl-space-top--half {
                    margin-top: 16px !important;
                    font-size: 14px !important;
                    overflow-wrap: break-word !important;
                }
            }

            h3 {
                margin: 0 0 8px 0 !important;
            }

            .pl-anchor {
                font-size: 14px !important;
                text-decoration: underline !important;
                color: $red !important;

                span {
                    font-size: 14px !important;
                    color: $red !important;
                }

                &:hover {
                    text-decoration: none !important;
                }
            }
        }

        .pl-columns {
            margin-top: 0 !important;
        }

        .pl-button--primary {
            margin-top: 26px !important;
        }

        .pl-toogle-subheading {
            padding: 0 !important;
            @media screen and (max-width: 767px) {
                text-align: center !important;
            }

            b {
                font-weight: normal !important;
            }
        }
    }

    .pl-modal {
        .pl-card {
            background: $white !important;
        }

        .pl-button--primary {
            margin-top: 0 !important;
        }
    }

    .pl-page-registered {
        max-width: 964px !important;
        margin: 0 !important;

        .pl-card {
            margin-top: 32px !important;
        }

        .pl-page-registered-header {
            ul {
                background: none !important;
                border: 1px solid $darkGrey !important;
                box-shadow: none !important;
                list-style: none;
                margin: 32px 0 0 0 !important;
                padding: 32px !important;

                li {
                    color: $black !important;
                    font-size: 16px !important;
                    text-align: left !important;
                }
            }

            p {
                margin: 0 !important;
                @media screen and (max-width: 767px) {
                    text-align: center !important;
                }
            }
        }

        .pl-courier-label-box-download {
            div {
                color: $black !important;
            }

            .six {
                b {
                    margin-bottom: 8px !important;
                    display: block !important;
                }

                div:not(.pl-label-download-button-wrapper) {
                    @media screen and (min-width: 768px) {
                        width: 200% !important;
                    }
                }
            }

            .pl-button-icon {
                display: none !important;
            }

            .pl-shopfinder-column {
                @media screen and (max-width: 767px) {
                    margin: 0 !important;
                }
            }

            .pl-space-top {
                margin: 0 !important;
            }

            .pl-columns {
                @media screen and (max-width: 767px) {
                    margin: 0 !important;
                }
            }

            .pl-button {
                margin-top: 30px !important;
                @media screen and (min-width: 768px) {
                    width: auto !important;
                    margin-top: 24px !important;
                }

                &.pl-shopfinder-button {
                    margin-top: 20px !important;
                    @media screen and (min-width: 768px) {
                        position: relative !important;
                        left: -14.9% !important;
                        margin-top: 24px !important;
                    }

                    .pl-courier-label {
                        position: relative !important;

                        &::after {
                            content: '' !important;
                            position: absolute !important;
                            top: 1px !important;
                            left: -20px !important;
                            width: 13px !important;
                            height: 14px !important;
                            background: url('../images/icons/finder-icon.svg') top left no-repeat !important;
                            background-size: contain !important;
                        }
                    }
                }
            }
        }

        .pl-packing-slip {
            .six {
                width: 100% !important;
                margin-left: 0 !important;
                color: $black !important;
            }

            .pl-button {
                @media screen and (min-width: 768px) {
                    width: auto !important;
                }
            }
        }

        .pl-toggle-section-header {
            margin: 0 !important;
            padding: 0 !important;
        }

        .pl-space-top--double {
            margin-top: 0 !important;
        }

        .pl-toggle-section {
            .pl-toogle-title {
                padding: 0 !important;
                margin: 24px 0 0 0 !important;
                @media screen and (max-width: 767px) {
                    text-align: center !important;
                    margin: 37px 0 0 0 !important;
                }

                .pl-toggle-icon {
                    margin: 0 !important;
                }
            }
        }

        .pl-customer-info {
            border: 0 !important;
            background: $lightGrey !important;
            padding: 20px 32px 32px 32px !important;
            margin-top: 0 !important;
            @media screen and (min-width: 768px) {
                margin-top: 99px !important;
            }

            h3 {
                margin: 0 0 12px 0 !important;
                font-weight: bold !important;
                font-size: 24px !important;
                line-height: 24px !important;
            }
        }

        .pl-order-information {
            div,
            span {
                color: $black !important;
                line-height: 24px !important;
            }
        }

        .pl-card-section--center,
        .pl-register-another {
            padding: 0 !important;
        }

        .pl-register-another {
            padding-top: 28px !important;
        }

        .pl-article-list-notreturnable .pl-card-article .pl-article-details {
            opacity: 1 !important;
            padding: 16px 32px !important;
            @media screen and (max-width: 767px) {
                padding: 16px !important;
            }

            .pl-article-img {
                border: 1px solid $darkGrey !important;
            }

            .pl-article-image {
                align-items: flex-start !important;
            }

            .pl-article-desc-list {
                padding: 0 0 0 20px !important;

                .pl-desc-item {
                    margin: 0 !important;
                    padding: 0 !important;
                }

                .pl-desc-term,
                .pl-desc-text {
                    opacity: 1 !important;
                    color: $black !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                }

                .pl-desc-term {
                    font-weight: bold !important;
                    flex: 0 !important;
                }
            }
        }

        .pl-page-registered-header {
            p {
                span {
                    color: $black !important;
                }
            }
        }

        .pl-register-another-button {
            @media screen and (min-width: 768px) {
                width: 101% !important;
            }
        }
    }

    .steps {
        background: none !important;
        border: 1px solid $darkGrey !important;
        box-shadow: none !important;
        padding: 32px !important;
        margin-top: 32px !important;

        p {
            color: $black !important;
            font-size: 16px !important;

            &:first-of-type {
                @media screen and (min-width: 768px) {
                    margin-bottom: 14px !important;
                }
            }


            span {
                font-size: 16px !important;
                color: $black !important;

                @media screen and (max-width: 767px) {
                    display: block !important;
                    text-align: left !important;
                    padding-bottom: 14px !important;
                }

                &:first-of-type {
                    @media screen and (min-width: 768px) {
                        padding-right: 52px !important;
                        position: relative;
                    }

                    &::after {
                        @media screen and (min-width: 768px) {
                            content: '' !important;
                            position: absolute !important;
                            top: 2px !important;
                            right: 13px !important;
                            width: 14px !important;
                            height: 20px !important;
                            background: url('../images/icons/arrow-right-red.svg') top left no-repeat !important;
                        }
                    }
                }
            }

            &:last-of-type {
                span {
                    &:last-of-type {
                        @media screen and (max-width: 767px) {
                            padding-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .instructions {
        span {
            color: $black !important;
        }
    }

    .pl-confirm-return-modal {
        .pl-text-center {
            text-align: left;
        }

        .pl-modal {
            max-width: 430px !important;
        }
    }

    .pl-modal {
        max-width: 548px !important;
        h3 {
            text-align: left !important;
            font-size: 23px !important;
            line-height: 33px !important;
        }

        .pl-card {
            padding: 40px !important;

            @media screen and (max-width: 767px) {
                padding: 40px 32px 45px 32px !important;
            }

            h3 {
                margin-bottom: 16px !important;
            }
        }

        .pl-modal-close {
            opacity: 1 !important;
            top: 40px !important;
            right: 40px !important;
        }

        .pl-space-top--double {
            margin-top: 0 !important;
        }

        .pl-space-bottom--half,
        .pl-space-bottom {
            margin: 0 !important;
        }

        label {
            color: $black !important;
            font-size: 14px !important;
            line-height: 19px !important;
            font-weight: bold !important;
            margin-bottom: 10px !important;

            @media screen and (max-width: 767px) {
                font-size: 13px !important;
                line-height: 18px !important;
            }
        }

        .pl-input {
            margin: 0 0 14px 0 !important;
            font-size: 14px !important;
        }

        .pl-row {
            display: flex !important;
            flex-wrap: wrap !important;

            .six {
                width: 100%;
                @media screen and (min-width: 768px) {
                    width: 46% !important;
                    padding-right: 16px !important;
                }
            }

            .two {
                padding-right: 16px !important;
                width: 27.5% !important;

                @media screen and (min-width: 768px) {
                    width: 22% !important;
                }
            }

            .four {
                width: 72% !important;
                @media screen and (min-width: 768px) {
                    width: 32% !important;
                }
            }
            select.pl-input {
                margin-right: 16px !important;
                border-radius: 24px !important;
                border: 1px solid $darkGrey !important;
                height: 32px !important;
                padding: 0 15px !important;
                background-size: 24px 24px !important;
                background-image: url(../images/arrow-drop-down.svg) !important;
                background-color: $lighterGrey !important;
                background-repeat: no-repeat !important;
                color: $buttonGrey !important;
            }
        }

        .pl-modal-buttons {
            padding-top: 14px !important;
            @media screen and (min-width: 768px) {
                padding-top: 27px !important;
            }

            .pl-row {
                .six {
                    width: 100% !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    @media screen and (min-width: 768px) {
                        width: auto !important;
                    }

                    .pl-button {
                        width: 100% !important;
                        @media screen and (min-width: 768px) {
                            width: 136px !important;
                        }

                        span {
                            font-size: 14px !important;
                        }
                    }

                    .pl-button:not(.pl-button--primary) {
                        background: $white !important;
                        border: 2px solid $color-secondary !important;
                        color: $color-secondary !important;
                        border-radius: 24px !important;
                        height: 33px !important;

                        span {
                            color: $color-secondary !important;
                            position: relative !important;
                            top: -2px !important;
                        }

                        &::after,
                        &::before {
                            display: none !important;
                        }

                        &:hover {
                            background: $color-secondary !important;
                            span {
                                color: $white !important;
                            }
                        }
                    }

                    &:first-of-type {
                        margin-bottom: 12px !important;
                        @media screen and (min-width: 768px) {
                            margin: 0 12px 0 0 !important;
                        }
                    }
                }
            }
        }
    }

    .pl-style-article-checkbox {
        .pl-article-options {
            .eight {
                margin-top: 27px !important;

                .pl-select-label {
                    display: none !important;
                }
            }

             > .pl-space-bottom--half {
                position: relative !important;
                width: 50% !important;
                top: 27px !important;

            }
        }
    }

    .pl-space-top--double.pl-additional-return-labels {
        margin-top: 32px !important;
        position: relative !important;

        .six {
            width: 100% !important;
            margin: 0 !important;

            .pl-button--primary {
                width: 268px !important;
                margin-top: 32px !important;

                @media screen and (max-width: 767px) {
                    width: 100% !important;
                    margin-top: 24px !important;
                }
            }
        }

        .pl-space-top {
            @media screen and (min-width: 768px) {
                position: absolute !important;
                bottom: 49px !important;
                left: 331px !important;
            }

            p {
                text-decoration: underline;
                text-align: center !important;
                color: $red !important;
                font-size: 14px !important;
                line-height: 19px !important;
                cursor: pointer !important;
                margin-bottom: 0 !important;

                @media screen and (min-width: 768px) {
                    margin: 0 !important;
                }

                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }
}

#pl-returns-plugin {
    --color-primary: #4C4C4C !important;
    --color-error: $crimson !important;
    --font-size: 16px !important;
    --font-color: #4C4C4C !important;
    --color-grey: #DFDFDF !important;
}

// requested in the comment of the ticket APLR-25
.parcellab-portal-wrapper .parcellab-portal-delivery-section {
    display: none !important;
}
