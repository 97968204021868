.pt_content {
    .innerWidth {
        @include media-breakpoint-down(md) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .breadcrumbs__wrapper.breadcrumbs--content-asset {
        margin-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }
}

#wrapper.pt_content #primary .content-asset h1.contact-us__title {
    color: $black;
}

.contact-us__subtitle {
    font-size: 16px;
    line-height: 1.7;
    color: $color-primary;
}

.contact-us__form {
    max-width: 490px;

    .parsley-errors-list {
        margin-top: -0.75em !important;
        margin-bottom: 0.5em !important;

        > li {
            padding-top: 6px;
            padding-bottom: 0;
            color: #e31837;
            font-size: 12px;
            line-height: normal;
        }
    }

    .form-row-button {
        margin-top: 15px;
        padding-top: 25px;
        text-align: right;
        border-top: 1px solid #e3dede;
    }

    .form-row__required {
        font-size: .75rem;
        line-height: 1.7;
        margin-top: -10px;
        margin-bottom: 0;
        text-align: left;
    }

    textarea.error,
    select.error {
        color: #4C4C4C;
    }

    .recaptcha-custom-error-message {
        color: #dd4b39;
        font-size: 12px;
        padding: 4px 0
    }

    .recaptcha-custom-error {
        border: 1px solid #dd4b39;
        padding: 5px
    }
}