.pt_club-details {
    #pdpMain {
        .product-detail {
            .pdpForm {
                position: relative;
                .product-add-to-cart {
                    padding: 0 0 2px;

                    @include media-breakpoint-down(sm) {
                        overflow: visible;
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 475px;
                        margin: 0 auto;
                        padding: 0 50px 2px;
                    }
                }
				&.club {
					.notify-me-link-wrapper {
						display: block;
					}
				}
            }
        }
        &.club-pdp-main {
            .loader {
                z-index: 5;
            }

			.disable-commerce {
				.product-top {
					border-bottom: 1px solid #e3dede;
    				padding-bottom: 9px;
					margin-right: -0.625rem;
					@include media-breakpoint-up(lg) {
						margin-right: 0;
					}
					.product-main-info {
						border: none;
						padding-bottom: 0;
					}
				}
			}

            .product-core-info {
                margin-top: 20px;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }

                .breadcrumb {
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
            }

            .mobile-breadcrumb {

                .p1-content-disable {
                    .breadcrumb {
                        margin-bottom: 20px;
                    }
                }

                .breadcrumb {
                    display: block;
                    text-align: center;
                    padding-bottom: 16px;
                    border-bottom: $base-border;
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-top: 16px;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }

                    .breadcrumb-element {
                        font-size: 11px;
                        font-weight: 600;
                        color: black;
                        text-transform: uppercase;
                        &::after {
                            color: $color-secondary;
                        }
                    }
                }
            }
        }

        .club-tab-label {
            &.active {
                font-weight: bold;
            }
        }
        .club-tab-container {
            margin: 0 0 23px 0;
            display: none;

            @include media-breakpoint-up(lg) {
                margin: 0 50px 44px;
            }

            &.review {
                margin-bottom: 44px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 48px;
                }

                &.review.utility,
                &.review.iron {
                    @include media-breakpoint-up(md) {
                        margin-bottom: 44px;
                    }
                }
            }

            &.club.utility,
            &.grip.iron {
                @include media-breakpoint-up(md) {
                    margin-bottom: 34px;
                }
            }

            &.active {
                display: block;
            }

            &.putter {
                .grip-brand,
                .grip-size {
                    display: none;
                }

                .grip-type .label-container .label.label-grip-type {
                    display: block;
                }
            }
        }
        .product-core-info {
            @include media-breakpoint-up(lg) {
                display: flex;
            }

            .product-col-1 {
                width: 100%;
                max-width: none;
                margin-left: 0;
                margin-top: 0;
                @include media-breakpoint-up(lg) {
                    width: 51%;
                    max-width: none;
                    flex: 1 1 auto;
                }
                @include custom-media-breakpoint-up(xl) {
                    width: 51%;
                }
            }
            .product-col-2 {
                width: 100%;
                @include media-breakpoint-up(lg) {
                    flex: 0 0 auto;
                    max-width: 475px;
                    width: 49%;
                    padding: 30px 0;
                    margin-right: 0;
                }
                @include custom-media-breakpoint-up(xl) {
                    max-width: 614px;
                    width: 49%;
                }
            }
            .breadcrumb {
                margin: 16px 0 0 20px;
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
                .breadcrumb-element {
                    font-size: 11px;
                    font-weight: 600;
                    color: black;
                    text-transform: uppercase;
                    &::after {
                        color: $color-secondary;
                    }
                }
            }
            .product-image-container-outer {
                position: relative;
            }
            .product-image-container {
                position: relative;

                .product-primary-image {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    text-align: center;
                    position: relative;
                    float: none;
                    .regular.slider {
                        margin-bottom: 3px;
                        background-color: $img-gray;
                        @include media-breakpoint-up(lg) {
                            max-height: calc(100vh - 80px);
                            margin-bottom: 7px;
                        }
                        @include media-breakpoint-up(xl) {
                            max-height: calc(100vh - 260px);
                        }
                        .slick-active {
                            &:focus {
                                outline: none;
                            }
                        }
                        .thumb {
                            a {
                                display: block;
                            }
                            img,
                            video {
                                display: block;
                                width: auto;
                                max-width: 100%;
                                margin: 0 auto;
                                @include media-breakpoint-up(lg) {
                                    max-height: calc(100vh - 80px);
                                }
                                @include media-breakpoint-up(xl) {
                                    max-height: calc(100vh - 260px);
                                }

                                &:focus {
                                    outline: none;
                                }
                            }

                            &.grip-img,
                            &.shaft-img {
                                .product-image {
                                    display: flex;
                                    height: 100%;
                                }

                                .primary-image {
                                    width: 100%;
                                    height: auto;
                                    margin: auto;
                                }
                            }
                        }
                    }
                    .slick-arrow {
                        width: 32px;
                        height: 32px;
                        top: 50%;
                        transform: translateY(-50%);
                        &.slick-prev {
                            background: url(../images/icons/arrow-left-black.svg) center no-repeat;
                            background-size: 24px 24px;
                        }
                        &.slick-next {
                            background: url(../images/icons/arrow-right-black.svg) center no-repeat;
                            background-size: 24px 24px;
                        }
                        &.slick-disabled {
                            display: none !important;
                        }
                    }
                }
                .product-thumbnails {
                    display: block;
                    width: auto;
                    padding: 0;
                    margin: 0;
                    max-width: 820px;
                    overflow: hidden;
                    @include media-breakpoint-up(lg) {
                        padding: 0 32px;
                        margin: 0 auto;
                        width: 100%;
                    }
                    .carousel {
                        height: auto;
                        position: relative;
                        overflow: visible;
                        margin: 0 -2px;
                        .slick-list {
                            padding-right: 40px;
                            overflow: hidden;
                            @include media-breakpoint-up(lg) {
                                padding-right: 0;
                            }
                        }
                    }
                    .thumb {
                        line-height: 0;
                        padding: 0 2px;
                        @include media-breakpoint-up(lg) {
                            padding: 0 3px;
                        }
                        &.slick-slide {
                            &.slick-current,
                            &.is-active {
                                .thumbnail-link {
                                    position: relative;
                                }
                                img {
                                    border: none;
                                }
                            }
                            &.slick-current {
                                .thumbnail-link {
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: 0;
                                        display: block;
                                        width: 100%;
                                        height: 4px;
                                    }
                                }
                            }
                            &.slick-current {
                                &::after {
                                    background-color: $color-secondary;
                                }
                            }
                            a {
                                display: inline-block;
                                position: relative;
                                width: 100%;
                                height: 65px;
                                background-color: $img-gray;
                                @include media-breakpoint-up(xl) {
                                    height: 118px;
                                }
                                img {
                                    height: 100%;
                                    width: auto;
                                    max-width: 100%;
                                    margin: 0 auto;
                                    padding: 0;
                                    &.video-thumb-icon {
                                        width: 12px;
                                        height: 14px;
                                        position: absolute;
                                        left: 14px;
                                        bottom: 14px;
                                    }
                                }
                            }

                            .thumbnail-link {
                                cursor: pointer;
                            }
                        }

                        &.grip-img,
                        &.shaft-img {
                            .thumbnail-link {
                                display: flex;
                            }

                            .productthumbnail {
                                width: 100%;
                                height: auto;
                                margin: auto;
                            }
                        }
                    }
                    .slick-arrow {
                        display: none !important;
                        background: url(../images/icons/arrow-down-black.svg) center no-repeat;
                        background-size: 24px 24px;
                        width: 32px;
                        height: 32px;
                        position: absolute;
                        @include media-breakpoint-up(lg) {
                            display: block !important;
                        }
                        &.slick-prev {
                            @include media-breakpoint-up(lg) {
                                left: -32px;
                            }

                        }
                        &.slick-next {
                            @include media-breakpoint-up(lg) {
                                right: -32px;
                            }
                        }
                        &.slick-disabled {
                            display: none !important;
                        }
                    }
                }
            }

            .desktop-product-info {
                @include media-breakpoint-up(lg) {
                    max-width: 475px;
                    margin: 0 auto;
                }
            }

            .clubs-tab-containers,
            .product-add-to-cart {
                margin: 0 20px;
                @include media-breakpoint-up(lg) {
                    margin: 0;
                }
            }
            .product-main-info {
                margin: 0 30px 18px;
                @include media-breakpoint-up(lg) {
                    margin: 0 50px 18px;
                }
            }
            .clubs-tab-containers {
                min-height: auto;
                @include media-breakpoint-up(lg) {
                    max-width: 475px;
                    margin: 0 auto;
                }
            }
            .product-name {
                margin: 8px 0;
                letter-spacing: normal;
            }
            .product-price {
                position: static;
                margin-bottom: 8px;
                span {
                    font-size: 16px;
                    font-weight: 500;
                    &.price-standard {
                        margin-right: 8px;
                    }
                }
            }
            .promotion {
                padding: 0;
                margin-bottom: 8px;
            }
            .clubs-tab-labels {
                padding: 0 20px;
                margin-bottom: 20px;
                border-top: $base-border;
                border-bottom: $base-border;

                @include media-breakpoint-up(lg) {
                    padding: 0;
                }

                .clubs-tab-labels-inner {
                    @include media-breakpoint-up(lg) {
                        max-width: 475px;
                        margin: 0 auto;
                        padding: 0 50px;
                    }

                    &.grip-completed {
                        .club-tab-label {
                            &.club.active + .shaft.fulfilled {
                                background: url(../images/icons/icon-checkmark-active.svg) 13px center no-repeat;
                                background-size: 10px 10px;
                                cursor: pointer;
                                pointer-events: auto;

                                &:hover {
                                    color: $color-secondary;
                                }
                            }
                        }
                    }

                    &.tab-inactive {
                        .club-tab-label {
                            &.active {
                                + .club-tab-label {
                                    color: $color-border;
                                }
                            }
                        }
                    }
                }

                .club-tab-label {
                    cursor: default;
                    float: left;
                    position: relative;
                    display: inline-block;
                    padding: 16px 10px 16px 28px;
                    margin-right: 0;
                    font-size: 14px;
                    font-weight: bold;
                    background: url(../images/icons/icon-checkmark-not-active.svg) 13px center no-repeat;
                    background-size: 10px 10px;
                    color: $color-border;

                    @include media-breakpoint-up(lg) {
                        padding-right: 14px;
                        padding-left: 32px;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    &.active {
                        background-color: $color-accent;
                        color: $color-secondary;

                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 4px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-color: $color-secondary;
                        }

                        + .club-tab-label {
                            color: $black;
                        }
                    }

                    &.fulfilled {
                        background: url(../images/icons/icon-checkmark-active.svg) 13px center no-repeat;
                        background-size: 10px 10px;
                        cursor: pointer;
                        color: $black;

                        &:hover {
                            background: $color-accent url(../images/icons/icon-checkmark-active.svg) 13px center no-repeat;
                            background-size: 10px 10px;
                            color: $color-secondary;
                        }
                    }

                    &.enabled {
                        cursor: pointer;
                    }

                    &.club.active + .shaft.fulfilled {
                        background: url(../images/icons/icon-checkmark-not-active.svg) 13px center no-repeat;
                        background-size: 10px 10px;
                        cursor: auto;
                        pointer-events: none;

                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
            .product-variations {
                float: none;

                @include media-breakpoint-down(md) {
                    max-width: 100vw;
                }

                .club-tab-container {
                    .pdp-my-designs {
                        padding-bottom: 10px;
                        .swatchanchor-wrapper {
                            padding-top: 3px;
                        }

                        &.manage-active .select-header {
                            .custom-designs-save {
                                display: block;
                            }

                            .custom-designs-manage {
                                display: none;
                            }
                        }

                        &.manage-active .delete-candidate-message {
                            display: block;
                            padding: 0 0 0 8px;

                        }

                        .select-header {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;

                            .custom-designs-manage,
                            .custom-designs-save {
                                color: $black;
                                font-weight: normal;
                                text-decoration: underline;
                                font-size: 14px;
                                margin-right: 8px;

                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            .custom-designs-save {
                                display: none;
                            }
                        }

                        #myDesignSwatches label {
                            background: #ececec;
                            cursor: pointer;

                            &.delete-candidate {
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    left: -1px;
                                    top: -1px;
                                    width: 68px;
                                    height: 68px;
                                    border: 1px solid #E31837;
                                    box-sizing: border-box;
                                }

                                &:before {
                                    background: url("../images/icons/red-cross.png") no-repeat;
                                    top: 4px;
                                    content: '';
                                    position: absolute;
                                    right: 4px;
                                    width: 12px;
                                    height: 12px;
                                    background-size: 12px;
                                }

                                &:hover {
                                    border-color: $trans;
                                }
                            }
                        }

                        .spacerbar {
                            background-color: #b5b5b5;
                            display: block;
                            height: 1px;
                            margin: 16px 0;
                            width: 100%;
                        }

                        .delete-candidate-message {
                            padding-top: 8px;
                            display: none;
                            bottom: 8px;
                            font-size: 12px;
                            color: #E31837;
                            position: absolute;
                        }

                        .custom-design-swatch {
                            display: flex;

                            img {
                                object-fit: contain;
                            }
                        }
                    }
                    .label-container {
                        display: flex;
                    }
                    .label {
                        display: block;
                        margin-bottom: 10px;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;

                        &.label-finish {
                            margin-bottom: 18px;
                        }


                        @media only screen and (min-width: 320px) and (max-width: 480px) {
                            font-size: 13px;
                            white-space: nowrap;
                        }

                        &.label-shaft-id,
                        &.label-shaft-flex,
                        &.label-grip-type {
                            display: none;
                        }
                    }
                    .inline-value {
                        font-size: 14px;
                        font-weight: normal;
                        margin-left: auto;
                        &.value-club-setcomp {
                            margin-right: 8px;
                        }
                    }
                    .radio-buttons-group {
                        display: block;
                        margin: 0 -8px;
                        &.orientation {
                            margin: 0 -10px;
                            .radio-button {
                                width: calc(50% - 20px);
                                margin: 0 10px 10px;
                            }
                        }
                        &.shaft-flex {
                            margin-bottom: 10px;
                        }
                        &.grip-assembly-option {
                            .radio-button {
                                width: calc((100% / 3) - 10px);
                            }
                        }
                        &.error {
                            &.orientation {
                                .radio-buttons-container {
                                    &::before {
                                        width: calc(100% - 16px);
                                    }
                                }
                            }
                            &.finish-select {
                                .radio-buttons-container {
                                    &::before {
                                        top: 2px;
                                    }
                                }
                            }
                            .radio-buttons-container {
                                position: relative;
                                &::before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    width: calc(100% - 6px);
                                    height: calc(100% - 6px);
                                    top: -2px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    border: 1px solid $error-red;
                                }
                            }
                        }
                        &.slider-range-group {
                            margin-left: 0;
                            margin-right: 0;
                            margin-top: 25px;

                            .label-club-setcomp {
                                margin-left: 0;

                                .tooltip {
                                    top: -1px;
                                }
                            }
                            .label-build {
                                margin-left: 5px;
                            }
                        }
                        &.club-setcomp.utility-attribute {
                            .radio-buttons-container {
                                display: flex;
                                flex-wrap: wrap;

                                .radio-button {
                                    float: none;

                                    @include media-breakpoint-down(sm) {
                                        width: calc(25% - 4px);
                                        margin-left: 2px;
                                        margin-right: 2px;
                                    }

                                    .radio-button-label {
                                        @include media-breakpoint-down(sm) {
                                            padding-left: 0;
                                            padding-right: 0;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                        &.grip-assembly-option.utility-attribute {
                            margin-bottom: -10px;
                        }
                        .label {
                            margin-left: 10px;
                        }
                        .radio-button {
                            width: calc(25% - 10px);
                            margin: 0 5px 10px;

                            @include media-breakpoint-down(sm) {
                                width: calc(25% - 6px);
                                margin: 0 3px 10px;
                            }

                            input[type=radio]:checked + .radio-button-label.oos-disabled {
                                color: #999;
                                background-color: #f0f0f0;
                                border: 1px solid #e2dede;
                                box-shadow: inset 0 0 0 1px #000000;
                            }

                            input[type=radio]:checked + .radio-button-label:not(.oos-disabled) {
                                opacity: 1;
                                color: $white;
                                box-shadow: none;
                                border-color: $black;
                                background-color: $black;
                            }

							input[type=checkbox] {
								position: absolute;
								left: -999em;
								font-size: 16px;
								&:checked + .radio-button-label {
									box-shadow: inset 0 0 0 2px $black;
								}
							}
							input[type=checkbox]:checked + .radio-button-label {
								color: $white;
								box-shadow: none;
								border-color: $black;
								background-color: $black;
							}
                            input[disabled]~ .radio-button-label {
                                opacity: 0.5;
                                background-color: #f0f0f0;

                                &:hover {
                                    border-color: $bon-jour;
                                    box-shadow: none;
                                }
                            }
                            .radio-button-label {
                                display: block;
                                padding: 12px 4px;
                                font-size: 14px;
                                font-weight: bold;
                                line-height: normal;
                                text-align: center;
                                border: $base-border;
                                border-radius: 2px;

                                &.oos-disabled {
                                    color: #999;
                                    background-color: #f0f0f0;
                                    border-color: #999;

                                    &:hover {
                                        box-shadow: inset 0 0 0 1px #000000;
                                    }
                                }

                                @include media-breakpoint-down(sm) {
                                    padding-left: 2px;
                                    padding-right: 2px;
                                }
                            }

                            &.swatchanchor {
                                cursor: pointer;
                                width: 68px;
                                height: 68px;
                                padding: 3px;
                                border: 1px solid $trans;
                                border-radius: 2px;
                                margin: 0 0 8px 8px;
                                background: $gray-white;


                                &.selected,
                                &:hover {
                                    border-color: $black;
                                }

                                &.not-selectable {
                                    opacity: 0.5;

                                    &:hover {
                                        border-color: $trans;
                                    }
                                }

                                &.oos-finish-swatch {
                                    img {
                                        opacity: .3;
                                    }

                                    &.selected,
                                    &:hover {
                                        border-color: $text-gray;
                                    }

                                    .tooltip-info {
                                        position: absolute;
                                        z-index: 1;
                                        top: -34px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        padding: 5px 10px;
                                        border-radius: 10px;
                                        background: $text-gray;
                                        color: $white;
                                        font-size: 10px;
                                        letter-spacing: 0.35px;
                                        white-space: nowrap;
                                        display: none;

                                        &::before {
                                            border: solid;
                                            border-color: $text-gray $trans;
                                            border-width: 4px 4px 0 4px;
                                            bottom: -4px;
                                            content: "";
                                            display: block;
                                            left: calc(50% - 4px);
                                            position: absolute;
                                            z-index: 1;
                                          }
                                    }

                                    &:hover {
                                        .tooltip-info {
                                            display: block;
                                        }
                                    }
                                }

                                &:first-of-type {
                                    &.oos-finish-swatch {
                                        .swatchanchor {
                                            .tooltip-info {
                                                left: 0;
                                                transform: none;

                                                &::before {
                                                    left: 31px;
                                                }
                                            }
                                        }
                                    }
                                }

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }

                        #design-your-own-swatch {
                            width: auto;
                            min-width: 66px;
                            position: relative;
                            padding: 0;


                            .design-your-own-icon {
                                width: 66px;
                                height: 66px;
                                vertical-align: middle;
                                border-radius: 0;
                                &.german,
                                &.french {
                                    width: 98px;
                                }
                            }

                            .design-your-own-label {
                                position: absolute;
                                font-size: 10px;
                                font-weight: 700;
                                letter-spacing: -0.05px;
                                color: $black;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                text-align: center;
                                text-transform: capitalize;
                                padding: 0 5px;
                                transform: translateY(-50%);
                            }
                        }

                        .swatchanchor-wrapper {
                            margin-bottom: 14px;
                        }

                        .slider-range-wrapper {
                            visibility: hidden;
                            margin-left: 8px;
                            margin-right: 8px;

                            &.active {
                                visibility: visible;
                            }
                        }
                    }
                    .select-container {
                        margin-bottom: 15px;
                        &.two-cols {
                            &.club-swing-weight-adjustment {
                                width: calc(50% - 10px);
                                float: left;
                                .label-container {
                                    width: 150%;
                                    white-space: nowrap;
                                }
                            }
                            &.club-hosel-adjustment {
                                width: calc(50% - 10px);
                                float: right;
                            }
                        }
                        &.three-cols {
                            &.club-weight-cg-adjustment {
                                width: calc((100% / 3) - 5px);
                                float: right;
                            }
                            &.club-swing-weight-adjustment {
                                width: calc((100% / 3) - 5px);
                                float: left;
                                .label-container {
                                    width: 200%;
                                }
                            }
                            &.club-hosel-adjustment {
                                width: calc((100% / 3) - 5px);
                                margin-left: 8px;
                                float: left;
                            }
                        }

                        &.club-hosel-adjustment {
                            margin-top: 27px;
                            .label-container {
                                display: none;
                            }
                        }


                        &.shaft-length {
                            width: calc(50% - 10px);
                            float: left;
                            margin-bottom: 23px;

                            @include media-breakpoint-up(lg) {
                                margin-bottom: 44px;
                            }

                            &.full-width-container {
                                width: 100%;
                                float: none;
                            }
                        }

                        &.shaft-tipping {
                            width: calc(50% - 10px);
                            float: right;
                        }
                        &.error {
                            select {
                                border-color: $error-red;
                            }
                        }
                        &.shaft-id,
                        &.grip-type {
                            select {
                                display: none;
                            }

                            .preview__selected-value {
                                position: relative;
                                width: 100%;
                                height: auto;
                                min-height: 42px;
                                border: 1px solid #e8e8e8;
                                padding: 6px 45px 6px 12px;
                                text-align: left;
                                font-size: 14px;
                                color: #4C4C4C;

                                &.active {
                                    &::after {
                                        transform: rotate(180deg);
                                    }
                                }

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    display: block;
                                    order: 3;
                                    margin-left: auto;
                                    width: 42px;
                                    height: 100%;
                                    background: url('../images/arrow-drop-down.svg') no-repeat center;
                                    background-size: 24px 24px;
                                    transition: transform 0.3s ease;
                                }

                                .preview__variant__value__designation {
                                    display: inline;
                                    margin-top: 2px;
                                }
                            }

                            .preview__collection {
                                display: none;
                                overflow-y: auto;
                                max-height: 250px;
                                border: 1px solid #e8e8e8;
                                border-top: none;
                            }

                            .preview__variant {
                                display: block;
                                text-align: left;
                                padding: 5px 12px;
                                width: 100%;
                                font-size: 14px;
                                color: #4C4C4C;
                                background: white;
                                border-bottom: 1px solid #e8e8e8;

                                &:last-child {
                                    border-bottom: none;
                                }

                                &:hover,
                                &.selected {
                                    background: #f0f0f0;
                                }
                            }

                            .preview__variant__value__over-price {
                                color: $red;
                            }

                            .preview__variant__value__designation {
                                margin-top: 3px;
                                display: block;
                                font-size: 12px;
                                font-weight: 600;
                            }

                            .shaft-image-container {
                                margin-top: 5px;
                                margin-bottom: 0;
                                max-width: 100%;
                                border: none;
                            }
                        }
                    }
                    select {
                        position: relative;
                    }

                    .shaft-image-container {
                        border: $base-border;
                        margin-bottom: 15px;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .club-review-edits-container {
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 20px 15px;
                            line-height: normal;
                            &:nth-child(even) {
                                background-color: $color-table-grey;
                            }
                            .club-review-label {
                                width: 22%;
                                font-weight: 600;
                            }
                            .club-review-name {
                                width: 63%;
                            }
                            .club-review-state {
                                width: 15%;
                                font-size: 13px;
                                font-weight: 600;
                                cursor: pointer;
                                text-decoration: underline;
                                text-align: right;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    &.review {
                        .select-container-items {
                            display: flex;
                            align-items: flex-end;
                            margin-left: -3px;
                            margin-right: -3px;
                        }

                        .select-container {
                            flex: 1;

                            &.three-cols,
                            &.two-cols {
                                &.club-hosel-adjustment,
                                &.club-swing-weight-adjustment,
                                &.club-weight-cg-adjustment,
                                &.club-loft-adjustmen,
                                &.club-lie-adjustment,
                                &.putter-weight-adjustment {
                                    margin-top: 0;
                                    margin-left: 3px;
                                    margin-right: 3px;
                                    float: none;
                                    width: auto;
                                }
                            }

                            &.club-swing-weight-adjustment {
                                order: 1;
                            }

                            &.club-hosel-adjustment {
                                order: 50;
                            }

                            &.club-weight-cg-adjustment {
                                order: 100;
                            }
                        }
                    }

                    .club-slider-range {
                        margin-left: 17px;
                        margin-right: 17px;
                    }

                    .slider-range-labels {
                        position: relative;
                        margin: 33px auto 0;
                        font-size: 0;
                        margin-left: 50%;
                        transform: translateX(-50%);

                        .slider-range-label {
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 500;
                            color: $bon-jour;
                            text-align: center;
                            display: inline-block;

                            &.active,
                            &.single-active {
                                color: $white;
                                text-align: center;

                                .circle {
                                    background-color: $black;
                                    border-radius: 50%;
                                    height: 24px;
                                    color: $white;
                                    display: inline-block;
                                    text-align: center;
                                    width: 24px;
                                }
                            }
                        }

                        .slider-range-label.active ~ .slider-range-label {
                            color: $white;
                            text-align: center;

                            .circle {
                                background-color: $black;
                                border-radius: 50%;
                                height: 24px;
                                color: $white;
                                display: inline-block;
                                text-align: center;
                                width: 24px;
                            }
                        }

                        .slider-range-label.active ~ .slider-range-label.active ~ .slider-range-label {
                            color: $bon-jour;

                            .circle {
                                background-color: transparent;
                                color: $bon-jour;
                            }
                        }
                    }

                    .ui-slider-handle {
                        &.single-active {
                            margin-left: -17px;
                        }

                        &.top {
                            z-index: 3;
                        }

                        &.hide {
                            visibility: hidden;
                        }
                    }

                    .single-active {
                        .ui-slider-handle {
                            &.top + .ui-slider-handle {
                                z-index: 4;
                            }
                        }
                    }
                }
            }

            .product-add-to-cart {
                position: relative;

                .cart-button-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    #add-to-cart {
                        margin: 0;
                        padding: 13px 24px;
                        max-width: none;
                        width: 100%;
                        font-size: 15px;
                        letter-spacing: -0.07px;
                        text-align: left;
                        text-transform: none;
                        border: none;
                        background: $color-secondary url("../images/icons/arrow-right-white.svg") no-repeat right 20px center;
                        background-size: 12px 12px;

                        @include media-breakpoint-down(md) {
                            padding: 13px 19px;
                        }

                        &:hover,
                        &:focus {
                            background-color: $shiraz;
                            border-color: $shiraz;
                            color: $white;
                        }

                        &.button-inactive {
                            opacity: 0.5;
                        }

                        &[value="Add to Cart"] {
                            text-align: center;
                            background-image: none;
                        }
                    }

                    .inventory {
                        width: 80px;

                        + #add-to-cart {
                            margin-top: 0;
                            width: calc(100% - 100px);
                        }
                    }
                }

                .next-button-message {
                    display: flex;
                    padding: 0;
                    margin: 16px 0 0;
                    width: 55%;
                    @include media-breakpoint-up(lg) {
                        padding: 0 8px;
                        width: calc(100% - 64px);
                    }

                    .next-button-message-inner {
                        display: inline;
                        .content-asset {
                            display: inline;
                            line-height: 16px;
                        }

                        .tooltip-icon {
                            &::before {
                                margin-left: 4px;
                                transform: translateY(-1px);
                            }
                        }
                    }
                }
                .club-lead-time-message-container,
                .configurator-error-message {
                    padding: 0;
                    width: 55%;
                    color: $color-secondary;
                    margin-top: 16px;

                    @include media-breakpoint-up(lg) {
                        padding: 0 8px;
                        width: calc(100% - 100px);
                    }
                    span {
                        display: block;
                        margin-bottom: 12px;
                    }
                }
                .configurator-error-message,
                .club-lead-time-message {
                    display: none;
                }
                .reset-form {
                    position: absolute;
                    top: 63px;
                    right: 0;
                    color: $black;
                    font-size: 13px;
                    font-weight: bold;
                    text-decoration: underline;
                    @include media-breakpoint-up(lg) {
                        right: 58px;
                    }
                }
            }

            .tooltip-icon {
                &::before {
                    content: '!';
                    width: 14px;
                    height: 14px;
                    font-size: 8px;
                    line-height: 12px;
                    margin-left: 8px;
                    color: $black;
                    border-color: $black;
                }
            }
        }
    }

    .top-content-container {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-direction: column-reverse;
        }
    }

    .tagline-message {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        position: relative;
        text-align: right;
        @include media-breakpoint-up(lg) {
            padding: 100px 0;
        }
        .message-wrapper {
            max-width: 1242px;
            margin: 0 auto;
            padding: 0 32px;
            text-align: left;
            @include media-breakpoint-up(lg) {
                padding: 0 50px;
            }
            .text-content {
                @include media-breakpoint-up(lg) {
                    width: 33%;
                    margin: 50px 0 150px;
                }
                > span {
                    display: block;
                    line-height: normal;
                    &.tagline-header {
                        margin-bottom: 12px;
                        font-weight: bold;
                        letter-spacing: .12rem;
                        text-transform: uppercase;
                        color: $color-secondary;
                    }
                    &.tagline-subheader {
                        margin-bottom: 36px;
                        font-size: 30px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    &.tagline-subcopy {
                        font-size: 14px;
                        line-height: 1.75em;
                    }
                }
            }
        }
        .bg-image {
            display: inline-block;
            width: calc(100% - 32px);
            margin-bottom: 46px;
            @include media-breakpoint-up(lg) {
                position: absolute;
                right: 0;
                top: 100px;
                width: auto;
                max-width: 60%;
                margin-bottom: 0;
            }
        }
    }

    .pdp-content-wrapper {
        max-width: 1242px;
        width: 100%;
        margin: 0 auto;
        @include media-breakpoint-up(lg) {
            padding: 0 50px;
        }
    }

    .product-highlights {
        margin: 0 auto 120px auto;
        .highlights-section-header {
            display: block;
            max-width: 1242px;
            padding: 0 32px;
            margin: 0 auto 12px auto;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                padding: 0 50px;
            }
        }
        .highlights-carousel {
            .slick-list {
                margin: 0;
                overflow: hidden;
                @include media-breakpoint-up(lg) {
                    margin: 0 -10px;
                    padding: 0 50px !important;
                }
                @include media-breakpoint-up(xl) {
                    padding: 0 calc((100% - 1140px) / 2) !important;
                }
            }
            .slick-slide {
                visibility: visible;
            }
            .slick-arrow {
                width: 32px;
                height: 60px;
                margin: 0;
                top: calc(50vw - 30px);
                border-radius: 2px;
                opacity: 1;
                box-shadow: 0 10px 10px rgba(0,0,0,.15);
                display: block !important;
                background-color: $white !important;
                @include media-breakpoint-up(lg) {
                    top: 40%;
                    width: 60px;
                }
                &.slick-disabled {
                    display: none !important;
                }
                &.slick-next {
                    left: auto;
                    right: 0;
                    @include media-breakpoint-up(lg) {
                        right: 10px;
                    }
                    @include media-breakpoint-up(xl) {
                        right: calc((100% - 1140px) / 2 - 40px);
                    }
                }
                &.slick-prev {
                    left: 0;
                    right: auto;
                    @include media-breakpoint-up(lg) {
                        left: 10px;
                    }
                    @include media-breakpoint-up(xl) {
                        left: calc((100% - 1140px) / 2 - 40px);
                    }
                }
                &::after,
                &::before {
                    margin: 0;
                    font-size: 16px;
                    font-weight: bold;
                    color: $gray-warm-old;
                }
            }
        }
        .highlight-item {
            @include media-breakpoint-up(lg) {
                padding: 0 10px;
            }
            &:focus {
                outline: none;
            }
            .image-container {
                margin-bottom: 25px;
                position: relative;
                overflow: hidden;
                height: calc(100vw - 17px);
                @include media-breakpoint-up(lg) {
                    height: calc(40vw - 17px);
                }
                @include media-breakpoint-up(xl) {
                    height: 556px;
                }

                &.preview-image {
                    .button-play-video {
                        position: absolute;
                        bottom: 20px;
                        left: 20px;
                        border: 1px solid $color-secondary;
                    }
                }
                video {
                    position: absolute;
                    height: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
                img {
                    display: block;
                    width: 100%;
                }
            }
            .text-container {
                padding: 0 32px;
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
                > span {
                    display: block;
                }
                .item-header {
                    margin-bottom: 11px;
                    font-size: 16px;
                    font-weight: 600;
                }
                .item-copy {
                    font-size: 14px;
                }
            }
        }
    }

    // Specifications section
    .product-spec {
        margin-bottom: 40px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
            border-bottom: $base-border;
        }


        // Specification tabs
        .product-spec-tabs {
            border-bottom: $base-border;
            .spec-tab-title {
                margin: 0 32px 20px;
                font-size: 16px;
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    margin: 25px 0;
                }
            }
            .spec-tab-items {
                padding: 0;
                margin: 0;
                list-style: none;
                text-align: center;
                border-top: $base-border;
                @include media-breakpoint-up(lg) {
                    border: none;
                }
                .spec-tab-item {
                    position: relative;
                    display: inline-block;
                    padding: 0 20px;
                    margin: 0 -2px;
                    &.active::after {
                        content: '';
                        width: 100%;
                        height: 4px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: $color-secondary;
                    }
                    a {
                        display: inline-block;
                        padding: 26px 0;
                        font-size: 15px;
                        font-weight: bold;
                        line-height: normal;
                        color: black;
                        text-decoration: none;
                        border: none;
                    }
                }
            }
        }

        // Specifications tabs content
        .spec-tab-content {
            display: none;
            &.active {
                display: block;
            }
            @include media-breakpoint-up(lg) {
                box-sizing: border-box;
            }

            .spec-section-header {
                margin-top: 40px;
                margin-bottom: 25px;
                padding: 0 0 14px 18px;
                font-size: 14px;
                text-transform: uppercase;
                border-bottom: $base-border;
                line-height: normal;
                @include media-breakpoint-up(lg) {
                    margin-top: 70px;
                    margin-bottom: 40px;
                }
            }

            // Club Head tab
            &.club-head-tab {
                @include media-breakpoint-up(lg) {
                    margin-bottom: 126px;
                }
                .spec-table-container {
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-up(lg) {
                        justify-content: space-between;
                        flex-direction: row;
                    }
                    .spec-table {
                        min-width: 0;
                        @include media-breakpoint-up(lg) {
                            width: 42%;
                            margin-bottom: 30px;
                        }
                        &.table-left {
                            tr:nth-child(odd) {
                                background-color: #fafafa;
                                @include media-breakpoint-up(lg) {
                                    background-color: $white;
                                }
                            }
                            tr:nth-child(even) {
                                background-color: $white;
                                @include media-breakpoint-up(lg) {
                                    background-color: #fafafa;
                                }
                            }
                        }
                        &.table-right {
                            margin-bottom: 20px;
                            @include media-breakpoint-up(lg) {
                                margin-bottom: 30px;
                            }
                        }
                        td {
                            text-align: right;
                        }
                    }
                }
                .spec-table-footnote {
                    padding: 0 32px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $color-secondary;
                    @include media-breakpoint-up(lg) {
                        padding: 0;
                    }
                }
            }

            // Select & Grip tab
            &.shaftgrip-tab {
                @include media-breakpoint-up(lg) {
                    padding-bottom: 96px;
                }
            }

            // Custom tab
            &.custom-tab {

                .spec-text-container {
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-up(lg) {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
                .spec-text-content {
                    padding: 0 32px;
                    @include media-breakpoint-up(lg) {
                        margin-top: 80px;
                        margin-bottom: 80px;
                        padding: 0 15px;
                    }
                    &.left-section {
                        margin-bottom: 76px;
                        order: 1;
                        @include media-breakpoint-up(lg) {
                            width: 25.5%;
                            margin-bottom: 0;
                            padding: 0 32px 0 0;
                            order: 0;
                        }
                    }
                    &.right-section {
                        order: 2;
                        @include media-breakpoint-up(lg) {
                            width: 40.5%;
                            padding: 0 0 0 98px;
                        }
                    }
                    .spec-header {
                        margin-bottom: 12px;
                        &.spes-header-primary {
                            color: $color-secondary;
                        }
                    }
                    .spec-subheader {
                        margin-bottom: 28px;
                        font-size: 30px;
                        line-height: normal;
                    }
                    .spec-text-content-item:not(:last-child) {
                        padding-bottom: 22px;
                        margin-bottom: 34px;
                        border-bottom: $base-border;
                    }
                }

                .spec-image-container {
                    margin-bottom: 34px;
                    order: 0;
                    @include media-breakpoint-up(lg) {
                        width: 34%;
                        margin-bottom: 0;
                        order: 1;
                    }
                    .spec-image-wrapper {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                        img {
                            display: block;
                            max-width: none;
                            width: 100%;
                            @include media-breakpoint-up(lg) {
                                width: auto;
                                height: 100%;
                                max-width: none;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            table {
                min-width: 0;
                tr {
                    th,
                    td {
                        padding: 20px 18px;
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }
            sup {
                font-size: 60%;
                top: -.75em;
                &.table-item-badge {
                    color: $black;
                }
            }

        }

        .shaftgrip-items {
            display: flex;
            align-items: flex-end;
            margin: 0;
            overflow-x: auto;
            @include media-breakpoint-up(lg) {
                display: block;
                margin: 0 -10px;
                overflow-x: visible;
            }
            .shaftgrip-item {
                display: inline-block;
                width: 80%;
                padding: 0 10px;
                margin: 0 -2px 28px -2px;
                @include media-breakpoint-up(lg) {
                    width: 25%;
                    overflow: hidden;
                }
                &:first-child {
                    padding-left: 32px;
                    @include media-breakpoint-up(lg) {
                        padding-left: 10px;
                    }
                }
                &:last-child {
                    padding-right: 32px;
                    @include media-breakpoint-up(lg) {
                        padding-right: 10px;
                    }
                }
                .item-badge {
                    display: inline-block;
                    margin-bottom: 28px;
                    padding: 2px 8px;
                    color: $white;
                    background: $black;
                    line-height: normal;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .item-image {
                    line-height: 0;
                    img {
                        width: 100%;
                        margin-bottom: 32px;
                    }
                }
                .item-title {
                    margin-bottom: 12px;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: 0;
                    text-transform: none;
                }
                table {
                    th,
                    td {
                        font-size: 12px;
                        padding: 8px;
                        text-align: center;
                        white-space: nowrap;
                        &:first-child {
                            text-align: left;
                        }
                    }
                }
            }
        }

    }

    // Large Video Section
    .large-video {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto 40px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }
        .image-container {
            position: relative;
            .button-play-video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .cta-button {
        display: inline-block;
        padding: 11px 12px;
        margin: 0 18px 18px 0;
        font-size: 15px;
        font-weight: bold;
        line-height: normal;
        color: $black;
        text-decoration: none;
        background-color: transparent;
        border: $base-border;
        border-radius: 2px;
        &.cta-button-secondary {
            border-color: $color-secondary;
            &:hover {
                @include media-breakpoint-up(lg) {
                    border-color: $color-secondary;
                    background-color: $color-secondary;
                }
            }
        }
        &:hover {
            @include media-breakpoint-up(lg) {
                color: $white;
                border-color: $black;
                background-color: $black;
            }
        }
    }
    .button-play-video {
        padding: 13px 14px 13px 45px;
        font-size: 15px;
        font-weight: bold;
        line-height: normal;
        color: white;
        border: 1px solid $color-secondary;
        border-radius: 2px;
        background: url(../images/icons/icon-play-video.svg) left 15px center no-repeat;
        background-size: 14px 16px;
        &:hover {
            color: $white;
            background-color: $color-secondary;
            border: 1px solid $color-secondary;
        }
    }

    select {
        position: relative;
        height: 42px;
    }

    .loader {
        .loader-indicator {
            transform: translate(-50%, -50%);
            width: 200px;
            height: 40px;
            background: url(../images/infinite-loading-indicator.gif) no-repeat center;
            background-size: 100%;
        }
    }
}

.highlight-video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $black;
    z-index: 9999;
    width: calc(100% - 32px);
    @include media-breakpoint-up(lg) {
        width: 640px;
    }
    .highlight-video-player {
        width: 100%;
    }
    .close-video-player {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        font-size: 32px;
        line-height: 50px;
        text-align: center;
        text-decoration: none;
        color: $white;
        z-index: 9999;
   }
}

.modal-open.titleist-modal-fadein {
    .modal-backdrop {
        background: transparent;
    }
}

.club-product-pdp-content-assets .product-content {
    box-sizing: border-box;
    overflow-x: hidden;

    *, *:before, *:after {
        box-sizing: content-box;
    }
    .titleist-modal {
        background-color: rgba(0, 0, 0, 0);
        transition: background 0.3s ease-in;

        &.in {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    .tab-controls {
        a {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

body .ui-tooltip.ui-widget-content {
	box-shadow: 0 3px 5px rgba(15, 15, 15, 0.26);
	border-radius: 2px;
	background-color: #f6f6f6;

	.ui-tooltip-content {
        font-weight: 400;
        font-size: rem(10.5px);
        color: $white;
        line-height: normal;

        @include media-breakpoint-up(lg) {
			padding: 35px 32px;
		}

		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}

#pdpMain {
    .mobile-breadcrumb {
        .p1-content-disable {
            .breadcrumb {
                margin-bottom: 20px;
            }
        }

        .breadcrumb {
            display: block;
            text-align: center;
            padding-bottom: 16px;
            border-bottom: $base-border;
            margin-bottom: 0;
            margin-left: 0;
            margin-top: 16px;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            .breadcrumb-element {
                font-size: 11px;
                font-weight: 600;
                color: black;
                text-transform: uppercase;
                &::after {
                    color: $color-secondary;
                }
            }
        }
    }

    .product-core-info {
        margin-top: 20px;

        .mobile-product-info {
            &.no-comm {
                .product-price,
                .promotion {
                    display: none;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            display: flex;
            align-items: flex-start;
        }

        .breadcrumb {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .product-col-1 {
            width: 100%;
            max-width: none;
            margin-left: 0;
            margin-top: 0;
            @include media-breakpoint-up(lg) {
                width: 51%;
                max-width: none;
                flex: 1 1 auto;
            }
            @include custom-media-breakpoint-up(xl) {
                width: 51%;
            }
        }
        .product-col-2 {
            width: 100%;
            @include media-breakpoint-up(lg) {
                flex: 0 0 auto;
                max-width: 475px;
                width: 49%;
                padding-top: 30px;
                padding-right: 0;
                padding-left: 0;
                margin-right: 0;
            }
            @include custom-media-breakpoint-up(xl) {
                max-width: 614px;
                width: 49%;
            }
        }
        .breadcrumb {
            margin: 16px 0 0 20px;
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
            .breadcrumb-element {
                font-size: 11px;
                font-weight: 600;
                color: black;
                text-transform: uppercase;
                &::after {
                    color: $color-secondary;
                }
            }
        }
        .product-image-container-outer {
            position: relative;
        }
        .product-image-container {
            position: relative;

            &.loading {
                opacity: 0.3;
            }

            .product-primary-image {
                display: block;
                width: 100%;
                max-width: 100%;
                text-align: center;
                position: relative;
                float: none;
                .regular.slider {
                    margin-bottom: 3px;
                    background-color: $img-gray;
                    @include media-breakpoint-up(lg) {
                        max-height: calc(100vh - 80px);
                        margin-bottom: 7px;
                    }
                    @include media-breakpoint-up(xl) {
                        max-height: calc(100vh - 260px);
                    }
                    .slick-active {
                        &:focus {
                            outline: none;
                        }
                    }
                    .thumb {
                        a {
                            display: block;
                        }
                        img,
                        video {
                            display: block;
                            width: auto;
                            max-width: 100%;
                            margin: 0 auto;
                            @include media-breakpoint-up(lg) {
                                max-height: calc(100vh - 80px);
                            }
                            @include media-breakpoint-up(xl) {
                                max-height: calc(100vh - 260px);
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        &.grip-img,
                        &.shaft-img {
                            .product-image {
                                display: flex;
                                height: 100%;
                            }

                            .primary-image {
                                width: 100%;
                                height: auto;
                                margin: auto;
                            }
                        }
                    }
                }
                .slick-arrow {
                    width: 32px;
                    height: 32px;
                    top: 50%;
                    transform: translateY(-50%);
                    &.slick-prev {
                        background: url(../images/icons/arrow-left-black.svg) center no-repeat;
                        background-size: 24px 24px;
                    }
                    &.slick-next {
                        background: url(../images/icons/arrow-right-black.svg) center no-repeat;
                        background-size: 24px 24px;
                    }
                    &.slick-disabled {
                        opacity: 0.5;
                    }
                }
            }
            .product-thumbnails {
                display: block;
                width: auto;
                padding: 0;
                margin: 0;
                max-width: 820px;
                overflow: hidden;
                @include media-breakpoint-up(lg) {
                    padding: 0 32px;
                    margin: 0 auto;
                    width: 100%;
                }
                .carousel {
                    height: auto;
                    position: relative;
                    overflow: visible;
                    margin: 0 -2px;
                    .slick-list {
                        padding-right: 40px;
                        overflow: hidden;
                        @include media-breakpoint-up(lg) {
                            padding-right: 0;
                        }
                    }
                    .slick-track {
                        display: inline-block;
                    }
                }
                .thumb {
                    line-height: 0;
                    padding: 0 2px;
                    @include media-breakpoint-up(lg) {
                        padding: 0 3px;
                    }
                    &.slick-slide {
                        &.selected {
                            .thumbnail-link {
                                position: relative;
                                &::after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    display: block;
                                    width: 100%;
                                    height: 4px;
                                    background-color: $color-secondary;
                                }
                            }

                            img {
                                border: none;
                            }
                        }
                        a {
                            display: inline-block;
                            position: relative;
                            width: 100%;
                            height: 65px;
                            background-color: $img-gray;
                            @include media-breakpoint-up(xl) {
                                height: 118px;
                            }
                            img {
                                height: 100%;
                                width: auto;
                                max-width: 100%;
                                margin: 0 auto;
                                padding: 0;
                                &.video-thumb-icon {
                                    width: 12px;
                                    height: 14px;
                                    position: absolute;
                                    left: 14px;
                                    bottom: 14px;
                                }
                            }
                        }

                        .thumbnail-link {
                            cursor: pointer;
                        }
                    }

                    &.grip-img,
                    &.shaft-img {
                        .thumbnail-link {
                            display: flex;
                        }

                        .productthumbnail {
                            width: 100%;
                            height: auto;
                            margin: auto;
                        }
                    }
                }
                .slick-arrow {
                    display: none !important;
                    background: url(../images/icons/arrow-down-black.svg) center no-repeat;
                    background-size: 24px 24px;
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    @include media-breakpoint-up(lg) {
                        display: block !important;
                    }
                    &.slick-prev {
                        @include media-breakpoint-up(lg) {
                            left: -32px;
                        }

                    }
                    &.slick-next {
                        @include media-breakpoint-up(lg) {
                            right: -32px;
                        }
                    }
                    &.slick-disabled {
                        opacity: 0.5;
                    }
                }
            }
        }

        .desktop-product-info {
            @include media-breakpoint-up(lg) {
                max-width: 475px;
                margin: 0 auto;
            }
        }

        .clubs-tab-containers,
        .product-add-to-cart {
            margin: 0 20px;
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
        .product-main-info {
            margin: 0 30px 18px;
            @include media-breakpoint-up(lg) {
                margin: 0 50px 18px;
            }
        }
        .clubs-tab-containers {
            min-height: auto;
            @include media-breakpoint-up(lg) {
                max-width: 475px;
                margin: 0 auto;
            }
        }
        .badge-wrapper {
            margin-bottom: 15px;
        }
        .product-name {
            margin: 8px 0;
        }
        .product-price {
            position: static;
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 500;
            span {
                font-size: 16px;
                font-weight: 500;
                &.price-standard {
                    margin-right: 8px;
                }
            }
        }
        .promotion {
            padding: 0;
            margin-bottom: 8px;
            .promotion-callout {
                color: $red;
            }
        }
    }

    .pdp-p1-module {
        position: relative;

        &.p1-content-disable {
            display: none; // Hide the p1 header content until it becomes sticky

            &.sticky-child {
                display: block;
            }

            .p1-header.sticky {
                display: flex;
            }
        }

        .p1-header {
            display: none;
            position: absolute;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px 70px;
            color: $white;
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            z-index: 1;
            @include media-breakpoint-up(lg) {
                display: flex;
            }
            &.sticky {
                height: 60px;
                display: flex;
                padding: 0;
                position: fixed;
                bottom: 0;
                left: 0;
                color: $black;
                background: $white;
                box-shadow: 0 -5px 5px rgba(0, 0, 0, .15);
                z-index: 1001;
                @include media-breakpoint-up(lg) {
                    bottom: auto;
                    top: 0;
                    padding: 0 30px;
                    box-shadow: 0 5px 5px rgba(0, 0, 0, .15);
                }
                .p1-header-left {
                    .subcategory-name {
                        border-color: $black;
                    }
                    .prod-name {
                        color: $black;
                        background: transparent url("../images/icons/arrow-down-black.svg") no-repeat right 8px center;
                        background-size: 12px 12px;
                    }
                    .product-name-image {
                        &.product-name-image-sticky {
                            display: none;

                            @include media-breakpoint-up(lg) {
                                display: block;
                            }
                        }
                    }
                    .product-name-image-sticky + .product-name-image {
                        display: none;
                    }
                    @include media-breakpoint-down(xs) {
                        .prod-name-mobile {
                            padding: 0 rem(8px);
                            font-size: 13px;
                        }
                    }
                }
                .p1-header-center {
                    display: block;
                }
                .p1-header-right {
                    .customize-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-height: 36px;

                        &.btn-not-available {
                            background-color: $black;
                            border-color: $black;
                            color: $white;

                            &:hover,
                            &:focus {
                                background-color: $black;
                                border-color: $black;
                                color: $white;
                            }
                        }

                        span {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 13px;
                            height: rem(40px);
                        }

                        &.customize-button-club {
                            @include respond(200px, 460px) {
                                padding-right: 0;
                                padding-left: 0;
                            }
                        }
                    }
                    .pricing-section.no-price {
                        @include media-breakpoint-up(lg) {
                            display: none;
                        }
                    }
                }
            }
            > div {
                width: calc(100% / 3);
                height: 100%;
                @include media-breakpoint-up(lg) {
                    width: auto;
                    height: auto;
                }
            }
            .p1-header-left {
                display: flex;
                align-items: center;
                .product-name-image {
                    display: none;
                    padding-right: 12px;
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }

                    &.product-name-image-sticky {
                        display: none;
                    }

                    img {
                        display: block;
                        height: 40px;
                    }
                }
                .subcategory-name {
                    display: none;
                    padding-right: 12px;
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    border-right: 1px solid $white;
                    white-space: nowrap;
                    @include media-breakpoint-up(lg) {
                        display: inline-block;
                    }
                }
                .prod-name {
                    display: none;
                    padding: 0 30px 0 12px;
                    font-size: 14px;
                    font-weight: bold;
                    color: $white;
                    cursor: pointer;
                    border: none;
                    box-shadow: none;
                    background: transparent url("../images/icons/arrow-down-white.svg") no-repeat right 8px center;
                    background-size: 12px 12px;
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                    option {
                        color: $black;
                        font-size: 13px;
                    }
                }
                .prod-name-mobile {
                    padding: 0 20px 0 16px;
                    font-size: 15px;
                    font-weight: 600;
                    position: relative;
                    max-height: rem(53px);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            .p1-header-center {
                display: none;
                border-left: $base-border;
                text-align: center;
                @include media-breakpoint-up(lg) {
                    border: none;
                }
                .header-anchors-mobile {
                    width: auto;
                    max-width: 100%;
                    height: 60px;
                    border: none;
                    font-size: 15px;
                    font-weight: 600;
                    color: $black;
                    box-shadow: none;
                    background: transparent url(../images/icons/arrow-down-black.svg) no-repeat right 8px center;
                    background-size: 12px 12px;
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 13px;
                        min-width: rem(118px);
                        height: rem(50px);
                        padding-right: 1.8em;
                    }
                }
                .header-anchors {
                    display: none;
                    @include media-breakpoint-up(lg) {
                        display: flex;
                        align-items: center;
                    }
                    .header-anchor {
                        margin: 0 20px;
                        padding: 17px 0;
                        position: relative;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        &:hover::after,
                        &.active::after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            background-color: $color-secondary;
                        }
                        a {
                            font-size: 15px;
                            font-weight: 600;
                            line-height: normal;
                            color: $black;
                            text-decoration: none;
                            &:hover {
                                @include media-breakpoint-up(lg) {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
                .section-unavailable {
                    display: none;
                }
            }
            .p1-header-right {
                display: flex;
                align-items: center;
                .pricing-section {
                    display: none;
                    flex-direction: column;
                    text-align: right;
                    @include media-breakpoint-up(lg) {
                        display: flex;
                        &.no-price,
                        &.no-comm {
                            display: none;
                        }
                    }
                    .lowest-price {
                        span {
                            font-size: 13px;
                            font-weight: bold;
                        }
                    }
                }
                .customize-button {
                    display: none;
                    margin: 0;
                    font-size: 15px;
                    text-transform: capitalize;
                    width: 100%;
                    height: 100%;
                    @include media-breakpoint-up(lg) {
                        margin: 0 0 0 24px;
                        width: auto;
                        height: auto;
                        line-height: normal;
                    }
                }
            }
        }
        .p1-content {
            img {
                display: block;
                width: 100%;
            }
            video {
                width: 100%;
            }
        }
    }

	.product-content {
		.club-pdp-compare * {
			box-sizing: border-box;
		}
        .product-content-item,
        .club-pdp-compare {
            position: relative;
        }
	}

    .club-pdp-compare {
		color: $black;
        max-width: 1140px;
        margin: 0 auto 30px auto;
		line-height: initial;
        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
        }
        .compare-tables {
            display: flex;
            justify-content: center;
            .compare-table {
                width: 50%;
                padding: 0 11px;
                @include media-breakpoint-up(lg) {
                    padding: 0 65px;
                    max-width: 549px;
                }
                &:first-child {
                    @include media-breakpoint-up(lg) {
                        border-right: $base-border;
                    }
                }
            }
            .compare-product-image {
                margin-bottom: 50px;
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 35px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: transparent;
                    @include media-breakpoint-up(lg) {
                        height: 70px;
                    }
                }
                img {
                    display: block;
                    width: 70%;
                    margin: 0 auto;
                }
            }
            .compare-prod-selector {
                margin-bottom: 46px;
				box-sizing: border-box;
    			width: 100%;
				padding: 0.5em 2.5em 0.5em 1em;
				@include font-regular(14px);
				color: $primary-color;
				position: relative;
				line-height: 1.15;
    			height: 42px;
            }
            .compare-product-copy {
                display: block;
                padding: 0 20%;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                text-transform: uppercase;
            }
            .compare-row {
                padding: 0 0 24px 0;
                margin-bottom: 24px;
                border-bottom: $base-border;
				box-sizing: border-box;
                @include media-breakpoint-down(sm) {
                    padding-left: 10px;
                }
                .compare-attribute-title {
                    display: block;
                    margin-bottom: 24px;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .compare-attribute-value {
                    display: block;
                    font-size: 16px;
                    &.graphical {
                        display: flex;
                        flex-direction: column;
                        @include media-breakpoint-up(lg) {
                            flex-direction: row;
                        }
                        .graph-value-item {
                            position: relative;
                            display: block;
                            padding-left: 144px;
                            margin-bottom: 10px;
                            @include media-breakpoint-down(sm) {
                                width: auto;
                                padding-left: 55px;
                                font-size: 14px;
                            }
                            @include media-breakpoint-up(lg) {
                                width: 127px;
                                height: auto;
                                margin-right: 20px;
                                margin-bottom: 0;
                                padding-top: 30px;
                                padding-left: 0;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                            &.selected::after {
                                background-color: $color-secondary;
                            }
                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 66px;
                                height: 20px;
                                background-color: $color-gray5;
                                width: 127px;
                                @include media-breakpoint-down(sm) {
                                    width: 50px;
                                }
                                @include media-breakpoint-up(lg) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &.table {
                        margin: 0 -20px;
                        @include media-breakpoint-up(lg) {
                            margin: 0;
                        }
                    }
                    table {
                        font-size: 14px;
						line-height: initial;
						min-width: initial;
                        tr:nth-child(even) {
                            background-color: $gray-white;
                        }
                        th {
                            padding: 8px 0 8px 20px;
                            @include media-breakpoint-up(lg) {
                                padding: 19px 0 19px 20px;
                            }
                        }
                        td {
                            padding: 8px 20px 8px 0;
                            text-align: right;
                            @include media-breakpoint-up(lg) {
                                padding: 19px 20px 19px 0;
                            }
                        }
                    }
                }
            }
            .best-for {
                padding-left: 20px;
                span {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
				p {
					color: $black;
					line-height: 1.6em;
					margin: 1em 0;
				}
            }

            .best-for + .shop-now-row.shop-now-row--border {
                margin-left: 0;
                padding-top: 24px;
                border-top: 1px solid #e2dede;
            }

            .compare-row + .shop-now-row.shop-now-row--border {
                padding-top: 4px;
            }

            .shop-now-row {
                margin-top: 20px;
                margin-left: -20px;

                &.shop-now-row--border {
                    margin-left: 0;
                    padding-top: 24px;
                }

                .button {
                    background: $crimson;
                    color: $white;
                    width: 150px;
                    padding: 10px;
                    line-height: normal;

                    &:hover {
                        color: $white;
                    }

                    &::before,
                    &::after {
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

    .mobile-product-info {
        .ball-logo {
            display: block;
            margin: 8px 0;
            height: 1.5625rem;
        }
    }
    .club-pdp-recommendations {
        @include media-breakpoint-up(lg) {
            border-top: $base-border;
        }
    }
    &.pdp-tag-customizer {
        .product-core-info {
            .product-image-container {
                .product-primary-image {
                    margin-bottom: 0;
                    padding-bottom: 30px;
                }
            }
        }
    }

    .einstein-pdp-recommendations2 {
        .club-pdp-recommendations {
            @include media-breakpoint-up(lg) {
                border-bottom: $base-border;
                border-top: none;
                padding-bottom: 55px;
            }

            .slick-next {
                &[aria-disabled=false] {
                    display: block !important;
                }
                left: auto;

                &::after {
                    content: '';
                }
            }

            .slick-prev {
                &[aria-disabled=false] {
                    display: block !important;
                }

                &::before {
                    content: '';
                }
            }
        }
    }

    .no-compare {
        .einstein-pdp-recommendations2 {
            .club-pdp-recommendations {
                border: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
}

.cart-empty .club-pdp-recommendations {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.club-pdp-recommendations {
    width: 100%;
    padding-top: 24px;
    margin: 0 auto 120px auto;
    clear: both;

    @include media-breakpoint-up(lg) {
        padding-top: 55px;
    }

    * {
        box-sizing: border-box !important;
    }

    .tiles-container {
        padding: 0;

        &.slider-small {
            ul.slick-dots {
                @include respond(largeUp) {
                    display: none !important;
                }
            }
        }

        .slick-list {
            width: calc(100% + 10px);
            margin: 0 0 0 -10px;
            padding: 0 47.75% 0 0 !important;
            @include respond(largeUp) {
                padding: 0 34px 20px 14px !important;
                margin: 0;
                width: 100%;
            }
            @media screen and (min-width: 1024px) {
                padding: 0 36px 0 16px !important;
            }
            @media screen and (min-width: 1025px) {
                padding: 0 75px 0 55px !important;
                margin: 0;
                width: 100%;
            }
            .slick-track {
                margin: 0 auto;
            }
        }
        .slick-dots {
            position: relative;
            width: 37%;
            text-align: center;
            padding: 0!important;
            height: 5px;
            background: #f6f6f6;
            margin: 20px auto 0;
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;

            li {
                flex-grow: 1;
                margin: 0;
                padding: 0;
                button {
                    border: none !important;
                    width: 100% !important;
                    border-radius: 10px !important;
                    background-color: #f6f6f6 !important;
                    height: 5px !important;
                }
                &.slick-active button {
                    background-color: $red !important;
                }
            }
        }
        .slick-arrow {
            width: 32px;
            height: 60px;
            top: 45%;
            box-shadow: 0 10px 10px rgba(0, 0, 0, .15);
            transform: translateY(-50%);
            opacity: 1;
            @include media-breakpoint-up(lg) {
                width: 60px;
            }
            &:hover,
            &:focus {
                background-color: $white !important;
            }
            &.slick-prev {
                background: $white url(../images/icons/arrow-left-red.svg) center no-repeat;
                background-size: 24px 24px;
                left: 0;
                @include media-breakpoint-up(lg) {
                    left: calc(5% - 30px);
                }

            }
            &.slick-next {
                background: $white url(../images/icons/arrow-right-red.svg) center no-repeat;
                background-size: 24px 24px;
                right: 0;
                @include media-breakpoint-up(lg) {
                    right: calc(5% - 30px);
                }
            }
            &.slick-disabled {
                display: none !important;
            }
        }
        .grid-tile {
            margin: 0;
            padding: 0 0 0 20px;
            visibility: visible;

            .product-image {
                .thumb-link {
                    padding-bottom: 20.75%;
                    @include respond(largeUp) {
                        padding-bottom: 4.2%;
                    }
                    @media screen and (min-width: 1024px) {
                        padding-bottom: 7.1%;
                    }
                    @media screen and (min-width: 1025px) {
                        padding-bottom: 14.6%;
                    }
                }
            }
            .product-tile {
                max-height: none;
                .product-tile-details {
                    padding: 0;
                }
                .product-name {
                    display: flex;
                    padding-right: 60px;
                    line-height: 1.25rem;
                }
                .product-pricing {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

.product-content-headers {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 64px;
    }
    .product-content-header {
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $color-secondary;
        font-family: $sans-serif;
		line-height: 1.875rem;
        letter-spacing: .075em;
        font-weight: 400;
    }
    .product-content-subheader {
        margin-top: 0;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
        line-height: 1;
    }
}

.out-of-stock-message {
    position: relative;
    top: -10px;
    font-size: 12px;
    color: #E31837;
    padding: 0 0 8px 8px;
    display: none;

    &.out-of-stock-message-active {
        display: block;
    }
}

.customizer-unavailable-modal {
    display: none;
}
