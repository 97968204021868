/////////////////////////
// Checkout Summary Panel
/////////////////////////
#wrapper.pt_checkout {
	#main {
		#secondary {
			width: 30%;
			float: right;
			padding-top: 56px;

			@include respond(small) {
				width: 100%;
				padding-top: 36px;
			}

			.section-header {
				@include font-semibold();
				@include font-size(20px);
				padding: 11px 0;
				border-top: 1px solid $gray-light;
				border-bottom: 1px solid $gray-light;
				background: none;
				color: $gray-dark;

				@include respond(small) {
					font-size: 14px!important;
				}
				a {
					float: right;
					color: $crimson;
					@include font-size(14px);
					text-decoration: underline;
					text-transform: none;
					font-family: $titillium;
					&:hover{
						text-decoration: none;
					}
					@include respond(small) {
						@include font-size(12px);
					}
				}
			}
			.checkout-mini-cart {
				background: $white;
				max-height: rem(425px);

				@include respond(small) {
					max-height: none;
					overflow: visible;
				}

				.mini-cart-product {
					padding: 10px 0 23px;
				}

				.mini-cart-name,
				.mini-cart-attributes,
				.mini-cart-pricing {
					//padding-right: rem(10px);
					//box-sizing: border-box;
				}

                .mini-bag-attributes {
                    &.collapse {
                        max-height: none !important;
                    }

                    .label,
                    .value {
                        font-size: 12px;
                        color: $black;
                    }

                    .label {
                        font-weight: bold;
                        text-transform: capitalize;
                    }

                    .attribute {
                        padding-bottom: 3px;

                        &:last-of-type {
                            padding-bottom: 0;
                        }
                    }
                }

                .pli-wrapper {
                    padding-bottom: 5px;
                    .label,
                    .value {
                        font-size: 12px;
                        line-height: 14px;
                        color: $black;
                    }

                    .label {
                        font-weight: bold;
                    }
                }

                .mini-bag-pricing {
                    padding-top: 20px;
                }

                .club-section-display-inner {

                    .club-section-display {

                        .product-option {
                            .label,
                            .value {
                                font-size: 12px;
                                color: $black;
                            }
                        }
                    }

                    .club-section-display-global {
                        max-height: none;
                    }
                }

                .mini-bag-name-value {
                    font-size: 14px;
                    color: $black;
                    margin-bottom: 12.5px;
                    display: inline-block;
                }

                .mini-bag-image,
                .mini-bag-product-info{
                    padding-top: 1em;
                }

                .view-more,
                .view-less {
                    display: none;
                }

                .media-wrap {
                    margin-top: 0;
                }
            }

			.checkout-order-totals {
				border-top: 1px solid $gray-light;
				padding: 10px 0 rem(37px);
				background: none;

				@include respond(small) {
					padding-bottom: 5px;
				}

				.order-totals-table {
					tr{
						td{
							color: $gray-dark;
							@include font-size(12px);
							padding: 3px 0 2px;;

							@include respond(small) {
								padding: 2px 0;
							}

							&:first-child {
								text-align: left;
								@include font-bold();
								width: 60%;
							}
						}

						&.order-subtotal {
							td:last-child {
								@include font-bold();
							}
						}

						&.discount {
							td {
								color: $crimson;
								.discount-amt {
									white-space: nowrap;
								}
							}
						}

						&.order-sales-tax {
							td {
								padding-bottom: rem(13px);

								@include respond(small) {
									padding-bottom: rem(17px);
								}
							}
						}
						&.order-total {
							td {
								padding-top: rem(13px);
								border-top: 1px solid $gray-light;
								@include font-bold(14px);
							}
						}
					}
				}

				.order-summary-slot {
					margin-top: 2em;
				}
			}
		}

		#primary .summary{
			.summary-address{
				display: inline-flex;
				width: 100%;
				> div {
					width: 50%;
					display: inline-block;
				}
				.payment-instruments {
					.details {
						padding-bottom: 25px;
					}
					span.icon-PayPal {
						display: block;
						background: url("../images/paypal.jpg") no-repeat right center;
						width: 120px;
						height: 30px;
						background-size: 120px auto;
						padding: 10px 0;
					}
					.label {
						font-size: 13px;
						text-transform: uppercase;
						font-weight: bold;
					}
					.value {
						color: $gray-warm;
					}
				}
			}
			.address-static{
				color: $gray-warm;
				line-height: 22px;
				font-size: 14px;
				padding-bottom: 25px;
				padding-top: 10px;
			}
			#shipping-method-list{
				.form-field-tooltip{
					left: 350px;
					position: absolute;
					top: 0;
					margin: 7px 0 0 18%;
					padding-top: 0;

					@include respond(large) {
						margin-top: 0;
					}
					@include respond(small) {
						left: 0;
						position: relative;
						top: 0;
						text-align: right;
						padding-left: 0;
						width: 100%;
						text-align: right;
						margin: 0;
					}
					a{
						color: $gray-warm;
						font-size: 11px;
					}
				}
			}
		}
		#primary {
			.order-summary-footer {
				border-top: 1px solid $gray-nurse;
				.checkout-privacy-policy {
					margin-bottom: 24px;
				}
			}
		}
	}
}

@media print {
  .continue, footer {
     display: none;
   }
}
